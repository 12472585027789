import shortid from 'shortid'
/**
 * Return a Card object unlinked by default
 */
export default class EmptyCard {
  constructor(appId, tokenId = 0, unlinked = true, folderId) {
    this.appId = appId
    this.cardId = shortid()
    this.title = ''
    this.tokenId = tokenId
    this.updateTime = Date.now().toString()
    this.createdTime = Date.now().toString()
    this.unlinked = unlinked
    this.folders = folderId ? [folderId] : []
    this.parts = [{ type: '' }]
  }
}
