import PropTypes from 'prop-types'
import { useEffect, useState, useContext } from 'react'
import { useIntl } from 'react-intl'
import { PhotoboothEditForm, MainContainerContext } from 'containers'
import { BasicButton, BasicModal } from 'components'

import connectStore from 'redux-box/dist/connectStore'
import { storeModule as ModuleModules } from 'store/modules'
import { storeModule as AppModule } from 'store/app'

import { Icon } from 'react-icons-kit'
import { edit2 } from 'react-icons-kit/feather/edit2'
import { plus } from 'react-icons-kit/feather/plus'
import { lock } from 'react-icons-kit/feather/lock'

import { StyledButtonContainer } from './styles'
import messages from './messages'

const LockModal = ({ app, appId, id, modules, onSave, open, setOpen, toggleDisable, voxpopId }) => {
  const [photobooth, setPhotobooth] = useState()
  const [edit, setEdit] = useState(false)

  const context = useContext(MainContainerContext)

  const intl = useIntl()

  useEffect(() => {
    const data = modules.getVoxpopById(id)
    setPhotobooth(data)
  }, [id, modules.voxpop.list])

  const toggleOpen = () => {
    setOpen(!open)
    toggleDisable()
  }

  const toggleEdit = () => {
    setEdit(!edit)
    toggleDisable()
  }

  return (
    <>
      <StyledButtonContainer>
        {!!app.access.Photobooth ? (
          <BasicButton
            color="green"
            medium
            onClick={toggleOpen}
            label={intl.formatMessage(messages.create)}
            icon={<Icon icon={plus} />}
          />
        ) : (
          <BasicButton
            icon={<Icon icon={lock} />}
            onClick={() => context.toggleUpgradeModal()}
            label={intl.formatMessage(messages.create)}
            medium
            color="green"
          />
        )}

        {(id || voxpopId) && (
          <BasicButton
            color="blue"
            medium
            onClick={() => setEdit(!edit)}
            label={intl.formatMessage(messages.edit)}
            icon={<Icon icon={edit2} />}
          />
        )}
      </StyledButtonContainer>

      {open && (
        <BasicModal
          isOpen={open}
          toggle={toggleOpen}
          content={<PhotoboothEditForm onCancel={toggleOpen} onSave={onSave} appId={appId} isNew />}
          title={intl.formatMessage(messages.photobooths)}
        />
      )}

      {edit && (
        <BasicModal
          isOpen={edit}
          toggle={toggleEdit}
          content={
            <PhotoboothEditForm
              appId={appId}
              data={photobooth}
              onSave={toggleEdit}
              onCancel={toggleEdit}
              noDelete
            />
          }
          title={intl.formatMessage(messages.photobooths)}
        />
      )}
    </>
  )
}

LockModal.propTypes = {
  app: PropTypes.object,
  appId: PropTypes.string,
  id: PropTypes.string,
  locks: PropTypes.any,
  onChange: PropTypes.func,
  onSave: PropTypes.func,
  open: PropTypes.bool,
  modules: PropTypes.object.isRequired,
  setOpen: PropTypes.func,
  toggleDisable: PropTypes.func,
  voxpopId: PropTypes.string,
}

export default connectStore({ app: AppModule, modules: ModuleModules })(LockModal)
