import styled from 'styled-components'

export const StyledInput = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  margin-bottom: 10px;

  span {
    font-family: Nunito;
    font-weight: 600;
    font-size: 14px;
    margin-right: 10px;
  }

  button {
    padding: 4px 5px 3px 5px;
    margin-left: 5px;
  }
`
