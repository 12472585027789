/**
*
* DropdownButton
*
*/

import React from 'react'
import {
  ButtonDropdown,
  DropdownToggle,
  DropdownItem,
  DropdownMenu,
} from 'reactstrap'
// import PropTypes from 'prop-types'
// import styled from 'styled-components'


class DropdownButton extends React.PureComponent { // eslint-disable-line react/prefer-stateless-function
  constructor(props, ...rest) {
    super(props, ...rest)
    this.state = {
      btnOpen: false,
    }
  }
  render() {
    const { label, children } = this.props
    return (

      <ButtonDropdown
        style={{ display: 'block' }}
        color="primary"
        isOpen={this.state.btnOpen}
        toggle={() => { this.setState({ btnOpen: !this.state.btnOpen }) }}
      >
        <DropdownToggle caret>
          {label}
        </DropdownToggle>
        <DropdownMenu>
          {children.map((btn, i) => <DropdownItem {...btn.props} tag={btn.type} key={`btn-dropdown-${i}`}>{btn.props.children}</DropdownItem>)}
        </DropdownMenu>
      </ButtonDropdown>

    )
  }
}

DropdownButton.propTypes = {

}

export default DropdownButton
