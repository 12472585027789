import PropTypes from 'prop-types'
import msjToken from 'assets/svg/pastille-gris.svg'
import cardBorder from 'assets/img/emptycardbottom.png'
import styled from 'styled-components'

const StyledContainer = styled.div`
  position: relative;
  margin-top: 1rem;
  display: flex;
  flex-direction: row;

  @media (max-width: 768px) {
    flex-direction: column;
  }

  h2 {
    font-family: Nunito;
    font-weight: 700;

    @media (max-width: 768px) {
      margin-top: 2rem;
    }
  }

  p {
    max-width: 400px;
  }

  div:first-of-type {
    margin-bottom: 1rem;
    margin-right: 1rem;
    position: relative;
  }
`

const StyledEmptyCard = styled.div`
  width: 355px;
  min-width: 355px;
  height: 305px;
  min-height: 305px;
  background-color: #f8f8f8;
  border-radius: 8px;

  @media (max-width: 568px) {
    flex-direction: column;
  }
`

const StyledToken = styled.img`
  width: 74px;
  height: 74px;
  position: absolute;
  top: 18px;
  left: 18px;
`

const StyledCardBottom = styled.img`
  position: absolute;
  bottom: 0;
  left: 0px;
  width: 355px;
`

const EmptyPage = ({ title, text }) => (
  <StyledContainer>
    <div>
      <StyledEmptyCard />
      <StyledToken src={msjToken} alt="msj token" />
      <StyledCardBottom src={cardBorder} alt="card bottom border" />
    </div>

    <div>
      {title && <h2>{title}</h2>}
      {text && <p>{text}</p>}
    </div>
  </StyledContainer>
)

EmptyPage.propTypes = {
  title: PropTypes.string,
  text: PropTypes.string,
}

export default EmptyPage
