import PropTypes from 'prop-types'
import { CancelModal, DeleteModal, ResetModal } from 'components'

const CardEditModals = ({
  appId,
  cancelPage,
  cardId,
  deletePage,
  resetPage,
  toggleCancel,
  toggleDelete,
  toggleReset,
  onFormChange,
}) => {
  return (
    <>
      {cancelPage && <CancelModal open={cancelPage} toggle={toggleCancel} onFormChange={onFormChange} />}

      {deletePage && <DeleteModal appId={appId} cardId={cardId} open={deletePage} toggle={toggleDelete} />}

      {resetPage && <ResetModal appId={appId} cardId={cardId} open={resetPage} toggle={toggleReset} />}
    </>
  )
}

CardEditModals.propTypes = {
  appId: PropTypes.string,
  cancelPage: PropTypes.bool,
  cardId: PropTypes.string,
  deletePage: PropTypes.bool,
  resetPage: PropTypes.bool,
  toggleCancel: PropTypes.func,
  toggleDelete: PropTypes.func,
  toggleReset: PropTypes.func,
  onFormChange: PropTypes.func
}

export default CardEditModals
