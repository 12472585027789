import { defineMessages } from 'react-intl'

const scope = 'app.components.PartActions.LanguageLinkForm'

export default defineMessages({
  abbreviation: { id: `${scope}.abbreviation` },
  abbreviationError: { id: `${scope}.abbreviationError` },
  abbreviationHelp: { id: `${scope}.abbreviationHelp` },
  languageLinkEmoji: { id: `${scope}.languageLinkEmoji` },
  center: { id: `${scope}.center` },
  left: { id: `${scope}.left` },
  link: { id: `${scope}.link` },
  linkError: { id: `${scope}.linkError` },
  linkHelp: { id: `${scope}.linkHelp` },
  linkWarning: { id: `${scope}.linkWarning` },
  right: { id: `${scope}.right` },
  infos: { id: `${scope}.infos` },
  tutorial: { id: `${scope}.tutorial` },
  languageLinkPosition: { id: `${scope}.languageLinkPosition` },
  languageLinkPositionInfo: { id: `${scope}.languageLinkPositionInfo` },
  languageLinkApperance: { id: `${scope}.languageLinkApperance` },
  languageLinkApperanceInfo: { id: `${scope}.languageLinkApperanceInfo` },
  languageLinkApperanceOutlined: { id: `${scope}.languageLinkApperanceOutlined` },
  languageLinkApperanceOutlinedHelp: { id: `${scope}.languageLinkApperanceOutlinedHelp` },
})
