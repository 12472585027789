import { useState } from 'react'
import PropTypes from 'prop-types'
import { useIntl } from 'react-intl'
import { Col } from 'reactstrap'
import { BasicInput, BasicCheckbox, BasicFormSection, BasicInfo } from 'components'

import messages from '../messages'

const GeneralInfo = ({ handleLangChange, photobooth, getModuleByName, id }) => {
  const [inModal, setInModal] = useState(photobooth.inModal || false)

  const intl = useIntl()

  const handleValidation = (value) => {
    const photobooths = getModuleByName('voxpop', value) || []
    const differentId = photobooths.filter((p) => p.id !== id)
    return differentId.length <= 0
  }

  return (
    <>
      <div style={{ marginTop: '10px' }}>
        <BasicInfo text={intl.formatMessage(messages.defaultInfo)} />
      </div>

      <BasicInput
        name="name"
        label={intl.formatMessage(messages.name)}
        helpMessage={intl.formatMessage(messages.nameHelp)}
        error={intl.formatMessage(messages.nameError)}
        validation={{
          async: (value) => handleValidation(value),
        }}
        required
      />

      {/*<BasicInput*/}
      {/*  type="select"*/}
      {/*  name="part.shareAdmin.language"*/}
      {/*  label={intl.formatMessage(messages.language)}*/}
      {/*  helpMessage={intl.formatMessage(messages.languageHelp)}*/}
      {/*  onChange={handleLangChange}*/}
      {/*>*/}
      {/*  <option value="fr">{intl.formatMessage(messages.french)}</option>*/}
      {/*  <option value="en">{intl.formatMessage(messages.english)}</option>*/}
      {/*</BasicInput>*/}

      <BasicCheckbox
        name="part.inModal"
        label={intl.formatMessage(messages.openInModal)}
        helpMessage={intl.formatMessage(messages.openInModalHelp)}
        onChange={(e) => setInModal(e.target.checked)}
      />

      {inModal && (
        <BasicFormSection>
          <Col>
            <BasicInput
              name="part.inModalButtonText"
              label={intl.formatMessage(messages.modalButtonText)}
              helpMessage={intl.formatMessage(messages.modalButtonTextHelp)}
              placeholder={intl.formatMessage(messages.modalButtonTextPlaceholder)}
              error={intl.formatMessage(messages.required)}
              /*required*/
            />

            <BasicCheckbox
              name="part.inModalButtonAlternate"
              label={intl.formatMessage(messages.alternativeStyle)}
              helpMessage={intl.formatMessage(messages.alternativeStyleHelp)}
            />
          </Col>
        </BasicFormSection>
      )}
    </>
  )
}

GeneralInfo.propTypes = {
  handleLangChange: PropTypes.func,
  getModuleByName: PropTypes.func,
  photobooth: PropTypes.object,
}

export default GeneralInfo
