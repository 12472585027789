import PropTypes from 'prop-types'
import { useIntl } from 'react-intl'
import { BasicInfo, BasicButton, Spinner } from 'components'
import { rtePhotoboothId } from 'utils/routes'
import styled from 'styled-components'

import { connectStore } from 'redux-box'
import { storeModule as ModuleModules } from 'store/modules'

import { Icon } from 'react-icons-kit'
import { externalLink } from 'react-icons-kit/feather'

import messages from '../messages'

const StyledContainer = styled.div`
  margin: 10px 20px;
`

const FbsContent = ({ appId, modules, part, wrap }) => {
  const linkedPhotobooth = modules.getVoxpopById(part.voxpopId || part.id)
  const intl = useIntl()
  return (
    <StyledContainer>
      {!modules.fetching ? (
        <BasicInfo
          text={intl.formatMessage(!!linkedPhotobooth ? messages.fbsLinked : messages.fbsDeleted)}
          children={
            !!linkedPhotobooth && (
              <BasicButton
                isLink
                href={rtePhotoboothId(appId, part.voxpopId || part.id)}
                target="_blank"
                label={intl.formatMessage(messages.viewPhotobooth)}
                icon={<Icon icon={externalLink} />}
                linkstyle
                small
              />
            )
          }
          wrap={wrap}
        />
      ) : (
        <Spinner style={{ padding: '20px', marginTop: '20px' }} />
      )}
    </StyledContainer>
  )
}

FbsContent.propTypes = {
  appId: PropTypes.string,
  modules: PropTypes.object,
  part: PropTypes.object,
  wrap: PropTypes.bool,
}

export default connectStore({ modules: ModuleModules })(FbsContent)
