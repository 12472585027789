const mutations = {
  DATA_SET_VISITCOUNTER: (state, { data }) => {
    state.visitCounters = data
  },
  DATA_SET_THEMES: (state, { data }) => {
    state.themes = data
  },
  DATA_SET_THEME: (state, { theme }) => {
    state.themes[theme.value] = theme
  },
  DATA_REMOVE_THEME: (state, { theme }) => {
    state.themes = Object.values(state.themes).filter((t) => t.value !== theme.value)
  },
  // status
  DATA_RESET_STATUS: (state) => {
    state.fetching = false
    state.saving = false
    state.success = false
    state.deleting = false
    state.deleted = false
    state.error = false
  },
  // fetch
  DATA_FETCHING: (state) => {
    state.fetching = true
  },
  DATA_FETCH_ERROR: (state) => {
    state.fetching = false
    state.error = true
  },
  DATA_FETCH_SUCCESS: (state) => {
    state.fetching = false
  },
  // save
  DATA_SAVING: (state) => {
    state.saving = true
    state.success = false
    state.error = false
  },
  DATA_SAVE_SUCCESS: (state) => {
    state.saving = false
    state.success = true
    state.error = false
  },
  DATA_SAVE_ERROR: (state) => {
    state.saving = false
    state.success = false
    state.error = true
  },
  // delete
  DATA_DELETING: (state) => {
    state.deleting = true
    state.deleted = false
    state.error = false
  },
  DATA_DELETE_SUCCESS: (state) => {
    state.deleting = false
    state.deleted = true
    state.error = false
  },
  DATA_DELETE_ERROR: (state) => {
    state.deleting = false
    state.deleted = false
    state.error = true
  },
}

export default mutations
