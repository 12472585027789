import { call } from 'redux-saga/effects'
import uuidv4 from 'uuid/v4'

import rsFirebase from './rsf'

export function* getFileInfos(path) {
  const metadata = yield call(rsFirebase.storage.getFileMetadata, path)
  const url = yield call(rsFirebase.storage.getDownloadURL, path)

  return { url, metadata }
}

export function* uploadFile64(string, url) {
  yield call(rsFirebase.storage.uploadString, url, string, 'base64')
  const fileInfo = yield getFileInfos(url)
  return fileInfo
}

export function* uploadFile(file, url) {
  yield call(rsFirebase.storage.uploadFile, url, file)
  const fileInfo = yield getFileInfos(url)
  return fileInfo
}

// create a file in the DB
export function* createFile(appId, fileType, file) {
  const id = uuidv4()
  const path = `appdata/${appId}/files-uploaded/${id}`
  const data = {
    id,
    dateAdded: new Date(Date.now()),
    fileType, // image or video
    file, // { name, location, url, size, width, height, type (mimetype) }
  }
  try {
    yield call(rsFirebase.firestore.setDocument, path, data, { merge: true })
    return id
  } catch (error) {
    return error
  }
}

export function* updateVideoFile(appId, fileId, url) {
  const path = `appdata/${appId}/files-uploaded/${fileId}`
  const data = {
    file: {
      location: 'vimeo',
      url,
    },
  }
  try {
    yield call(rsFirebase.firestore.setDocument, path, data, { merge: true })
    return true
  } catch (error) {
    return error
  }
}
