import styled from 'styled-components'

export const StyledButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 10px;
  margin-bottom: 10px;

  button:first-of-type {
    margin-right: 10px;
  }

  @media (max-width: 590px) {
    flex-direction: column;

    a {
      margin-top: 15px;
    }
  }
`
