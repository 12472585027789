import PropTypes from 'prop-types'
import { useState } from 'react'
import { useIntl } from 'react-intl'
import { Collapse } from 'reactstrap'
import { BasicButton, BasicTooltip, BasicTextArea } from 'components'
import { getColor } from 'styles/functions'
import styled from 'styled-components'

import { connectStore } from 'redux-box'
import { storeModule as AppModule } from 'store/app'
import { storeModule as SessionModule } from 'store/manageUsers'

import { Icon } from 'react-icons-kit'
import { info } from 'react-icons-kit/feather/info'

import messages from './messages'

const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin: 20px 20px 0 20px;

  & > div {
    display: flex;
    flex-direction: row;
  }
`

const StyledInfo = styled.div`
  position: relative;
  margin-left: 5px;

  i {
    color: ${getColor('blue')};
    display: flex !important;
  }
`

const StyledTextArea = styled.div`
  font-family: monospace;
  width: 100%;
  margin-top: 10px;
`

const RawEditor = ({ app, parts, onChange, session }) => {
  const [open, setOpen] = useState(false)

  const intl = useIntl()

  return app.access.RawEditor || session.profile.superadmin ? (
    <StyledContainer>
      <div>
        <BasicButton
          onClick={() => setOpen(!open)}
          label={intl.formatMessage(open ? messages.close : messages.open)}
          color="blue"
          outlined
          small
        />

        {open && (
          <>
            <StyledInfo data-for="rawEeditor" data-tip="">
              <Icon icon={info} />
            </StyledInfo>
            <BasicTooltip id="rawEeditor" content={intl.formatMessage(messages.info)} />
          </>
        )}
      </div>

      <Collapse isOpen={open}>
        <StyledTextArea>
          <BasicTextArea
            name="rawdata"
            value={JSON.stringify(parts, null, 2)}
            onChange={onChange}
            onBlur={onChange}
          />
        </StyledTextArea>
      </Collapse>
    </StyledContainer>
  ) : null
}

RawEditor.defaultProps = {
  parts: [{ type: '' }],
}

RawEditor.propTypes = {
  app: PropTypes.object,
  onChange: PropTypes.func,
  parts: PropTypes.array,
  session: PropTypes.object,
}

export default connectStore({ app: AppModule, session: SessionModule })(RawEditor)
