import styled from 'styled-components'

export const StyledInput = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  margin-bottom: 10px;
  margin-top: 5px;

  &:last-of-type {
    margin-bottom: 0;
  }

  span {
    font-family: Nunito;
    font-weight: 600;
    font-size: 14px;
    margin-right: 10px;
    white-space: nowrap;
  }
`
