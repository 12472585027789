import { defineMessages } from 'react-intl'

const scope = 'app.components.PartActions.LocksForm'

export default defineMessages({
  create: { id: `${scope}.create` },
  deletedLock: { id: `${scope}.deletedLock` },
  edit: { id: `${scope}.edit` },
  error: { id: `${scope}.error` },
  infoLock: { id: `${scope}.infoLock` },
  infoLockCancel: { id: `${scope}.infoLockCancel` },
  infoLockPage: { id: `${scope}.infoLockPage` },
  locks: { id: `${scope}.locks` },
  select: { id: `${scope}.select` },
  tutorial: { id: `${scope}.tutorial` },
  url: { id: `${scope}.url` },
  urlError: { id: `${scope}.urlError` },
  urlHelp: { id: `${scope}.urlHelp` },
})
