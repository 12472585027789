const state = {
  list: {},
  fetching: false,
  saving: false,
  success: false,
  error: false,
  deleting: false,
  deleted: false,
}
export default state
