const actions = {
  // Themes
  deleteTheme: (theme) => ({ type: 'DATA_DELETE_THEME', theme }),
  getThemes: () => ({ type: 'DATA_GET_THEMES' }),
  saveTheme: (theme) => ({ type: 'DATA_SAVE_THEME', theme }),
  resetStatus: () => ({ type: 'DATA_RESET_STATUS' }),

  // Visit Counter
  getVisitCounter: (appId) => ({ type: 'DATA_GET_VISITCOUNTER', appId }),
}

export default actions
