import PropTypes from 'prop-types'
import { useState, useEffect } from 'react'
import { useIntl } from 'react-intl'
import { BasicInput, PartOptions, BasicInfo, BasicButton } from 'components'
import { rteModulesId, rteAppId } from 'utils/routes'
import { newEvent } from 'utils'

import connectStore from 'redux-box/dist/connectStore'
import { storeModule as ModuleModules } from 'store/modules'

import { Icon } from 'react-icons-kit'
import { externalLink } from 'react-icons-kit/feather/externalLink'

import messages from './messages'

const InputForm = ({ appId, modules, part, partIndex, onChange, options, onFormChange }) => {
  const [selectedInput, setSelectedInput] = useState(part.id || null)

  const intl = useIntl()

  const selectableInputs = modules.getList('input') || []

  useEffect(() => {
    if (!part.id) {
      onChange(newEvent('id', null), partIndex)
    }
  }, [])

  const handleOnChange = (e) => {
    setSelectedInput(e.target.value)
    onChange(e, partIndex)
  }

  return (
    <>
      <PartOptions partIndex={partIndex} onChange={onChange} values={options} onFormChange={onFormChange} />

      <BasicInput
        type="select"
        label={intl.formatMessage(messages.input)}
        error={intl.formatMessage(messages.required)}
        name="id"
        onChange={(e) => {
          handleOnChange(e);
          onFormChange(true);
        }}
        value={part.id}
        required
      >
        <option value="" disabled>
          {intl.formatMessage(messages.select)}
        </option>

        {selectableInputs.length > 0 &&
          selectableInputs.map((input, i) => (
            <option key={i} value={input.id}>
              {input.name}
            </option>
          ))}
      </BasicInput>

      {selectedInput && (
        <div style={{ marginBottom: '15px' }}>
          <BasicButton
            color="blue"
            href={rteModulesId(appId, 'INPUTS_SINGLE', selectedInput)}
            target="_blank"
            medium
            isLink
            label={intl.formatMessage(messages.edit)}
            icon={<Icon icon={externalLink} />}
          />
        </div>
      )}

      <BasicInfo
        text={intl.formatMessage(messages.info)}
        children={
          <BasicButton
            label={intl.formatMessage(messages.tutorial)}
            icon={<Icon icon={externalLink} />}
            isLink
            href={rteAppId(appId, 'TUTORIAL')}
            target="_blank"
            linkstyle
            small
          />
        }
      />
    </>
  )
}

InputForm.propTypes = {
  appId: PropTypes.string,
  disabled: PropTypes.bool,
  modules: PropTypes.object,
  onChange: PropTypes.func,
  options: PropTypes.object,
  part: PropTypes.object,
  partIndex: PropTypes.number,
  onFormChange: PropTypes.func,
}

export default connectStore({ modules: ModuleModules })(InputForm)
