/**
*
* InputCopyContent
*
*/

import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { InputGroup, Input, InputGroupAddon, Button } from 'reactstrap'
import { Icon } from 'react-icons-kit'
import { copy } from 'react-icons-kit/feather/copy'
import { CopyToClipboard } from 'react-copy-to-clipboard'

const Container = styled.div`
  position: relative;
`
const CopiedSpan = styled.span`
  color: red;
  position : absolute;
  top: .5rem;
  left: .625rem;
  background: #e9ecef;
  width: calc(100% - .625rem);
`

class InputCopyContent extends React.PureComponent { // eslint-disable-line react/prefer-stateless-function
  constructor(props) {
    super(props)
    this.state = {
      copied: false,
    }
    this.showCopied = this.showCopied.bind(this)
  }
  showCopied() {
    this.setState({ copied: true })
    setTimeout(() => {
      this.setState({ copied: false })
    }, 1000)
  }
  render() {
    const { text } = this.props
    return (
      <Container>
        <InputGroup>
          <Input value={text} readOnly />
          <InputGroupAddon addonType="append">
            <CopyToClipboard
              text={text}
              onCopy={this.showCopied}
            >
              <Button><Icon icon={copy} /></Button>
            </CopyToClipboard>
          </InputGroupAddon>
        </InputGroup>
        {this.state.copied ? <CopiedSpan>Copied.</CopiedSpan> : null}
      </Container>
    )
  }
}

InputCopyContent.propTypes = {
  text: PropTypes.string,
}

export default InputCopyContent
