import { defineMessages } from 'react-intl'

const scope = 'app.components.ShareModalFolders'
const scope2 = 'app.containers.PageManageUsers';
const scope3 = 'app.components.NavBar';

export default defineMessages({
  accessSubtitle: { id: `${scope}.accessSubtitle` },
  share: { id: `${scope}.share` },
  userHasAccess: { id: `${scope}.userHasAccess` },
  addAccess: { id: `${scope}.addAccess` },
  editAccess: { id: `${scope}.editAccess` },
  removeAccess: { id: `${scope}.removeAccess` },
  choicePeriodAccess: { id: `${scope}.choicePeriodAccess` },
  publicLink: { id: `${scope}.publicLink` },
  periodToEnd: { id: `${scope}.periodToEnd` },
  noPeriod: { id: `${scope}.noPeriod` },
  allAccess: { id: `${scope}.allAccess` },
  hasAccess: { id: `${scope}.hasAccess` },
  customAccess: { id: `${scope}.customAccess` },
  noAccess: { id: `${scope}.noAccess` },
  startDate: { id: `${scope}.startDate` },
  endDate: { id: `${scope}.endDate` },
  role_admin: { id: `${scope2}.role_admin` },
  role_superowner: { id: `${scope2}.role_superowner` },
  role_owner: { id: `${scope2}.role_owner` },
  role_viewer: { id: `${scope2}.role_viewer` },
  role_editor: { id: `${scope2}.role_editor` },
  role_redactor: { id: `${scope2}.role_redactor` },
  rolesAction: { id: `${scope}.rolesAction` },
  periodAction: { id: `${scope}.periodAction` },
  deleteAction: { id: `${scope}.deleteAction` },
  gotToManageUser: { id: `${scope}.gotToManageUser` },
  manageusers: { id: `${scope3}.manageusers` },
  linkViewFolder: { id: `${scope}.linkViewFolder` },
  copyLinkViewFolder: { id: `${scope}.copyLinkViewFolder` },
  helpSectionUsers: { id: `${scope}.helpSectionUsers` },
})
