import PropTypes from 'prop-types'
import { useEffect, useState } from 'react'
import { useIntl } from 'react-intl'
import { BasicModal, BasicButton } from 'components'
import { rteCardsTag } from 'utils/routes'
import history from 'services/history'

import { connectStore } from 'redux-box'
import { storeModule as CardModule } from 'store/card'
import { storeModule as CardListModule } from 'store/cardList'
import { storeModule as ModuleModules } from 'store/modules'

import InfoModal from './InfoModal'
import messages from './messages'
import { storeModule as ManageUsersModule } from "../../store/manageUsers";

/*
  users can only delete pages (not landing pages)
  onDelete redirect
  .. if in pages: do nothing
  .. if in project: do nothing
  .. if elsewhere: redirect to history.goBack()
  .
  users cannot delete fbs-content or listitem pages
*/
const DeleteModal = ({ appId, card, cardId, open, toggle, tagId, modules, cardList, manageUsers }) => {
  const [isItem, setIsItem] = useState(false)
  const [isFbsContent, setIsFbsContent] = useState(false)

  const intl = useIntl()

  useEffect(() => {
    const selectedCard = cardList.getCardById(cardId)
    const item = selectedCard.parent && modules.getListItemById(selectedCard.parent)
    setIsItem(!!item)

    const shareFacebook = selectedCard.parts && selectedCard.parts.find((part) => part.type === 'fbs-content')
    setIsFbsContent(!!shareFacebook)
  }, [cardId])

  const handleOnDelete = () => {
    manageUsers.deleteUserRights(appId, manageUsers.profile, {
      type: 'cardsDelete',
      cardId: cardId,
    });
    card.deleteCard(cardId, appId)
    toggle()
    if (!tagId) history.push(rteCardsTag(appId, 'all'))
  }

  return (
    <>
      {isItem || isFbsContent ? (
        <InfoModal appId={appId} toggle={toggle} open={open} isItem={isItem} onConfirm={handleOnDelete} />
      ) : (
        <BasicModal
          title={intl.formatMessage(messages.deleteTitle)}
          size="small"
          content={<p>{intl.formatMessage(messages.deleteInfo)}</p>}
          actions={
            <>
              <BasicButton onClick={toggle} label={intl.formatMessage(messages.cancel)} outlined />
              <BasicButton
                onClick={handleOnDelete}
                label={intl.formatMessage(messages.confirm)}
                color="pink"
              />
            </>
          }
          isOpen={open}
          toggle={toggle}
        />
      )}
    </>
  )
}

DeleteModal.propTypes = {
  appId: PropTypes.string,
  card: PropTypes.object,
  cardList: PropTypes.object,
  cardId: PropTypes.string,
  modules: PropTypes.object,
  open: PropTypes.bool,
  toggle: PropTypes.func,
  tagId: PropTypes.string,
  manageUsers: PropTypes.object,
}

export default connectStore({ card: CardModule, modules: ModuleModules, cardList: CardListModule, manageUsers: ManageUsersModule })(
  DeleteModal
)
