import styled, { css } from 'styled-components'

export const StyledMultiSelect = styled.div`
  margin: 10px 0;

  .multiselect-container {
    padding: 3px 0 0 0;
  }

  .search-wrapper {
    min-height: 48px;
    border-radius: 10px;
    border: 1px solid #bebebe;
    padding: 10px 30px 10px 14px;
    font-size: 15px;
    width: 100%;
    background-color: white;
    display: inline-flex;
    align-items: baseline;
    flex-wrap: wrap;

    &::placeholder {
      color: #bebebe;
    }
  }

  input {
    margin-top: 1px;
  }

  label {
    font-family: Nunito;
    font-size: 16px;
    font-weight: 600;
  }

  .chip {
    position: relative;
    color: #406cb3;
    border: 2px solid #406cb3;
    background-color: transparent;
    border-radius: 20px;
    font-weight: 600;
    padding: 1px 30px 1px 10px;
    max-width: 40ch;
    min-width: 4ch;
    overflow: hidden;
    text-overflow: ellipsis;
    display: inline-block;

    @media (max-width: 568px) {
      min-width: calc(4ch + 40px);
    }

    i {
      position: absolute;
      top: 1px;
      right: 5px;
      width: 17px;
      height: 17px;
      font-size: 17px;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-left: 8px;
      padding-top: 4px;
    }
  }

  .optionListContainer {
    padding: 0;
  }
  
  .highlight,
  li:hover {
    background: #406cb3;
  }

  ${(props) =>
    props.disabled &&
    css`
      pointer-events: none;

      .search-wrapper {
        background-color: #e9ecef;
      }

      input {
        display: none;
      }
    `}
`
