import { useState, useEffect } from 'react'
import { normalizeText } from 'utils'

// params are ['name', 'value', etc.]
export function useSearch({ list, params }) {
  const [searchQuery, setSearchQuery] = useState('')
  const [filteredList, setFilteredList] = useState(list)

  const formatText = (str) => (str && str.length > 0 ? normalizeText(str).toLowerCase().trim() : '')

  useEffect(() => {
    const filtered = {
      ...params.map((p) =>
        Object.values(list).filter((el) => formatText(el[p]).indexOf(formatText(searchQuery)) !== -1)
      ),
    }

    let res = {}
    Object.values(filtered).map((el) => (res = { ...res, ...el }))

    setFilteredList(res)
  }, [searchQuery])

  useEffect(() => {
    setFilteredList(list)
    setSearchQuery('')
  }, [list])

  return { filteredList, setSearchQuery, searchQuery }
}
