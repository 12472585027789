import PropTypes from 'prop-types'
import { useIntl } from 'react-intl'
import { DropdownItem, UncontrolledDropdown, DropdownMenu, DropdownToggle } from 'reactstrap'

import { connectStore } from 'redux-box'
import { storeModule as ModuleApp } from 'store/app'

import { Icon } from 'react-icons-kit'
import { ic_swap_vert as sort } from 'react-icons-kit/md/ic_swap_vert'

import messages from './messages'
import styled from 'styled-components'

const StyledRow = styled.div`
  display: flex;
  align-items: baseline;
  justify-content: space-between;
  text-transform: uppercase;
  color: #89959f;
  border-bottom: 1px solid #1a2d4b;
  padding: 5px 0;
  font-size: 12px;

  p {
    margin: 0;
  }
`

const StyledToggle = styled(DropdownToggle)`
  background-color: transparent;
  border: 1px solid white;
  border-radius: 100%;
  cursor: pointer;
  transition: all ease-in-out 0.2s;
  width: 20px !important;
  height: 20px !important;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;

  i {
    display: flex !important;
  }

  svg {
    color: white;
    transition: all ease-in-out 0.2s;
  }

  &:hover {
    border-color: #ffd232;
    color: #ffd232;

    svg {
      color: #ffd232;
    }
  }
`

const StyledItem = styled(DropdownItem)`
  font-family: Nunito;
  font-size: 14px;
  line-height: 22px;
  cursor: pointer;
`

const SortFolders = ({ app }) => {
  const intl = useIntl()

  const handleOnChange = (value) => {
    app.sortFolders(app.id, value)
  }

  return (
    <StyledRow>
      <p>{intl.formatMessage(messages.folders)}</p>
      <UncontrolledDropdown>
        <StyledToggle>
          <Icon icon={sort} />
        </StyledToggle>

        <DropdownMenu style={{ overflow: 'auto' }}>
          {/* Name ASC */}
          <StyledItem onClick={() => handleOnChange('asc')}>
            {intl.formatMessage(messages.sortByNameAsc)}
          </StyledItem>

          {/* Name DESC */}
          <StyledItem onClick={() => handleOnChange('desc')}>
            {intl.formatMessage(messages.sortByNameDesc)}
          </StyledItem>

          {/* Created NEW */}
          <StyledItem onClick={() => handleOnChange('new')}>
            {intl.formatMessage(messages.sortByNew)}
          </StyledItem>

          {/* Created OLD */}
          <StyledItem onClick={() => handleOnChange('old')}>
            {intl.formatMessage(messages.sortByOld)}
          </StyledItem>
        </DropdownMenu>
      </UncontrolledDropdown>
    </StyledRow>
  )
}

SortFolders.propTypes = {
  app: PropTypes.object.isRequired,
}

export default connectStore({ app: ModuleApp })(SortFolders)
