import PropTypes from 'prop-types'
import { BasicInfo, BasicInput } from "components";
import { useIntl } from 'react-intl'

import messages from './messages'

const TagsForm = ({ part, onChange, onFormChange }) => {
  const intl = useIntl()

  return (
    <>
      <BasicInfo text={intl.formatMessage(messages.tagsInfo)} />

      <BasicInput
        type="text"
        label={intl.formatMessage(messages.tagsBeforeLabel)}
        onChange={(e) => {
          onChange(e);
          onFormChange(true);
        }}
        name="tags.beforeText"
        value={part.tags ? part.tags.beforeText : ''}
      />

      <BasicInput
        type="text"
        label={intl.formatMessage(messages.tagsAfterLabel)}
        onChange={(e) => {
          onChange(e);
          onFormChange(true);
        }}
        name="tags.afterText"
        value={part.tags ? part.tags.afterText : ''}
      />
    </>
  )
}

TagsForm.propTypes = {
  onChange: PropTypes.func,
  onCheckedChange: PropTypes.func,
  part: PropTypes.object,
  onFormChange: PropTypes.func,
}

export default TagsForm
