const getRandomStoryById = ({ list }, id) => Object.values(list).find((i) => i.id === id)

const getRandomStoryByName = ({ list }, name) => Object.values(list).filter((l) => l.name === name)

const selectors = {
  getRandomStoryById: (state) => (id) => getRandomStoryById(state, id),
  getRandomStoryByName: (state) => (name) => getRandomStoryByName(state, name),
  randomStory: (state) => (id) => state.list[id] || {},
}
export default selectors
