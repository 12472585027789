import { createSagas } from 'redux-box'
import { call, put } from 'redux-saga/effects'
import { push } from 'react-router-redux'
import { chunkArray } from 'services/utils'
import { NBR_PHOTOBOOTH_FILES_PER_PAGE } from 'utils/consts'
import orderBy from 'lodash/orderBy'

import rsFirebase from 'services/rsf'
import uuidv4 from 'uuid/v4'
import { getPhotobooth } from './workers'

function* getVoxpopFiles(appId, id) {
  const data = []
  const path = `appdata/${appId}/files-received/`
  const colRef = rsFirebase.app.firestore().collection(path)

  const collection = yield call(rsFirebase.firestore.getCollection, colRef.where('origin.id', '==', id))

  collection.forEach((file) => {
    data.push(file.data())
  })

  return data
}

function* deleteItemInLists(appId, id) {
  let data = []
  try {
    const collection = yield call(rsFirebase.firestore.getCollection, `appdata/${appId}/list`)
    collection.forEach((document) => {
      const doc = document.data()
      data.push(doc)
    })

    for (let i = 0; i < data.length; i += 1) {
      data[i] = { ...data[i], children: data[i].children.filter((child) => child.id !== id) }
      yield call(rsFirebase.firestore.setDocument, `appdata/${appId}/list/${data[i].id}`, data[i])
    }

    return data
  } catch (error) {
    yield put({ type: 'MODULE_SAVE_ERROR', error })
  }
}

export default createSagas({
  *MODULE_GET_ALL({ appId }) {
    const modules = ['list', 'listitem', 'trigger', 'input', 'voxpop']
    const data = {}
    try {
      for (let i = 0; i < modules.length; i += 1) {
        const module = modules[i]
        data[module] = {}
        const collection = yield call(rsFirebase.firestore.getCollection, `appdata/${appId}/${module}`)

        collection.forEach((document) => {
          const doc = document.data()
          data[module][doc.id] = { ...doc }
        })
      }

      for (let i = 0; i < Object.keys(data.voxpop).length; i += 1) {
        const id = Object.keys(data.voxpop)[i]
        const files = yield call(getVoxpopFiles, appId, id)

        const group = chunkArray(orderBy(files, 'dateAdded', 'desc'), NBR_PHOTOBOOTH_FILES_PER_PAGE)
        data.voxpop[id].files = group
        data.voxpop[id].allFiles = files
      }

      yield put({ type: 'MODULE_IS_FETCHING' })
      yield put({ type: 'MODULE_SET_ALL', data })
      yield put({ type: 'MODULE_FETCHED' })
      return data
    } catch (error) {
      yield put({ type: 'MODULE_FETCH_ERROR', error })
    }
  },
  *MODULE_GET_LIST({ module, appId }) {
    try {
      const collection = yield call(rsFirebase.firestore.getCollection, `appdata/${appId}/${module}`)
      const data = { module, list: {} }
      collection.forEach((document) => {
        const doc = document.data()
        data.list[doc.id] = doc
      })
      yield put({ type: 'MODULE_SET_LIST', data })
      return collection
    } catch (error) {
      yield put({ type: 'MODULE_FETCH_ERROR', error })
    }
  },
  *MODULE_SAVE({ module, appId, data, onSave }) {
    if (!data.id) data.id = uuidv4()
    try {
      yield put({ type: 'MODULE_SAVING', module })
      yield call(rsFirebase.firestore.setDocument, `appdata/${appId}/${module}/${data.id}`, data)
      yield put({ type: 'MODULE_ADD_ONE', module, data })
      if (onSave && typeof onSave === 'function') {
        onSave(data.id, data)
      }
      yield put({ type: 'MODULE_SAVE_SUCCESS', module })
      return data.id
    } catch (error) {
      yield put({ type: 'MODULE_SAVE_ERROR', error })
    }
  },
  *MODULE_LIST_ADD_ITEM({ appId, item, id }) {
    try {
      yield put({ type: 'MODULE_SAVING', module: 'list' })
      const snapshot = yield call(rsFirebase.firestore.getDocument, `appdata/${appId}/list/${id}`)
      const list = snapshot.data()
      const index = Object.values(list.children).findIndex((child) => child.id === item.id)
      const i = index < 0 ? Object.keys(list.children).filter((key) => key !== 'style').length : index
      list.children[i] = item
      yield call(rsFirebase.firestore.setDocument, `appdata/${appId}/list/${id}`, list)
      yield put({ type: 'MODULE_ADD_ONE', module: 'list', data: list })
      yield put({ type: 'MODULE_SAVE_SUCCESS', module: 'list' })
    } catch (error) {
      yield put({ type: 'MODULE_SAVE_ERROR', error })
    }
  },
  *MODULE_LIST_DELETE_ITEM({ appId, item, id }) {
    try {
      yield put({ type: 'MODULE_SAVING', module: 'list' })
      const snapshot = yield call(rsFirebase.firestore.getDocument, `appdata/${appId}/list/${id}`)
      let list = snapshot.data()
      list.children = Object.values(list.children).filter((child) => child.id !== item.id)
      yield call(rsFirebase.firestore.setDocument, `appdata/${appId}/list/${id}`, list)
      yield put({ type: 'MODULE_ADD_ONE', module: 'list', data: list })
      yield put({ type: 'MODULE_SAVE_SUCCESS', module: 'list' })
    } catch (error) {
      yield put({ type: 'MODULE_SAVE_ERROR', error })
    }
  },
  *MODULE_SAVE_PHOTOBOOTH({ appId, id, data, onSave }) {
    try {
      let photobooth = {}

      if (!id) {
        data.id = uuidv4()
        photobooth = yield call(getPhotobooth, appId, data)
      } else {
        data.id = id
        const snapshot = yield call(rsFirebase.firestore.getDocument, `appdata/${appId}/voxpop/${id}`)
        const dbPhotbooth = snapshot.data()
        photobooth = yield call(getPhotobooth, appId, { ...dbPhotbooth, ...data })
      }

      yield call(rsFirebase.firestore.setDocument, `appdata/${appId}/voxpop/${photobooth.id}`, photobooth)

      yield put({
        type: 'MODULE_PHOTOBOOTH_SAVING_SUCCESS',
        photobooth,
      })

      if (typeof onSave === 'function') {
        onSave(data.id)
      }
    } catch (error) {
      yield put({ type: 'MODULE_PHOTOBOOTH_SAVE_ERROR' })
    }
  },
  *MODULE_DELETE({ module, appId, data, redirectTo }) {
    if (!data.id) return false
    try {
      yield put({ type: 'MODULE_DELETING', module })
      yield call(rsFirebase.firestore.deleteDocument, `appdata/${appId}/${module}/${data.id}`)
      yield put({ type: 'MODULE_REMOVE_ONE', module, data })

      if (module === 'listitem') {
        const lists = yield call(deleteItemInLists, appId, data.id)
        yield put({ type: 'MODULE_SET_LISTS', lists })
        yield put({ type: 'CARD_DELETE', cardId: data.cardId, appId })
      }

      if (redirectTo) yield put(push(redirectTo))
      yield put({ type: 'MODULE_DELETE_SUCCESS', module })
      return data.id
    } catch (error) {
      yield put({ type: 'MODULE_SAVE_ERROR', error })
    }
  },
})
