import PropTypes from 'prop-types'
import { useState } from 'react'
import { AvGroup, AvInput, AvFeedback } from 'availity-reactstrap-validation'
import { BasicLabel } from 'components'
import styled from 'styled-components'

const StyledInput = styled.input`
  -webkit-appearance: none;
  -moz-appearance: none;
  width: 100%;

  &::-webkit-slider {
    &-runnable-track {
      -webkit-appearance: none;
      background: #34b076;
      height: 2px;
    }

    &-thumb {
      -webkit-appearance: none;
      border: 2px solid #34b076;
      border-radius: 50%;
      height: 25px;
      width: 25px;
      max-width: 80px;
      position: relative;
      bottom: 11px;
      background-color: white;
      cursor: -webkit-grab;

      &:active {
        cursor: -webkit-grabbing;
      }
    }
  }

  &::-moz-range {
    &-track {
      -moz-appearance: none;
      background: #34b076;
      height: 2px;
    }

    &-thumb {
      -moz-appearance: none;
      border: 2px solid;
      border-radius: 50%;
      height: 25px;
      width: 25px;
      max-width: 80px;
      position: relative;
      bottom: 11px;
      background-color: #34b076;
      cursor: -moz-grab;

      &:active {
        cursor: -moz-grabbing;
      }
    }
  }

  &:focus {
    outline: none;
  }
`

const StyledRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  input {
    flex: 1;

    &:first-of-type {
      margin-right: 20px;
    }
  }
`

const BasicSlider = ({ error, partIndex, label, helpMessage, value, onChange, name }) => {
  const [val, setVal] = useState(value || '100')

  const partName = partIndex ? `${name}-${partIndex}` : name

  const handleOnChange = (e) => {
    setVal(e.target.value)
    onChange(e)
  }

  return (
    <AvGroup>
      <BasicLabel label={label} helpMessage={helpMessage} />

      <StyledRow>
        <StyledInput
          id={partName}
          name={partName}
          min={5}
          max={100}
          type="range"
          value={val}
          step={5}
          onChange={handleOnChange}
        />

        <AvInput
          type="number"
          id={partName}
          name={partName}
          min={5}
          max={100}
          onChange={handleOnChange}
          value={val}
        />
      </StyledRow>

      {error && <AvFeedback>{error}</AvFeedback>}
    </AvGroup>
  )
}

BasicSlider.defaultProps = {
  onChange: () => {},
}

BasicSlider.propTypes = {
  error: PropTypes.string,
  helpMessage: PropTypes.string,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  name: PropTypes.string,
  onChange: PropTypes.func,
  partIndex: PropTypes.any,
  value: PropTypes.any,
}

export default BasicSlider
