import { call, all } from 'redux-saga/effects'
import uid from 'uid'

import rsFirebase from 'services/rsf'
import { feedbackDirectLink } from 'utils/generator'
import { getAccess } from '../../domain/plans'
import { LAST_APP_VISITED } from "../../utils/consts";

export function* updateLegalContacts(data) {
  const toDelete = []
  const toDeleteIndex = Object.keys(data.legalContacts).length
  for (let i = 0; i < toDeleteIndex; i += 1) {
    toDelete.push(yield call(rsFirebase.firestore.deleteDocument, `appdata/${data.id}/legalContacts/${i}`))
  }
  yield all(toDelete)

  const filteredLegals = data.legalContacts.filter((l) => l)
  const contacts = []
  const legalIndex = Object.keys(filteredLegals).length
  for (let i = 0; i < legalIndex; i += 1) {
    contacts.push(
      yield call(rsFirebase.firestore.setDocument, `appdata/${data.id}/legalContacts/${i}`, filteredLegals[i])
    )
  }
  yield all(contacts)
}

export function saveLastApp(appId) {
  localStorage.setItem(LAST_APP_VISITED, appId);
}

export function* saveApp(data) {
  yield call(rsFirebase.firestore.setDocument, `appdata/${data.id}`, data)
}

export function* getAppLegalContacts(appId) {
  const snapshot = yield call(rsFirebase.firestore.getCollection, `appdata/${appId}/legalContacts`)
  const legalContacts = []
  snapshot.forEach((contact) => {
    legalContacts.push({ ...contact.data(), id: contact.id })
  })
  return legalContacts
}

export function* getMoreAppInfos(appId) {
  const snapshot = yield call(rsFirebase.firestore.getDocument, `appdata/${appId}`)
  const info = snapshot.data()

  const aoSnapshot = yield call(rsFirebase.firestore.getCollection, `appdata/${appId}/addOns`)
  let addOns = {}
  aoSnapshot.forEach((addOn) => {
    addOns = { ...addOns, [addOn.id]: addOn.data() }
  })

  return getAccess({ addOns: addOns, plan: info.plan })
}

// genere le contenu des cards de feedback
export function generateAllCards(appId, feedbackId, name, place) {
  const updateTime = Date.now().toString()
  const childIds = [
    {
      id: 's1',
      title: 'Très Satisfait',
      link: feedbackDirectLink(appId, `${feedbackId}_s1`),
      url: 'https://firebasestorage.googleapis.com/v0/b/smartjourney-app.appspot.com/o/apps%2Fmsjdata%2F1-0-tres-satisfait-fyo0lui.png?alt=media&token=ef4aaf7a-fc0e-4b52-a9aa-173f97cb0a3f',
    },
    {
      id: 's2',
      title: 'Satisfait',
      link: feedbackDirectLink(appId, `${feedbackId}_s2`),
      url: 'https://firebasestorage.googleapis.com/v0/b/smartjourney-app.appspot.com/o/apps%2Fmsjdata%2F1-1-satisfait-mewqw50.png?alt=media&token=2f36a477-36dc-48d4-90e3-80e48ed3ef57',
    },
    {
      id: 's3',
      title: 'Insatisfait',
      link: feedbackDirectLink(appId, `${feedbackId}_s3`),
      url: 'https://firebasestorage.googleapis.com/v0/b/smartjourney-app.appspot.com/o/apps%2Fmsjdata%2F1-2-insatisfait-sewipmf.png?alt=media&token=41e7699c-bcbd-480f-b25b-3db70e085b7e',
    },
    {
      id: 's4',
      title: 'Très Insatisfait',
      link: feedbackDirectLink(appId, `${feedbackId}_s4`),
      url: 'https://firebasestorage.googleapis.com/v0/b/smartjourney-app.appspot.com/o/apps%2Fmsjdata%2F1-3-tres-insatisfait-4ko66u4.png?alt=media&token=f5bd6f09-a107-4700-8a0e-e41c977231d6',
    },
  ]
  const parentParts = childIds.map((child) => ({
    type: 'Picture',
    url: child.url,
    link: child.link,
    locked: true,
  }))
  const counterId = uid()

  const parentCard = {
    title: name,
    tokenId: 0,
    parts: [
      ...parentParts,
      {
        type: 'VisitCounter',
        counterId,
        name,
        generator: 'feedback',
        locked: true,
      },
    ],
    cardId: feedbackId,
    updateTime,
    unlinked: true,
    place,
    type: 'feedback-parent',
  }

  const childs = childIds.map((child) => ({
    title: `${name} - ${child.title}`,
    tokenId: 0,
    parts: [
      {
        type: 'VisitCounter',
        counterId,
        name: `${name} - ${child.title}`,
        locked: true,
      },
    ],
    cardId: `${feedbackId}_${child.id}`,
    updateTime,
    unlinked: true,
    place,
    type: 'feedback-child',
    parent: feedbackId,
  }))
  childs.unshift(parentCard)
  return childs
}