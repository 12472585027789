import { defineMessages } from 'react-intl'

const scope = 'app.components.PartActions.ListForm'

export default defineMessages({
  edit: { id: `${scope}.edit` },
  info: { id: `${scope}.info` },
  list: { id: `${scope}.list` },
  required: { id: `${scope}.required` },
  select: { id: `${scope}.select` },
  tutorial: { id: `${scope}.tutorial` },
})
