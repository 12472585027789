import EmptyCard from 'services/card.cls'

export const getNewCard = (values, appId) => {
  const newCard = new EmptyCard(appId)
  newCard.title = values.title || ''
  newCard.type = 'listitem'
  newCard.parent = values.id
  newCard.place = ''
  newCard.folders = values.folders
  newCard.style = values.style || ''

  if (values.template === 'AirCanada') {
    // SPECIAL ORDER FOR AIR CANADA
    newCard.title = values.pageTitle || ''
    newCard.parts = [
      { id: values.id, style: { displayType: 'custom.disponibilitees' }, type: 'ListItem' },
      { id: values.id, style: { displayType: 'H2' }, type: 'ListItem' },
      { id: values.id, style: { displayType: 'Picture' }, type: 'ListItem' },
      { id: values.id, style: { displayType: 'Text' }, type: 'ListItem' },
      { id: values.id, style: { displayType: 'custom.allergiesImg' }, type: 'ListItem' },
      { id: values.id, style: { displayType: 'custom.allergiesTxt' }, type: 'ListItem' },
      { id: values.id, style: { displayType: 'custom.backBtnUrl' }, type: 'ListItem' },
      { id: values.id, style: { displayType: 'Trigger' }, type: 'ListItem' },
    ]
  } else {
    newCard.parts = [
      { type: 'ListItem', id: values.id, style: { displayType: 'H2' } },
      { type: 'ListItem', id: values.id, style: { displayType: 'Picture' } },
      { type: 'ListItem', id: values.id, style: { displayType: 'Text' } },
    ]

    if (values.triggerId && values.triggerId !== '') {
      newCard.parts.push({ type: 'ListItem', id: values.id, style: { displayType: 'Trigger' } })
    }

    if (values.custom) {
      Object.entries(values.custom).forEach(([key]) => {
        newCard.parts.push({ type: 'ListItem', id: values.id, style: { displayType: `custom.${key}` } })
      })
    }
  }

  values.cardId = newCard.cardId
  return newCard
}
