import PropTypes from 'prop-types'
import { useEffect, useState, useRef } from 'react'
import { BasicButton, BasicLabel } from 'components'
import { useDropzone } from 'react-dropzone'
import { useIntl } from 'react-intl'
import { AvGroup, AvInput } from 'availity-reactstrap-validation'

import { Icon } from 'react-icons-kit'
import { image } from 'react-icons-kit/feather/image'
import { ic_clear as clear } from 'react-icons-kit/md/ic_clear'

import { StyledDropZone, StyledContent, StyledText, StyledIcon, StyledButton, StyledErrorMsg } from './styles'
import { StyledPreviewContainer, StyledPreviewButton, StyledPreviewImage } from './styles'
import messages from './messages'

const DropZone = ({ error, file, label, helpMessage, onChange, onDelete, name, url, required }) => {
  const [files, setFiles] = useState([])
  const [dbUrl, setDbUrl] = useState(url || '')
  const [showPreview, setShowPreview] = useState(false)
  const inputRef = useRef(null)
  const [fileTypeError, setFileTypeError] = useState(false);

  const intl = useIntl()

  useEffect(() => {
    setShowPreview(getShowPreview())
  }, [])

  useEffect(() => {
    setShowPreview(getShowPreview())
  }, [file, files, url])

  useEffect(() => {
    setFiles([file])
    if (!!files && files[0] && files[0] !== undefined) setDbUrl('')
  }, [file])

  useEffect(() => {
    setDbUrl(url)
    if (url && url.length > 0) setFiles([])
  }, [url])

  const { getRootProps, getInputProps } = useDropzone({
    accept: 'image/jpeg, image/png, image/x-png, image/svg+xml, image/gif',
    maxFiles: 1,
    onDrop: (acceptedFiles, fileRejections) => {
      if (fileRejections.length > 0) {
        setFileTypeError(true);
      } else {
        setFileTypeError(false);
        onChange(acceptedFiles)
        setFiles(
          acceptedFiles.map((file) =>
            Object.assign(file, {
              preview: URL.createObjectURL(file),
            })
          )
        )
      }
    },
  })

  const getError = () =>
    inputRef &&
    inputRef.current &&
    inputRef.current.context &&
    inputRef.current.context.FormCtrl.getInputState(name).error

  const getShowPreview = () => {
    if (!!files && files[0] && files[0] !== undefined) return true
    else if (dbUrl && dbUrl.length > 0) return true
    return false
  }

  const getSrc = () => {
    if (!!files && files.length > 0 && files[0] && files[0] !== undefined) return files[0].preview
    else if (dbUrl && dbUrl.length > 0) return url
    return null
  }

  const handleOnRemove = () => {
    setFiles([])
    setDbUrl('')
    onDelete()
  }

  return (
    <>
      <AvGroup ref={inputRef}>
        <BasicLabel label={label} required={required} helpMessage={helpMessage} />

        {/* for data in forms */}
        <AvInput
          name={name}
          value={getSrc()}
          style={{ margin: 0, height: 0, padding: 0, visibility: 'hidden' }}
          required={required}
        />
      </AvGroup>

      {showPreview ? (
        <StyledPreviewContainer>
          <StyledPreviewImage src={getSrc()} alt="preview" />

          <StyledPreviewButton>
            <BasicButton
              outlined
              color="pink"
              small
              icon={<Icon icon={clear} />}
              onClick={handleOnRemove}
              round
            />
          </StyledPreviewButton>
        </StyledPreviewContainer>
      ) : (
        <>
          <div {...getRootProps()}>
            <StyledDropZone>
              <StyledContent error={getError()}>
                <StyledText>
                  <input {...getInputProps()} />

                  <StyledIcon>
                    <Icon icon={image} />
                  </StyledIcon>

                  <span>{intl.formatMessage(messages.dropMessage)}</span>
                </StyledText>

                <StyledButton>
                  <BasicButton
                    color="green"
                    small
                    outlined
                    label={intl.formatMessage(messages.browse)}
                    onClick={() => {}}
                  />
                </StyledButton>
              </StyledContent>
            </StyledDropZone>
          </div>
          {getError() && <StyledErrorMsg>{error}</StyledErrorMsg>}
          {fileTypeError && <StyledErrorMsg>{intl.formatMessage(messages.errorImgTypeFile)}</StyledErrorMsg>}
        </>
      )}
    </>
  )
}

DropZone.propTypes = {
  error: PropTypes.string,
  file: PropTypes.object,
  label: PropTypes.string,
  helpMessage: PropTypes.string,
  onChange: PropTypes.func,
  onDelete: PropTypes.func,
  name: PropTypes.string,
  url: PropTypes.string,
  required: PropTypes.bool,
}

export default DropZone
