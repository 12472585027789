import { all, call, fork, put } from 'redux-saga/effects'
import uid from 'uid'
import shortid from 'shortid'

import rsFirebase from 'services/rsf'
import { splitFileName, youtubeParser } from 'services/utils'
import { createFile, updateVideoFile, uploadFile, uploadFile64 } from 'services/fileManager'

import logger from "services/logger";

export const generateNewCard = (data) => {
  const { title, tagGaLabel, tagGaChecked, tokenId, parts, cardId, updateTime, unlinked, place, theme, linkLogo, openBlankLinkLogo, type, parent, folders } = data

  const typeToUse = type ? type : unlinked ? 'unlinked' : 'card' // eslint-disable-line

  return {
    title: title || '',
    tagGaLabel: tagGaLabel || '',
    tagGaChecked: tagGaChecked !== undefined ? tagGaChecked : true,
    place: place || '',
    theme: theme || null,
    linkLogo: linkLogo || '',
    openBlankLinkLogo: openBlankLinkLogo || false,
    parts,
    updateTime,
    cardId,
    tokenId,
    unlinked: unlinked || null,
    type: typeToUse,
    parent: parent || '',
    folders: folders || [],
  }
}

export const duplicateCard = (card) => {
  const id = uid()
  return {
    title: card.title || '',
    place: card.place || '',
    theme: card.theme || '',
    linkLogo: card.linkLogo || '',
    openBlankLinkLogo: card.openBlankLinkLogo || false,
    parts: card.parts,
    createdTime: Date.now().toString(),
    cardId: id,
    tokenId: 0,
    type: 'card',
    parent: card.parent || '',
    folders: card.folders || [],
  }
}

export function* uploadPartFiles(data) {
  const { appId, parts, cardId } = data

  // si ya une part qui a une file (type="Picture")
  // on upload l'image pi on prend l'url pi on l'ajoute a la part comme "url"
  // pi on retire le "file" du part et le base64

  for (let i = 0; i < parts.length; i += 1) {
    const part = parts[i]

    // si c'est une image, on save pas l'image, on utilise le base64 généré par le canvas
    if (part.type === 'Picture' || part.type === 'Button-Half') {
      if (part.file) {
        const fileNameObj = splitFileName(part.file.name)
        const fileName = `${cardId}-${i}-${fileNameObj.name}-${uid()}.${fileNameObj.ext}`
        const path = `/apps/${appId}/${fileName}`

        const fileInfos = part.base64
          ? yield uploadFile64(part.base64.slice(23), path)
          : yield uploadFile(part.file, path)

        part.size = fileInfos.metadata.size

        part.file = null
        part.base64 = null
        part.url = fileInfos.url

        part.fileId = yield createFile(appId, 'image', {
          url: fileInfos.url,
          location: 'firebase',
          name: fileName,
          size: fileInfos.metadata.size,
          width: part.imageWidth,
          height: part.imageHeight,
        })
      }

      if (!part.url) {
        part.type = ''
      }
    }

    if (part.type === 'Video' && part.file) {
      const fileNameObj = splitFileName(part.file.name)
      const fileName = `${cardId}-${i}-${fileNameObj.name}-${uid()}.${fileNameObj.ext}`

      // Create file in DB
      const fileId = yield createFile(appId, 'video', {
        url: '',
        location: '',
        name: fileName,
      })

      part.fileId = fileId
      const name = appId+' - '+fileId

      try {
        const videoId = yield call(uploadVideoToVimeo, part.file, name)
        const videoUrl = `https://vimeo.com/${videoId}`

        yield call(updateVideoFile, appId, fileId, videoUrl)

        part.file = null
        part.url = videoUrl
        part.videoId = videoId
        part.location = 'vimeo'
        part.fileId = fileId

        if (!part.url) {
          part.type = ''
        }
      } catch (error) {
        console.error('Error:', error)
      }
    }

    if (part.type === 'Audio' && part.file) {
      const fileNameObj = splitFileName(part.file.name)
      const fileName = `${cardId}-${i}-${fileNameObj.name}-${uid()}.${fileNameObj.ext}`
      const url = `/apps/${appId}/${fileName}`
      const fileInfos = yield uploadFile(part.file, url)
      // Create file in DB
      part.fileId = yield createFile(appId, 'audio', {
        url: fileInfos.url,
        location: 'firebase',
        name: fileName,
        size: fileInfos.metadata.size,
      })
      part.size = fileInfos.metadata.size
      part.file = null
      part.url = fileInfos.url

      if (!part.url) {
        part.type = ''
      }
    }

    if (part.type === 'VideoYoutube') {
      part.id = youtubeParser(part.src || part.url)
      if (!part.url.includes('https://')) {
        part.url = `https://${part.src || part.url}`
      }
      // part.time = new URL(part.url).searchParams.get('t')
    }

    if (part.type === 'Lock') {
      parts[i] = {
        type: 'Lock',
        id: part.id,
        options: part.options || {},
      }
    }
  }
  return parts
}

// on update la card au cards de l'app
export function* updateCardInfo(appId, myCard) {
  // BEFORE SAVING, make sure there is an id
  if (!appId) {
    const message = {
      case: 'ERROR SAVING CARD - NO APPID',
      appId,
      cardToSave: myCard,
    };
    logger(message);
    throw new Error('NEED AN APP ID');
  }

  // Generate a new cardId if it's missing
  if (!myCard.cardId || myCard.cardId === '') {
    const message = {
      case: 'WARNING SAVING CARD - NO CARDID',
      appId,
      cardToSave: myCard,
    };
    logger(message);
    myCard.cardId = shortid.generate(); // Ensure you have `shortid` available
  }

  // Double-check to throw an error if cardId is still missing after the generation attempt
  if (!myCard.cardId || myCard.cardId === '') {
    const message = {
      case: 'ERROR SAVING CARD - NO CARDID',
      appId,
      cardToSave: myCard,
    };
    logger(message);
    throw new Error('NEED A CARD ID');
  }

  // Update or create the document with `myCard` data
  yield call(rsFirebase.firestore.setDocument, `appdata/${appId}/cards/${myCard.cardId}`, myCard, { merge: true });
}

export function* updateLastCardUpdateTime(appId, { updateTime }) {
  yield call(rsFirebase.firestore.updateDocument, `appdata/${appId}`, { lastCardUpdateTime: updateTime })
}

export function* updateCardList(app, card) {
  return yield call(
    rsFirebase.firestore.updateDocument,
    `appdata/${app.id}`,
    { [`cardList.${card.cardId}`]: card.cardId }
  )
}

export function* updateOneCard(newCard, appId) {
  const myDataNow = newCard
  myDataNow.appId = appId
  myDataNow.updateTime = Date.now().toString()

  try {
    // manage files
    myDataNow.parts = yield uploadPartFiles(myDataNow)

    // save part
    yield all([fork(updateCardInfo, appId, newCard), fork(updateLastCardUpdateTime, appId, myDataNow)])
    return myDataNow
  } catch (error) {
    console.log('ERROR', error) // eslint-disable-line no-console
  }
}

/* ANNA : Code mort conservé pour mémoire en cas de besoin de fix pour oubli de report lors du refacto
export function* updateCards(cards, appId) {
  for (const card of cards) {
    yield call(updateOneCard, card, appId);
  }
}*/
/* ANNA : Code mort conservé pour mémoire en cas de besoin de fix pour oubli de report lors du refacto
export function* updateCards(newCards, appId) {
  // Fetch all cards from Firestore
  const snapshot = yield call(rsFirebase.firestore.getCollection, `appdata/${appId}/cards`)
  const firestoreCards = {}
  snapshot.forEach((doc) => {
    firestoreCards[doc.id] = doc.data()
  })

  // Update or add new cards
  for (let key in newCards) {
    if (newCards.hasOwnProperty(key)) {
      yield updateOneCard(newCards[key], appId)
      // Remove the card from firestoreCards after it's updated
      delete firestoreCards[key]
    }
  }

  // Delete cards that are not in newCards
  for (let key in firestoreCards) {
    if (firestoreCards.hasOwnProperty(key)) {
      yield call(rsFirebase.firestore.deleteDocument, `appdata/${appId}/cards/${key}`)
    }
  }
}*/

export function* createOneCard(newCard, app, withFiles = true) {
  const myDataNow = newCard
  myDataNow.appId = app.id

  myDataNow.updateTime = Date.now().toString()
  // myDataNow.appInfo = getCachedAppInfo(appId)

  try {
    if (withFiles) {
      myDataNow.parts = yield uploadPartFiles(myDataNow)
    }
    yield all([
      fork(updateCardInfo, app.id, myDataNow),
      // fork(updateLastCardUpdateTime, app.id, myDataNow),
      // fork(updateTokenCardLink, myDataNow, app),
    ])
    return myDataNow
  } catch (error) {
    console.log('ERROR', error) // eslint-disable-line no-console
  }
  return myDataNow
}

export function* deleteContent(path) {
  return yield call(rsFirebase.firestore.deleteDocument, path)
}

export function* deleteItemInLists(appId, id) {
  let data = []
  try {
    const collection = yield call(rsFirebase.firestore.getCollection, `appdata/${appId}/list`)
    collection.forEach((document) => {
      const doc = document.data()
      data.push(doc)
    })

    for (let i = 0; i < data.length; i += 1) {
      data[i] = { ...data[i], children: data[i].children.filter((child) => child.id !== id) }
      yield call(rsFirebase.firestore.setDocument, `appdata/${appId}/list/${data[i].id}`, data[i])
    }

    return data
  } catch (error) {
    yield put({ type: 'MODULE_SAVE_ERROR', error })
  }
}

async  function postData(file, name) {

  const access_token = "a48fd3bfc74bb555477f627bce5d7c04"
  const size =  parseInt(file.size, 10)

  try {
    const response = await fetch('https://api.vimeo.com/me/videos', {
      method: 'POST',
      headers: {
        'Authorization': `Bearer ${access_token}`,
        'Content-Type': 'application/json',
        'Accept': 'application/vnd.vimeo.*+json;version=3.4',
      },
      body: JSON.stringify({
        'upload': {
          'approach': 'tus',
          'size': size
        },
        "name": name,
        "privacy.embed": "public",
        "privacy.view": "anybody"
      }),
    })
    return response.json()
  } catch (error) {
    console.error('Error:', error);
  }
}

async function uploadVids(file, upload_link) {

  try {
    let uploadOffset = 0
    while (uploadOffset < file.size) {
      const response = await fetch(upload_link, {
        method: 'PATCH',
        headers: {
          'Tus-Resumable': `1.0.0`,
          'Upload-Offset': `${uploadOffset}`,
          'Content-Type': 'application/offset+octet-stream',
        },
        body: file,
      })

      if (!response.ok) {
        throw new Error(`Failed to upload. Status: ${response.status} - ${response.statusText}`)
      }

      uploadOffset = parseInt(response.headers.get('Upload-Offset'), 10)
      if(uploadOffset=== file.size){
        return response.headers.get('url')
      }
    }

  } catch (error) {
    console.error('Error:', error)
  }
}

async function uploadVideoToVimeo(file, name){
  const myData = await postData(file, name)
  const upload_link = myData.upload.upload_link
  if(!!upload_link){
    await uploadVids(file, upload_link)
    return myData.uri.replace("/videos/", "");
  }
}

export function* deleteCardFromCardList(appId, cardId) {
  try {
    // Step 1: Fetch the document
    const documentPath = `appdata/${appId}`;
    const doc = yield call(rsFirebase.firestore.getDocument, documentPath);
    if (doc.exists) {
      const data = doc.data();
      const cardList = data.cardList || {};

      // Step 2: Modify the map in your application
      if (cardList.hasOwnProperty(cardId)) {
        delete cardList[cardId]; // Remove the key-value pair
      }

      // Step 3: Update the document with the modified map
      yield call(
        rsFirebase.firestore.updateDocument,
        documentPath,
        { cardList } // Update the entire cardList field
      );
    }
  } catch (error) {
    console.error("Failed to delete card from cardList", error);
    // Handle error
  }
}