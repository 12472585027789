import PropTypes from 'prop-types'
import { rteLockId } from 'utils/routes'
import { useIntl } from 'react-intl'
import { BasicInfo, BasicButton } from 'components'
import styled from 'styled-components'

import connectStore from 'redux-box/dist/connectStore'
import { storeModule as LockModule } from 'store/locks'

import { Icon } from 'react-icons-kit'
import { externalLink } from 'react-icons-kit/feather'

import messages from '../messages'
import { StyledListLocks, StyledListLocksTitle } from './styles'

const StyledContainer = styled.div`
  margin: 10px 20px;
`

const LockInfo = ({ appId, parts, modules, wrap, types }) => {
  const getLock = (id) => modules.getLockById(id) || null
  const intl = useIntl()

  return (
    <StyledContainer>
      <BasicInfo
        text={intl.formatMessage(messages.Locks)}
        wrap={wrap}
        forLocks
      >
        <ul>
          {parts.map((part) => {
            const lock = getLock(part.id)
            return (
              <StyledListLocks key={part.id}>
                {lock && types.includes(part.type) ? (
                  <div>
                    <StyledListLocksTitle>{lock.name}</StyledListLocksTitle>
                    <BasicButton
                      label={intl.formatMessage(messages.viewLock)}
                      icon={<Icon icon={externalLink} />}
                      isLink
                      href={rteLockId(appId, part.id)}
                      target="_blank"
                      linkstyle
                      small
                    />
                  </div>
                ) : (
                  <BasicButton
                    label={intl.formatMessage(messages.viewLock)}
                    icon={<Icon icon={externalLink} />}
                    isLink
                    href={rteLockId(appId, part.id)}
                    target="_blank"
                    linkstyle
                    small
                  />
                )}
              </StyledListLocks>
            )
          })}
        </ul>
      </BasicInfo>
      <hr/>
    </StyledContainer>
  );
}

LockInfo.propTypes = {
  appId: PropTypes.string,
  modules: PropTypes.object,
  parts: PropTypes.array,
  types: PropTypes.array,
  wrap: PropTypes.bool,
}

export default connectStore({ modules: LockModule })(LockInfo)
