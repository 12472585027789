import PropTypes from 'prop-types'
import { useIntl } from 'react-intl'
import { Card } from 'reactstrap'
import { BasicInfo, BasicButton, Spinner } from 'components'
import { rtePhotoboothId } from 'utils/routes'
import { getColor } from 'styles/functions'
import styled from 'styled-components'

import { connectStore } from 'redux-box'
import { storeModule as ModuleModules } from 'store/modules'

import { Icon } from 'react-icons-kit'
import { share2 } from 'react-icons-kit/feather/share2'
import { externalLink } from 'react-icons-kit/feather'

import messages from './messages'

const StyledP = styled.p`
  display: flex;
  flex-direction: row;
  align-items: center;
  font-weight: 600;

  i {
    display: flex !important;
    margin-right: 7px;
  }
`

const StyledCard = styled(Card)`
  background-color: #f4f4f4;
  padding: 20px;
  min-height: 125px;
`

const StyledSpinner = styled.div`
  height: 83px;
  display: flex;
  justify-content: center;
  align-items: center;
`

const FbsContent = ({ appId, part, modules, partIndex }) => {
  const intl = useIntl()

  const linkedPhotobooth = !!modules.getVoxpopById(part.voxpopId || part.id)

  return (
    <StyledCard key={`fbsContent-${partIndex}`}>
      {modules.fetching ? (
        <StyledSpinner>
          <Spinner />
        </StyledSpinner>
      ) : (
        <>
          <StyledP>
            <Icon icon={share2} />
            {intl.formatMessage(messages[part.type])}
          </StyledP>

          <BasicInfo text={intl.formatMessage(messages.info)} />

          {!!linkedPhotobooth ? (
            <div style={{ paddingTop: '10px' }}>
              <BasicButton
                isLink
                href={rtePhotoboothId(appId, part.voxpopId || part.id)}
                target="_blank"
                label={intl.formatMessage(messages.viewPhotobooth)}
                icon={<Icon icon={externalLink} />}
                linkstyle
                small
              />
            </div>
          ) : (
            <StyledP style={{ margin: '10px 0 0 0', color: getColor('orange'), fontWeight: '500' }}>
              {intl.formatMessage(messages.deleted)}
            </StyledP>
          )}
        </>
      )}
    </StyledCard>
  )
}

FbsContent.propTypes = {
  appId: PropTypes.string,
  modules: PropTypes.object,
  part: PropTypes.object,
  partIndex: PropTypes.number,
}

export default connectStore({ modules: ModuleModules })(FbsContent)
