import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import { useIntl } from 'react-intl'
import { rteModulesId } from 'utils/routes'

import { alertTriangle } from 'react-icons-kit/feather/alertTriangle'
import { externalLink } from 'react-icons-kit/feather/externalLink'
import { Icon } from 'react-icons-kit'

import messages from '../../messages'
import { StyledNotFoundMsg } from '../styles'

const EmptyList = ({ appId, currentList }) => {
  const intl = useIntl()

  return (
    <div style={{ display: 'flex', flexDirection: 'column' }}>
      <StyledNotFoundMsg>
        <Icon icon={alertTriangle} style={{ color: '#f59f32' }} />
        <span>{intl.formatMessage(messages.emptyList)}</span>
      </StyledNotFoundMsg>

      <Link
        to={rteModulesId(appId, 'LISTS_SINGLE', currentList.id)}
        target="_blank"
        style={{ margin: '10px 26px', display: 'flex', alignItems: 'center' }}
      >
        {intl.formatMessage(messages.clickHereList)}
        <Icon
          icon={externalLink}
          style={{
            margin: '0 10px',
            height: '16px',
            width: '16px',
            display: 'flex',
          }}
        />
      </Link>
    </div>
  )
}

EmptyList.propTypes = {
  appId: PropTypes.string,
  currentList: PropTypes.object,
}

export default EmptyList
