import PropTypes from 'prop-types'
import {BasicCheckbox, BasicWarning} from 'components'
import { useIntl } from 'react-intl'

import connectStore from 'redux-box/dist/connectStore'
import { storeModule as AppModule } from 'store/app'

import messages from './messages'

const StyleForm = ({ app, part, partIndex, onCheckedChange, onFormChange }) => {
  const intl = useIntl()

  return (
    <>
      <BasicCheckbox
        partIndex={partIndex}
        label={intl.formatMessage(messages.alternate)}
        helpMessage={intl.formatMessage(messages.alternateHelp)}
        name="style.alternate"
        value={part.style ? part.style.alternate : false}
        onChange={(e) => {
          onCheckedChange(e);
          onFormChange(true);
        }}
      />

      <BasicCheckbox
        partIndex={partIndex}
        label={intl.formatMessage(messages.flat)}
        helpMessage={intl.formatMessage(messages.flatHelp)}
        name="style.flat"
        value={part.style ? part.style.flat : false}
        onChange={(e) => {
          onCheckedChange(e);
          onFormChange(true);
        }}
      />

      {!!app.access.MSJBrowser && !part.return && (
        <div style={{ marginTop: '20px' }}>
          <BasicCheckbox
            partIndex={partIndex}
            label={intl.formatMessage(messages.msjBrowser)}
            name="msjBrowser"
            value={part.msjBrowser || false}
            onChange={(e) => {
              onCheckedChange(e);
              onFormChange(true);
            }}
          />
          <BasicWarning text={intl.formatMessage(messages.warning)} style={{ marginTop: '-15px' }} />
        </div>
      )}
    </>
  )
}

StyleForm.propTypes = {
  app: PropTypes.object,
  isExternalLink: PropTypes.bool,
  onCheckedChange: PropTypes.func,
  part: PropTypes.object,
  partIndex: PropTypes.number,
  onFormChange: PropTypes.func,
}

export default connectStore({ app: AppModule })(StyleForm)
