import PropTypes from 'prop-types'
import styled from 'styled-components'

import { ScheduleTooltip } from '../components/Tooltips'

const StyledWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin: 20px;
`

const StyledText = styled.div`
  p {
    margin: 0 !important;
    line-height: 20px;
    ${({ wrap }) => wrap === 'true' && 'word-break: break-all'}
  }
`

const Text = ({ part, partIndex, cardId }) => {
  return (
    <StyledWrapper key={`${cardId}-txt-${partIndex}`}>
      <StyledText dangerouslySetInnerHTML={{ __html: part.text }} />
      <ScheduleTooltip part={part} />
    </StyledWrapper>
  )
}

Text.propTypes = {
  part: PropTypes.object,
  partIndex: PropTypes.number,
  cardId: PropTypes.string,
}

export default Text
