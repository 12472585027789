import PropTypes from 'prop-types'
import { facebookShareUrl } from 'utils/generator'

import { Icon } from 'react-icons-kit'
import { facebook } from 'react-icons-kit/fa/facebook'

import styled from 'styled-components'

export const FBContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`

const FBButton = styled.a`
  color: white;
  background-color: #2665b7;
  display: flex;
  align-items: center;
  width: fit-content;
  max-width: 100%;
  padding: 10px 30px 10px 0;
  border-radius: 3px;
  outline: none;
  border: none;
  cursor: pointer;

  i {
    width: 20px;
    height: 20px;
    padding: 0 20px;
    position: relative;

    svg {
      width: 20px;
      height: 20px;
    }

    &:after {
      content: '';
      width: 1px;
      height: 100%;
      position: absolute;
      top: 0;
      right: -15px;
      background-color: white;
    }
  }

  &:hover {
    color: white;
  }

  span {
    padding-left: 30px;
    text-align: start;
    cursor: pointer;
    font-weight: 700;
  }
`

const FacebookButton = ({ text, tokenId, cardId, appId }) => (
  <FBContainer>
    <FBButton href={facebookShareUrl(appId, tokenId, cardId)} target="_blank">
      <Icon icon={facebook} />
      <span>{text}</span>
    </FBButton>
  </FBContainer>
)

FacebookButton.propTypes = {
  appId: PropTypes.string,
  cardId: PropTypes.any,
  text: PropTypes.string,
  tokenId: PropTypes.any,
}

export default FacebookButton
