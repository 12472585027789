import PropTypes from 'prop-types'
import { useEffect } from 'react'
import styled from 'styled-components'
import { search } from 'react-icons-kit/feather/search'
import { Icon } from 'react-icons-kit'

const StyledContainer = styled.div`
  position: relative;

  @media (max-width: 576px) {
    width: 100%;
  }
`

const StyledInput = styled.input`
  height: 48px;
  border-radius: 10px !important;
  border: 1px solid #bebebe;
  padding: 13px;
  font-size: 15px;
  width: auto;

  @media (max-width: 576px) {
    width: 100%;
  }

  &::placeholder {
    color: #bebebe;
  }
`

const StyledIcon = styled(Icon)`
  position: absolute;
  top: 12px;
  right: 10px;
  color: #bebebe;
  ${({ onClick }) => onClick && 'cursor: pointer'};

  svg {
    width: 24px;
    height: 24px;
  }
`

const SearchBar = ({ placeholder, searchQuery, setSearchQuery }) => {
  useEffect(() => {
    document.addEventListener('keydown', handleOnKeyDown)

    return () => {
      document.removeEventListener('keydown', handleOnKeyDown)
    }
  }, [])

  const handleOnKeyDown = (e) => {
    if (e.key === 'Enter' && !!e.target.value && e.target.name === 'search') {
      setSearchQuery(e.target.value)
    }
  }

  return (
    <StyledContainer>
      <StyledIcon icon={search} />

      <StyledInput
        value={searchQuery}
        onInput={(e) => setSearchQuery(e.target.value)}
        type="search"
        placeholder={placeholder || ''}
        name="search"
      />
    </StyledContainer>
  )
}

SearchBar.defaultProps = {
  searchQuery: '',
  setSearchQuery: () => {},
}

SearchBar.propTypes = {
  placeholder: PropTypes.string,
  searchQuery: PropTypes.string,
  setSearchQuery: PropTypes.func,
}

export default SearchBar
