import { defineMessages } from 'react-intl'

const scope = 'app.components.BasicToast'

export default defineMessages({
  copied: { id: `${scope}.copied` },
  deleted: { id: `${scope}.deleted` },
  error: { id: `${scope}.error` },
  saving: { id: `${scope}.saving` },
  success: { id: `${scope}.success` },
})
