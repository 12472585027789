import { defineMessages } from 'react-intl'

const scope = 'app.components.PartActions.PictureForm'

export default defineMessages({
  compression: { id: `${scope}.compression` },
  compressionInfo: { id: `${scope}.compressionInfo` },
  hideLinkIcon: { id: `${scope}.hideLinkIcon` },
  hideLinkIconHelp: { id: `${scope}.hideLinkIconHelp` },
  image: { id: `${scope}.image` },
  imageError: { id: `${scope}.imageError` },
  imageHelp: { id: `${scope}.imageHelp` },
  imageUrl: { id: `${scope}.imageUrl` },
  imageUrlError: { id: `${scope}.imageUrlError` },
  legend: { id: `${scope}.legend` },
  legendHelp: { id: `${scope}.legendHelp` },
  link: { id: `${scope}.link` },
  linkError: { id: `${scope}.linkError` },
  linkHelp: { id: `${scope}.linkHelp` },
  newTab: { id: `${scope}.newTab` },
  zoom: { id: `${scope}.zoom` },
  zoomHelp: { id: `${scope}.zoomHelp` },
})
