import PropTypes from 'prop-types'
import { useIntl } from 'react-intl'
import { BasicInput, BasicWarning } from 'components'
import { validationsPatterns } from 'utils'

import messages from '../messages'
import { StyledLegend } from './styles'

const PageValidation = () => {
  const intl = useIntl()

  return (
    <>
      <hr />

      <StyledLegend>{intl.formatMessage(messages.pageParams)}</StyledLegend>

      <div style={{ margin: '20px 0 10px 0' }}>
        <BasicWarning text={intl.formatMessage(messages.warning)} />
      </div>

      <BasicInput label={intl.formatMessage(messages.title)} name="onTrigger.validate.title" />

      <BasicInput label={intl.formatMessage(messages.text)} name="onTrigger.validate.text" />

      <BasicInput
        label={intl.formatMessage(messages.imageUrl)}
        name="onTrigger.validate.imageUrl"
        helpMessage={intl.formatMessage(messages.imageUrlHelp)}
        error={intl.formatMessage(messages.urlError)}
        validation={{ pattern: validationsPatterns.url }}
      />

      <BasicInput
        label={intl.formatMessage(messages.buttonText)}
        name={'onTrigger.validate.buttonText'}
        helpMessage={intl.formatMessage(messages.buttonTextHelp)}
      />

      <BasicInput label={intl.formatMessage(messages.textUnderButton)} name="onTrigger.validate.text2" />

      <BasicInput
        label={intl.formatMessage(messages.imageUnderButton)}
        name="onTrigger.validate.imageUrl2"
        error={intl.formatMessage(messages.urlError)}
        validation={{ pattern: validationsPatterns.url }}
      />
    </>
  )
}

PageValidation.propTypes = {
  isChecked: PropTypes.bool,
}

export default PageValidation
