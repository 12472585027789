import PropTypes from 'prop-types'
import { Fragment } from 'react'
import connectStore from 'redux-box/dist/connectStore'
import { storeModule as AppModule } from 'store/app'

import { StyledTagContainer, StyledFolderTag } from './styles'

const FolderTag = ({ app, folders }) => {
  const isNameEmpty = (name) => name && name.split(' ').join('') !== ''

  const getFolder = (id) => app.getFolderById(id)

  // to handle color values before hex were implemented
  const getColor = (color) => {
    switch (color) {
      case 'pink':
        return '#EF456F'
      case 'grey':
        return '#88959F'
      case 'green':
        return '#3DB076'
      case 'blue':
        return '#3F6CB3'
      case 'yellow':
        return '#FFD232'
      case 'orange':
        return '#F59E33'
      default:
        return color
    }
  }

  return (
    <>
      {folders && (
        <StyledTagContainer>
          {folders.map((id, i) => (
            <Fragment key={i}>
              {getFolder(id) && isNameEmpty(getFolder(id).name) && (
                <StyledFolderTag color={getColor(getFolder(id).color)}>{getFolder(id).name}</StyledFolderTag>
              )}
            </Fragment>
          ))}
        </StyledTagContainer>
      )}
    </>
  )
}

FolderTag.propTypes = {
  app: PropTypes.object,
  folders: PropTypes.array,
}

export default connectStore({ app: AppModule })(FolderTag)
