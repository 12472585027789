import PropTypes from 'prop-types'
import { useIntl } from 'react-intl'
import { DropdownItem, UncontrolledDropdown } from 'reactstrap'
import { rteAppId } from 'utils/routes'
import { connectStore } from 'redux-box'
import { storeModule as SessionModule } from 'store/manageUsers'
import { Link } from 'react-router-dom'

import { Icon } from 'react-icons-kit'
import { logOut } from 'react-icons-kit/feather/logOut'

import messages from '../messages'
import { StyledUserIcon, StyledLabel, StyledUserDropdown, StyledUserDropdownToggle } from './styles'

const UserDropdown = ({ appId, onClick, session, isMobile }) => {
  const intl = useIntl()

  return (
    <UncontrolledDropdown
      nav
      inNavbar
      style={isMobile ? { margin: '10px 0' } : { margin: '10px 20px 10px 10px' }}
    >
      <StyledUserDropdownToggle nav caret style={{ padding: '0' }}>
        <StyledUserIcon>
          <svg x="0" y="0" viewBox="0 -50 563.43 563.43" fill="#12263F">
            <path d="M280.79,314.559c83.266,0,150.803-67.538,150.803-150.803S364.055,13.415,280.79,13.415S129.987,80.953,129.987,163.756S197.524,314.559,280.79,314.559z M280.79,52.735c61.061,0,111.021,49.959,111.021,111.021S341.851,274.776,280.79,274.776s-111.021-49.959-111.021-111.021S219.728,52.735,280.79,52.735z" />
            <path d="M19.891,550.015h523.648c11.102,0,19.891-8.789,19.891-19.891c0-104.082-84.653-189.198-189.198-189.198H189.198C85.116,340.926,0,425.579,0,530.124C0,541.226,8.789,550.015,19.891,550.015z M189.198,380.708h185.034c75.864,0,138.313,56.436,148.028,129.524H41.17C50.884,437.607,113.334,380.708,189.198,380.708z" />
          </svg>
        </StyledUserIcon>

        <StyledLabel>
          {session.profile.firstName}&nbsp;{session.profile.lastName}
        </StyledLabel>
      </StyledUserDropdownToggle>

      <StyledUserDropdown>
        <Link to={rteAppId(appId, 'SETTINGS')}>
          <DropdownItem onClick={onClick}>{intl.formatMessage(messages.settings)}</DropdownItem>
        </Link>

        <Link to={rteAppId(appId, 'CONTACT')}>
          <DropdownItem onClick={onClick}>{intl.formatMessage(messages.contact)}</DropdownItem>
        </Link>

        <DropdownItem className="logout" href="/logout/" onClick={onClick}>
          {intl.formatMessage(messages.logout)}
          <Icon icon={logOut} style={{ marginLeft: '10px' }} />
        </DropdownItem>
      </StyledUserDropdown>
    </UncontrolledDropdown>
  )
}

UserDropdown.defaultProps = {
  onClick: () => {},
  user: '',
  isMobile: false,
}

UserDropdown.propTypes = {
  appId: PropTypes.string,
  onClick: PropTypes.func,
  session: PropTypes.object,
  user: PropTypes.string,
  isMobile: PropTypes.bool,
}

export default connectStore({ session: SessionModule })(UserDropdown)
