import PropTypes from 'prop-types'
import { AudioForm, SoundCloudForm } from 'components'

const Audios = ({ part, partIndex, app, onChange, onVideoChange, onFormChange }) => {
  return (
    <>
      {part.type === 'Audio' && (
        <AudioForm onChange={onChange} onVideoChange={onVideoChange} part={part} partIndex={partIndex} onFormChange={onFormChange} />
      )}

      {part.type === 'SoundCloud' && <SoundCloudForm onChange={onChange} part={part} partIndex={partIndex} onFormChange={onFormChange} />}
    </>
  )
}

Audios.propTypes = {
  app: PropTypes.object,
  onChange: PropTypes.func,
  onVideoChange: PropTypes.func,
  part: PropTypes.object,
  partIndex: PropTypes.number,
  onFormChange: PropTypes.func,
}

export default Audios
