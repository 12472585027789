import PropTypes from 'prop-types'
import { RedirectForm, RandomRedirectForm, PartRandomReset, RandomRedirectStoryForm, RandomRedirectStoryResetForm } from 'components'

const Redirections = ({ part, partIndex, app, tokenId, onChange, onCheckedChange, toggleDisable, onFormChange }) => {
  return (
    <>
      {part.type === 'Redirect' && <RedirectForm onChange={onChange} part={part} partIndex={partIndex} onFormChange={onFormChange} />}

      {part.type === 'Random' && (
        <RandomRedirectForm
          onChange={onChange}
          onCheckedChange={onCheckedChange}
          part={part}
          partIndex={partIndex}
          onFormChange={onFormChange}
        />
      )}

      {part.type === 'RandomReset' && (
        <PartRandomReset
          appId={app.id}
          handleOnChange={onChange}
          options={part.options}
          part={part}
          partIndex={partIndex}
          tokenId={tokenId}
          onFormChange={onFormChange}
        />
      )}

      {part.type === 'RandomStory' && (
        <RandomRedirectStoryForm
          appId={app.id}
          onChange={onChange}
          part={part}
          partIndex={partIndex}
          toggleDisable={toggleDisable}
        />
      )}

      {part.type === 'RandomStoryReset' && (
        <RandomRedirectStoryResetForm
          onChange={onChange}
          part={part}
          partIndex={partIndex}
          toggleDisable={toggleDisable}
        />
      )}
    </>
  )
}

Redirections.propTypes = {
  app: PropTypes.object,
  onChange: PropTypes.func,
  part: PropTypes.object,
  partIndex: PropTypes.number,
  tokenId: PropTypes.number,
  onFormChange: PropTypes.func,
}

export default Redirections
