const mutations = {
  // used on fetch card content
  CARD_SET_CARDINFO: (state, { card }) => {
    state.cardId = card.cardId
    state.folders = card.folders || []
    state.parent = card.parent
    state.parts = card.parts
    state.place = card.place || ''
    state.style = card.style || ''
    state.defaultTheme = card.defaultTheme || ''
    state.theme = card.theme || ''
    state.linkLogo = card.linkLogo ? card.linkLogo : ''
    state.openBlankLinkLogo = card.openBlankLinkLogo || false
    state.title = card.title
    state.tagGaChecked = card.tagGaChecked
    state.tagGaLabel = card.tagGaLabel || ''
    state.tokenId = card.tokenId
    state.type = card.type
    state.updateTime = card.updateTime
  },
  // used on delete content
  CARD_SET_CARD_CONTENT: (state, { card }) => {
    state.parts = card.parts
    state.title = card.title
    state.tagGaChecked = card.tagGaChecked
    state.tagGaLabel = card.tagGaLabel
    state.place = card.place
    state.style = card.style
    state.updateTime = card.updateTime
    state.theme = card.theme
    state.linkLogo = card.linkLogo
    state.openBlankLinkLogo = card.openBlankLinkLogo
    state.folders = card.folders
  },
  // folders (in cards = array of ids)
  CARD_ADD_FOLDER: (state, { folder }) => {
    state.folders = [...state.folders, folder.id]
  },
  CARD_SET_FOLDERS: (state, { folderIds }) => {
    state.folders = folderIds
  },
  CARD_RESET_STATE: (state) => {
    state.cardId = ''
    state.createdTime = 0
    state.defaultTheme = ''
    state.folders = []
    state.parent = undefined
    state.parts = [{ type: '' }]
    state.place = ''
    state.style = ''
    state.theme = ''
    state.linkLogo = ''
    state.openBlankLinkLogo = false
    state.title = ''
    state.tagGaChecked = true
    state.tagGaLabel = ''
    state.tokenId = 0
    state.type = 'card'
    state.updateTime = 0
  },
  // status
  CARD_RESET_STATUS: (state) => {
    state.fetching = false
    state.saving = false
    state.success = false
    state.deleting = false
    state.deleted = false
    state.error = false
    state.copied = false
  },
  // fetch
  CARD_FETCHING: (state) => {
    state.fetching = true
  },
  CARD_FETCH_ERROR: (state) => {
    state.fetching = false
    state.error = true
  },
  CARD_FETCH_SUCCESS: (state) => {
    state.fetching = false
  },
  // copy
  CARD_COPY_SUCCESS: (state) => {
    state.copied = true
    state.saving = false
    state.error = false
  },
  // save
  CARD_SAVING: (state) => {
    state.saving = true
    state.success = false
    state.error = false
  },
  CARD_SAVE_SUCCESS: (state) => {
    state.saving = false
    state.success = true
    state.error = false
  },
  CARD_SAVE_ERROR: (state) => {
    state.saving = false
    state.success = false
    state.error = true
  },
  // delete
  CARD_DELETING: (state) => {
    state.deleting = true
    state.deleted = false
    state.error = false
  },
  CARD_DELETE_SUCCESS: (state) => {
    state.deleting = false
    state.deleted = true
    state.error = false
  },
  CARD_DELETE_ERROR: (state) => {
    state.deleting = false
    state.deleted = false
    state.error = true
  },
}

export default mutations
