import PropTypes from 'prop-types'
import objectPath from 'object-path'
import { BasicInput, DropZone, BasicFormSection } from 'components'
import { useIntl } from 'react-intl'
import { FormText, Col } from 'reactstrap'
import { TEMPLATES } from 'utils/consts'

import { StyledLegend } from './styles'
import messages from '../messages'

const Template = ({ appId, data, template, onTemplateChange }) => {
  const isAC = appId.indexOf('alatable') !== -1 || appId.indexOf('aircanada') !== -1

  const intl = useIntl()

  return (
    <>
      {isAC ? (
        <>
          <hr />

          <BasicInput
            name="template"
            type="select"
            label={intl.formatMessage(messages.template)}
            helpMessage={intl.formatMessage(messages.templateHelp)}
            onChange={onTemplateChange}
            required
          >
            <option value="default">{intl.formatMessage(messages.templateDefault)}</option>
            <option value="AirCanada">{intl.formatMessage(messages.templateAC)}</option>
          </BasicInput>
        </>
      ) : (
        <BasicInput type="hidden" name="template" value="default" />
      )}

      {template && template !== 'default' && (
        <BasicFormSection>
          <Col>
            <StyledLegend htmlFor="presentation">
              {intl.formatMessage(messages.titleCustomFields)}
            </StyledLegend>
            <FormText>{intl.formatMessage(messages.customFieldsHelp)}</FormText>
            {TEMPLATES[template] &&
              TEMPLATES[template].map((input, i) =>
                input.inputType === 'file' ? (
                  <DropZone
                    label={intl.formatMessage(messages[input.label])}
                    helpMessage={intl.formatMessage(messages[input.helpMessage])}
                    accept={input.accept}
                    name={input.name}
                    url={objectPath.get(data, input.name)}
                    type="image"
                    key={i}
                  />
                ) : (
                  <BasicInput
                    name={input.name}
                    label={intl.formatMessage(messages[input.label])}
                    helpMessage={intl.formatMessage(messages[input.helpMessage])}
                    type={input.type}
                    required={input.required}
                    error={input.errorMessage && intl.formatMessage(messages[input.errorMessage])}
                    validation={input.validation && input.validation}
                  />
                )
              )}
          </Col>
        </BasicFormSection>
      )}
    </>
  )
}

Template.propTypes = {
  appId: PropTypes.string,
  data: PropTypes.object,
  onTemplateChange: PropTypes.func,
  template: PropTypes.string,
}

export default Template
