import PropTypes from 'prop-types'
import qs from 'qs'
import { useState, useEffect } from 'react'
import { withRouter } from 'react-router-dom'
import { Button } from 'reactstrap'
import { Link } from 'react-router-dom'
import { useIntl } from 'react-intl'
import { rteCardsTag, rteFolderTag } from 'utils/routes'

import { StyledContainer, StyledSmallLabel, StyledButtons } from './styles'
import messages from './messages'

const Filters = ({ currentFolder, tagId, appId, noActive, onClick, location }) => {
  const [cardsView, setCardsView] = useState('collapse')

  const intl = useIntl()

  useEffect(() => {
    const currentView = qs.parse(location.search, { ignoreQueryPrefix: true }).view
    if (currentView) {
      setCardsView(currentView)
    } else {
      setCardsView('collapse')
    }
  }, [location])

  return (
    <StyledContainer>
      <StyledSmallLabel style={{ marginBottom: '-10px' }}>
        {intl.formatMessage(messages.show)}
      </StyledSmallLabel>

      <StyledButtons>
        <Button
          active={!noActive && tagId === 'all'}
          tag={Link}
          to={{
            pathname: currentFolder
              ? rteFolderTag(appId, currentFolder.id, 'all')
              : rteCardsTag(appId, 'all'),
            search: `?view=${cardsView}`,
          }}
          onClick={onClick}
        >
          {intl.formatMessage(messages.all)}
        </Button>

        <Button
          active={!noActive && (tagId === 'linked' || !tagId)}
          tag={Link}
          to={{
            pathname: currentFolder
              ? rteFolderTag(appId, currentFolder.id, 'linked')
              : rteCardsTag(appId, 'linked'),
            search: `?view=${cardsView}`,
          }}
          onClick={onClick}
        >
          {intl.formatMessage(messages.linkedCards)}
        </Button>

        <Button
          active={!noActive && tagId === 'unlinked'}
          tag={Link}
          to={{
            pathname: currentFolder
              ? rteFolderTag(appId, currentFolder.id, 'unlinked')
              : rteCardsTag(appId, 'unlinked'),
            search: `?view=${cardsView}`,
          }}
          onClick={onClick}
        >
          {intl.formatMessage(messages.unlinkedCards)}
        </Button>
      </StyledButtons>
    </StyledContainer>
  )
}

Filters.propTypes = {
  appId: PropTypes.string,
  currentFolder: PropTypes.object,
  location: PropTypes.object,
  noActive: PropTypes.bool,
  onClick: PropTypes.func,
  tagId: PropTypes.string,
}

export default withRouter(Filters)
