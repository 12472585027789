import PropTypes from 'prop-types'
import { Component } from 'react'
import { injectIntl } from 'react-intl'
import { CardBody, Label, Button, Card, Row, Col, Alert } from 'reactstrap'
import { AvForm, AvGroup, AvInput, AvFeedback } from 'availity-reactstrap-validation'

import messages from '../messages'

class FeedbackCreationForm extends Component {
  constructor(props, ...rest) {
    super(props, ...rest)
    this.state = {}
    this.submitForm = this.submitForm.bind(this)
  }
  submitForm(event, values) {
    this.props.onValidSubmit(values)
    if (this.form) {
      this.form.reset()
    }
  }

  render() {
    const { status, intl } = this.props
    return (
      <div>
        <h4>{intl.formatMessage(messages.createModule)}</h4>
        <Card>
          <CardBody>
            <AvForm
              onValidSubmit={this.submitForm}
              ref={(c) => {
                this.form = c
              }}
            >
              <Row>
                <Col>
                  <AvGroup>
                    <Label htmlFor="name" required>
                      {intl.formatMessage(messages.moduleName)}
                    </Label>
                    <AvInput type="text" name="name" id="name" disabled={status.fetching} required />
                    <AvFeedback style={{ marginTop: '10px' }}>
                      {intl.formatMessage(messages.feedbackError)}
                    </AvFeedback>
                  </AvGroup>
                </Col>
                <Col>
                  <AvGroup>
                    <Label htmlFor="name">{intl.formatMessage(messages.moduleLocation)}</Label>
                    <AvInput type="text" name="place" id="place" disabled={status.fetching} />
                  </AvGroup>
                </Col>
                <Col>
                  <Button
                    style={{ marginTop: '2rem', paddingRight: '1.5rem' }}
                    btn-color="green"
                    size="lg"
                    className="ico classic"
                  >
                    <i>+</i>
                    {intl.formatMessage(messages.addModule)}
                  </Button>
                </Col>
              </Row>
              {status.sent && <Alert color="success">{intl.formatMessage(messages.feedbackSuccess)}</Alert>}
            </AvForm>
          </CardBody>
        </Card>
      </div>
    )
  }
}

FeedbackCreationForm.propTypes = {
  onValidSubmit: PropTypes.func.isRequired,
  status: PropTypes.object.isRequired,
}

export default injectIntl(FeedbackCreationForm)
