import { defineMessages } from 'react-intl'

const scope = 'app.components.PartActions.SubtitleForm'

export default defineMessages({
  required: { id: `${scope}.required` },
  subtitle: { id: `${scope}.subtitle` },
  subtitleHelp: { id: `${scope}.subtitleHelp` },
  type: { id: `${scope}.type` },
  subtitleH2: { id: `${scope}.subtitleH2` },
  subtitleH3: { id: `${scope}.subtitleH3` },
})
