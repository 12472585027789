const mutations = {
  RANDOM_STORY_SAVE_ERROR: (state) => {
    state.error = true
    state.saving = false
    state.success = false
  },
  RANDOM_STORY_SAVE_SUCCESS: (state) => {
    state.error = false
    state.saving = false
    state.success = true
  },
  RANDOM_STORY_SAVING: (state) => {
    state.error = false
    state.saving = true
    state.success = false
  },
  RANDOM_STORY_STATUS_RESET: (state) => {
    state.deleted = false
    state.deleting = false
    state.error = false
    state.saving = false
    state.success = false
  },
  RANDOM_STORY_DELETING: (state) => {
    state.deleted = false
    state.deleting = true
    state.error = false
  },
  RANDOM_STORY_DELETE_SUCCESS: (state) => {
    state.deleted = true
    state.deleting = false
    state.error = false
  },
  RANDOM_STORY_GET_LIST: (state) => {
    state.fetching = true
    state.error = false
    state.success = false
  },
  RANDOM_STORY_SET_LIST: (state, { data }) => {
    state.list = data || {}

    state.fetching = false
    state.error = false
    state.success = false
  },
  RANDOM_STORY_ADD_TO_LIST: (state, { data }) => {
    state.list[data.id] = data

    state.error = false
    state.success = false
  },
  RANDOM_STORY_DELETE_FROM_LIST: (state, { id }) => {
    const list = { ...state.list }
    delete list[id]
    state.list = list

    state.error = false
  },
  RANDOM_STORY_ERROR: (state) => {
    state.error = true
  },
}
export default mutations
