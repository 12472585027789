import PropTypes from 'prop-types'
import { useIntl } from 'react-intl'
import { StyledTagContainer, StyledSystemTag } from './styles'
import messages from '../messages'

const SystemTag = ({ type }) => {
  const intl = useIntl()

  return (
    <>
      {type && type !== 'card' && type !== 'unlinked' && (
        <StyledTagContainer>
          <StyledSystemTag>
            {intl.formatMessage(messages.system)}&nbsp;{type}
          </StyledSystemTag>
        </StyledTagContainer>
      )}
    </>
  )
}

SystemTag.propTypes = {
  type: PropTypes.string,
}

export default SystemTag
