import PropTypes from 'prop-types'
import { rteModulesId } from 'utils/routes'
import { useIntl } from 'react-intl'
import { BasicInfo, BasicButton } from 'components'
import styled from 'styled-components'

import { connectStore } from 'redux-box'
import { storeModule as ModuleModules } from 'store/modules'

import { Icon } from 'react-icons-kit'
import { externalLink } from 'react-icons-kit/feather'

import messages from '../messages'

const StyledContainer = styled.div`
  margin: 10px 20px;
`

const ItemInfo = ({ appId, modules, type, parent, wrap }) => {
  const hasLinkedCard = parent && modules.getListItemById(parent)
  const intl = useIntl()
  return (
    <>
      {type === 'listitem' && (
        <StyledContainer>
          <BasicInfo
            text={intl.formatMessage(hasLinkedCard ? messages.itemLinked : messages.itemDeleted)}
            children={
              hasLinkedCard && (
                <BasicButton
                  label={intl.formatMessage(messages.viewItem)}
                  icon={<Icon icon={externalLink} />}
                  isLink
                  href={rteModulesId(appId, 'ITEMS_SINGLE', parent)}
                  target="_blank"
                  linkstyle
                  small
                />
              )
            }
            wrap={wrap}
          />
        </StyledContainer>
      )}
    </>
  )
}

ItemInfo.propTypes = {
  appId: PropTypes.string,
  modules: PropTypes.object,
  type: PropTypes.string,
  parent: PropTypes.string,
  wrap: PropTypes.bool,
}

export default connectStore({ modules: ModuleModules })(ItemInfo)
