import PropTypes from 'prop-types'
import { BasicTooltip, BasicButton } from 'components'
import uid from 'uid'

import { storeModule as AppModule } from 'store/app'
import connectStore from 'redux-box/dist/connectStore'

import { Icon } from 'react-icons-kit'
import { ic_schedule as AlarmIcon } from 'react-icons-kit/md/ic_schedule'

import ScheduleResume from 'components/PartOptions/ScheduleResume'
import { StyledIcon } from './styles'

const ScheduleTooltip = ({ app: { access }, part }) => {
  const partId = uid()

  const hasSchedule =
    part &&
    !!part.options &&
    !!part.options.display &&
    !!part.options.display.range &&
    !!part.options.display.range[0].date

  return (
    <>
      {hasSchedule && (
        <>
          <StyledIcon>
            <div data-for={`options-${partId}`} data-tip="">
              <BasicButton icon={<Icon icon={AlarmIcon} />} small outlined round color="yellow" />
            </div>
          </StyledIcon>

          <BasicTooltip
            id={`options-${partId}`}
            content={<ScheduleResume values={part.options.display} color="white" inForm={false} />}
            maxWidth={250}
          />
        </>
      )}
    </>
  )
}

ScheduleTooltip.propTypes = {
  app: PropTypes.object,
  part: PropTypes.object,
}

export default connectStore({ app: AppModule })(ScheduleTooltip)
