import { Icon } from 'react-icons-kit'
import styled from 'styled-components'

export const StyledTimeDateIcon = styled(Icon)`
  color: #6c757d;
  padding-left: 5px;
  display: flex !important;

  svg {
    height: 20px;
    width: 20px;
  }
`

export const DatePickerWrapper = styled.div`
  display: flex;
  margin: -5px 0;

  @media (max-width: 992px) {
    flex-direction: column;
  }

  select {
    margin-right: 10px;

    @media (max-width: 992px) {
      margin-right: 0;
    }
  }

  .ant-picker {
    margin: 10px 0 10px 10px !important;
    margin-left: 10px;

    @media (max-width: 992px) {
      margin: -10px 0 20px 0 !important;
    }
  }
`

export const TimeZonesWrapper = styled.div`
  display: flex;

  @media (max-width: 576px) {
    flex-direction: column;
  }

  div {
    flex: 1;
    margin-bottom: -10px;
  }

  .form-group {
    flex: 1;

    &:first-of-type {
      margin-right: 10px;
    }
  }
`
