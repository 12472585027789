import PropTypes from 'prop-types'
import { useIntl } from 'react-intl'
import { useState, useEffect } from 'react'

import { connectStore } from 'redux-box'
import { storeModule as AppModule } from 'store/app'
import { storeModule as DataModule } from 'store/data'
import { storeModule as ModulesModule } from 'store/modules'

import { Icon } from 'react-icons-kit'
import { ic_color_lens as IconTheme } from 'react-icons-kit/md/ic_color_lens'
import { loader } from 'react-icons-kit/feather/loader'

import { StyledTagContainer, StyledThemeTag, StyledLoader } from './styles'
import messages from '../messages'

const ThemeTag = ({ app, data, modules, theme }) => {
  const [cardTheme, setCardTheme] = useState({})

  const intl = useIntl()

  const getCardTheme = () => {
    let res = ''
    if (theme) {
      res = theme
    } else if (app.theme && getThemes().find((t) => t.value === app.theme)) {
      res = app.theme
    } else {
      Object.values(getThemes()).forEach((t) => {
        if (t.value === app.id) {
          res = app.id
        }
      })
    }

    if (!!res) {
      res = res.replaceAll('-', '_')
      const themeExists = Object.values(getThemes()).filter((t) => t.value === res)
      if (themeExists.length > 0) return themeExists
    }

    return Object.values(getThemes()).filter((t) => t.value === 'msj_logo')
  }

  const getThemes = () => {
    let themes = []
    // eslint-disable-next-line array-callback-return
    Object.values(data.themes).map((t) => {
      if (app.themes.indexOf(t.value) !== -1) {
        themes.push(t)
      } else if (t.value === app.id) {
        themes.push(t)
      }
    })
    return themes
  }

  useEffect(() => {
    setCardTheme(getCardTheme())
  }, [app])

  return (
    <StyledTagContainer>
      {app.fetching || modules.fetching ? (
        <StyledLoader>
          <Icon icon={loader} />
        </StyledLoader>
      ) : (
        <StyledThemeTag>
          <Icon icon={IconTheme} />
          {intl.formatMessage(messages.theme)}&nbsp;{cardTheme.length > 0 && cardTheme[0].name}
        </StyledThemeTag>
      )}
    </StyledTagContainer>
  )
}

ThemeTag.propTypes = {
  app: PropTypes.object.isRequired,
  data: PropTypes.object.isRequired,
  modules: PropTypes.object.isRequired,
  theme: PropTypes.string,
}

export default connectStore({ app: AppModule, data: DataModule, modules: ModulesModule })(ThemeTag)
