import React from 'react'
import PropTypes from 'prop-types'
import { Label } from 'reactstrap'
import { AvGroup, AvInput, AvFeedback } from 'availity-reactstrap-validation'

import { FormattedMessage } from 'react-intl'
import messages from './messages'

class PartSurveyMonkeyForm extends React.Component {
  constructor(props) {
    super(props)
    this.state = { userInput: '', webLink: '', installationCode: '' }
    this.surveyMonkeyParser = this.surveyMonkeyParser.bind(this)
    this.parseScriptTag = this.parseScriptTag.bind(this)
    this.parseLink = this.parseLink.bind(this)
  }

  surveyMonkeyParser(userInput) {
    if (userInput.includes('script')) {
      this.parseScriptTag(userInput)
    } else {
      this.parseLink(userInput)
    }
  }

  parseScriptTag(input) {
    const startIndex = input.indexOf('(function')
    const endIndex = input.indexOf('</script>')

    const installationCode = input.substring(startIndex, endIndex)

    const e = { target: { id: 'src', value: installationCode } }
    this.props.handleOnChange(e, this.props.partIndex)
  }

  parseLink(input) {
    const e = { target: { id: 'src', value: input } }
    this.setState({ webLink: input })
    this.props.handleOnChange(e, this.props.partIndex)
  }

  render() {
    return (
      <div>
        <AvGroup>
          <Label htmlFor={'userInput'}>
            <FormattedMessage {...messages.inputLabel} />
          </Label>
          <AvInput
            type="textarea"
            name={`userInput-${this.props.partIndex}`}
            id="userInput"
            value={this.props.userInput}
            onChange={(e) => {
              this.props.handleOnChange(e, this.props.partIndex)
              this.surveyMonkeyParser(e.target.value)
            }}
            required
          />
          <AvFeedback>
            <FormattedMessage {...messages.inputError} />
          </AvFeedback>
        </AvGroup>
      </div>
    )
  }
}

PartSurveyMonkeyForm.propTypes = {
  handleOnChange: PropTypes.func.isRequired,
  partIndex: PropTypes.number.isRequired,
  userInput: PropTypes.string,
}

export default PartSurveyMonkeyForm
