export const parseDate = (dateStr) => {
  if (!dateStr) return null;

  const parts = dateStr.split('/');
  const day = parseInt(parts[0], 10);
  const month = parseInt(parts[1], 10) - 1;
  const year = parseInt(parts[2], 10);

  return new Date(year, month, day);
}

export const checkAccessDates = (access) => {
  const currentDate = new Date();
  const cardStartDate = access.startDate ? parseDate(access.startDate) : null;
  const cardEndDate = access.endDate ? parseDate(access.endDate) : null;

  if (cardStartDate) {
    cardStartDate.setHours(0, 0, 0, 0);
  }
  if (cardEndDate) {
    cardEndDate.setHours(23, 59, 59, 999);
  }

  if (cardStartDate && cardEndDate) {
    return currentDate >= cardStartDate && currentDate <= cardEndDate;
  } else if (cardStartDate) {
    return currentDate >= cardStartDate;
  } else if (cardEndDate) {
    return currentDate <= cardEndDate;
  }
  return true;
}

export const getUserApps = (apps, user) => {
   const userAppsDisabled = user.apps
    .filter(app => {
      return app.roles.some(role => {
        const isDisabled = role.disable === true;
        const dateCheck = checkAccessDates(role);
        return isDisabled || !dateCheck;
      });
    })
    .map(app => app.appId);

  return apps.filter(appId => !userAppsDisabled.includes(appId));
}
