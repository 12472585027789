import PropTypes from 'prop-types'
import { useState } from 'react'
import { useIntl } from 'react-intl'
import { BasicInput, BasicInputArray, BasicInputDuration, BasicRadioButtons, BasicInfo } from 'components'
import { validationsPatterns } from 'utils'

import { StyledLegend } from './styles'
import messages from '../messages'

const Answers = ({ lock }) => {
  const [onError, setOnError] = useState(lock && lock.onError ? lock.onError.action : '')

  const intl = useIntl()

  const handleCheckChange = (e) => setOnError(e.target.value)

  return (
    <>
      <StyledLegend>{intl.formatMessage(messages.answers)}</StyledLegend>

      <BasicInfo text={intl.formatMessage(messages.answersInfo)} />

      <BasicInputArray
        name={'answers'}
        list={lock ? lock.answers : null}
        errorMessage={intl.formatMessage(messages.answerErrors)}
        label={intl.formatMessage(messages.acceptedAnswers)}
        helpMessage={intl.formatMessage(messages.acceptedAnswersHelp)}
        addLabel={intl.formatMessage(messages.addAnswers)}
        required
      />

      <BasicInputDuration
        label={intl.formatMessage(messages.unlockTime)}
        helpMessage={intl.formatMessage(messages.unlockTimeHelp)}
        name="unlockTime"
        values={lock && Object.keys(lock).length > 0 ? lock.unlockTime : {}}
      />

      <StyledLegend color="green" style={{ marginTop: '10px' }}>
        {intl.formatMessage(messages.rightAnswerTitle)}
      </StyledLegend>

      <BasicInput
        name="onSuccess.url"
        label={intl.formatMessage(messages.redirectUrl)}
        helpMessage={intl.formatMessage(messages.redirectHelp)}
        error={intl.formatMessage(messages.redirectError)}
        placeholder="https://youtu.be/dQw4w9WgXcQ"
        required
        validation={{
          pattern: validationsPatterns.url,
        }}
      />

      <StyledLegend color="orange" style={{ marginTop: '20px' }}>
        {intl.formatMessage(messages.wrongAnswerTitle)}
      </StyledLegend>

      <BasicRadioButtons
        name="onError.action"
        label={intl.formatMessage(messages.action)}
        error={intl.formatMessage(messages.actionError)}
        onChange={handleCheckChange}
        radioButtons={[
          { label: intl.formatMessage(messages.wrongAnswerSubtitle), value: 'message' },
          { label: intl.formatMessage(messages.wrongAnswerRedirect), value: 'redirect' },
        ]}
        required
      />

      {onError === 'redirect' && (
        <BasicInput
          label={intl.formatMessage(messages.redirectUrl)}
          type="text"
          name="onError.url"
          error={intl.formatMessage(messages.redirectError)}
          placeholder="https://youtu.be/dQw4w9WgXcQ"
          required
          validation={{
            pattern: validationsPatterns.url,
          }}
        />
      )}

      {onError === 'message' && (
        <BasicInput
          label={intl.formatMessage(messages.errorMsg)}
          helpMessage={intl.formatMessage(messages.errorMsgHelp)}
          placeholder={intl.formatMessage(messages.errorMsgPlaceholder)}
          error={intl.formatMessage(messages.errorMsgError)}
          type="text"
          name="onError.message"
          required
        />
      )}
    </>
  )
}

Answers.propTypes = {
  lock: PropTypes.object,
}

export default Answers
