import PropTypes from 'prop-types'
import { useState, useEffect } from 'react'
import { useIntl } from 'react-intl'
import { Lock } from 'components'

import connectStore from 'redux-box/dist/connectStore'
import { storeModule as LockModule } from 'store/locks'

import NotFound from './NotFound'
import messages from '../messages'

const ModuleLock = ({ modules, id }) => {
  const [lock, setLock] = useState()
  const intl = useIntl()

  useEffect(() => {
    const foundLock = modules.getLockById(id) || {}
    setLock(foundLock)
  }, [modules])

  return (
    <div style={{ display: 'flex', flexDirection: 'column', margin: '0 auto' }}>
      {Object.keys(lock).length > 0 ? (
        <Lock values={lock} />
      ) : (
        <NotFound id={id} type={intl.formatMessage(messages.lock)} fetching={modules.fetching} />
      )}
    </div>
  )
}

ModuleLock.propTypes = {
  modules: PropTypes.object,
  id: PropTypes.string,
}

export default connectStore({ modules: LockModule })(ModuleLock)
