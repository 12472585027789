import moment from 'moment'
import { chunkArray } from 'services/utils'
import { NBR_PHOTOBOOTH_FILES_PER_PAGE } from 'utils/consts'

const mutations = {
  DATA_GET_VOXPOPLIST: (state) => {
    state.loading = true
  },
  DATA_SET_VOXPOPLIST: (state, { data }) => {
    state.voxpopList = data
    state.loading = false
  },
  DATA_GET_VOXPOPFILES: (state) => {
    state.loading = true
  },
  DATA_REMOVE_VOXPOPFILES: (state) => {
    state.displayFiles = false
  },
  DATA_SET_VOXPOPFILES: (state, { voxpopId, data }) => {
    const list = { ...state.voxpopList }
    const index = Object.values(list).findIndex((v) => v.id === voxpopId)
    const voxpop = Object.values(list).filter((v) => v.id === voxpopId)

    const dataWithTimestamp = data.map((file) => {
      file.dateAdded.timestamp = file.dateAdded.seconds * 1000
      file.dateAddeString = moment(file.dateAdded.timestamp).format('DD-MM-YYYY')
      return file
    })
    const group = chunkArray(dataWithTimestamp, NBR_PHOTOBOOTH_FILES_PER_PAGE)

    voxpop.completeFiles = group

    list[index] = { ...list[index], completeFiles: group }
    state.voxpopList = list

    state.displayPage = 0
    state.displayVoxpopId = voxpopId
    state.loading = false
    state.displayFiles = group
  },
  DATA_SET_DISPLAYPAGE: (state, { page }) => {
    state.displayPage = page - 1
  },
}
export default mutations
