import { defineMessages } from 'react-intl'

const scope = 'app.components.BasicInputDuration'

export default defineMessages({
  days: { id: `${scope}.days` },
  hours: { id: `${scope}.hours` },
  minutes: { id: `${scope}.minutes` },
  timeUnit: { id: `${scope}.timeUnit` },
  unitError: { id: `${scope}.unitError` },
  unlockTimeError: { id: `${scope}.unlockTimeError` },
})
