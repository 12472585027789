import { DropdownItem, DropdownToggle } from 'reactstrap'
import styled, { css } from 'styled-components'

export const StyledActionTitle = styled.div`
  display: flex;
  align-items: flex-end;
  font-family: Nunito;
  font-size: 18px;
  font-weight: 600;
  color: #11263f;
  margin-bottom: 20px;

  @media (max-width: 579px) {
    margin-top: 30px;
  }
`

export const StyledItem = styled(DropdownItem)`
  display: flex;
  flex-direction: row;
  align-items: center;
  outline: none !important;
  cursor: pointer;

  ${({ selected }) =>
    selected &&
    css`
      color: #7094cc;
      background-color: #f8f9fa;
    `}

  &:hover, &:active {
    color: #7094cc;
    background-color: #f8f9fa;
  }

  i {
    display: flex !important;
    margin-left: 7px;
  }
`

export const StyledLegend = styled.legend`
  color: #3f6cb3;
  font-weight: 600 !important;
  font-size: 14px !important;
  padding: 4px 24px;
  margin: 10px 0 0 0 !important;
`

export const StyledToggle = styled(DropdownToggle)`
  height: 48px;
  border-radius: 10px !important;
  border: 1px solid #bebebe;
  padding: 13px;
  font-size: 15px;
  margin-bottom: 10px;
  width: 100%;
  height: 48px;
  color: #495057;
  background-color: white;
  text-align: start;
  background-image: url("data:image/svg+xml;utf8,<svg fill='black' height='24' viewBox='0 0 24 24' width='24' xmlns='http://www.w3.org/2000/svg'><path d='M7 10l5 5 5-5z'/><path d='M0 0h24v24H0z' fill='none'/></svg>");
  background-position: 99% center;
  background-repeat: no-repeat;
  cursor: default !important;

  &:focus {
    color: #495057;
    background-color: #fff;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgb(0 123 255 / 25%) !important;
  }
`
