import PropTypes from 'prop-types'
import { useIntl } from 'react-intl'
import styled from 'styled-components'
import msjSurpris from 'assets/img/MSJ-Surpris.png'

import messages from './messages'

const StyledContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  background-color: white;
  padding-bottom: 20px;
  padding-right: 20px;
  ${({ inmodal }) => inmodal === 'true' && 'margin-top: -30px'};

  img {
    max-width: 150px;
  }

  p {
    font-family: Nunito;
    font-size: 16px;
    line-height: 18px;
    font-weight: 600;
    padding-top: 20px;
  }
`

const StyledRow = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 20px;
`


const ModalFolder = ({ inModal }) => {
  const intl = useIntl()

  return (
    <StyledContainer inmodal={String(inModal)}>
      <img src={msjSurpris} alt="emoji" />

      <StyledRow>
        <p>{intl.formatMessage(messages.ohNo)}</p>
      </StyledRow>
    </StyledContainer>
  )
}

ModalFolder.propTypes = {
  inModal: PropTypes.bool,
}

export default ModalFolder
