import PropTypes from 'prop-types'
import { useContext } from 'react'
import { LangContext } from 'containers/LanguageProvider'
import { DropdownItem, UncontrolledDropdown } from 'reactstrap'
import { useIntl } from 'react-intl'

import messages from '../messages'
import { StyledLPLabel, StyledLPDropdown, StyledUserDropdownToggle } from './styles'

const LanguageToggle = ({ onClick, isMobile }) => {
  const context = useContext(LangContext)

  const intl = useIntl()

  return isMobile ? (
    <StyledLPLabel
      onClick={() => {
        context.selectLanguage(context.locale.includes('en') ? 'fr' : 'en')
        onClick()
      }}
      style={{ cursor: 'pointer' }}
    >
      {context.locale.includes('en') ? 'FR' : 'EN'}
    </StyledLPLabel>
  ) : (
    <UncontrolledDropdown nav inNavbar style={{ margin: '15px 0 0 0' }}>
      <StyledUserDropdownToggle nav caret style={{ padding: '0' }}>
        <StyledLPLabel>{context.locale.includes('en') ? 'EN' : 'FR'} </StyledLPLabel>
      </StyledUserDropdownToggle>

      <StyledLPDropdown style={{ marginRight: '100px' }}>
        <DropdownItem
          onClick={() => {
            context.selectLanguage('fr')
            onClick()
          }}
        >
          {intl.formatMessage(messages.fr)}
        </DropdownItem>
        <DropdownItem
          onClick={() => {
            context.selectLanguage('en')
            onClick()
          }}
        >
          {intl.formatMessage(messages.en)}
        </DropdownItem>
      </StyledLPDropdown>
    </UncontrolledDropdown>
  )
}

LanguageToggle.defaultProps = {
  onClick: () => {},
  isMobile: false,
}

LanguageToggle.propTypes = {
  onClick: PropTypes.func,
  isMobile: PropTypes.bool,
}

export default LanguageToggle
