import PropTypes from 'prop-types'
import { BasicInput, DropZone, BasicInfo } from 'components'
import { useIntl } from 'react-intl'
import { StyledLegend } from './styles'
import messages from '../messages'

const PageInfo = ({ image }) => {
  const intl = useIntl()

  return (
    <>
      <StyledLegend>{intl.formatMessage(messages.productPage)}</StyledLegend>

      <div style={{ marginTop: '20px' }}>
        <BasicInput
          name="pageTitle"
          label={intl.formatMessage(messages.pageTitle)}
          helpMessage={intl.formatMessage(messages.pageTitleHelp)}
        />

        <DropZone
          label={intl.formatMessage(messages.image)}
          helpMessage={intl.formatMessage(messages.imageHelp)}
          accept="image/jpeg, image/png, image/x-png, image/svg+xml"
          name="img"
          url={image}
          type="image"
        />

        <div style={{ margin: '-15px 0 0 0' }}>
          <BasicInfo text={intl.formatMessage(messages.imageFormat)} />
        </div>

        <BasicInput
          name="text"
          label={intl.formatMessage(messages.text)}
          type="textarea"
          helpMessage={intl.formatMessage(messages.textHelp)}
        />
      </div>
    </>
  )
}

PageInfo.propTypes = {
  image: PropTypes.string,
}

export default PageInfo
