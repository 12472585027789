import PropTypes from 'prop-types'
import { useIntl } from 'react-intl'
import { BasicInfo, BasicInput, PartOptions } from 'components'

import messages from './messages'

const PartVisitCounterForm = ({ handleOnChange, partIndex, name, options, onFormChange }) => {
  const intl = useIntl()

  return (
    <>
      <PartOptions partIndex={partIndex} onChange={handleOnChange} values={options} onFormChange={onFormChange} />

      <BasicInput
        label={intl.formatMessage(messages.nameLabel)}
        type="text"
        partIndex={partIndex}
        name="name"
        required
        value={name}
        onChange={(e) => {
          handleOnChange(e, partIndex);
          onFormChange(true);
        }}
        error={intl.formatMessage(messages.nameError)}
      />

      <BasicInfo text={intl.formatMessage(messages.intro)} />
    </>
  )
}

PartVisitCounterForm.propTypes = {
  handleOnChange: PropTypes.func.isRequired,
  partIndex: PropTypes.number.isRequired,
  name: PropTypes.string,
  options: PropTypes.any,
  onFormChange: PropTypes.func,
}

export default PartVisitCounterForm
