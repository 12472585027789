import PropTypes from 'prop-types'
import { addBrToText } from 'utils'
import { Img } from 'components'
import { FormText } from 'reactstrap'
import styled from 'styled-components'

import { ScheduleTooltip } from '../components/Tooltips'

const StyledLegend = styled(FormText)`
  text-align: center;
  margin: 5px 20px 0 20px;
  line-height: 14px;
  word-break: break-word;
`

const StyledWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: ${({ options }) => (options ? 'space-between' : 'center')};
  align-items: center;
  margin: ${({ options }) => (options ? '20px' : '20px 0')};
`

const Picture = ({ cardId, part, partIndex }) => {
  const hasOptions = part.options && part.options.display && part.options.display.length !== {}

  return (
    <StyledWrapper key={`${cardId}-picture-${partIndex}`} options={hasOptions}>
      <div>
        {part.link ? (
          <a href={part.link} target="_blank" rel="noreferrer">
            <Img src={part.url} alt="preview" />
          </a>
        ) : (
          <Img src={part.url} alt="preview" />
        )}

        {part.legend && <StyledLegend color="muted">{addBrToText(part.legend, 'part-lgd')}</StyledLegend>}
      </div>

      <ScheduleTooltip part={part} />
    </StyledWrapper>
  )
}

Picture.propTypes = {
  cardId: PropTypes.string,
  part: PropTypes.object,
  partIndex: PropTypes.number,
}

export default Picture
