import styled, { keyframes } from 'styled-components'
import { DropdownMenu, DropdownToggle } from 'reactstrap'

import { styles } from '../../../assets/styles-variables'

// shared styles
export const StyledLabel = styled.span`
  font-family: Nunito;
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  color: white;

  @media (max-width: 768px) {
    font-size: 16px;
  }
`

export const StyledLPLabel = styled(StyledLabel)`
  font-size: 14px;
  font-weight: 600;
`

export const StyledTag = styled.span`
  background-color: ${styles.color.yellow};
  color: #12263f;
  height: fit-content;
  width: fit-content;
  border-radius: 10px;
  font-family: Nunito;
  font-weight: 600;
  font-size: 8px;
  line-height: 13px;
  padding: 0px 5px;

  @media (min-width: 768px) {
    margin-left: 10px;
  }
`

// user dropdown
export const StyledUserIcon = styled.i`
  background-color: ${styles.color.yellow};
  padding: 3px;
  border-radius: 100%;
  margin-right: 10px;

  @media (max-width: 768px) {
    padding: 1px 2px;
  }

  svg {
    width: 24px;
    height: 24px;

    @media (max-width: 768px) {
      width: 20px;
      height: 20px;
    }
  }
`

export const StyledUserDropdown = styled(DropdownMenu)`
  font-family: Nunito;
  font-size: 16px;
  margin-top: 10px;
  overflow-y: auto;
  transition: all ease-in-out 0.2s;

  @media (max-width: 768px) {
    background: ${styles.color.secondary};
    border: none;
    margin-top: 0;
  }

  button {
    cursor: pointer;
    padding: 5px 25px !important;

    @media (max-width: 768px) {
      color: white;
      padding: 5px 35px !important;
    }
  }

  a {
    color: #212529;
    text-decoration: none;
    width: 100%;
    transition: none;

    @media (max-width: 768px) {
      color: white;

      &.logout {
        padding-left: 35px;
      }
    }
  }
`

export const StyledLPDropdown = styled(StyledUserDropdown)`
  margin: 0;
  margin-left: -64px;
  min-width: fit-content;

  @media (max-width: 768px) {
    margin-left: -24px;
  }
`

export const StyledUserDropdownToggle = styled(DropdownToggle)`
  display: flex;
  flex-direction: row;
  align-items: center;
`

// app dropdown
export const StyledDropDown = styled.div`
  display: flex;
  flex-direction: column;
  background: white;
  max-height: 500px;
  width: 100%;
  padding: 10px 20px;
  z-index: 2;
  position: absolute;
  top: 54px;
  font-size: 16px;
  font-weight: 600;
  line-height: 22px;
  overflow-y: auto;
  border: 1px solid rgba(0, 0, 0, 0.125);

  @media (max-width: 768px) {
    top: 38px;
  }

  button {
    padding: 8px 0;
    border: none;
    background-color: transparent;
    text-align: left;
    outline: none;
    cursor: pointer;

    @media (max-width: 768px) {
      min-height: 30px;

      &:not(:first-of-type) {
        margin-top: 1rem;
      }
    }
  }
`

export const StyledInput = styled.input`
  white-space: break-spaces;
  background: transparent;
  border: none;
  font-family: Nunito;
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  color: white;
  padding-left: 10px;
  margin-top: 5px;
  outline: none;
  text-overflow: ellipsis;

  @media (max-width: 768px) {
    font-size: 16px;
  }
`

export const StyledApp = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  position: relative;

  @media (min-width: 768px) {
    flex-direction: column;
    align-items: flex-start;
  }
`

export const StyledName = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  i {
    color: white;
    cursor: pointer;

    svg {
      width: 20px;
      height: 20px;
    }
  }
`

const spin = keyframes`
  from {
      transform:rotate(0deg);
  }
  to {
      transform:rotate(360deg);
  }
`

export const StyledLoader = styled.i`
  display: flex !important;
  animation: ${spin} 3s linear infinite;
  color: white;
  margin-left: 20px;
`
