import { defineMessages } from 'react-intl'

const scope = 'app.components.DropZone'

export default defineMessages({
  browse: { id: `${scope}.browse` },
  dropMessage: { id: `${scope}.dropMessage` },
  fileTooLarge: { id: `${scope}.fileTooLarge` },
  smallDropMessage: { id: `${scope}.smallDropMessage` },
  errorVideoTypeFile: { id: `${scope}.errorVideoTypeFile` },
})
