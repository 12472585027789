import { CardBody, Button } from 'reactstrap'
import styled from 'styled-components'

export const StyledHeaderContainer = styled(CardBody)`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-content: center;
  padding-bottom: 0;
  position: relative;

  @media (max-width: 890px) {
    flex-direction: column-reverse;
  }

  &.open:before {
    content: '';
    position: absolute;
    background-color: transparent;
    left: 0;
    top: 10px;
    height: 50px;
    width: 100%;
    border-radius: 8px 8px 0 0;
    box-shadow: 0 -15px 0 0 #f8f8f8;
  }
`

export const StyledInnerContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-content: center;
  padding-bottom: 0;
  flex-grow: 1;

  @media (max-width: 890px) {
    flex-direction: column;
    margin-top: -35px;
  }

  & > div:first-of-type {
    margin-right: 20px;
    margin-top: 10px;
  }
`
export const StyledIconContainer = styled.div`
  display: flex;
  flex-direction: row;
  height: fit-content;

  @media (max-width: 890px) {
    align-self: flex-end;
  }
`

export const StyledIconButton = styled(Button)`
  position: relative;
  background-color: white;
  border: 1px solid #12263f;
  border-radius: 100%;
  color: #12263f;
  padding: ${({ isfolder }) => (isfolder === 'true' ? '1px 5px' : '1px 2px')};
  transition: all 0.2s ease-in-out;
  z-index: 1;

  @media (max-width: 768px) {
    float: right;
  }

  svg {
    height: ${({ isfolder }) => (isfolder === 'true' ? '24px' : '30px')};
    width: ${({ isfolder }) => (isfolder === 'true' ? '24px' : '30px')};
  }

  &.active:after {
    content: '';
    position: absolute;
    background-color: transparent;
    right: -5px;
    top: 38px;
    height: 65px;
    width: 44px;
    border-bottom-left-radius: 30px;
    border-bottom-right-radius: 30px;
    box-shadow: 0 -65px 0 0 #12263f;
    z-index: -1;
  }

  &:first-of-type {
    margin-right: 10px;
  }

  &:hover,
  &.active,
  &:active {
    color: white !important;
    background-color: #12263f !important;
    border: 1px solid transparent !important;
    cursor: pointer;
  }
`
