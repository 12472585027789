const state = {
  fetching: true,
  error: false,
  input: {
    list: {},
    fetching: false,
    saving: false,
    success: false,
    error: false,
    deleting: false,
    deleted: false,
  },
  list: {
    list: {},
    fetching: false,
    saving: false,
    success: false,
    error: false,
    deleting: false,
    deleted: false,
  },
  listitem: {
    list: {},
    fetching: false,
    saving: false,
    success: false,
    error: false,
    deleting: false,
    deleted: false,
  },
  trigger: {
    list: {},
    fetching: false,
    saving: false,
    success: false,
    error: false,
    deleting: false,
    deleted: false,
  },
  voxpop: {
    list: {},
    fetching: false,
    saving: false,
    success: false,
    error: false,
    deleting: false,
    deleted: false,
  },
}
export default state
