import PropTypes from 'prop-types'
import { Helmet } from 'react-helmet'
import { useIntl } from 'react-intl'
import { BasicButton, PageTitle } from 'components'
import { MainAppContainer } from 'containers'

import { Icon } from 'react-icons-kit'
import { star } from 'react-icons-kit/feather/star'
import { externalLink } from 'react-icons-kit/feather'

import VisitCounter from './VisitCounter'
import { StyledCard, StyledIcon, StyledSmallLegend } from './styles'
import messages from './messages'

const PageData = ({
  match: {
    params: { appId },
  },
}) => {
  const intl = useIntl()

  return (
    <>
      <Helmet title={intl.formatMessage(messages.metaTitle)} />

      <MainAppContainer appId={appId}>
        <PageTitle>{intl.formatMessage(messages.statistics)}</PageTitle>

        <StyledSmallLegend>{intl.formatMessage(messages.statistics)}</StyledSmallLegend>

        <StyledCard>
          <p>{intl.formatMessage(messages.info)}</p>

          <p>
            <StyledIcon icon={star} />
            {intl.formatMessage(messages.stayTuned)}
            <StyledIcon icon={star} />
          </p>

          <p style={{ marginTop: '40px' }}>{intl.formatMessage(messages.moreInfo)}</p>

          <BasicButton
            href={'https://analytics.google.com'}
            target="_blank"
            label={intl.formatMessage(messages.analytics)}
            icon={<Icon icon={externalLink} />}
            linkstyle
            small
          />
        </StyledCard>

        <VisitCounter appId={appId} />
      </MainAppContainer>
    </>
  )
}

PageData.propTypes = {
  match: PropTypes.object,
}

export default PageData
