import VimeoPlayer from 'components/VimeoPlayer'
import DropdownButton from 'components/DropdownButton'
import moment from 'moment'
import PropTypes from 'prop-types'
import { Component } from 'react'
import { injectIntl } from 'react-intl'
import { Card, CardBody, CardText, CardFooter, Col } from 'reactstrap'
import { viewOnFacebookLink } from 'utils/generator'
import { Player } from 'video-react'
import messages from './messages'

class File extends Component {
  constructor(props, ...rest) {
    super(props, ...rest)

    this.state = {
      display: 'block',
      fbDeleted: '',
    }

    // this.urlExists = this.urlExists.bind(this)
  }

  removeFB(thisFile) {
    const { intl } = this.props
    // eslint-disable-next-line
    if (confirm(intl.formatMessage(messages.removePublicAccess)) === true) {
      thisFile.removeFromFacebook(thisFile.appId)
      this.setState({ fbDeleted: ', share-to-facebook-deleted' })
    }
  }

  deleteFile(thisFile) {
    const { intl } = this.props
    // eslint-disable-next-line
    if (confirm(intl.formatMessage(messages.deleteFile)) === true) {
      thisFile.delete(thisFile.appId)
      this.setState({ display: 'none' })
    }
  }

  rightVideo() {
    const videoFile = this.props.file.file
    if (videoFile.location === 'vimeo') {
      const vimeoID = videoFile.url.split('/')[videoFile.url.split('/').length - 1]
      return <VimeoPlayer videoId={vimeoID} />
    }
    // on prend pour aquis que c firebase
    return <Player playsInline poster="" preload={'none'} src={videoFile.url} />
  }

  render() {
    const { file, intl } = this.props

    return (
      <Col sm="4" style={{ display: this.state.display }}>
        <Card style={{ marginBottom: '30px' }}>
          {file.fileType === 'video' && this.rightVideo()}
          {file.fileType === 'photo' && <img width="100%" src={file.file.url} alt={file.name} />}

          <CardBody>
            <CardText>
              <b>{intl.formatMessage(messages.voxPopName)}</b>&nbsp;{file.origin.name}
            </CardText>

            <CardText>
              <b>{intl.formatMessage(messages.actions)}</b>&nbsp;
              {Object.keys(file.source).join(', ')}
              {this.state.fbDeleted}
            </CardText>

            {(file.source['copy-public-link'] || file.source['save-to-facebook']) &&
              !file.source['save-to-facebook-deleted'] && (
                <CardText>
                  <a rel="noreferrer" href={viewOnFacebookLink(file.appId, file.id)} target="_blank">
                    {intl.formatMessage(messages.viewFacebook)}
                  </a>
                </CardText>
              )}

            <DropdownButton label={intl.formatMessage(messages.delete)}>
              <button
                onClick={() => {
                  this.removeFB(file)
                }}
              >
                {intl.formatMessage(messages.ofFacebook)}
              </button>
              <button
                onClick={() => {
                  this.deleteFile(file)
                }}
              >
                {intl.formatMessage(messages.theFile)}
              </button>
            </DropdownButton>
          </CardBody>
          <CardFooter className="text-muted">
            {moment(file.dateAdded.seconds * 1000).format('lll')}
          </CardFooter>
        </Card>
      </Col>
    )
  }
}

File.propTypes = {
  file: PropTypes.any,
}

export default injectIntl(File)
