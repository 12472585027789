import { defineMessages } from 'react-intl'

const scope = 'app.components.NavBar.Folders'

export default defineMessages({
  addFolder: { id: `${scope}.addFolder` },
  all: { id: `${scope}.all` },
  cancel: { id: `${scope}.cancel` },
  color: { id: `${scope}.color` },
  folderError: { id: `${scope}.folderError` },
  folderName: { id: `${scope}.folderName` },
  folders: { id: `${scope}.folders` },
  save: { id: `${scope}.save` },
  sortByNameAsc: { id: `${scope}.sortByNameAsc` },
  sortByNameDesc: { id: `${scope}.sortByNameDesc` },
  sortByNew: { id: `${scope}.sortByNew` },
  sortByOld: { id: `${scope}.sortByOld` },
  openFormFolder: { id: `${scope}.openFormFolder` },
  ohNo: { id: `${scope}.ohNo` },
})
