import PropTypes from 'prop-types'
import { PartOptions, BasicTextArea } from 'components'
import { useIntl } from 'react-intl'
import { parseHtmlTag, newEvent } from 'utils'

import messages from './messages'

// time start value is handled manually
const VimeoForm = ({ onChange, part, partIndex, onFormChange }) => {
  const intl = useIntl()

  const handleUrlChange = (e) => {
    if (e.target.value.includes('<iframe')) {
      const data = parseHtmlTag(e.target.value)
      onChange(data, partIndex)
    } else {
      onChange(newEvent('timeStart', getTimeStart(e.target.value)), partIndex)
    }
    onChange(e, partIndex)
  }

  const getTimeStart = (value) => {
    if (value.indexOf('#t=') !== -1) {
      const res = value.split('#t=')
      const time = res[res.length - 1].replace('s', '')
      return time
    }
    return 0
  }

  const handleValidation = (value) => {
    const isIframe = value.indexOf('<iframe') >= 0
    const isSrcVimeo = value.indexOf('src="https://player.vimeo.com') >= 0
    const isVimeo = value.indexOf('https://vimeo.com/') >= 0 || value.indexOf('https://www.vimeo.com/') >= 0

    return (isIframe && isSrcVimeo) || (!isIframe && isVimeo)
  }

  return (
    <>
      <PartOptions partIndex={partIndex} onChange={onChange} values={part.options} onFormChange={onFormChange} />

      <BasicTextArea
        name="url"
        label={intl.formatMessage(messages.vimeo)}
        helpMessage={intl.formatMessage(messages.vimeoHelp)}
        error={intl.formatMessage(messages.vimeoError)}
        onChange={(e) => {
          handleUrlChange(e);
          onFormChange(true);
        }}
        partIndex={partIndex}
        value={part.url}
        required
        validation={{
          async: (value) => handleValidation(value),
        }}
        size="small"
      />

      <BasicTextArea
        label={intl.formatMessage(messages.legend)}
        helpMessage={intl.formatMessage(messages.legendHelp)}
        name="legend"
        partIndex={partIndex}
        value={part.legend || ''}
        onChange={(e) => {
          onChange(e, partIndex);
          onFormChange(true);
        }}
        size="xsmall"
      />
    </>
  )
}

VimeoForm.propTypes = {
  onChange: PropTypes.func,
  part: PropTypes.object,
  partIndex: PropTypes.number,
  onFormChange: PropTypes.func,
}

export default VimeoForm
