import { call, put } from 'redux-saga/effects'
import { splitFileName } from 'services/utils'
import EmptyCard from 'services/card.cls'
import { uploadFile, createFile } from 'services/fileManager'
import rsFirebase from 'services/rsf'
import uid from 'uid'
import uuidv4 from 'uuid/v4'

function* createShareCard(appId, id, name) {
  const fbCard = new EmptyCard(appId, 0, true)

  fbCard.parts = [{ type: 'fbs-content', locked: true, id: id }]
  fbCard.type = 'facebook-share-content'
  fbCard.place = `Selfie Booth: ${name}`

  // add to app
  yield put({ type: 'APP_ADD_CARD', cardId: fbCard.cardId })

  // add to cardList in store
  yield put({ type: 'CARDLIST_ADD', card: fbCard })

  // save in DB
  yield put({
    type: 'CARD_SAVE_SHARE_PAGE',
    data: fbCard,
    appId: appId,
  })

  return fbCard.cardId
}

function* setStickers(stickers, voxpopId, appId) {
  if (stickers && stickers.length > 0) {
    const stickerFiles = [...stickers]

    for (let i = 0; i < stickers.length; i += 1) {
      const sticker = stickers[i]

      if (sticker && sticker.name) {
        const fileNameObj = splitFileName(sticker.name)
        const fileName = `${voxpopId}-${fileNameObj.name}-${uid()}.${fileNameObj.ext}`
        const fileInfos = yield uploadFile(sticker, `/apps/${appId}/${fileName}`)

        stickerFiles[i] = fileInfos.url

        yield createFile(appId, 'image', {
          url: fileInfos.url,
          size: fileInfos.metadata.size,
          location: 'firebase',
          name: fileName,
        })
      }
    }

    return stickerFiles
  }

  return []
}

function* setWatermark(watermarkFile, voxpopId, appId) {
  if (typeof watermarkFile === 'string') {
    return watermarkFile
  }

  let url = ''

  const fileNameObj = splitFileName(watermarkFile.name)
  const fileName = `${voxpopId}-${fileNameObj.name}-${uid()}.${fileNameObj.ext}`
  const fileInfos = yield uploadFile(watermarkFile, `/apps/${appId}/${fileName}`)

  url = fileInfos.url

  yield createFile(appId, 'image', {
    url: fileInfos.url,
    size: fileInfos.metadata.size,
    location: 'firebase',
    name: fileName,
  })

  return url
}

export function* getPhotobooth(appId, data) {
  const { part } = data

  if (!data.dateAdded) {
    data.dateAdded = new Date(Date.now())
  } else {
    data.dateUpdated = new Date(Date.now())
  }

  if (!part.shareAdmin.language) {
    part.shareAdmin.language = 'fr'
  }

  // same data as old photobooth object
  data.appId = appId
  part.inputVideo = false
  part.ios = {}
  part.name = data.name
  part.rotateCamera = true
  part.shareEmail = { show: false }
  part.shareAdmin.show = true
  part.shareAdmin.forceFirst = true
  part.type = 'Photobooth'

  if (part.shareFacebook.show || part.shareLink.show) {
    const cardsSnap = yield call(rsFirebase.firestore.getCollection, `appdata/${appId}/cards`)
    const cards = {}
    cardsSnap.forEach((card) => {
      cards[card.id] = card.data()
    })
    let doesCardExists = false

    if (part.shareFacebook.cardId) {
      doesCardExists = Object.values(cards).find((c) => c.cardId === data.part.shareFacebook.cardId)
    }

    if (!part.shareFacebook.cardId || !doesCardExists) {
      const voxpopId = uuidv4()
      const shareFacebookCardId = yield call(createShareCard, appId, data.id, data.name)
      part.shareFacebook.cardId = shareFacebookCardId
      part.voxpopId = voxpopId
    }
  }

  try {
    part.watermarkUrl = yield call(setWatermark, part.watermarkFile, part.voxpopId, appId)
    delete part.watermarkFile
  } catch (error) {}

  part.stickers = yield call(setStickers, part.stickers, part.voxpopId, appId)

  return data
}
