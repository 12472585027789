import PropTypes from 'prop-types'
import { useState } from 'react'
import { Collapse } from 'reactstrap'
import { BasicButton } from 'components'

import { Icon } from 'react-icons-kit'
import { chevronDown } from 'react-icons-kit/feather/chevronDown'
import { chevronUp } from 'react-icons-kit/feather/chevronUp'

const ToggleWrapper = ({ title, children, collapsable }) => {
  const [open, setOpen] = useState(false)

  const toggle = () => {
    if (collapsable) {
      setOpen(!open)
    }
  }

  return (
    <>
      {title && (
        <BasicButton
          onClick={toggle}
          icon={<Icon icon={open ? chevronUp : chevronDown} />}
          label={title}
          small
          outlined
          color="green"
        />
      )}
      {collapsable ? <Collapse isOpen={open}>{children}</Collapse> : children}
    </>
  )
}

ToggleWrapper.defaultProps = {
  children: null,
  collapsable: true,
}

ToggleWrapper.propTypes = {
  title: PropTypes.string,
  children: PropTypes.any,
  collapsable: PropTypes.bool,
}

export default ToggleWrapper
