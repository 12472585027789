import React from 'react'
import styled from 'styled-components'
import { Row } from 'reactstrap'

const StyledWrapper = styled(Row)`
  border: 1px solid rgb(187, 187, 187);
  border-radius: 10px;
  margin: 1em 0px;
  padding-bottom: 15px;

  legend {
    font-size: 1rem;
    margin-top: 1.2rem;
    font-weight: bold;
  }
`
const BasicFormSection = (props) => <StyledWrapper {...props} />

BasicFormSection.propTypes = {}

export default BasicFormSection
