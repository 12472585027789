import { useState } from 'react'
import PropTypes from 'prop-types'
import { useIntl } from 'react-intl'
import { BasicInput, BasicCheckbox, BasicFormSection, BasicInfo } from 'components'
import { Col } from 'reactstrap'
import { validationsPatterns } from 'utils'

import { StyledLegend } from './styles'
import messages from '../messages'

const ShareFrame = ({ photobooth }) => {
  const [shareLink, setShareLink] = useState((photobooth.shareLink && photobooth.shareLink.show) || false)
  const [shareFacebook, setShareFacebook] = useState(
    (photobooth.shareFacebook && photobooth.shareFacebook.show) || false
  )
  const [shareDownload, setShareDownload] = useState(
    (photobooth.shareDownload && photobooth.shareDownload.show) || false
  )

  const intl = useIntl()

  return (
    <>
      <StyledLegend>{intl.formatMessage(messages.sf.title)}</StyledLegend>

      <BasicInput
        name="part.shareAdmin.thanks"
        label={intl.formatMessage(messages.sf.thanks)}
        helpMessage={intl.formatMessage(messages.sf.thanksHelp)}
        placeholder={intl.formatMessage(messages.sf.thanksPlaceholder)}
      />

      <BasicCheckbox
        name="part.shareLink.show"
        label={intl.formatMessage(messages.sf.link)}
        onChange={(e) => setShareLink(e.target.checked)}
      />

      {shareLink && (
        <>
          <BasicInfo text={intl.formatMessage(messages.sf.shareInfo)} />

          <BasicFormSection>
            <Col>
              <BasicInput
                name="part.shareLink.label"
                label={intl.formatMessage(messages.sf.linkButton)}
                helpMessage={intl.formatMessage(messages.sf.linkButtonHelp)}
                placeholder={intl.formatMessage(messages.sf.linkButtonPlaceholder)}
              />
            </Col>
          </BasicFormSection>
        </>
      )}

      <BasicCheckbox
        name="part.shareFacebook.show"
        label={intl.formatMessage(messages.sf.facebook)}
        onChange={(e) => setShareFacebook(e.target.checked)}
      />

      {shareFacebook && (
        <>
          <BasicInfo text={intl.formatMessage(messages.sf.shareInfo)} />

          <BasicFormSection>
            <Col>
              <div style={{ height: 0, visibility: 'hidden', marginTop: '-10px' }}>
                <BasicInput name="part.shareFacebook.cardId" type="text" disabled />
              </div>

              <BasicInput
                name="part.shareFacebook.label"
                label={intl.formatMessage(messages.sf.facebookButton)}
                helpMessage={intl.formatMessage(messages.sf.facebookButtonHelp)}
                placeholder={intl.formatMessage(messages.sf.facebookButtonPlaceholder)}
              />

              <BasicInput
                name="part.shareFacebook.message"
                label={intl.formatMessage(messages.sf.facebookMessage)}
                helpMessage={intl.formatMessage(messages.sf.facebookMessageHelp)}
                placeholder={intl.formatMessage(messages.sf.facebookMessagePlaceholder)}
              />

              <BasicInput
                name="part.shareFacebook.hashtag"
                label={intl.formatMessage(messages.sf.hashtag)}
                helpMessage={intl.formatMessage(messages.sf.hashtagHelp)}
                placeholder={intl.formatMessage(messages.sf.hashtagPlaceholder)}
                error={intl.formatMessage(messages.sf.hashtagError)}
                validation={{ pattern: validationsPatterns.oneHashtag }}
              />
            </Col>
          </BasicFormSection>
        </>
      )}

      <BasicCheckbox
        name="part.shareDownload.show"
        label={intl.formatMessage(messages.sf.download)}
        onChange={(e) => setShareDownload(e.target.checked)}
      />

      {shareDownload && (
        <BasicFormSection>
          <Col>
            <BasicInput
              name="part.shareDownload.label"
              label={intl.formatMessage(messages.sf.downloadButton)}
              helpMessage={intl.formatMessage(messages.sf.downloadButtonHelp)}
              placeholder={intl.formatMessage(messages.sf.downloadButtonPlaceholder)}
            />
          </Col>
        </BasicFormSection>
      )}
    </>
  )
}

ShareFrame.propTypes = {
  photobooth: PropTypes.object,
}

export default ShareFrame
