import PropTypes from 'prop-types'
import { Pagination as BootstrapPagination, PaginationItem, PaginationLink } from 'reactstrap'

import { Icon } from 'react-icons-kit'
import { chevronLeft } from 'react-icons-kit/feather/chevronLeft'
import { chevronRight } from 'react-icons-kit/feather/chevronRight'
import { chevronsLeft } from 'react-icons-kit/feather/chevronsLeft'
import { chevronsRight } from 'react-icons-kit/feather/chevronsRight'

import SimpleBar from 'simplebar-react'
import 'simplebar/dist/simplebar.min.css'

import styled from 'styled-components'
import { getColor } from 'styles/functions'

const StyledPagination = styled.div`
  .page-link {
    color: ${getColor('blue')};
    font-family: 'Open Sans', sans-serif;
  }

  .page-item.active .page-link {
    border-color: ${getColor('blue')};
    background-color: ${getColor('blue')};
  }

  i {
    display: flex !important;
  }
`

const Pagination = ({ currentPage, nbrPages, changePage }) => {
  const leftDisabled = currentPage === 0
  const rightDisabled = currentPage === nbrPages - 1

  const handleChange = (e, disabled, page) => {
    e.preventDefault()
    if (!disabled) changePage(page - 1)
  }

  return (
    <SimpleBar style={{ width: '100%', marginBottom: '10px' }}>
      <StyledPagination>
        <BootstrapPagination>
          <PaginationItem disabled={leftDisabled} onClick={(e) => handleChange(e, leftDisabled, 1)}>
            <PaginationLink href="#">
              <Icon icon={chevronsLeft} />
            </PaginationLink>
          </PaginationItem>

          <PaginationItem disabled={leftDisabled} onClick={(e) => handleChange(e, leftDisabled, currentPage)}>
            <PaginationLink href="#">
              <Icon icon={chevronLeft} />
            </PaginationLink>
          </PaginationItem>

          {[...Array(nbrPages)].map((p, i) => (
            <PaginationItem active={currentPage === i} key={i} onClick={(e) => handleChange(e, false, i + 1)}>
              <PaginationLink href="#">{i + 1}</PaginationLink>
            </PaginationItem>
          ))}

          <PaginationItem
            disabled={rightDisabled}
            onClick={(e) => handleChange(e, rightDisabled, currentPage + 2)}
          >
            <PaginationLink href="#">
              <Icon icon={chevronRight} />
            </PaginationLink>
          </PaginationItem>

          <PaginationItem disabled={rightDisabled} onClick={(e) => handleChange(e, rightDisabled, nbrPages)}>
            <PaginationLink href="#">
              <Icon icon={chevronsRight} />
            </PaginationLink>
          </PaginationItem>
        </BootstrapPagination>
      </StyledPagination>
    </SimpleBar>
  )
}

Pagination.propTypes = {
  changePage: PropTypes.func,
  currentPage: PropTypes.number,
  nbrPages: PropTypes.number,
}

export default Pagination
