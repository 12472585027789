import styled from 'styled-components'

const handleVideoRatio = (ratio) => {
  switch (ratio.ratio) {
    case '1-1':
      return '300px'
    case '4-3':
      return '135px'
    case '21-9':
      return '115px'
    case '3-4':
      return '400px'
    case '9-16':
      return '524px'
    case '9-21':
      return '660px'
    default:
      return '182px'
  }
}

export const StyledPreviewBox = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: ${(ratio) => handleVideoRatio(ratio)}};
  width: 260px;
  border: 2px dashed #dedede;
  border-radius: 8px;
  color: #dedede;

  svg {
    height: 30px;
    width: 30px;
  }
`
