import { defineMessages } from 'react-intl'

const scope = 'app.components.PartActions.TriggerForm'

export default defineMessages({
  edit: { id: `${scope}.edit` },
  info: { id: `${scope}.info` },
  required: { id: `${scope}.required` },
  select: { id: `${scope}.select` },
  trigger: { id: `${scope}.trigger` },
  tutorial: { id: `${scope}.tutorial` },
})
