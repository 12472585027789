import PropTypes from 'prop-types'
import { useState, useEffect } from 'react'
import { useIntl } from 'react-intl'

import connectStore from 'redux-box/dist/connectStore'
import { storeModule as ModuleModules } from 'store/modules'

import messages from '../../messages'
import NotFound from '../NotFound'
import Products from './Products'

const ModuleList = ({ appId, modules, id }) => {
  const [currentList, setCurrentList] = useState({})

  const intl = useIntl()

  useEffect(() => {
    setCurrentList(modules.list.list[id])
  }, [])

  const type = currentList && currentList.style && currentList.style.displayType

  return type ? (
    <Products currentList={currentList} appId={appId} type={type} />
  ) : (
    <NotFound id={id} type={intl.formatMessage(messages.list)} fetching={modules.fetching} />
  )
}

ModuleList.propTypes = {
  appId: PropTypes.string,
  modules: PropTypes.object,
  id: PropTypes.string,
}

export default connectStore({ modules: ModuleModules })(ModuleList)
