import PropTypes from 'prop-types'
import { DropZone, PartOptions, BasicTextArea } from 'components'
import { useIntl } from 'react-intl'
import messages from './messages'

const AudioForm = ({ onChange, onVideoChange, part, partIndex, onFormChange }) => {
  const intl = useIntl()

  const handleRemoveAudio = () => onVideoChange(null, partIndex)

  const handleVideoChange = (droppedAccepted) => {
    onVideoChange(droppedAccepted[0], partIndex)
  }

  return (
    <>
      <PartOptions partIndex={partIndex} onChange={onChange} values={part.options} onFormChange={onFormChange} />

      <DropZone
        type="audio"
        name="file"
        partIndex={partIndex}
        label={intl.formatMessage(messages.audio)}
        helpMessage={intl.formatMessage(messages.audioHelp)}
        accept="audio/mpeg"
        file={part.file}
        url={part.url}
        maxSize={10485760} // 10MB
        onDrop={handleVideoChange}
        onRemoveFile={handleRemoveAudio}
        error={intl.formatMessage(messages.required)}
        rejectLabel={intl.formatMessage(messages.fileTypeError)}
        required
        size="small"
        onFormChange={onFormChange}
      />

      <BasicTextArea
        size="xsmall"
        label={intl.formatMessage(messages.legend)}
        helpMessage={intl.formatMessage(messages.legendHelp)}
        name="legend"
        partIndex={partIndex}
        value={part.legend || ''}
        onChange={(e) => {
          onChange(e, partIndex);
          onFormChange(true);
        }}
      />
    </>
  )
}

AudioForm.propTypes = {
  onChange: PropTypes.func,
  onVideoChange: PropTypes.func,
  part: PropTypes.object,
  partIndex: PropTypes.any,
  onFormChange: PropTypes.func,
}

export default AudioForm
