import styled from 'styled-components'

export const StyledAttribution = styled.div`
  img {
    width: 150px;
  }

  text-align: left;
`

// comes from class invalid-feedback in bootstrap file
export const StyledErrorMsg = styled.p`
  opacity: 1;
  width: 100%;
  font-size: 12px;
  color: #dc3545;
`
