import PropTypes from 'prop-types'
import { useIntl } from 'react-intl'
import { BasicInput, BasicTextArea, BasicInfo } from 'components'

import { StyledLegend } from './styles'
import messages from '../messages'

const GeneralInfo = ({ getLockByName, id }) => {
  const intl = useIntl()

  const handleValidation = (value) => {
    const locks = getLockByName(value) || []
    const differentId = locks.filter((l) => l.id !== id)
    return differentId.length <= 0
  }

  return (
    <>
      <BasicInput
        name="name"
        label={intl.formatMessage(messages.name)}
        error={intl.formatMessage(messages.nameError)}
        helpMessage={intl.formatMessage(messages.nameHelp)}
        placeholder={intl.formatMessage(messages.namePlaceholder)}
        required
        validation={{
          async: (value) => handleValidation(value),
        }}
      />

      <hr />

      <StyledLegend>{intl.formatMessage(messages.content)}</StyledLegend>

      <BasicInfo text={intl.formatMessage(messages.contentHelp)} />

      <BasicInput
        name="display.title"
        label={intl.formatMessage(messages.title)}
        error={intl.formatMessage(messages.titleError)}
        helpMessage={intl.formatMessage(messages.titleHelp)}
        placeholder={intl.formatMessage(messages.titlePlaceholder)}
        required
      />

      <BasicTextArea
        name="display.text"
        label={intl.formatMessage(messages.text)}
        helpMessage={intl.formatMessage(messages.textHelp)}
        placeholder={intl.formatMessage(messages.textPlaceholder)}
        size="small"
      />

      <BasicInput
        name="display.button"
        label={intl.formatMessage(messages.buttonText)}
        error={intl.formatMessage(messages.buttonTextError)}
        helpMessage={intl.formatMessage(messages.buttonTextHelp)}
        placeholder={intl.formatMessage(messages.buttonTextPlaceholder)}
        required
      />
    </>
  )
}

GeneralInfo.propTypes = {
  id: PropTypes.string,
  getLockByName: PropTypes.func,
}

export default GeneralInfo
