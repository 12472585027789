import { createSagas } from 'redux-box'
import {call, put, all, fork/*, take*/} from 'redux-saga/effects'
import rsFirebase from 'services/rsf'
import history from 'services/history'
import { rteCardEditId } from 'utils/routes'
import {
  generateNewCard,
  updateCardList,
  updateOneCard,
  createOneCard,
  deleteContent,
  duplicateCard,
  deleteItemInLists, deleteCardFromCardList,
} from './workers'
import { getUserId, getUserProfile } from "../manageUsers/workers";

export default createSagas({
  *CARD_FETCH_BY_ID({ appId, cardId }) {
    try {
      const delay = (ms) => new Promise(resolve => setTimeout(resolve, ms));

      yield put({ type: 'CARD_FETCHING' });
      let cardSnap = null;
      let retries = 3;
      while (retries > 0) {
        cardSnap = yield call(rsFirebase.firestore.getDocument, `appdata/${appId}/cards/${cardId}`);
        if (cardSnap && cardSnap.exists) {
          break;
        }
        retries -= 1;
        yield call(delay, 300);
      }
      const card = cardSnap && cardSnap.exists ? cardSnap.data() : undefined;
      yield put({
        type: 'CARD_SET_CARDINFO',
        card: card !== undefined ? card : { cardId, parts: [{ type: '' }], tokenId: 0, linkLogo: '' },
      });
      yield put({ type: 'CARD_FETCH_SUCCESS' });
    } catch (error) {
      yield put({ type: 'CARD_FETCH_ERROR' });
    }
  },
  *CARD_DUPLICATE_CARD({ cardId, appId }) {
    try {
      const cardSnap = yield call(rsFirebase.firestore.getDocument, `appdata/${appId}/cards/${cardId}`)
      const card = cardSnap.data()
      const newCard = duplicateCard(card)
      const appSnap = yield call(rsFirebase.firestore.getDocument, `appdata/${appId}`)
      const app = appSnap.data()
      yield put({ type: 'CARD_SAVING' })
      yield call(createOneCard, newCard, app)
      const userId = yield call(getUserId);
      const user = yield call(getUserProfile, userId);
      yield put({type: 'MANAGE_USER_UPDATE_RIGHTS', appId: app.id, user: user, options: {
          type: 'cards',
          name: newCard.title.trim(),
          card: newCard,
        }
      })
      yield put({ type: 'CARD_SET_CARDINFO', card: newCard })
      const testSnap = yield call(rsFirebase.firestore.getDocument, `appdata/${appId}/cards/${newCard.cardId}`)
      const test = testSnap.data()

      history.push(rteCardEditId(appId, test.cardId))
      yield put({ type: 'CARD_COPY_SUCCESS' })
    } catch (error) {
      yield put({ type: 'CARD_SAVE_ERROR' })
    }
  },
  *CARD_SET_CONTENT({ card }) {
    try {
      yield put({ type: 'CARD_FETCHING' })
      yield put({ type: 'CARD_SET_CARDINFO', card })
      yield put({ type: 'CARD_FETCH_SUCCESS' })
    } catch (error) {
      yield put({ type: 'CARD_FETCH_ERROR' })
    }
  },
  // used in modules workers, on save Photobooth
  *CARD_SAVE_SHARE_PAGE({ data, appId }) {
    try {
      const newCard = generateNewCard(data)
      yield call(updateOneCard, newCard, appId)
    } catch (error) {
      yield put({ type: 'CARD_SAVE_ERROR' })
    }
  },
  *CARD_SAVE({ data, app, isNew = false }) {
    try {
      yield put({ type: 'CARD_SAVING' })
      const newCard = generateNewCard(data)
      if (isNew) {
        newCard.unlinked = true
        if(data.theme === '') {
          newCard.theme = app.theme
        }
        yield call(createOneCard, newCard, app)
        const userId = yield call(getUserId);
        const user = yield call(getUserProfile, userId);
        yield put({type: 'MANAGE_USER_UPDATE_RIGHTS', appId: app.id, user: user, options: {
            type: 'cards',
            name: data.title.trim(),
            card: data,
          }
        })
        //yield take('MANAGE_USER_UPDATE_RIGHTS_SUCCESS')
        yield call(updateCardList, app, newCard)
      } else {
        const updated = yield call(updateOneCard, newCard, app.id)
        yield updated
      }
      yield put({ type: 'CARD_SET_CARDINFO', card: newCard })
      yield put({ type: 'CARDLIST_UPDATE_CARD', card: newCard })
      yield put({ type: 'CARD_FETCH_BY_ID', cardId: newCard.cardId, appId: app.id })
      yield put({ type: 'CARD_SAVE_SUCCESS' })
    } catch (error) {
      yield put({ type: 'CARD_SAVE_ERROR' })
    }
  },
  *CARD_SAVE_FOLDER({ data, appId }) {
    try {
      yield put({ type: 'CARD_SAVING' })
      const cardSnap = yield call(rsFirebase.firestore.getDocument, `appdata/${appId}/cards/${data.cardId}`)
      const card = cardSnap.data()
      card.folders = data.folders
      yield call(rsFirebase.firestore.updateDocument, `appdata/${appId}/cards/${data.cardId}`, card)
      yield put({ type: 'CARDLIST_UPDATE_CARD', card })
      yield put({ type: 'CARD_SAVE_SUCCESS' })
    } catch (error) {
      yield put({ type: 'CARD_SAVE_ERROR' })
    }
  },
  *CARD_UPDATE({ data, appId }) {
    try {
      yield put({ type: 'CARD_SAVING' })
      const card = generateNewCard(data)
      const update = yield call(updateOneCard, card, appId)
      yield update
      yield put({ type: 'CARD_SET_CARDINFO', card })
      yield put({ type: 'CARDLIST_UPDATE_CARD', card })
      yield put({ type: 'CARD_SAVE_SUCCESS' })
    } catch (error) {
      yield put({ type: 'CARD_SAVE_ERROR' })
    }
  },
  *CARD_DELETE({ cardId, appId }) {
    try {

      const cardSnap = yield call(rsFirebase.firestore.getDocument, `appdata/${appId}/cards/${cardId}`)
      const card = cardSnap.data()
      if (card.type === 'listitem') {
        const snapshot = yield call(
          rsFirebase.firestore.getDocument,
          `appdata/${appId}/listitem/${card.parent}`
        )
        let listitem = snapshot.data()
        if (listitem) {
          const listitemId = listitem.id
          yield call(rsFirebase.firestore.deleteDocument, `appdata/${appId}/listitem/${listitemId}`)
          const lists = yield call(deleteItemInLists, appId, listitemId)
          yield put({ type: 'MODULE_SET_LISTS', lists })
        }
      }

      yield all([
        fork(deleteCardFromCardList, appId, cardId),
        fork(deleteContent, `appdata/${appId}/cards/${cardId}`),
      ])
      yield put({ type: 'CARD_DELETING' })
      yield put({ type: 'CARDLIST_DELETE_CARD', cardId })
      yield put({ type: 'APP_DELETE_CARD', cardId })
      yield put({ type: 'CARD_DELETE_SUCCESS' })
    } catch (error) {
      yield put({ type: 'CARD_DELETE_ERROR' })
    }
  },
  *CARD_DELETE_CONTENT({ cardId, appId }) {
    try {
      const cardSnap = yield call(rsFirebase.firestore.getDocument, `appdata/${appId}/cards/${cardId}`)
      const card = cardSnap.data()
      card.parts = [{ type: '' }]
      card.title = ''
      card.place = ''
      card.style = ''
      card.updateTime = Date.now().toString()
      card.theme = ''
      card.folders = []
      yield call(rsFirebase.firestore.updateDocument, `appdata/${appId}/cards/${cardId}`, card)
      yield put({ type: 'CARD_DELETING' })
      yield put({ type: 'CARDLIST_UPDATE_CARD', card })
      yield put({ type: 'CARD_SET_CARD_CONTENT', card })
      yield put({ type: 'CARD_DELETE_SUCCESS' })
    } catch (error) {
      yield put({ type: 'CARD_DELETE_ERROR' })
    }
  },
})
