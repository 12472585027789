import PropTypes from 'prop-types'
import { BasicInput, BasicWarning, BasicButton } from 'components'
import { useIntl } from 'react-intl'
import { Icon } from 'react-icons-kit'
import { externalLink } from 'react-icons-kit/feather/externalLink'

import messages from '../messages'

const Trigger = ({ appId, triggers }) => {
  const intl = useIntl()

  return (
    <>
      <BasicInput
        type="select"
        name="triggerId"
        label={intl.formatMessage(messages.triggerId)}
        helpMessage={intl.formatMessage(messages.triggerHelp)}
      >
        <option value="" disabled>
          {intl.formatMessage(messages.selectOne)}
        </option>

        {Object.keys(triggers).length > 0 &&
          triggers.map((trigger, i) => (
            <option value={`${trigger.id}`} key={i}>
              {trigger.name}
            </option>
          ))}
      </BasicInput>

      <BasicButton
        color="blue"
        isLink
        small
        linkstyle
        label={intl.formatMessage(messages.newTrigger)}
        href={`/${appId}/modules/triggers/new`}
        icon={<Icon icon={externalLink} />}
        target="_blank"
      />

      <BasicWarning text={intl.formatMessage(messages.triggerWarning)} />
    </>
  )
}

Trigger.propTypes = {
  appId: PropTypes.string,
  triggers: PropTypes.any,
}

export default Trigger
