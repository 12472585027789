import styled, { css } from 'styled-components'

export const StyledCollapse = styled.div`
  display: flex;
  flex-direction: row;
  height: 30px;
  border-bottom: 1px solid lightgrey;
  margin-top: 30px;
  margin-bottom: 20px;

  i {
    color: #89959f;
    cursor: pointer;
  }

  legend {
    cursor: pointer;
  }
`

export const StyledLegend = styled.legend`
  font-family: Nunito;
  font-size: 16px;
  font-weight: 600 !important;
  color: black;
  margin-top: 0 !important;
`

export const StyledCollapseDiv = styled.div`
  ${({ open }) =>
  !open &&
  css`
      height: 0;
      visibility: hidden;
      margin-top: -10px;

      .form-group {
        margin: 0;
      }
    `}
`

export const StyledLangLabelContainer = styled.div`
  ;
  width: 40px;
  height: 50px;
  border: 2px solid black;
  text-align: center;
  border-radius: 5px;
  margin-left: 15px;
  background-color: ${(props) => (props.outlined ? 'transparent' : 'black')};

  span {
    color: ${(props) => (props.outlined ? 'black' : 'white')};
    position: relative;
    top: 12px;
  }
`

export const StyledLangSquareContainer = styled.div`
  width: 40px;
  height: 40px;
  border: 2px solid black;
  text-align: center;
  border-radius: 5px;
  margin-left: 13px;
  background-color: ${(props) => (props.outlined ? 'transparent' : 'black')};

  span {
    color: ${(props) => (props.outlined ? 'black' : 'white')};
    position: relative;
    top: 8px;
  }
`

export const StyledLangCircleContainer = styled.div`
  width: 40px;
  height: 40px;
  border: 2px solid black;
  border-radius: 100%;
  text-align: center;
  margin-left: 11px;
  background-color: ${(props) => (props.outlined ? 'transparent' : 'black')};
  
  span {
    color: ${(props) => (props.outlined ? 'black' : 'white')};
    position: relative;
    top: 7px;
  }
`