import { useState } from 'react'
import PropTypes from 'prop-types'
import { useIntl } from 'react-intl'
import { ImgDropzone, BasicInput, PartOptions, BasicTextArea, BasicCheckbox } from 'components'
import { validationsPatterns, newEvent } from 'utils'

import StyleForm from './StyleForm'
import messages from './messages'

/* 
  what's not shared between picture and picture url:

  - picture -
  ... dropzone ...
  ... compressed option ...

  - picture url -
  ... image url input ...
*/
const PictureForm = ({ onChange, onCheckedChange, onPictureChange, part, partIndex, onFormChange }) => {
  const [image, setImage] = useState(part.file || part.url || null)
  const [isNew, setIsNew] = useState(false)

  const intl = useIntl()

  const canImageBeCompressed = (type) => type !== 'image/svg+xml' && type !== 'image/gif'

  const handleDropZoneChange = (acceptedFiles) => {
    const checked = canImageBeCompressed(acceptedFiles[0].type)
    onChange(newEvent('isCompressed', checked), partIndex)
    onPictureChange(acceptedFiles[0], partIndex)
    setImage(acceptedFiles[0])
    setIsNew(true)
  }

  const handleRemovePicture = () => {
    setImage(null)
    onPictureChange(null, partIndex)
  }

  return (
    <>
      <PartOptions
        partIndex={partIndex}
        onChange={onChange}
        values={part.options}
        styleForm={
          <StyleForm
            part={part}
            onCheckedChange={(e) => onCheckedChange(e, partIndex)}
            isNew={!!part.fileId && !isNew}
            unchecked={!image}
            partIndex={partIndex}
            onChange={(e) => onChange(e, partIndex)}
            onFormChange={onFormChange}
          />
        }
        onFormChange={onFormChange}
      />

      {part.type === 'Picture' ? (
        <ImgDropzone
          name={`file-${partIndex}`}
          error={intl.formatMessage(messages.imageError)}
          file={part.file}
          label={intl.formatMessage(messages.image)}
          helpMessage={intl.formatMessage(messages.imageHelp)}
          url={part.url}
          onChange={(e) => {
            handleDropZoneChange(e);
            onFormChange(true);
          }}
          onDelete={handleRemovePicture}
          required
        />
      ) : (
        <BasicInput
          name="url"
          label={intl.formatMessage(messages.imageUrl)}
          value={part.url}
          partIndex={partIndex}
          onChange={(e) => {
            onChange(e, partIndex);
            onFormChange(true);
          }}
          required
          error={intl.formatMessage(messages.imageUrlError)}
          validation={{
            pattern: validationsPatterns.url,
          }}
        />
      )}

      <BasicTextArea
        size="xsmall"
        label={intl.formatMessage(messages.legend)}
        helpMessage={intl.formatMessage(messages.legendHelp)}
        name="legend"
        partIndex={partIndex}
        value={part.legend || ''}
        onChange={(e) => {
          onChange(e, partIndex);
          onFormChange(true);
        }}
      />

      <BasicCheckbox
        label={intl.formatMessage(messages.zoom)}
        helpMessage={intl.formatMessage(messages.zoomHelp)}
        name="withZoom"
        partIndex={partIndex}
        value={part.withZoom}
        onChange={(e) => {
          onCheckedChange({ target: { id: 'hideLinkIcon', checked: false } }, partIndex)
          onCheckedChange(e, partIndex)
          onFormChange(true);
        }}
      />
    </>
  )
}

PictureForm.propTypes = {
  onChange: PropTypes.func,
  onCheckedChange: PropTypes.func,
  onPictureChange: PropTypes.func,
  part: PropTypes.object,
  partIndex: PropTypes.number,
  onFormChange: PropTypes.func,
}

export default PictureForm
