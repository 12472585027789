import { VERSION } from 'utils/consts'
import { useIntl } from 'react-intl'
import styled from 'styled-components'

import messages from './messages'

const StyledFooter = styled.footer`
  color: #fff;
  width: 100%;
  position: fixed;
  bottom: 0;
  left: 0;
  font-size: 0.875rem;
  line-height: 1;
  text-align: center;
  background-color: #03162c;
  opacity: 0.9;
  padding: 0.625rem 0 0.625rem 13rem;
  z-index: 7;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  @media screen and (max-width: 768px) {
    position: relative;
    width: 100%;
    padding: 10px 20px;
    left: 0;
  }

  a {
    color: #3f6cb3;
  }

  span:last-of-type {
    font-size: 0.625rem;
    padding: 0 0.625rem;
  }
`

const Footer = () => {
  const intl = useIntl()

  return (
    <StyledFooter>
      {new Date().getFullYear()}
      {intl.formatMessage(messages.copyright)}
      <span>V.{VERSION}</span>
    </StyledFooter>
  )
}

export default Footer
