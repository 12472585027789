import PropTypes from 'prop-types'
import { useState, useEffect } from 'react'
import { useIntl } from 'react-intl'
import { BasicInput, PartOptions, BasicButton, BasicInfo } from 'components'
import { TEMPLATES } from 'utils/consts'
import { rteModulesId, rteAppId } from 'utils/routes'
import { newEvent } from 'utils'

import connectStore from 'redux-box/dist/connectStore'
import { storeModule as ModuleModules } from 'store/modules'

import { Icon } from 'react-icons-kit'
import { externalLink } from 'react-icons-kit/feather/externalLink'

import messages from './messages'

const ItemForm = ({ appId, modules, onChange, options, part, partIndex, onFormChange }) => {
  const [selectedItem, setSelectedItem] = useState(part.id || null)

  const intl = useIntl()

  const selectableItems = modules.getList('listitem') || []

  const item = modules.listitem.list[selectedItem]

  const template = item ? TEMPLATES[item.template] : []

  useEffect(() => {
    if (!part.id) {
      onChange(newEvent('id', null), partIndex)
    }
  }, [])

  const handleOnChange = (e) => {
    setSelectedItem(e.target.value)
    onChange(e, partIndex)
  }

  return (
    <>
      <PartOptions partIndex={partIndex} onChange={onChange} values={options} onFormChange={onFormChange} />

      <BasicInput
        type="select"
        label={intl.formatMessage(messages.item)}
        name="id"
        onChange={(e) => {
          handleOnChange(e);
          onFormChange(true);
        }}
        value={part.id}
        error={intl.formatMessage(messages.itemError)}
        required
      >
        <option value="" disabled>
          {intl.formatMessage(messages.selectItem)}
        </option>

        {selectableItems.length > 0 &&
          selectableItems.map((item, i) => (
            <option key={i} value={`${item.id}`}>
              {item.name}
            </option>
          ))}
      </BasicInput>

      {selectedItem && (
        <div style={{ marginBottom: '15px' }}>
          <BasicButton
            color="blue"
            href={rteModulesId(appId, 'ITEMS_SINGLE', selectedItem)}
            target="_blank"
            medium
            isLink
            label={intl.formatMessage(messages.edit)}
            icon={<Icon icon={externalLink} />}
          />
        </div>
      )}

      {selectedItem && (
        <BasicInput
          type="select"
          label={intl.formatMessage(messages.display)}
          name="style.displayType"
          helpMessage={intl.formatMessage(messages.displayHelp)}
          error={intl.formatMessage(messages.displayError)}
          onChange={(e) => {
            onChange(e, partIndex);
            onFormChange(true);
          }}
          value={part.style ? part.style.displayType : ''}
          required
        >
          <option value="" disabled>
            {intl.formatMessage(messages.selectDisplay)}
          </option>

          <option value="H2">{intl.formatMessage(messages.subtitle)}</option>
          <option value="Picture">{intl.formatMessage(messages.picture)}</option>
          <option value="Text">{intl.formatMessage(messages.text)}</option>

          {selectedItem.triggerId && <option value="Trigger">{intl.formatMessage(messages.trigger)}</option>}

          {template &&
            template.map((t, i) => (
              <option value={t.name} key={i}>
                {intl.formatMessage(messages[t.name])}
              </option>
            ))}
        </BasicInput>
      )}

      <BasicInfo
        text={intl.formatMessage(messages.info)}
        children={
          <BasicButton
            label={intl.formatMessage(messages.tutorial)}
            icon={<Icon icon={externalLink} />}
            isLink
            href={rteAppId(appId, 'TUTORIAL')}
            target="_blank"
            linkstyle
            small
          />
        }
      />
    </>
  )
}

ItemForm.propTypes = {
  appId: PropTypes.string,
  modules: PropTypes.object,
  onChange: PropTypes.func,
  options: PropTypes.object,
  part: PropTypes.object,
  partIndex: PropTypes.number,
  onFormChange: PropTypes.func,
}

export default connectStore({ modules: ModuleModules })(ItemForm)
