import PropTypes from 'prop-types'
import { FormText } from 'reactstrap'
import { AvRadio } from 'availity-reactstrap-validation'
import { StyledGroup } from "./styles";

const BasicRadioButtonsImage = ({
  error,
  helpMessage,
  inline,
  name,
  partIndex,
  onChange,
  radioButtons,
  required,
  value,
}) => {
  const partName = partIndex ? `${name}-${partIndex}` : name

  const handleImageClick = (radioValue) => {
    onChange({target: {name: partName, value: radioValue}});
  }

  return (
    <StyledGroup
      value={value}
      id={partName}
      name={partName}
      required={required}
      errorMessage={error}
      onChange={onChange}
      inline={inline}
    >
      {helpMessage && <FormText>{helpMessage}</FormText>}

      <div style={{display: 'flex', justifyContent: 'space-around', marginTop: '20px'}}>
      {radioButtons.map((rb, i) => (
        <div key={`rb-${i}`} className="custom-radio-image" onClick={() => handleImageClick(rb.value)}>
          {rb.image}
          <AvRadio label="" value={rb.value} disabled={rb.disabled} required={required} />
        </div>
      ))}
      </div>
    </StyledGroup>
  )
}

BasicRadioButtonsImage.defaultProps = {
  inline: false,
  name: '',
  onChange: () => {},
}

BasicRadioButtonsImage.propTypes = {
  error: PropTypes.string,
  helpMessage: PropTypes.string,
  inline: PropTypes.bool,
  name: PropTypes.string,
  onChange: PropTypes.func,
  partIndex: PropTypes.number,
  radioButtons: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string,
      label: PropTypes.string,
      disabled: PropTypes.bool,
      image: PropTypes.element,
      width: PropTypes.string,
    })
  ),
  required: PropTypes.bool,
  value: PropTypes.any,
}

export default BasicRadioButtonsImage
