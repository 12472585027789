import PropTypes from 'prop-types'
import { useState } from 'react'
import { useIntl } from 'react-intl'
import { PartOptions, BasicInput, BasicButton, BasicInfo, Spinner } from 'components'
import { newEvent } from 'utils'
import { rteAppId } from 'utils/routes'

import { connectStore } from 'redux-box'
import { storeModule as ModuleModules } from 'store/modules'

import { Icon } from 'react-icons-kit'
import { externalLink } from 'react-icons-kit/feather/externalLink'

import PhotoboothModal from './PhotoboothModal'
import ModuleForm from './ModuleForm'
import messages from './messages'

const PhotoboothForm = ({ appId, id, modules, onChange, options, partIndex, toggleDisable, voxpopId, onFormChange }) => {
  const [open, setOpen] = useState(false)

  const intl = useIntl()

  const allPhotobooths = modules.getList('voxpop', 'name')

  const exists = modules.getVoxpopById(id || voxpopId)

  const toggleModal = () => {
    setOpen(!open)
    toggleDisable()
  }

  const handleOnSave = (id) => {
    onChange(newEvent('id', id), partIndex)
    onChange(newEvent('type', 'Photobooth'), partIndex)
    toggleModal()
  }

  return (
    <>
      <PartOptions
        moduleForm={<ModuleForm partIndex={partIndex} onChange={onChange} options={options} onFormChange={onFormChange} />}
        partIndex={partIndex}
        onChange={onChange}
        values={options}
        onFormChange={onFormChange}
      />

      {/* part.voxpopId as value for old photobooths */}
      {modules.fetching ? (
        <Spinner style={{ margin: '40px', paddingBottom: '40px' }} />
      ) : (
        <>
          <BasicInput
            type="select"
            name="id"
            value={exists ? id || voxpopId : ''}
            onChange={(e) => {
              onChange(e, partIndex);
              onFormChange(true);
            }}
            required
            label={intl.formatMessage(messages.photobooth)}
            error={intl.formatMessage(messages.required)}
            partIndex={partIndex}
          >
            <option value="" disabled>
              {intl.formatMessage(messages.select)}
            </option>

            {Object.values(allPhotobooths).map(
              (photobooth, i) =>
                photobooth.name &&
                photobooth.name.length > 0 && (
                  <option value={photobooth.id} key={i}>
                    {photobooth.name}
                  </option>
                )
            )}
          </BasicInput>
        </>
      )}

      <PhotoboothModal
        appId={appId}
        onSave={handleOnSave}
        id={id}
        toggleDisable={toggleDisable}
        open={open}
        setOpen={() => setOpen(!open)}
      />

      <BasicInfo
        text={intl.formatMessage(messages.info)}
        children={
          <BasicButton
            label={intl.formatMessage(messages.tutorial)}
            icon={<Icon icon={externalLink} />}
            isLink
            href={rteAppId(appId, 'TUTORIAL')}
            target="_blank"
            linkstyle
            small
          />
        }
      />
    </>
  )
}

PhotoboothForm.propTypes = {
  appId: PropTypes.string,
  id: PropTypes.string,
  modules: PropTypes.object,
  onChange: PropTypes.func,
  options: PropTypes.object,
  partIndex: PropTypes.number,
  toggleDisable: PropTypes.func,
  voxpopId: PropTypes.string,
  onFormChange: PropTypes.func,
}

export default connectStore({ modules: ModuleModules })(PhotoboothForm)
