import PropTypes from 'prop-types'
import { useIntl } from 'react-intl'
import { BasicModal, BasicButton } from 'components'
import { rteCardsTag } from 'utils/routes'
import history from 'services/history'

import { connectStore } from 'redux-box'
import { storeModule as CardModule } from 'store/card'

import messages from './messages'
import { storeModule as ManageUsersModule } from "../../store/manageUsers";

/*
  users can only do reset on landing pages
*/
const ResetModal = ({ appId, card, cardId, open, toggle, manageUsers }) => {
  const intl = useIntl()

  const handleOnDelete = () => {
    manageUsers.deleteUserRights(appId, manageUsers.profile, {
      type: 'cardsReset',
      cardId: cardId,
    });
    card.deleteCardContent(cardId, appId)
    toggle()
    history.push(rteCardsTag(appId, 'linked'))
  }
  //TODO : déplacer cardId de linked à unlinked

  return (
    <BasicModal
      title={intl.formatMessage(messages.resetTitle)}
      size="small"
      content={<p>{intl.formatMessage(messages.resetInfo)}</p>}
      actions={
        <>
          <BasicButton onClick={toggle} label={intl.formatMessage(messages.cancel)} outlined />
          <BasicButton onClick={handleOnDelete} label={intl.formatMessage(messages.confirm)} color="pink" />
        </>
      }
      isOpen={open}
      toggle={toggle}
    />
  )
}

ResetModal.propTypes = {
  appId: PropTypes.string,
  card: PropTypes.object,
  cardId: PropTypes.string,
  open: PropTypes.bool,
  toggle: PropTypes.func,
  manageUsers: PropTypes.object,
}

export default connectStore({ card: CardModule, manageUsers: ManageUsersModule })(ResetModal)
