import { defineMessages } from 'react-intl'

const scope = 'app.components.PartActions.ItemForm'

export default defineMessages({
  'custom.allergiesImg': { id: `${scope}.custom.allergiesImg` },
  'custom.allergiesTxt': { id: `${scope}.custom.allergiesTxt` },
  'custom.backBtnText': { id: `${scope}.custom.backBtnText` },
  'custom.backBtnUrl': { id: `${scope}.custom.backBtnUrl` },
  'custom.disponibilitees': { id: `${scope}.custom.disponibitlitees` },
  display: { id: `${scope}.display` },
  displayError: { id: `${scope}.displayError` },
  displayHelp: { id: `${scope}.displayHelp` },
  edit: { id: `${scope}.edit` },
  info: { id: `${scope}.info` },
  item: { id: `${scope}.item` },
  itemError: { id: `${scope}.itemError` },
  picture: { id: `${scope}.picture` },
  selectDisplay: { id: `${scope}.selectDisplay` },
  selectItem: { id: `${scope}.selectItem` },
  subtitle: { id: `${scope}.subtitle` },
  text: { id: `${scope}.text` },
  trigger: { id: `${scope}.trigger` },
  tutorial: { id: `${scope}.tutorial` },
})
