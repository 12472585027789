import { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { AvGroup, AvInput, AvFeedback } from 'availity-reactstrap-validation'
import { BasicButton, BasicLabel } from 'components'
import { useIntl } from 'react-intl'

import { Icon } from 'react-icons-kit'
import { trash } from 'react-icons-kit/feather/trash'
import { plus } from 'react-icons-kit/feather/plus'

import { StyledInput } from './styles'
import messages from './messages'

const BasicInputArray = ({
  addLabel,
  children,
  errorMessage,
  handleOnChange,
  helpMessage,
  label,
  list,
  name,
  partIndex,
  required,
  type,
}) => {
  const [listState, setList] = useState(list || [''])

  const intl = useIntl()

  useEffect(() => {
    if (listState.length <= 0) {
      addInput()
    }
  }, [])

  const handleChangeList = (e) => {
    const { id, value } = e.target
    let newList = listState
    const index = id.split('.')[1]
    if (index !== undefined) {
      newList[index] = value
    } else {
      newList = Array.isArray(value) ? value : [value]
    }
    setList(newList)
  }

  const onChange = handleOnChange || handleChangeList

  const addInput = (e) => {
    e.preventDefault()
    const newList = listState.concat([''])
    onChange({ target: { id: name, value: newList } }, partIndex)
  }

  const removeInput = (e, i) => {
    e.preventDefault()
    if (listState.length === 1) {
      onChange({ target: { id: name, value: [''] } }, partIndex)
    } else {
      const newList = [...listState]
      newList.splice(i, 1)
      onChange({ target: { id: name, value: newList } }, partIndex)
    }
  }

  const handleOnBlur = (e) => {
    e.target.value = e.target.value.trim()
    onChange(e, partIndex)
  }

  return (
    <AvGroup>
      <BasicLabel label={label} required={required} helpMessage={helpMessage} name={name} />

      {listState.map((value, i) => (
        <StyledInput key={`url-input-${i}`}>
          <span>{i + 1}</span>
          <AvInput
            name={`${name}.${i}`}
            type={type}
            id={`${name}.${i}`}
            value={value}
            onChange={(e) => onChange(e, partIndex)}
            onBlur={handleOnBlur}
            key={`${name}.${i}`}
            required={required}
          >
            {children}
          </AvInput>

          <div style={{ marginLeft: '10px' }}>
            <BasicButton onClick={(e) => removeInput(e, i)} icon={<Icon icon={trash} />} outlined round />
          </div>
        </StyledInput>
      ))}

      {errorMessage ? <AvFeedback>{errorMessage}</AvFeedback> : ''}

      <div style={{ margin: '5px 0 20px 0' }}>
        <BasicButton
          color="green"
          medium
          onClick={addInput}
          label={addLabel || intl.formatMessage(messages.addLabel)}
          icon={<Icon icon={plus} />}
        />
      </div>
    </AvGroup>
  )
}

BasicInputArray.defaultProps = {
  errorMessage: '',
  handleOnChange: null,
  label: () => {},
  type: 'text',
}

BasicInputArray.propTypes = {
  addLabel: PropTypes.string,
  children: PropTypes.node,
  errorMessage: PropTypes.string,
  handleOnChange: PropTypes.func,
  helpMessage: PropTypes.string,
  label: PropTypes.string,
  list: PropTypes.arrayOf(PropTypes.string),
  name: PropTypes.string,
  partIndex: PropTypes.oneOf([PropTypes.string, PropTypes.number]),
  required: PropTypes.bool,
  type: PropTypes.string,
}

export default BasicInputArray
