import PropTypes from 'prop-types'
import { useIntl } from 'react-intl'
import { BasicInput } from 'components'

import messages from '../messages'

const RepeatSelect = ({ onChange, values, repeatOptions, date, onFormChange }) => {
  const intl = useIntl()

  return (
    <BasicInput
      type="select"
      name="options.display.repeat"
      label={intl.formatMessage(messages.repeat)}
      helpMessage={intl.formatMessage(messages.repeatHelp)}
      onChange={(e) => {
        onChange(e);
        onFormChange(true);
      }}
      value={(values && values.repeat) || 'no'}
    >
      <option value={false}>{intl.formatMessage(messages.repeat.no)}</option>

      {repeatOptions.includes('daily') && (
        <option value="daily">{intl.formatMessage(messages.repeat.daily)}</option>
      )}

      {repeatOptions.includes('weekly') && (
        <option value="weekly">
          {intl.formatMessage(messages.repeat.weekly, {
            date: intl.formatDate(date, { weekday: 'long' }),
          })}
        </option>
      )}

      {repeatOptions.includes('monthlyDay') && (
        <option value="monthlyDay">
          {intl.formatMessage(messages.repeat.monthlyDay, {
            date: intl.formatDate(date, { day: 'numeric' }),
          })}
        </option>
      )}

      {repeatOptions.includes('annuallyDay') && (
        <option value="annuallyDay">
          {intl.formatMessage(messages.repeat.annuallyDay, {
            date: intl.formatDate(date, {
              day: 'numeric',
              month: 'short',
            }),
          })}
        </option>
      )}

      {repeatOptions.includes('weekdays') && (
        <option value="weekdays">{intl.formatMessage(messages.repeat.weekdays)}</option>
      )}

      {repeatOptions.includes('weekends') && (
        <option value="weekends">{intl.formatMessage(messages.repeat.weekends)}</option>
      )}
    </BasicInput>
  )
}

RepeatSelect.propTypes = {
  date: PropTypes.any,
  onChange: PropTypes.func,
  repeatOptions: PropTypes.array,
  values: PropTypes.object,
  onFormChange: PropTypes.func,
}

export default RepeatSelect
