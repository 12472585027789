import PropTypes from 'prop-types'
import { useState, useEffect } from 'react'
import { useIntl } from 'react-intl'
import { BasicButton, ButtonWithConfirm, BasicWarning } from 'components'

import { connectStore } from 'redux-box'
import { storeModule as ModuleModules } from 'store/modules'

import messages from './messages'
import { StyledButtons } from './styles'

const PageActions = ({ id, isNew, loading, modules, noDelete, onCancel, onDelete, onEdit }) => {
  const [lists, setLists] = useState([])

  const intl = useIntl()

  useEffect(() => {
    setLists(modules.getItemInListById(id))
  }, [id])

  return (
    <StyledButtons>
      {!isNew && onDelete && !noDelete && (
        <ButtonWithConfirm
          color="pink"
          medium
          label={intl.formatMessage(messages.btnDelete)}
          onConfirm={onDelete}
          modalText={
            <>
              <p>{intl.formatMessage(messages.modalText)}</p>
              {lists.length > 0 && (
                <BasicWarning text={intl.formatMessage(messages.listWarning)} withBackground />
              )}
            </>
          }
          modalTitle={intl.formatMessage(messages.modalTitle)}
          modalSize="small"
          outlined
        />
      )}

      {onCancel && (
        <>
          <BasicButton medium onClick={onCancel} label={intl.formatMessage(messages.cancel)} outlined />

          <BasicButton
            medium
            color="green"
            label={intl.formatMessage(messages.btnSave)}
            type="submit"
            loading={loading}
          />
        </>
      )}

      {onEdit && (
        <BasicButton medium onClick={onEdit} label={intl.formatMessage(messages.edit)} color="blue" />
      )}
    </StyledButtons>
  )
}

PageActions.propTypes = {
  id: PropTypes.string,
  isNew: PropTypes.bool,
  loading: PropTypes.bool,
  modules: PropTypes.object,
  noDelete: PropTypes.bool,
  onCancel: PropTypes.func,
  onDelete: PropTypes.func,
  onEdit: PropTypes.func,
}

export default connectStore({ modules: ModuleModules })(PageActions)
