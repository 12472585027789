import PropTypes from 'prop-types'
import { BasicTextArea, PartOptions } from 'components'
import { useIntl } from 'react-intl'
import messages from './messages'

// when iFrame
const parseHtmlTag = (html) => {
  let e = {}
  try {
    const dom = document.createElement('html')
    dom.innerHTML = html
    const iframe = dom.getElementsByTagName('iframe')[0]
    const data = {
      height: iframe.getAttribute('height'),
      src: iframe.getAttribute('src'),
    }

    Object.keys(data).forEach((key) => {
      e = { target: { id: key, value: data[key] } }
    })
  } catch (error) {
    console.error(error)
  }
  return e
}

// when url
const parseSketchFabUrl = (inputUrl) => {
  let embeddedUrl = inputUrl
  const defaultUid = 1
  let uid = defaultUid
  const minUidChar = 25
  const entrypoint = 'https://sketchfab.com/models/'
  let parseByOption = '-'
  let splitArr = new Array(10)
  let uidIndex = 1 // index of unique id in the split array

  if (embeddedUrl.includes('embed') || embeddedUrl.includes('edit')) parseByOption = '/'

  splitArr = embeddedUrl.split(parseByOption)
  //  index of uid depends on url type
  switch (parseByOption) {
    case '-':
      uidIndex = 1
      break
    case '/':
      uidIndex = 2
      break
    default:
  }

  splitArr.forEach((item, i) => {
    if (item.length >= minUidChar && i === splitArr.length - uidIndex) {
      uid = item
    } else if (uid === defaultUid) {
      //  resetting previous data
      embeddedUrl = ' '
    }
  })

  embeddedUrl = `${entrypoint}${uid}/embed`

  const e = {
    target: { id: 'src', value: embeddedUrl },
  }
  return e
}

const SketchFabForm = ({ onChange, part, partIndex, onFormChange }) => {
  const intl = useIntl()

  const sketchFabParser = (input) => {
    let returnData = {}
    if (input.indexOf('iframe') >= 0) {
      returnData = parseHtmlTag(input)
    } else {
      returnData = parseSketchFabUrl(input)
    }
    onChange(returnData, partIndex)
  }

  const getIsLinkValid = (value) => {
    const splitOnNewLine = value.split(/\s+/).length <= 1
    const splitOnProtocol = value.split('https://').length === 2

    let isLinkValid = splitOnNewLine && splitOnProtocol ? true : false

    try {
      new URL(value)
    } catch (e) {
      isLinkValid = false
    }

    return isLinkValid
  }

  const handleValidation = (value) => {
    const isIframe = (value.match(/iframe/g) || []).length

    // iframe validation
    if (isIframe > 0) {
      const hasSrc = value.indexOf('src="https://sketchfab.com') >= 0
      const isSingleIframe = isIframe === 2
      return hasSrc && isSingleIframe
    }

    // link validation
    if (isIframe <= 0) {
      const isLinkSketchFab =
        value.includes('https://sketchfab.com') || value.includes('https://www.sketchfab.com')
      const isLinkValid = getIsLinkValid(value)
      return isLinkSketchFab && isLinkValid
    }

    return false
  }

  const handleOnChange = (e) => {
    onChange(e, partIndex)
    sketchFabParser(e.target.value)
  }

  const handleOnBlur = (e) => {
    e.target.value = e.target.value.trim()
    handleOnChange(e)
  }

  return (
    <>
      <PartOptions partIndex={partIndex} onChange={onChange} values={part.options} onFormChange={onFormChange} />

      <BasicTextArea
        name="userInput"
        label={intl.formatMessage(messages.sketchFab)}
        helpMessage={intl.formatMessage(messages.sketchFabHelp)}
        partIndex={partIndex}
        value={part.userInput}
        placeholder="https://sketchfab.com/3d-models/medieval-stairs-72aa84a481bd4b7f852467ed41e1fa01"
        validation={{ async: (value) => handleValidation(value) }}
        error={intl.formatMessage(messages.required)}
        onBlur={handleOnBlur}
        onChange={(e) => {
          handleOnChange(e);
          onFormChange(true);
        }}
        required
        size="small"
      />
    </>
  )
}

SketchFabForm.propTypes = {
  onChange: PropTypes.func,
  part: PropTypes.object,
  partIndex: PropTypes.number,
  onFormChange: PropTypes.func,
}

export default SketchFabForm
