import PropTypes from 'prop-types'
import { useState, useRef, useContext } from 'react'
import { GiphyFetch } from '@giphy/js-fetch-api'
import { Grid } from '@giphy/react-components'
import ResizeObserver from 'react-resize-observer'
import { useIntl } from 'react-intl'
import { BasicInput, PartOptions, BasicTextArea, DropZonePreview, BasicCheckbox } from 'components'
import { validationsPatterns, newEvent } from 'utils'
import { AvGroup, AvInput } from 'availity-reactstrap-validation'
import { LangContext } from 'containers'

import GiphyLogo from 'assets/img/PoweredBy_Horizontal_Light.png'

import { StyledAttribution, StyledErrorMsg } from './styles'
import messages from './messages'

const GiphyKey = 'sXpGFDGZs0Dv1mmNFvYaGUvYwKX0PWIh'

const GiphyForm = ({ onChange, part, partIndex, onFormChange }) => {
  const [query, setQuery] = useState()
  const [width, setWidth] = useState('80%')
  const [isGifSelected, setIsGifSelected] = useState(part.gif && part.gif !== '' ? true : false)
  const [rating, setRating] = useState(true)

  const context = useContext(LangContext)

  const inputRef = useRef(null)

  const intl = useIntl()

  const giphyFetch = new GiphyFetch(GiphyKey)

  const getGifs = () =>
    giphyFetch.search(query, {
      rating: rating ? 'g' : '',
      lang: context.locale.includes('en') ? 'en' : 'fr',
    })

  const getError = () =>
    inputRef &&
    inputRef.current &&
    inputRef.current.context &&
    inputRef.current.context.FormCtrl.getInputState('gif').error

  const handleSelectGif = (gif, e) => {
    e.preventDefault()
    setIsGifSelected(true)
    onChange(newEvent('gif', e.target.src), partIndex)
    onChange(newEvent('alt', e.target.alt), partIndex)
  }

  const handleRemoveGif = () => {
    setIsGifSelected(false)
    onChange(newEvent('gif', ''), partIndex)
    onChange(newEvent('alt', ''), partIndex)
  }

  return (
    <>
      <PartOptions partIndex={partIndex} onChange={onChange} values={part.options} onFormChange={onFormChange} />

      {/* for required in form */}
      <AvGroup ref={inputRef}>
        <AvInput
          name="gif"
          value={part.gif}
          style={{ margin: 0, height: 0, padding: 0, visibility: 'hidden' }}
          required
        />
      </AvGroup>

      <div style={isGifSelected ? { display: 'none' } : { display: 'block' }}>
        <BasicInput
          name="title"
          label={intl.formatMessage(messages.search)}
          helpMessage={intl.formatMessage(messages.searchHelp)}
          placeholder={intl.formatMessage(messages.searchPlaceholder)}
          onChange={(e) => {
            setQuery(e.target.value);
            onFormChange(true);
          }}
        />

        <BasicCheckbox
          label={intl.formatMessage(messages.rating)}
          name="gRated"
          onChange={() => {
            setRating(!rating)
            setIsGifSelected(true)
            setTimeout(() => setIsGifSelected(false), 1)
            onFormChange(true);
          }}
          value={rating}
        />
      </div>

      {getError() && <StyledErrorMsg>{intl.formatMessage(messages.required)}</StyledErrorMsg>}

      {!isGifSelected && (
        <div style={{ maxHeight: '500px', overflowY: 'scroll', margin: '20px 0' }}>
          <Grid
            onGifClick={handleSelectGif}
            fetchGifs={getGifs}
            width={width}
            columns={2}
            key={query}
            gutter={6}
            borderRadius={5}
          />
          <ResizeObserver onResize={({ width }) => setWidth(width)} />
        </div>
      )}

      {isGifSelected && (
        <>
          <DropZonePreview url={part.gif} onRemove={handleRemoveGif} type="image" />
          <StyledAttribution>
            <img className="attribution" src={GiphyLogo} alt="GIPHY Logo" />
          </StyledAttribution>
        </>
      )}

      <BasicInput
        label={intl.formatMessage(messages.link)}
        helpMessage={intl.formatMessage(messages.linkHelp)}
        name="link"
        partIndex={partIndex}
        value={part.link}
        error={intl.formatMessage(messages.linkError)}
        onChange={(e) => {
          onChange(e, partIndex);
          onFormChange(true);
        }}
        validation={{
          pattern: validationsPatterns.urlExtended,
        }}
      />

      <BasicTextArea
        label={intl.formatMessage(messages.legend)}
        helpMessage={intl.formatMessage(messages.legendHelp)}
        name="legend"
        partIndex={partIndex}
        value={part.legend || ''}
        onChange={(e) => {
          onChange(e, partIndex);
          onFormChange(true);
        }}
        size="small"
      />
    </>
  )
}

GiphyForm.propTypes = {
  onChange: PropTypes.func,
  part: PropTypes.object,
  partIndex: PropTypes.number,
  onFormChange: PropTypes.func,
}

export default GiphyForm
