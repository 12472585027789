import PropTypes from 'prop-types'
import { GiphyForm, PictureForm, SketchFabForm } from 'components'

const Images = ({ part, partIndex, app, onChange, onCheckedChange, onPictureChange, onFormChange }) => {
  return (
    <>
      {part.type === 'Picture' && (
        <PictureForm
          onChange={onChange}
          onCheckedChange={onCheckedChange}
          onPictureChange={onPictureChange}
          part={part}
          partIndex={partIndex}
          onFormChange={onFormChange}
        />
      )}

      {part.type === 'PictureUrl' && (
        <PictureForm
          onChange={onChange}
          onCheckedChange={onCheckedChange}
          onPictureChange={onPictureChange}
          part={part}
          partIndex={partIndex}
          onFormChange={onFormChange}
        />
      )}

      {part.type === 'Giphy' && <GiphyForm onChange={onChange} part={part} partIndex={partIndex} onFormChange={onFormChange} />}

      {part.type === 'SketchFab' && <SketchFabForm onChange={onChange} part={part} partIndex={partIndex} onFormChange={onFormChange} />}
    </>
  )
}

Images.propTypes = {
  app: PropTypes.object,
  onChange: PropTypes.func,
  onCheckedChange: PropTypes.func,
  onPictureChange: PropTypes.func,
  part: PropTypes.object,
  partIndex: PropTypes.number,
  onFormChange: PropTypes.func,
}

export default Images
