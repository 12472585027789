export const getColor = (color) => {
  switch (color) {
    case 'green':
      return '#34b076'
    case 'blue':
      return '#416db2'
    case 'yellow':
      return '#ffd232'
    case 'orange':
      return '#f39d36'
    case 'pink':
      return '#ef466f'
    case 'red':
      return 'red'
    default:
      return '#89959f'
  }
}
