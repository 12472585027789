import styled, { css } from 'styled-components'
import { Button, Card } from 'reactstrap'

export const StyledCard = styled(Card)`
  border-radius: 8px;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
  background-color: #f8f8f8;
  max-width: 771px;
  margin-right: 50px;

  @media (max-width: 579px) {
    margin-right: 0;
  }
`

export const StyledInnerCard = styled(Card)`
  background-color: white;
  border-radius: 0;
  min-height: 182px;
  border-left: none;
  border-right: none;
`

export const StyledAddButton = styled.div`
  margin: 20px 1.25rem;
`

export const StyledLegend = styled.div`
  text-transform: uppercase;
  font-weight: 600;
  color: #11263f;
  margin-left: 1.25rem;
  margin-top: 20px;
  margin-bottom: 20px;
`

export const StyledAction = styled(Button)`
  background: transparent;
  color: #5c5c5c;
  width: fit-content;
  padding: 0;
  border: 1px solid transparent;
  border-radius: 100%;
  transition: all 0.2s ease-in-out;

  @media (max-width: 576px) {
    background-color: transparent;
    color: #5c5c5c;
    border: 1px solid #5c5c5c;

    &:not(:first-of-type) {
      margin-right: 5px;
    }

    &.delete {
      border: 1px solid #fe0045;
      background-color: white;
    }
  }

  &:not(:last-of-type) {
    margin-bottom: 5px;

    @media (max-width: 576px) {
      margin-bottom: 0;
    }
  }

  &:hover,
  &:focus,
  &:active {
    background-color: transparent;
    color: #5c5c5c;
    border: 1px solid #5c5c5c;
  }

  &:disabled {
    background-color: transparent;
    color: #5c5c5c;
    border: 1px solid #5c5c5c;
    opacity: 0.3;
  }

  svg {
    height: 26px;
    width: 26px;
  }

  &.top i {
    transform: rotate(90deg) translateY(-1px);
  }

  &.bottom i {
    transform: rotate(-90deg);
  }

  &.delete {
    color: #fe0045;
    padding: 1px 2px;

    svg {
      height: 22px;
      width: 22px;
    }

    &:hover:not(:disabled) {
      border: 1px solid #fe0045;
      background-color: white;
    }

    &:disabled {
      color: #5c5c5c;
    }
  }
`

export const StyledFieldset = styled.fieldset`
  hr {
    margin: 20px 0 10px 0;
  }

  ${({ disabled }) =>
    disabled &&
    css`
      a:not([linkstyle='true']) {
        color: white;
        background-color: #9aa0a4;
        border: 2px solid #9aa0a4;
        pointer-events: none;
        cursor: default;
      }
      a[linkstyle='true'] {
        opacity: 0.65;
        color: #212529;
        background-color: transparent;
        border: none;
        pointer-events: none;
        cursor: default;
      }
    `}
`
