import axios from 'axios'

let logger = (data) =>
  new Promise((res, rej) => {
    axios({
      method: 'post',
      url: 'https://us-central1-smartjourney-app.cloudfunctions.net/logger-logger', // or https://us-central1-PROJECT_NAME.cloudfunctions.net/test_message_json
      headers: { 'content-type': 'application/json' },
      data,
    })
      .then((response) => {
        res()
      })
      .catch((error) => {
        rej()
      })
  })

logger = () => {}
export default logger
