import PropTypes from 'prop-types'
import { useState, useRef, useEffect } from 'react'

import { connectStore } from 'redux-box'
import { storeModule as SessionModule } from 'store/manageUsers'
import { storeModule as manageAppsModule } from 'store/manageApps'
import { storeModule as AppModule } from 'store/app'

import { Icon } from 'react-icons-kit'
import { chevronDown } from 'react-icons-kit/feather/chevronDown'
import { loader } from 'react-icons-kit/feather/loader'

import {
  StyledTag,
  StyledLabel,
  StyledDropDown,
  StyledInput,
  StyledApp,
  StyledName,
  StyledLoader,
} from './styles'
import { getUserApps } from "../../../utils/share";

const AppDropdown = ({ app, onChangeApp, session, className, manageApps }) => {
  const [searchQuery, setSearchQuery] = useState(app.id)
  const [open, setOpen] = useState(false)
  const [appList, setAppList] = useState(session.appList);

  const ref = useRef(null)
  const divRef = useRef(null)

  useEffect(() => {
    document.addEventListener('click', handleClickOutside)

    return () => {
      document.removeEventListener('click', handleClickOutside)
    }
  }, [])

  useEffect(() => {
    setSearchQuery(app.id)
  }, [app.id])

  useEffect(() => {
    if(!session.profile.superadmin && session.profile.apps) {
      setAppList(getUserApps(session.appList, session.profile))
    } else {
      setAppList(session.appList)
    }
  }, [session.profile, session.appList]);

  const listDisabledApps = manageApps.listDisabledApps
  const filteredAppList = appList.filter(app => !listDisabledApps.includes(app));
  const filteredList = filteredAppList && filteredAppList.filter((el) => el.includes(searchQuery.toLowerCase()))

  const handleSelect = (value) => {
    setSearchQuery(value)
    setOpen(false)
    onChangeApp(value)
  }

  const handleClickOutside = (e) => {
    if (divRef?.current && !divRef.current.contains(e.target)) {
      setOpen(false)
    }
  }

  const handleOnFocus = () => {
    if (ref.current) {
      ref.current.focus()
    }
    setSearchQuery('')
    setOpen(true)
  }

  const handleInputChange = (e) => {
    e.persist()
    setSearchQuery(e.target.value)
  }

  return (
    <div className={className} ref={divRef}>
      <StyledApp>
        {session.loading || app.fetching ? (
          <StyledLoader>
            <Icon icon={loader} />
          </StyledLoader>
        ) : appList.length > 1 ? (
          <>
            <StyledTag>APP</StyledTag>

            <StyledName>
              <StyledInput
                innerRef={ref}
                value={searchQuery}
                placeholder={app.id}
                type="select"
                name="search"
                onInput={handleInputChange}
                onFocus={handleOnFocus}
                onClick={handleOnFocus}
                onChange={() => {}}
                autoComplete="off"
              />

              <Icon icon={chevronDown} onClick={handleOnFocus} />
            </StyledName>

            {open && filteredList.length > 0 && (
              <StyledDropDown>
                {filteredList.map((el) => (
                  <button key={el} onClick={() => handleSelect(el)}>
                    {el}
                  </button>
                ))}
              </StyledDropDown>
            )}
          </>
        ) : (
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <StyledTag>APP</StyledTag>
            <StyledLabel style={{ marginTop: '5px', paddingLeft: '10px' }}>{appList[0]}</StyledLabel>
          </div>
        )}
      </StyledApp>
    </div>
  )
}

AppDropdown.defaultProps = {
  app: {},
  onChangeApp: () => {},
}

AppDropdown.propTypes = {
  app: PropTypes.object,
  className: PropTypes.string,
  onChangeApp: PropTypes.func,
  session: PropTypes.object,
}

export default connectStore({ session: SessionModule, app: AppModule, manageApps: manageAppsModule })(AppDropdown)
