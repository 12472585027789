import styled, { css } from 'styled-components'

const squareColor = css`rgba(100, 100, 100, .2)`
const squareSize = css`10px`

export const StyledImage = styled.img`
  max-width: 90%;
  max-height: 300px;
  background-color: rgba(255, 255, 255);
  background-image: linear-gradient(45deg, ${squareColor} 25%, transparent 25%),
    linear-gradient(135deg, ${squareColor} 25%, transparent 25%),
    linear-gradient(45deg, transparent 75%, ${squareColor} 75%),
    linear-gradient(135deg, transparent 75%, ${squareColor} 75%);
  background-position: 0 0, ${squareSize} 0, ${squareSize} calc(-1 * ${squareSize}),
    0 calc(-1 * ${squareSize});
  background-size: calc(2 * ${squareSize}) calc(2 * ${squareSize});
`

export const StyledContainer = styled.div`
  display: flex;
  align-items: flex-start;
  margin-top: 10px;
  margin-bottom: 20px;

  @media (max-width: 600px) {
    justify-content: flex-start;
    padding: 0;
  }

  ${({ disabled }) =>
    disabled &&
    css`
      cursor: default !important;
      pointer-events: none !important;
    `};
`

export const StyledButton = styled.div`
  margin-left: 10px;

  @media (max-width: 600px) {
    display: flex;
    justify-content: flex-end;
  }
`
