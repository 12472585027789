import { defineMessages } from 'react-intl'

const scope = 'app.containers.PageData'

export default defineMessages({
  appStatistics: { id: `${scope}.appStatistics` },
  analytics: { id: `${scope}.analytics` },
  info: { id: `${scope}.info` },
  metaTitle: { id: `${scope}.metaTitle` },
  moreInfo: { id: `${scope}.moreInfo` },
  name: { id: `${scope}.name` },
  statistics: { id: `${scope}.statistics` },
  stayTuned: { id: `${scope}.stayTuned` },
  total: { id: `${scope}.total` },
  visitCounter: { id: `${scope}.visitCounter` },
})
