import PropTypes from 'prop-types'
import connectStore from 'redux-box/dist/connectStore'
import { storeModule as ModuleModules } from 'store/modules'
import { Img, BasicInfo, BasicButton } from 'components'
import { useIntl } from 'react-intl'
import { addBrToText } from 'utils'

import { ScheduleTooltip } from '../components/Tooltips'
import NotFound from './NotFound'
import ModuleTrigger from './ModuleTrigger'
import { StyledPartWithOptions, StyledYellowButton } from './styles'
import messages from '../messages'

const ModuleItem = ({ part, modules, id, style = {} }) => {
  const foundListItem = modules.getListItemById(part.id) || null
  const { displayType } = part.style || ''
  const intl = useIntl()

  if (!foundListItem) {
    return (
      <StyledPartWithOptions>
        <ScheduleTooltip part={part} />
        <NotFound
          id={id}
          type={displayType ? intl.formatMessage(messages[displayType]) : intl.formatMessage(messages.item)}
          fetching={modules.fetching}
        />
      </StyledPartWithOptions>
    )
  }
  return (
    <>
      {displayType ? (
        <>
          {displayType === 'H2' && (
            <StyledPartWithOptions>
              <ScheduleTooltip part={part} />
              <h2>{foundListItem.name}</h2>
            </StyledPartWithOptions>
          )}

          {displayType === 'Picture' && foundListItem.img && (
            <StyledPartWithOptions>
              <ScheduleTooltip part={part} />
              <Img src={foundListItem.img} alt={foundListItem.name} />
            </StyledPartWithOptions>
          )}

          {displayType === 'Text' && foundListItem.text && (
            <StyledPartWithOptions>
              <ScheduleTooltip part={part} />
              <span>{addBrToText(foundListItem.text, 'part-txt')}</span>
            </StyledPartWithOptions>
          )}

          {displayType === 'custom.disponibilitees' && foundListItem.custom.disponibilitees && (
            <StyledPartWithOptions>
              <ScheduleTooltip part={part} />
              <span>{addBrToText(foundListItem.custom.disponibilitees, 'part-txt')}</span>
            </StyledPartWithOptions>
          )}

          {displayType === 'custom.allergiesTxt' && foundListItem.custom.allergiesTxt && (
            <StyledPartWithOptions>
              <ScheduleTooltip part={part} />
              <span>{addBrToText(foundListItem.custom.allergiesTxt, 'part-txt')}</span>
            </StyledPartWithOptions>
          )}

          {displayType === 'custom.allergiesImg' &&
            foundListItem.custom.allergiesImg &&
            foundListItem.custom.allergiesImg !== '' && (
              <StyledPartWithOptions>
                <ScheduleTooltip part={part} />
                <Img src={foundListItem.custom.allergiesImg} alt={foundListItem.name} />
              </StyledPartWithOptions>
            )}

          {displayType === 'custom.allergiesImg' &&
            foundListItem.custom.allergiesImg &&
            foundListItem.custom.allergiesImg === '' && (
              <StyledPartWithOptions>
                <ScheduleTooltip part={part} />
                <BasicInfo text={intl.formatMessage(messages.listitemImage)} />
              </StyledPartWithOptions>
            )}

          {displayType === 'custom.backBtnUrl' &&
            foundListItem.custom.backBtnUrl &&
            foundListItem.custom.backBtnText && (
              <StyledPartWithOptions>
                <ScheduleTooltip part={part} />
                <StyledYellowButton>
                  <BasicButton
                    href={foundListItem.custom.backBtnUrl}
                    target="_blank"
                    label={foundListItem.custom.backBtnText}
                  />
                </StyledYellowButton>
              </StyledPartWithOptions>
            )}

          {displayType === 'Trigger' && (
            <StyledPartWithOptions>
              <ScheduleTooltip part={part} />
              <ModuleTrigger id={foundListItem.triggerId} />
            </StyledPartWithOptions>
          )}
        </>
      ) : (
        <div style={{ textAlign: 'center' }}>
          <pre>{style.displayType}</pre>
        </div>
      )}
    </>
  )
}

ModuleItem.defaultProps = {
  style: {},
}

ModuleItem.propTypes = {
  part: PropTypes.object,
  modules: PropTypes.object,
  id: PropTypes.string,
  style: PropTypes.object,
}

export default connectStore({ modules: ModuleModules })(ModuleItem)
