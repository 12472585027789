import PropTypes from 'prop-types'
import { useState } from 'react'
import { useIntl } from 'react-intl'
import { HEX_COLORS } from 'utils/consts'
import { BasicInput, BasicLabel, FolderModal } from "components";
import Circle from '@uiw/react-color-circle'

import { StyledSave, StyledForm, StyledInput, StyledColorPicker } from './styles'
import messages from './messages'
import { cog as cogIcon } from 'react-icons-kit/ikons'
import { Icon } from "react-icons-kit";
import { connectStore } from "redux-box";
import { storeModule as ManageUsersModule } from "../../../store/manageUsers";

const AddFolderForm = ({ app, handleShowForm, manageUsers }) => {
  const [hex, setHex] = useState('#EF456F')
  const [openEdit, setOpenEdit] = useState(false)
  const intl = useIntl()

  const handleValidation = (value) => {
    const project = Object.values(app.folders).filter((folder) => folder.name === value) || []
    return project.length <= 0
  }

  const handleOpenModal = () => {
    setOpenEdit(!openEdit)
  }

  const handleOnSubmit = (e, values) => {
    e.preventDefault()
    app.saveFolder(app.id, { ...values, name: values.name.trim(), color: hex })
    manageUsers.updateUserRights(app.id, manageUsers.profile, {
      type: 'folders',
      name: values.name.trim()
    });
    handleShowForm()
  }

  return (
    <StyledForm onValidSubmit={handleOnSubmit}>
      <Icon
        style={{ color: 'white', textAlign: 'right', cursor: 'pointer', position: 'relative', top: '20px'}}
        icon={cogIcon}
        title={intl.formatMessage(messages.openFormFolder)}
        onClick={handleOpenModal}
      />
      {openEdit && (
        <FolderModal
          toggleModal={() => setOpenEdit(!openEdit)}
          isOpen={openEdit}
        />
      )}
      <StyledInput>
        <BasicInput
          type="text"
          label={intl.formatMessage(messages.folderName)}
          name="name"
          error={intl.formatMessage(messages.folderError)}
          validation={{
            async: (value) => handleValidation(value),
          }}
          required
        />
      </StyledInput>

      <StyledColorPicker>
        <BasicLabel label={intl.formatMessage(messages.color)} />
        <Circle color={hex} onChange={(color) => setHex(color.hex)} colors={HEX_COLORS} />
      </StyledColorPicker>

      <StyledSave type="submit">
        <span>{intl.formatMessage(messages.save)}</span>
      </StyledSave>
    </StyledForm>
  )
}

AddFolderForm.defaultProps = {
  handleShowAll: () => {},
}

AddFolderForm.propTypes = {
  app: PropTypes.object,
  manageUsers: PropTypes.object,
  handleShowForm: PropTypes.func,
}

export default connectStore({ manageUsers: ManageUsersModule })(AddFolderForm)
