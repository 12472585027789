import { defineMessages } from 'react-intl'

const scope = 'app.containers.PageItems'

export default defineMessages({
  add: { id: `${scope}.add` },
  allergies: { id: `${scope}.allergies` },
  allergiesHelp: { id: `${scope}.allergiesHelp` },
  allergiesImg: { id: `${scope}.allergiesImg` },
  allergiesText: { id: `${scope}.allergiesText` },
  allergiesTextHelp: { id: `${scope}.allergiesTextHelp` },
  allergiesTxt: { id: `${scope}.allergiesTxt` },
  availability: { id: `${scope}.availability` },
  availabilityHelp: { id: `${scope}.availabilityHelp` },
  backBtnText: { id: `${scope}.backBtnText` },
  backBtnTextError: { id: `${scope}.backBtnTextError` },
  backBtnTextHelp: { id: `${scope}.backBtnTextHelp` },
  backBtnUrl: { id: `${scope}.backBtnUrl` },
  backBtnUrlError: { id: `${scope}.backBtnUrlError` },
  backBtnUrlHelp: { id: `${scope}.backBtnUrlHelp` },
  btnDelete: { id: `${scope}.btnDelete` },
  btnSave: { id: `${scope}.btnSave` },
  cancel: { id: `${scope}.cancel` },
  customFieldsHelp: { id: `${scope}.customFieldsHelp` },
  customprefix: { id: `${scope}.customprefix` },
  disponibilitees: { id: `${scope}.disponibilitees` },
  edit: { id: `${scope}.edit` },
  generalInfo: { id: `${scope}.generalInfo` },
  header: { id: `${scope}.header` },
  icon: { id: `${scope}.icon` },
  iconFormat: { id: `${scope}.iconFormat` },
  iconHelp: { id: `${scope}.iconHelp` },
  id: { id: `${scope}.id` },
  image: { id: `${scope}.image` },
  imageFormat: { id: `${scope}.imageFormat` },
  imageHelp: { id: `${scope}.imageHelp` },
  img: { id: `${scope}.img` },
  intro: { id: `${scope}.intro` },
  itemTitle: { id: `${scope}.itemTitle` },
  itemTitleHelp: { id: `${scope}.itemTitleHelp` },
  list: { id: `${scope}.list` },
  listWarning: { id: `${scope}.listWarning` },
  metaTitle: { id: `${scope}.metaTitle` },
  modalText: { id: `${scope}.modalText` },
  modalTitle: { id: `${scope}.modalTitle` },
  name: { id: `${scope}.name` },
  nameError: { id: `${scope}.nameError` },
  nameHelp: { id: `${scope}.nameHelp` },
  newItem: { id: `${scope}.newItem` },
  newTrigger: { id: `${scope}.newTrigger` },
  page: { id: `${scope}.page` },
  pageNotFound: { id: `${scope}.pageNotFound` },
  pageTitle: { id: `${scope}.pageTitle` },
  pageTitleHelp: { id: `${scope}.pageTitleHelp` },
  productPage: { id: `${scope}.productPage` },
  savingProgress: { id: `${scope}.savingProgress` },
  search: { id: `${scope}.search` },
  selectOne: { id: `${scope}.selectOne` },
  shortName: { id: `${scope}.shortName` },
  shortNameHelp: { id: `${scope}.shortNameHelp` },
  subtitle: { id: `${scope}.subtitle` },
  template: { id: `${scope}.template` },
  templateAC: { id: `${scope}.templateAC` },
  templateDefault: { id: `${scope}.templateDefault` },
  templateHelp: { id: `${scope}.templateHelp` },
  text: { id: `${scope}.text` },
  textHelp: { id: `${scope}.textHelp` },
  title: { id: `${scope}.title` },
  titleCustomFields: { id: `${scope}.titleCustomFields` },
  triggerHelp: { id: `${scope}.triggerHelp` },
  triggerId: { id: `${scope}.triggerId` },
  triggerWarning: { id: `${scope}.triggerWarning` },
  viewCard: { id: `${scope}.viewCard` },
  viewLess: { id: `${scope}.viewLess` },
  viewList: { id: `${scope}.viewList` },
  viewMore: { id: `${scope}.viewMore` },
})
