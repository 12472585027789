import PropTypes from 'prop-types'
import { Icon } from 'react-icons-kit'
import { ic_expand_less as upIcon } from 'react-icons-kit/md/ic_expand_less'
import { ic_expand_more as downIcon } from 'react-icons-kit/md/ic_expand_more'
import { ic_first_page as topBottomIcon } from 'react-icons-kit/md/ic_first_page'
import { ic_clear as clear } from 'react-icons-kit/md/ic_clear'

import { StyledActionContainer, StyledAction } from './styles'

const UpDownButtons = ({ handleMoveBlock, handleRemoveBlock, handleResetBlock, onFormChange, index, locked, parts }) => {
  const handleDelete = () => (parts.length <= 1 ? handleResetBlock(index) : handleRemoveBlock(index))

  const isDeleteDisabled = () => parts.length === 1 && parts[0].type === ''

  return (
    <StyledActionContainer>
      <StyledAction
        onClick={(e) => {
          handleDelete(e);
          onFormChange(true);
        }}
        disabled={isDeleteDisabled() || locked}
        className="delete"
      >
        <Icon icon={clear} />
      </StyledAction>

      <StyledAction
        onClick={() => {
          handleMoveBlock(index, 'top');
          onFormChange(true);
        }}
        disabled={index < 1}
        className="top"
      >
        <Icon icon={topBottomIcon} />
      </StyledAction>

      <StyledAction
        onClick={() => {
          handleMoveBlock(index, 'up');
          onFormChange(true);
        }}
        disabled={index < 1}
      >
        <Icon icon={upIcon} />
      </StyledAction>

      <StyledAction
        onClick={() => {
          handleMoveBlock(index, 'down');
          onFormChange(true);
        }}
        disabled={index + 1 >= parts.length}
      >
        <Icon icon={downIcon} />
      </StyledAction>

      <StyledAction
        disabled={index + 1 >= parts.length}
        onClick={() => {
          handleMoveBlock(index, 'bottom');
          onFormChange(true);
        }}
        className="bottom"
      >
        <Icon icon={topBottomIcon} />
      </StyledAction>
    </StyledActionContainer>
  )
}

UpDownButtons.propTypes = {
  index: PropTypes.number,
  locked: PropTypes.bool,
  handleMoveBlock: PropTypes.func,
  handleRemoveBlock: PropTypes.func,
  handleResetBlock: PropTypes.func,
  onFormChange: PropTypes.func,
  parts: PropTypes.array,
}

export default UpDownButtons
