import styled from 'styled-components'

export const StyledTrigger = styled.div`
  display: flex;
  font-size: 16px;
  line-height: 18px;
  font-weight: 600;
  font-family: 'Nunito';

  i {
    display: flex !important;
    margin-right: 5px;
  }
`

export const StyledNotFoundMsg = styled.span`
  display: flex;
  align-items: top;
  font-size: 16px;
  line-height: 18px;
  font-weight: 600;
  font-family: 'Nunito';

  i {
    display: flex !important;
    margin-right: 5px;
  }

  small {
    color: #f59f32;
    marginleft: 20px;
  }
`

export const StyledInput = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  flex: 1;
  font-size: 16px;
  line-height: 18px;
  font-weight: 600;
  font-family: 'Nunito';

  label,
  span {
    font-size: 16px;
    line-height: 18px;
    font-weight: 600;
    font-family: 'Nunito';
  }

  input {
    min-height: 48px;
    border-radius: 6px;
    background-color: white;
    border: 1px solid #89959f;
    font-size: 15px;
    line-height: 20px;
    padding: 0 10px;
    color: #89959f;
  }
`

export const StyledMessage = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  i {
    display: flex !important;
    margin-right: 10px;
  }
`

export const StyledPartWithOptions = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin: 24px 1.25rem;

  h2 {
    font-family: 'Nunito', sans-serif;
    font-size: 22px;
    font-weight: 700;
    line-height: 24px;
    letter-spacing: -0.7px;
    margin-bottom: 0;
  }

  p {
    font-size: 15px;
    line-height: 22px;
  }
`

// pour aircanada
export const StyledYellowButton = styled.div`
  width: 100%;
  height: 38px;

  a {
    font-size: 15px;
    font-family: 'Nunito';
    font-weight: 600;
    line-height: 11px;
    color: black;
    background-color: #ffd232;
    padding: 6px 12px;
    margin-right: 20px;
    border: 2px solid #ffd232;
    border-radius: 16px;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    cursor: pointer;
    transition: all ease-in-out 0.2s;

    &:hover {
      background-color: transparent;
      color: black !important;
      border-color: #ffd232 !important;
    }
  }
`
