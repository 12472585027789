import { defineMessages } from 'react-intl'

const scope = 'app.components.CardEditActions'

export default defineMessages({
  cancel: { id: `${scope}.cancel` },
  copy: { id: `${scope}.copy` },
  delete: { id: `${scope}.delete` },
  expand: { id: `${scope}.expand` },
  newTab: { id: `${scope}.newTab` },
  save: { id: `${scope}.save` },
})
