import PropTypes from 'prop-types'
import { useIntl } from 'react-intl'
import styled from 'styled-components'
import { Spinner } from 'components'

import connectStore from 'redux-box/dist/connectStore'
import { storeModule as ModuleModules } from 'store/modules'

import { Icon } from 'react-icons-kit'
import { camera } from 'react-icons-kit/feather/camera'

import { NotFound } from '../modules'
import { ScheduleTooltip } from '../components/Tooltips'
import messages from './messages'

const StyledWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin: 20px;
`

const StyledLock = styled.div`
  display: flex;
  width: 100%;
  font-size: 16px;
  line-height: 18px;
  font-weight: 600;
  font-family: 'Nunito';

  i {
    display: flex !important;
    margin-right: 5px;
  }
`

const Photobooth = ({ modules, part, partIndex }) => {
  const foundPhotobooth = modules.getVoxpopById(part.id || part.voxpopId) || {}
  const intl = useIntl()

  return modules.fetching ? (
    <Spinner style={{ padding: '20px', marginBottom: '20px' }} />
  ) : (
    <StyledWrapper key={partIndex}>
      {Object.keys(foundPhotobooth).length > 0 ? (
        <StyledLock>
          <Icon icon={camera} />
          <span>
            {intl.formatMessage(messages.photobooth)}:&nbsp;{foundPhotobooth.name}
          </span>
        </StyledLock>
      ) : (
        <NotFound id={part.id} type={intl.formatMessage(messages.photobooth)} fetching={modules.fetching} />
      )}

      <ScheduleTooltip part={part} />
    </StyledWrapper>
  )
}

Photobooth.propTypes = {
  part: PropTypes.object,
  partIndex: PropTypes.number,
}

export default connectStore({ modules: ModuleModules })(Photobooth)
