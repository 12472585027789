import PropTypes from 'prop-types'
import { useIntl } from 'react-intl'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import { BasicTooltip, Pastille, BasicButton } from 'components'
import { cardDirectLink } from 'utils/generator'
import { ShareModal } from 'components';

import { Icon } from 'react-icons-kit'
import { externalLink } from 'react-icons-kit/feather'
import { link } from 'react-icons-kit/feather/link'
import { share2 } from 'react-icons-kit/feather/share2'

import { StyledContainer, StyledButtons, StyledLegend, StyledTitle } from './styles'
import messages from './messages'
import { useState } from 'react';
import { connectStore } from 'redux-box';
import { storeModule as SessionModule } from '../../store/manageUsers';
import { checkActionsAccess } from '../../services/utils';

const CardDisplayHeader = ({ appId, singleview, tokenId, cardId, place, title, tagGaLabel, tagGaChecked, folders, session }) => {
  const intl = useIntl()
  const [openModal, setOpenModal] = useState(false)

  return (
    <StyledContainer singleview={singleview}>
      <StyledTitle singleview={singleview}>
        <Pastille number={tokenId} />
        <h2>{title}</h2>
      </StyledTitle>

      {!tagGaChecked && tagGaChecked !== undefined && (
        <StyledLegend>
          <b>{intl.formatMessage(messages.googleAnalyticsTag)}</b>
          {tagGaLabel}
        </StyledLegend>
      )}

      {place && (
        <StyledLegend>
          <b>{intl.formatMessage(messages.note)}</b>
          {place}
        </StyledLegend>
      )}

      <StyledButtons>
        <div data-for={`copy-${cardId}`} data-tip={intl.formatMessage(messages.copy)}>
          <CopyToClipboard text={cardDirectLink(appId, tokenId, cardId)}>
            <BasicButton icon={<Icon icon={link} />} small outlined round />
          </CopyToClipboard>
        </div>

        <BasicTooltip id={`copy-${cardId}`} maxWidth={125} />

        <div data-for={`open-${cardId}`} data-tip={intl.formatMessage(messages.newTab)}>
          <BasicButton
            icon={<Icon icon={externalLink} />}
            small
            outlined
            round
            onClick={() => window.open(cardDirectLink(appId, tokenId, cardId))}
          />
        </div>

        {(checkActionsAccess(appId, session.profile, 'folders', 'share') ||
            checkActionsAccess(appId, session.profile, 'pages', 'share'))
          &&
          <div data-for={`share-${cardId}`} data-tip={intl.formatMessage(messages.share)}>
          <BasicButton
            icon={<Icon icon={share2} />}
            small
            outlined
            round
            onClick={() => setOpenModal(true)}
          />
        </div>}
        <BasicTooltip id={`share-${cardId}`} />
        {openModal && (
          <ShareModal
            openModal={openModal}
            setOpenModal={setOpenModal}
            cardId={cardId}
            tokenId={tokenId}
            isFolder={false}
            appId={appId}
            folders={folders}
            cardTitle={title}
          />
        )}

        <BasicTooltip id={`open-${cardId}`} />
      </StyledButtons>
    </StyledContainer>
  )
}

CardDisplayHeader.propTypes = {
  appId: PropTypes.string,
  cardId: PropTypes.string,
  singleview: PropTypes.bool,
  place: PropTypes.string,
  title: PropTypes.string,
  tokenId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  folders: PropTypes.array,
  session: PropTypes.object,
}

export default connectStore({ session: SessionModule })(CardDisplayHeader)
