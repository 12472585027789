import PropTypes from 'prop-types'
import { useIntl } from 'react-intl'
import { PartOptions, BasicInfo, BasicTextArea, VideoDropzone } from 'components'

import StyleForm from './StyleForm'
import messages from '../messages'

const VideoUploadForm = ({ onChange, onVideoChange, part, partIndex, onFormChange }) => {
  const intl = useIntl()

  return (
    <>
      <PartOptions
        partIndex={partIndex}
        onChange={onChange}
        values={part.options}
        styleForm={<StyleForm index={partIndex} onChange={onChange} style={part.style} onFormChange={onFormChange} />}
        onFormChange={onFormChange}
      />

      <VideoDropzone
        name={`file-${partIndex}`}
        error={intl.formatMessage(messages.videoError)}
        file={part.file}
        label={intl.formatMessage(messages.video)}
        helpMessage={intl.formatMessage(messages.videoHelp)}
        url={part.url}
        onChange={(file) => {
          onVideoChange(file[0], partIndex);
          onFormChange(true);
        }}
        onDelete={() => onVideoChange(null, partIndex, true)}
        required
      />

      <BasicInfo text={intl.formatMessage(messages.previewInfo)} />

      <BasicTextArea
        label={intl.formatMessage(messages.legend)}
        helpMessage={intl.formatMessage(messages.legendHelp)}
        name="legend"
        partIndex={partIndex}
        value={part.legend || ''}
        onChange={(e) => {
          onChange(e, partIndex);
          onFormChange(true);
        }}
        size="xsmall"
      />
    </>
  )
}

VideoUploadForm.propTypes = {
  onChange: PropTypes.func,
  onVideoChange: PropTypes.func,
  part: PropTypes.object,
  partIndex: PropTypes.number,
  onFormChange: PropTypes.func,
}

export default VideoUploadForm
