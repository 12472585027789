import PropTypes from 'prop-types'
import { useIntl } from 'react-intl'
import { AvForm } from 'availity-reactstrap-validation'
import { LayoutCard } from 'components'
import { scrollToError } from 'utils'
import uuidv4 from 'uuid/v4'

import connectStore from 'redux-box/dist/connectStore'
import { storeModule as LocksModule } from 'store/locks'

import messages from '../messages'
import PageActions from '../PageActions'
import Answers from './Answers'
import GeneralInfo from './GeneralInfo'

const LockEditForm = ({ appId, cardId, isNew, data, lockModule, onCancel, onDelete, onSave, noDelete }) => {
  const intl = useIntl()

  const handleOnSubmit = (e, errors, newLock) => {
    e.preventDefault()
    if (errors.length) {
      scrollToError()
      return false
    }
    const id = (data && data.id) || uuidv4()
    const currentLock = { ...data, ...newLock, ...{ id } }
    lockModule.saveLock(appId, cardId, currentLock, onSave)
    setTimeout(() => lockModule.resetStatus(), 5000)
    return true
  }

  return (
    <AvForm onSubmit={handleOnSubmit} model={data || null}>
      <fieldset disabled={lockModule.saving}>
        <LayoutCard
          title={isNew ? intl.formatMessage(messages.newLock) : intl.formatMessage(messages.edit)}
          headerChildren={
            <PageActions
              onCancel={onCancel}
              onDelete={onDelete}
              loading={lockModule.saving}
              isNew={isNew}
              noDelete={noDelete}
            />
          }
          footerChildren={
            <PageActions
              onCancel={onCancel}
              onDelete={onDelete}
              loading={lockModule.saving}
              isNew={isNew}
              noDelete={noDelete}
            />
          }
        >
          <GeneralInfo getLockByName={lockModule.getLockByName} id={(data && data.id) || ''} />

          <hr />

          <Answers lock={data} />
        </LayoutCard>
      </fieldset>
    </AvForm>
  )
}

LockEditForm.defaultProps = {
  onCancel: () => {},
  onDelete: () => {},
  onSave: () => {},
}

LockEditForm.propTypes = {
  appId: PropTypes.string,
  cardId: PropTypes.string,
  isNew: PropTypes.bool,
  data: PropTypes.object,
  lockModule: PropTypes.object,
  onCancel: PropTypes.func,
  onDelete: PropTypes.func,
  onSave: PropTypes.func,
  noDelete: PropTypes.bool,
}

export default connectStore({ lockModule: LocksModule })(LockEditForm)
