import PropTypes from 'prop-types'
import { BasicMultiSelect, BasicButton, BasicModal, FolderModal } from 'components'
import { useContext, useEffect, useState } from 'react'
import { useIntl } from 'react-intl'

import { connectStore } from 'redux-box'
import { storeModule as CardModule } from 'store/card'
import { storeModule as AppModule } from 'store/app'
import { storeModule as CardListModule } from 'store/cardList'

import messages from './messages'
import { Icon } from 'react-icons-kit'
import { plus } from 'react-icons-kit/feather/plus'
import { lock } from 'react-icons-kit/feather/lock'
import { MainContainerContext } from '../../containers'
import { storeModule as ManageUsersModule } from "../../store/manageUsers";

const Folders = ({ app, card, cardId, cardList, open, toggle, manageUsers }) => {
  const [userAppFolders, setUserAppFolders] = useState([])
  const [selectedFolders, setSelectedFolders] = useState([])
  const [openFolders, setOpenFolders] = useState(false)
  const [userFolders, setUserFolders] = useState([])
  const [userAccess, setUserAccess] = useState('')
  const user = manageUsers.profile;

  const intl = useIntl()

  const context = useContext(MainContainerContext)

  useEffect(() => {
    if(user && !user.superadmin) {
      let appIndex = user.apps.findIndex(userApp => userApp.appId === app.id);
      let roleUser = '';
      if (user.apps[appIndex].roles && user.apps[appIndex].roles.length > 0) {
        roleUser = user.apps[appIndex].roles[0];
        setUserAccess(roleUser.typeAccess)
      }
      if (roleUser.typeAccess === 'none_exept' && roleUser.access && roleUser.access.folders) {
        const folders = roleUser.access.folders.map(folder => folder.folderId);
        setUserFolders(folders);
      }
    }
  }, [user])

  useEffect(() => {
    setSelectedFolders([...getFolders()]);
    setUserAppFolders(getAppFolders());
  }, [card.folders, userFolders, app.folders]);

  const getFolders = () => {
    const c = cardList.getCardById(cardId)
    const values = c.folders ? c.folders.map((id) => app.getFolderById(id)) : []
    if(userAccess === 'none_exept') {
      const filteredValues = values.filter((item) => item && userFolders.includes(item.id))
      return filteredValues || []
    } else {
      return values.filter((item) => item) || []
    }
  }

  const getAppFolders = () => {
    if(userAccess === 'none_exept') {
      return app.folders.filter(folder => userFolders.includes(folder.id));
    } else {
      return app.folders
    }
  }

  const handleFolderChange = (values) => {
    setSelectedFolders(values)
  }

  const handleOnConfirm = () => {
    const values = selectedFolders.map((v) => v.id)
    card.saveCardFolder({ folders: values, cardId }, app.id)
    toggle()
  }

  const toggleFolders = () => setOpenFolders(!openFolders)

  return (
    <BasicModal
      title={intl.formatMessage(messages.folders)}
      content={
        <div>
          <BasicMultiSelect
            name="folders"
            options={Object.values(userAppFolders)}
            optionsValue="name"
            onChange={handleFolderChange}
            placeholder={intl.formatMessage(messages.select)}
            selectedValues={selectedFolders}
          />
          {!!app.access.Folders ? (
            <BasicButton
              onClick={toggleFolders}
              icon={<Icon icon={plus} />}
              label={intl.formatMessage(messages.addFolder)}
              small
              outlined
            />
          ) : (
            <BasicButton
              onClick={() => context.toggleUpgradeModal()}
              icon={<Icon icon={lock} />}
              label={intl.formatMessage(messages.addFolder)}
              small
              outlined
            />
          )}
          <FolderModal cardId={cardId} toggleModal={toggleFolders} isOpen={openFolders} />
        </div>
      }
      actions={
        <>
          <BasicButton onClick={toggle} label={intl.formatMessage(messages.cancel)} outlined />
          <BasicButton onClick={handleOnConfirm} label={intl.formatMessage(messages.confirm)} color="green" />
        </>
      }
      isOpen={open}
      toggle={toggle}
      size="small"
    />
  )
}

Folders.propTypes = {
  app: PropTypes.object.isRequired,
  card: PropTypes.object.isRequired,
  cardId: PropTypes.string.isRequired,
  cardList: PropTypes.object.isRequired,
  open: PropTypes.bool.isRequired,
  toggle: PropTypes.func.isRequired,
  manageUsers: PropTypes.object,
}

export default connectStore({ card: CardModule, cardList: CardListModule, app: AppModule, manageUsers: ManageUsersModule })(Folders)
