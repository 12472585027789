import { defineMessages } from 'react-intl'

const scope = 'app.components.PartActions.FacebookShareButtonForm'

export default defineMessages({
  hashtag: { id: `${scope}.hashtag` },
  hashtagError: { id: `${scope}.hashtagError` },
  hastagHelp: { id: `${scope}.hastagHelp` },
  message: { id: `${scope}.message` },
  messageHelp: { id: `${scope}.messageHelp` },
  text: { id: `${scope}.text` },
  textError: { id: `${scope}.textError` },
  textHelp: { id: `${scope}.textHelp` },
  warning: { id: `${scope}.warning` },
})
