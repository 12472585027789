import { defineMessages } from 'react-intl'

const scope = 'app.components.PartActions.FbsContent'

export default defineMessages({
  deleted: { id: `${scope}.deleted` },
  info: { id: `${scope}.info` },
  'fbs-content': { id: `${scope}.fbs-content` },
  viewPhotobooth: { id: `${scope}.viewPhotobooth` },
})
