import orderBy from 'lodash/orderBy'
import sortBy from 'lodash/sortBy'

const mutations = {
  MANAGE_SET_APPLIST: (state, { appList }) => {
    state.appList = appList.map((appId) => ({ appId }))
  },
  MANAGE_SET_LISTDISABLEDAPPS: (state, { allDisabledApps }) => {
    state.listDisabledApps = allDisabledApps
  },
  MANAGE_APP_SAVE_REQUEST: (state) => {
    state.createApp.fetching = true
    state.createApp.error = false
  },
  MANAGE_APP_SAVE_SETTINGS: (state, { data }) => {
    const apps = { ...state.apps.list }
    const index = Object.values(apps).findIndex((a) => a.id === data.id)

    if (index >= 0) {
      state.apps.list[index] = { ...state.apps.list[index], ...data }
    }
  },
  MANAGE_APP_SAVE_SUCCESS: (state, { app }) => {
    const apps = { ...state.apps.list }
    const index = Object.values(apps).findIndex((a) => a.id === app.id)

    state.createApp.error = false
    state.createApp.fetching = false
    state.createApp.success = true

    if (index < 0) {
      state.appList.push({ appId: app.id })
      state.appList = sortBy(state.appList, ['appId'])
    } else {
      state.apps.list[index] = { ...state.apps.list[index], ...app }
    }
  },
  MANAGE_APP_SAVE_FAILED: (state, { error }) => {
    state.createApp.fetching = false
    state.createApp.error = error
  },
  MANAGE_APP_CREATE_RESET: (state) => {
    state.createApp.success = false
    state.createApp.fetching = false
    state.createApp.error = false
  },
  MANAGE_APPS_GET_FETCHING: (state) => {
    state.apps.fetching = true
  },
  MANAGE_APPS_GET_SUCCESS: (state, { list }) => {
    const sortedApps = orderBy(list, [(app) => app.name && app.name.toLowerCase()], ['asc'])
    state.apps.fetching = false
    state.apps.error = false
    state.apps.list = Object.values(sortedApps)
  },
  MANAGE_APPS_GET_ERROR: (state, { error }) => {
    state.apps.fetching = false
    state.apps.error = error
  },
  MANAGE_APP_START_FETCHING: (state) => {
    state.getAppInfo.fetching = true
  },
  MANAGE_APP_STOP_FETCHING: (state) => {
    state.getAppInfo.fetching = false
  },
  MANAGE_APPS_SET_APP_INFO: (state, { appId, values }) => {
    const i = state.apps.list.findIndex((app) => app.id === appId)
    if (i >= 0) {
      state.apps.list[i] = { ...state.apps.list[i], ...values }
    }
  },
  MANAGE_APPS_SET_LEGALCONTACTS: (state, { appId, legalContacts }) => {
    state.getAppInfo.error = false
    state.createApp.error = false
    state.createApp.fetching = false
    state.createApp.success = false

    const i = state.apps.list.findIndex((app) => app.id === appId)
    if (i >= 0) {
      state.apps.list[i] = { ...state.apps.list[i], legalContacts: legalContacts }
    }
  },
  MANAGE_APPS_SET_ADDONS: (state, { appId, addOns }) => {
    state.getAppInfo.error = false
    state.createApp.error = false
    state.createApp.fetching = false
    state.createApp.success = false

    const i = state.apps.list.findIndex((app) => app.id === appId)
    if (i >= 0) {
      state.apps.list[i] = { ...state.apps.list[i], addOns: addOns }
    }
  },
  MANAGE_APPS_SET_USERS_LIST: (state, { users }) => {
    state.appInfo.userList = users
  },
  MANAGE_APPS_GET_INFO_FAILED: (state) => {
    state.getAppInfo.error = true
  },
  MANAGE_APPS_REMOVE_THEME: (state, { theme }) => {
    // eslint-disable-next-line array-callback-return
    Object.values(state.apps.list).map((app) => {
      if (!!app && !!app.themes) {
        const t = app.themes.filter((t) => t !== theme.value) || []
        const i = state.apps.list.findIndex((a) => a.id === app.id)
        state.apps.list[i] = { ...state.apps.list[i], ...{ themes: t } }
      }
    })
  },
}
export default mutations
