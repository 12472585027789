import PropTypes from 'prop-types'
import { useEffect, useState } from 'react'
import { useIntl } from 'react-intl'
import { BasicModal, PageAlerts } from 'components'

import { connectStore } from 'redux-box'
import { storeModule as DataModule } from 'store/data'

import ThemeList from './ThemeList'
import messages from './messages'

const ThemeModal = ({ data, open, toggle }) => {
  const [list, setList] = useState({})
  const intl = useIntl()

  useEffect(() => {
    if (data.themes) setList(data.themes)
  }, [data.themes])

  return (
    open && (
      <>
        <BasicModal
          title={intl.formatMessage(messages.themes)}
          toggle={toggle}
          isOpen={open}
          content={
            <>
              <PageAlerts
                saveInProgress={data.saving}
                saveSuccess={data.success}
                saveError={data.error}
                deleteInProgress={data.deleting}
                deleteSuccess={data.deleted}
                onResetStatus={() => data.resetStatus()}
              />
              <ThemeList list={list} />
            </>
          }
        />
      </>
    )
  )
}

ThemeModal.defaultProps = {
  open: false,
  toggle: () => {},
}

ThemeModal.propTypes = {
  data: PropTypes.object.isRequired,
  open: PropTypes.bool,
  toggle: PropTypes.func,
}

export default connectStore({ data: DataModule })(ThemeModal)
