import moment from 'moment-timezone'
import PropTypes from 'prop-types'
import { useState } from 'react'
import { useIntl } from 'react-intl'
import { BasicInputTimezone, BasicInfo, BasicCheckbox } from 'components'
import { newEvent } from 'utils'

import DEFAULTS from '../defaults'
import messages from '../messages'
import { valuesToTzRange } from './utils'
import { TimeZonesWrapper } from './styles'

/*
  Cannot hide and show input based on differentTz value
  because of AvForm validation function
*/
const TimeZonesFields = ({ values, onChange, onFormChange }) => {
  const [differentTz, setDifferentTz] = useState(values.differentTimezones)

  const intl = useIntl()

  const defaultTimeZone = moment.tz.guess() || DEFAULTS.timezone
  const tzRange = valuesToTzRange(values, defaultTimeZone)

  const handleTimeZoneChange = (e) => {
    if (differentTz) {
      onChange(newEvent(e.target.name, e.target.value))
    } else {
      onChange(newEvent('options.display.range.0.timezone', e.target.value))
      onChange(newEvent('options.display.range.1.timezone', e.target.value))
    }
    onFormChange(true);
  }

  const handleDiffTzChange = (e) => {
    if (!differentTz) {
      onChange(newEvent('options.display.range.1.timezone', tzRange[0]))
    }
    setDifferentTz(!differentTz)
    onChange(newEvent(e.target.name, !differentTz))
    onFormChange(true);
  }

  return (
    <>
      <BasicInfo text={`${intl.formatMessage(messages.timezonesDefault)} ${defaultTimeZone}`} />

      <TimeZonesWrapper>
        <BasicInputTimezone
          name="options.display.range.0.timezone"
          label={intl.formatMessage(differentTz ? messages.tzStart : messages.timezones)}
          value={tzRange[0]}
          onChange={handleTimeZoneChange}
        />

        <div style={!differentTz ? { visibility: 'hidden' } : {}}>
          <BasicInputTimezone
            name="options.display.range.1.timezone"
            label={intl.formatMessage(messages.tzEnd)}
            value={tzRange[1]}
            onChange={handleTimeZoneChange}
          />
        </div>
      </TimeZonesWrapper>

      <BasicCheckbox
        name="options.display.differentTimezones"
        label={intl.formatMessage(messages.diffTimezones)}
        value={differentTz}
        onChange={handleDiffTzChange}
      />
    </>
  )
}

TimeZonesFields.propTypes = {
  onChange: PropTypes.func,
  onFormChange: PropTypes.func,
}

export default TimeZonesFields
