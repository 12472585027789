/* eslint-disable no-underscore-dangle */
import rsFirebase from 'services/rsf'

class FileManager {
  constructor(file) {
    Object.keys(file).forEach((key) => {
      this[key] = file[key]
    })
  }

  _removeFileFromFacebookList(date) {
    return new Promise((res, rej) => {
      try {
        const path = `appdata/${this.appId}/facebook-share/${this.id}`
        const docRef = rsFirebase.app.firestore().doc(path)
        docRef.set(
          {
            deleted: date,
          },
          { merge: true }
        )
        res()
      } catch (error) {
        rej(error)
      }
    })
  }

  _updateFacebookDeleteSource(date) {
    return new Promise((res, rej) => {
      try {
        const path = `appdata/${this.appId}/files-received/${this.id}`
        const docRef = rsFirebase.app.firestore().doc(path)
        docRef.update({
          'source.share-to-facebook-deleted': date,
        })
        res()
      } catch (error) {
        rej(error)
      }
    })
  }

  async removeFromFacebook() {
    const date = new Date(Date.now())

    await this._removeFileFromFacebookList(date)
    await this._updateFacebookDeleteSource(date)

    return { 'share-to-facebook-deleted': date }
  }

  _deleteFile() {
    return new Promise((res, rej) => {
      try {
        const path = `appdata/${this.appId}/files-received/${this.id}`
        const docRef = rsFirebase.app.firestore().doc(path)
        docRef.delete().then(res)
      } catch (error) {
        rej(error)
      }
    })
  }

  _deleteFromVoxpopList() {
    return new Promise((res, rej) => {
      try {
        const path = `appdata/${this.appId}/${this.origin.type.toLowerCase()}/${this.origin.id}/files/${
          this.id
        }`
        const docRef = rsFirebase.app.firestore().doc(path)
        docRef.delete().then(res)
      } catch (error) {
        rej(error)
      }
    })
  }
  async delete() {
    const date = new Date(Date.now())
    await this._removeFileFromFacebookList(date)
    await this._deleteFile()
    await this._deleteFromVoxpopList()

    return {}
  }
}
export default FileManager
