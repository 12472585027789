import PropTypes from 'prop-types'
import { useEffect, useState } from 'react'
import { useIntl } from 'react-intl'
import { Nav, NavItem, NavLink, TabPane } from 'reactstrap'

import connectStore from 'redux-box/dist/connectStore'
import { storeModule as AppModule } from 'store/app'

import TimerForm from './form'
import messages from './messages'

import { StyledTabContent } from './styles'

const OptionsTabs = ({
  app,
  moduleForm,
  onChange,
  onClearOptions,
  partIndex,
  hideTimer,
  styleForm,
  tagsForm,
  options,
  emptyOptions,
  onFormChange
}) => {
  const [activeTab, setActiveTab] = useState('')

  const intl = useIntl()

  // Timer tab needs to be last
  // display = timer
  useEffect(() => {
    if (styleForm) {
      setActiveTab('style')
    } else if (moduleForm) {
      setActiveTab('module')
    } else if (tagsForm) {
      setActiveTab('tags')
    } else {
      setActiveTab('display')
    }
  }, [])

  const handleTabClick = (tab) => {
    if (activeTab !== tab) {
      setActiveTab(tab)
    }
  }

  return (
    <>
      <Nav tabs>
        {styleForm && (
          <NavItem>
            <NavLink
              className={activeTab === 'style' ? 'active' : ''}
              onClick={() => handleTabClick('style')}
            >
              {intl.formatMessage(messages.styleOptions)}
            </NavLink>
          </NavItem>
        )}

        {moduleForm && (
          <NavItem>
            <NavLink
              className={activeTab === 'module' ? 'active' : ''}
              onClick={() => handleTabClick('module')}
            >
              {intl.formatMessage(messages.moduleOptions)}
            </NavLink>
          </NavItem>
        )}

        {tagsForm && (
          <NavItem>
            <NavLink
              className={activeTab === 'tags' ? 'active' : ''}
              onClick={() => handleTabClick('tags')}
            >
              {intl.formatMessage(messages.tagsOptions)}
            </NavLink>
          </NavItem>
        )}

        {!hideTimer && (
          <NavItem>
            <NavLink
              className={activeTab === 'display' ? 'active' : ''}
              onClick={() => handleTabClick('display')}
            >
              {intl.formatMessage(messages.displayOptions)}
            </NavLink>
          </NavItem>
        )}
      </Nav>

      <StyledTabContent activeTab={activeTab}>
        <TabPane tabId="display">
          {!hideTimer && (
            <TimerForm
              app={app}
              values={options}
              onChange={(e) => onChange(e, partIndex)}
              clearOptions={onClearOptions}
              emptyOptions={emptyOptions}
              onFormChange={onFormChange}
            />
          )}
        </TabPane>

        {styleForm && <TabPane tabId="style">{styleForm}</TabPane>}

        {moduleForm && <TabPane tabId="module">{moduleForm}</TabPane>}

        {tagsForm && <TabPane tabId="tags">{tagsForm}</TabPane>}
      </StyledTabContent>
    </>
  )
}

OptionsTabs.propTypes = {
  app: PropTypes.object,
  emptyOptions: PropTypes.bool,
  hideTimer: PropTypes.bool,
  moduleForm: PropTypes.node,
  onChange: PropTypes.func,
  onClearOptions: PropTypes.func,
  options: PropTypes.object,
  partIndex: PropTypes.number,
  styleForm: PropTypes.node,
  tagsForm: PropTypes.node,
  onFormChange: PropTypes.func,
}

export default connectStore({ app: AppModule })(OptionsTabs)
