import styled from 'styled-components'
import { Navbar, NavItem } from 'reactstrap'
import { Link } from 'react-router-dom'

import { styles } from '../../assets/styles-variables'

export const StyledNavbar = styled(Navbar)`
  position: relative;
  background: ${styles.color.secondary};
  height: fit-content;
  list-style-type: none;
  border-bottom: 5px solid #ffd232;
  display: flex;
  flex-wrap: wrap;
  padding-left: 260px;

  @media (max-width: 768px) {
    padding-left: 20px;
    padding-right: 40px;
  }

  @media (min-width: 768px) {
    border: none;
  }
`

export const StyledDiv = styled.div`
  display: flex;
  flex-direction: row;
  margin: 0 10px;

  @media (max-width: 768px) {
    display: none;
  }
`

export const StyledDiv2 = styled.div`
  @media (min-width: 768px) {
    display: none;
  }
`

export const StyledNavHeader = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;

  img {
    width: 175px;
    height: 50px;

    @media (max-width: 768px) {
      display: none;
    }
  }

  button {
    border: none;
    padding: 0;
    outline: none;
    z-index: 5;
  }
`

export const StyledNavItem = styled(NavItem)`
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: baseline;

  @media (min-width: 768px) {
    display: none;
  }

  a {
    display: flex;
    align-items: flex-end;
  }

  i {
    color: white;
    margin-right: 10px;

    svg {
      height: 20px;
      width: 20px;
    }
  }

  span {
    color: white;
    font-family: Nunito;
    font-size: 16px;
    line-height: 18px;
  }

  &::before {
    ${({ selected }) => !selected && 'display: none'};
    content: '';
    position: absolute;
    border-left: 7px solid #3f6cb3;
    border-radius: 3px;
    height: ${({ type }) => (type === 'module' ? '40%' : '70%')};
    top: ${({ type }) => (type === 'module' ? '10px' : '7px')};
    left: -17px;
  }
`

export const StyledNavSeparation = styled.span`
  text-transform: uppercase;
  color: #b5bbc5;
  border-top: 1px solid #1a2d4b;
  padding: 10px 0;
  margin-top: 10px;

  @media (min-width: 768px) {
    display: none;
  }
`

export const StyledButton = styled(Link)`
  font-size: 12px !important;
  font-family: 'Nunito';
  font-weight: 600;
  line-height: 11px;
  color: #212529 !important;
  background-color: #ffd232;
  padding: 0 10px !important;
  margin-right: 20px;
  border: 2px solid #ffd232;
  border-radius: 16px;
  width: fit-content;
  display: flex;
  align-items: center;
  cursor: pointer;
  transition: all ease-in-out 0.2s;

  &:hover {
    background-color: transparent;
    color: #ffd232 !important;
    text-decoration: none !important;
  }
`
