const actions = {
  getApps: () => ({ type: 'MANAGE_APPS_GET_APPS' }),
  disableApp: (app) => ({ type: 'MANAGE_APP_DISABLE', app }),
  setAppList: (list) => ({ type: 'MANAGE_SET_APPLIST', list }),
  saveApp: (app, appId) => ({ type: 'MANAGE_APP_SAVE_REQUEST', app, appId }),
  resetCreateAppAction: () => ({ type: 'MANAGE_APP_CREATE_RESET' }),
  deleteDisabledApps: () => ({ type: 'MANAGE_APP_DELETE_DISABLED' }),
  getAppUserList: (appId) => ({ type: 'MANAGE_APP_USER_LIST', appId }),
  getAppInfo: (appId) => ({ type: 'MANAGE_APPS_GET_APP_INFO', appId }),
  getAppLegalContacts: (appId) => ({ type: 'MANAGE_APPS_GET_LEGALCONTACTS', appId }),
  getAppAddOns: (appId) => ({ type: 'MANAGE_APPS_GET_ADDONS', appId }),
  saveTheme: (data) => ({ type: 'MANAGE_SAVE_THEME', data }),
}
export default actions
