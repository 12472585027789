import { BasicInput, BasicInfo } from 'components'
import { useIntl } from 'react-intl'
import { StyledLegend } from './styles'
import messages from '../messages'

const ErrorFrame = () => {
  const intl = useIntl()

  return (
    <>
      <StyledLegend>{intl.formatMessage(messages.ef.title)}</StyledLegend>
      <BasicInfo text={intl.formatMessage(messages.ef.info)} />

      <BasicInput
        name="part.onError.message"
        label={intl.formatMessage(messages.ef.cameraError)}
        helpMessage={intl.formatMessage(messages.ef.cameraErrorHelp)}
        placeholder={intl.formatMessage(messages.ef.cameraErrorPlaceholder)}
      />

      <BasicInput
        name="part.onError.photo"
        label={intl.formatMessage(messages.ef.upload)}
        helpMessage={intl.formatMessage(messages.ef.uploadHelp)}
        placeholder={intl.formatMessage(messages.ef.uploadPlaceholder)}
      />
    </>
  )
}

export default ErrorFrame
