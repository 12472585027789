import PropTypes from 'prop-types'
import { Icon } from 'react-icons-kit'
import {
  box,
  camera,
  code,
  edit,
  externalLink,
  facebook,
  fileText,
  headphones,
  image,
  list,
  lock,
  minus,
  map,
  music,
  power,
  users,
  video,
  youtube,
} from 'react-icons-kit/feather'
import { handOUp } from 'react-icons-kit/fa/handOUp'
import styled from 'styled-components'

const StyledIcon = styled(Icon)`
  color: #11263f;
  margin-right: 10px;
  ${({ nobottom }) => !nobottom && 'margin-bottom: 2px'};

  svg {
    height: 28px;
    width: 28px;
  }
`

const ActionIcon = ({ current }) => {
  switch (current) {
    case 'Text':
    case 'H2':
      return <StyledIcon icon={fileText} />
    case 'Separator':
      return <StyledIcon icon={minus} nobottom="true" />
    case 'List':
      return <StyledIcon icon={list} />
    case 'Picture':
    case 'PictureUrl':
    case 'Giphy':
    case 'SketchFab':
      return <StyledIcon icon={image} />
    case 'Audio':
      return <StyledIcon icon={headphones} />
    case 'SoundCloud':
      return <StyledIcon icon={music} />
    case 'Video':
    case 'Voxpop':
      return <StyledIcon icon={video} />
    case 'VideoYoutube':
    case 'Vimeo':
      return <StyledIcon icon={youtube} />
    case 'Button':
    case 'Button-Half':
    case 'BtnUnity':
      return <StyledIcon icon={handOUp} />
    case 'FacebookShareButton':
      return <StyledIcon icon={facebook} />
    case 'GoogleMaps':
      return <StyledIcon icon={map} />
    case 'FormGoogle':
      return <StyledIcon icon={edit} />
    case 'Redirect':
    case 'Random':
    case 'RandomReset':
      return <StyledIcon icon={externalLink} />
    case 'Photobooth':
      return <StyledIcon icon={camera} />
    case 'VisitCounter':
      return <StyledIcon icon={users} />
    case 'Lock':
    case 'LockCancel':
    case 'LockPage':
      return <StyledIcon icon={lock} />
    case 'Trigger':
      return <StyledIcon icon={power} />
    case 'ListItem':
      return <StyledIcon icon={box} />
    case 'Input':
      return <StyledIcon icon={code} />
    default:
      return ''
  }
}

ActionIcon.propTypes = {
  current: PropTypes.string,
}

export default ActionIcon
