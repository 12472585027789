import PropTypes from 'prop-types'
import { useState, useEffect } from 'react'
import { BasicLabel } from 'components'
import { Multiselect } from 'multiselect-react-dropdown'

import { StyledMultiSelect } from './styles'

const BasicMultiSelect = ({
  closeOnSelect,
  disabled,
  helpMessage,
  hidePlaceholder,
  label,
  name,
  onChange,
  options,
  optionsValue,
  partIndex,
  placeholder,
  showCheckbox,
  selectedValues,
}) => {
  const [selected, setSelected] = useState([])

  useEffect(() => {
    setSelected(selectedValues)
  }, [selectedValues])

  const handleSelect = (v) => {
    setSelected(v)
    onChange(v)
  }

  const partName = partIndex ? `${name}-${partIndex}` : name

  return (
    <StyledMultiSelect disabled={disabled}>
      <BasicLabel label={label} helpMessage={helpMessage} />

      <Multiselect
        avoidHighlightFirstOption
        closeIcon="circle"
        closeOnSelect={closeOnSelect}
        displayValue={optionsValue}
        id={name}
        name={partName}
        onRemove={handleSelect}
        onSelect={handleSelect}
        options={options}
        placeholder={placeholder}
        selectedValues={selected}
        showArrow
        showCheckbox={showCheckbox}
        hidePlaceholder={hidePlaceholder}
      />
    </StyledMultiSelect>
  )
}

BasicMultiSelect.defaultProps = {
  closeOnSelect: true,
  disabled: false,
  onChange: () => {},
  showCheckbox: false,
}

BasicMultiSelect.propTypes = {
  closeOnSelect: PropTypes.bool,
  disabled: PropTypes.bool,
  helpMessage: PropTypes.any,
  hidePlaceholder: PropTypes.bool,
  label: PropTypes.any,
  name: PropTypes.string,
  onChange: PropTypes.func,
  options: PropTypes.array,
  optionsValue: PropTypes.string,
  partIndex: PropTypes.any,
  placeholder: PropTypes.string,
  selectedValues: PropTypes.any,
  showCheckbox: PropTypes.bool,
}

export default BasicMultiSelect
