import PropTypes from 'prop-types'
import { useState, useEffect } from 'react'
import { useIntl } from 'react-intl'
import { BasicInfo, BasicButton, UpgradeBox } from 'components'

import { Icon } from 'react-icons-kit'
import { trash } from 'react-icons-kit/feather/trash'

import RepeatSelect from './RepeatSelect'
import TimeAndDateFields from './TimeAndDateFields'
import TimeZonesFields from './TimeZonesFields'

import { getRepeatOptions } from './utils'
import messages from '../messages'

const TimerForm = ({ app, values, onChange, clearOptions, emptyOptions, onFormChange }) => {
  const [repeatOptions, setRepeatOptions] = useState([])

  const [startDate, setStartDate] = useState(values.range[0])
  const [endDate, setEndDate] = useState(values.range[1])

  const intl = useIntl()

  useEffect(() => {
    const opt = getRepeatOptions([{ ...startDate }, { ...endDate }])
    setRepeatOptions(opt)
  }, [startDate, endDate])

  const handleOnDateChange = (e) => {
    if (e.target.id.includes('1')) {
      setEndDate({ date: e.target.value })
    } else {
      setStartDate({ date: e.target.value })
    }
    onChange(e)
    onFormChange(true);
  }

  return (
    <>
      <BasicInfo
        text={intl.formatMessage(messages.displayExplanation)}
        children={
          (!!app.access.Timer || !emptyOptions) && (
            <div style={{ margin: '0 0 0 10px' }}>
              <BasicButton outlined onClick={clearOptions} round icon={<Icon icon={trash} />} type="button" />
            </div>
          )
        }
      />

      {!!app.access.Timer || !emptyOptions ? (
        <>
          <TimeAndDateFields values={values} onChange={onChange} onDateChange={handleOnDateChange} onFormChange={onFormChange} />

          <RepeatSelect
            values={values}
            onChange={onChange}
            repeatOptions={repeatOptions}
            date={startDate.date}
            onFormChange={onFormChange}
          />

          <TimeZonesFields
            values={values}
            onChange={onChange}
            onFormChange={onFormChange}
          />
        </>
      ) : (
        <UpgradeBox appId={app.id} />
      )}
    </>
  )
}

TimerForm.propTypes = {
  app: PropTypes.object,
  clearOptions: PropTypes.func,
  emptyOptions: PropTypes.bool,
  onChange: PropTypes.func,
  values: PropTypes.any,
  onFormChange: PropTypes.func,
}

export default TimerForm
