import styled from 'styled-components'
import { Button, Alert } from 'reactstrap'

export const StyledAlert = styled(Alert)`
  position: fixed;
  top: 10px;
  right: 10px;
  min-width: 350px;
  z-index: 2;
`

export const StyledActionContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 10px 0;
  min-width: 50px;
  position: absolute;
  right: -50px;
  top: 0;
  background-color: white;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
  box-shadow: 25px 0 20px -17px rgba(0, 0, 0, 0.1);

  @media (max-width: 576px) {
    flex-direction: row-reverse;
    box-shadow: none;
    right: 1.25rem;
  }
`

export const StyledAction = styled(Button)`
  background: transparent;
  color: #5c5c5c;
  width: fit-content;
  padding: 0;
  border: 1px solid transparent;
  border-radius: 100%;
  transition: all 0.2s ease-in-out;

  @media (max-width: 576px) {
    background-color: transparent;
    color: #5c5c5c;
    border: 1px solid #5c5c5c;

    &:not(:first-of-type) {
      margin-right: 5px;
    }

    &.delete {
      border: 1px solid #fe0045;
      background-color: white;
    }
  }

  &:not(:last-of-type) {
    margin-bottom: 5px;

    @media (max-width: 576px) {
      margin-bottom: 0;
    }
  }

  &:hover,
  &:focus,
  &:active {
    background-color: transparent;
    color: #5c5c5c;
    border: 1px solid #5c5c5c;
  }

  &:disabled {
    background-color: transparent;
    color: #5c5c5c;
    border: 1px solid #5c5c5c;
    opacity: 0.3;
  }

  svg {
    height: 26px;
    width: 26px;
  }

  &.top i {
    transform: rotate(90deg) translateY(-1px);
  }

  &.bottom i {
    transform: rotate(-90deg);
  }

  &.delete {
    color: #fe0045;
    padding: 1px 2px;

    svg {
      height: 22px;
      width: 22px;
    }

    &:hover:not(:disabled) {
      border: 1px solid #fe0045;
      background-color: white;
    }

    &:disabled {
      color: #5c5c5c;
    }
  }
`

export const StyledContainer = styled.div`
  display: flex;
  align-items: baseline;
  justify-content: ${({ title }) => (title === 'true' ? 'flex-end' : 'space-between')};
  flex-wrap: wrap;
  max-width: 771px;
  margin-bottom: 20px;
  ${({ title }) => title === 'true' && 'margin-top: 20px'};

  @media (max-width: 579px) {
    margin-right: 0;
    flex-direction: column;
  }

  span {
    font-family: Nunito;
    font-weight: 600;
    font-size: 18px;

    @media (max-width: 579px) {
      margin-bottom: 20px;
    }
  }
`

export const StyledButtonContainer = styled.div`
  max-width: 771px;
  display: flex;
  align-items: baseline;
  justify-content: flex-end;
  ${({ bottom }) => bottom && 'margin-top: 22px; margin-bottom: 200px'};

  @media (max-width: 579px) {
    justify-content: flex-start;
    flex-wrap: wrap;

    a,
    button {
      margin-bottom: 10px;
    }
  }
`

export const StyledLink = styled.div`
  display: flex;
  flex-direction: column;

  label {
    font-family: Nunito;
    font-weight: 700;
    font-size: 18px;
  }

  button,
  a {
    width: fit-content;
    border: none;
    background-color: transparent;
    padding: 0;
    font-size: 13px;
    font-weight: 400;
    color: #3f6cb3;
    text-decoration: underline !important;
    display: flex;
    align-items: center;
    cursor: pointer;

    &:hover,
    &:focus,
    &:active {
      background-color: transparent !important;
      border: none !important;
      color: #13263f !important;
      text-decoration: underline !important;
      outline: none;
      box-shadow: none;
    }

    &:disabled {
      background-color: transparent !important;
      border: none !important;
      color: #212529 !important;
    }

    i {
      width: 16px;
      height: 16px;
      display: flex !important;

      svg {
        width: 16px;
        height: 16px;
      }
    }

    &:is(:active, :focus) {
      outline: none;
    }
  }
`

export const StyledFlapPreview = styled.div`
  display: flex;
  width: 0;
  height: 100%;
  position: fixed;
  top: 0;
  right: 0;
  overflow-y: hidden;
  transition: width 1s ease;

  &.open {
    width: 40%;
    z-index: 1;
  }

  &.closed {
    width: 50px;
    overflow-y: hidden;
  }
  
  .toggle-button {
    width: 60px;
    position: relative;
    top: 40%;
    left: 6px;
  }

  .toggle-button img {
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
    box-shadow: 0 1px 15px rgba(0,0,0,0.1);
    cursor: pointer;
  }

  &.open .menu-content {
    box-shadow: 0 1px 15px #9e9e9e;
  }
  
  .menu-content {
    width: 100%;
    background-color: #406cb3;
  }
  
  .menu-content h2 {
    margin-bottom: 20px;
    text-align: center;
    color: #ffffff;
    margin-top: 5%;
  }
  
  .menu-content .iframe-container {
    margin: auto;
    margin-top: 5%;
    width: 519px;
    height: 650px;
  }
  
  .menu-content .iframe-container .frame-img {
    position: absolute;
  }
  
  .menu-content .iframe-container img {
    position: relative;
    left: 500px;
    z-index:1;
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
  }
  
  .menu-content iframe {
    width: 519px;
    height: 650px;
    border-left: 19px solid #ffd232;
    border-top: 19px solid #ffd232;
    border-bottom: 19px solid #ffd232;
    border-radius: 10px;
  }
  
  .menu-content .info {
    width: 519px;
    text-align: center;
    margin: auto;
    padding-top: 20px;
    font-size: 12px;
    color: white;
  }
  
  .menu-content .infoSuite {
    width: 519px;
    text-align: center;
    margin: auto;
    padding-top: 0px;
    font-size: 12px;
    color: white;
  }

  @media only screen and (min-width: 1440px) and (max-width: 1600px) {
    &.open {
      width: 41%;
      z-index: 1;
    }
    
    .menu-content .iframe-container {
      margin: auto;
      margin-top: 5%;
      width: 519px;
      height: 520px;
    }
    
    .menu-content iframe {
      height: 520px;
    }
    
    .menu-content .iframe-container img {
      height: 520px;
      position: relative;
      left: 505px;
      z-index:1;
    }
  }

  @media only screen and (min-width: 1300px) and (max-width: 1439px) {
    &.open {
      width: 43%;
      z-index: 1;
    }
    
    .menu-content .iframe-container {
      margin: auto;
      margin-top: 5%;
      width: 519px;
      height: 520px;
    }
    
    .menu-content iframe {
      height: 520px;
    }
    
    .menu-content .iframe-container img {
      height: 520px;
      position: relative;
      left: 505px;
      z-index:1;
    }
  }
`
