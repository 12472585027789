import { validationsPatterns } from 'utils'
import { defineMessages } from 'react-intl'
import dotenv from 'dotenv';
dotenv.config();

export const ADMIN_URL = 'https://admin.mysmartjourney.co'
export const VISIT_URL = 'https://visit.mysmartjourney.co'
export const VISIT_SHORT_URL = process.env.REACT_APP_BASE_URL_VISIT
export const ADMIN_SHORT_URL = process.env.REACT_APP_BASE_URL_ADMIN
export const PLAY_URL = 'https://play.msj.world'
export const LAST_APP_VISITED = process.env.REACT_APP_LAST_APP_VISITED_ID;
export const MSJ_ADMIN_LOCK = process.env.REACT_APP_MSJ_ADMIN_LOCK;
export const HOST_TYPE = process.env.REACT_APP_HOST_TYPE;
export const ROLLBAR_ACCESS_TOKEN = process.env.REACT_APP_ROLLBAR_ACCESS_TOKEN;

export const VERSION = 'v3.4.0'

export const TEMPLATES = {
  // TODO: put it in the DB!!
  default: [],
  AirCanada: [
    {
      name: 'custom.disponibilitees',
      label: 'availability',
      helpMessage: 'availabilityHelp',
      inputType: 'textarea',
      displayType: 'Text',
      errorMessage: 'backBtnTextError',
    },
    {
      name: 'custom.allergiesImg',
      label: 'allergies',
      helpMessage: 'allergiesHelp',
      inputType: 'file',
      accept: 'image/jpeg, image/png, image/x-png, image/svg+xml',
      displayType: 'Picture',
    },
    {
      name: 'custom.allergiesTxt',
      label: 'allergiesText',
      helpMessage: 'allergiesTextHelp',
      inputType: 'text',
      displayType: 'Text',
      errorMessage: 'backBtnTextError',
    },
    {
      name: 'custom.backBtnUrl',
      label: 'backBtnUrl',
      helpMessage: 'backBtnUrlHelp',
      inputType: 'text',
      displayType: 'Button',
      errorMessage: 'backBtnUrlError',
      validation: {
        pattern: validationsPatterns.url,
      },
    },
    {
      name: 'custom.backBtnText',
      label: 'backBtnText',
      helpMessage: 'backBtnTextHelp',
      inputType: 'text',
      displayType: 'Button',
      errorMessage: 'backBtnTextError',
    },
  ],
}

export const SYSTEM_TAGS = ['linked', 'unlinked', 'all', 'feedback']

const messages = defineMessages({
  pink: { id: 'app.const.colors.pink' },
  grey: { id: 'app.const.colors.grey' },
  green: { id: 'app.const.colors.green' },
  blue: { id: 'app.const.colors.blue' },
  yellow: { id: 'app.const.colors.yellow' },
  orange: { id: 'app.const.colors.orange' },
})

export const COLORS = [
  { value: 'pink', label: messages.pink },
  { value: 'grey', label: messages.grey },
  { value: 'green', label: messages.green },
  { value: 'blue', label: messages.blue },
  { value: 'yellow', label: messages.yellow },
  { value: 'orange', label: messages.orange },
]

export const NBR_PHOTOBOOTH_FILES_PER_PAGE = 20

export const FOLDERS_TO_DISPLAY = 4
export const CARDS_TO_DISPLAY = 20

export const HEX_COLORS = [
  // R1
  '#BD103B', // 25
  '#EF456F', // 26
  '#CC0000', // 1
  '#FF3333', // 2
  '#FF7777', // 3
  '#F4839F', // 27
  // R2
  '#FAC1CF', // 28
  '#FFBBBB', // 4
  '#FCDFBB', // 8
  '#FFF0BB', // 12
  '#F8BE77', // 7
  '#BC6C09', // 5
  // R3
  '#F59E33', // 6
  '#FFD232', // 10
  '#FFE176', // 11
  '#CB9F00', // 9
  '#29754F', // 13
  '#3DB076', // 14
  // R4
  '#78D0A4', // 15
  '#BBE8D1', // 16
  '#BDCEE8', // 20
  '#7B9CD0', // 19
  '#3F6CB3', // 18
  '#2A4877', // 17
  // R5
  '#5209ED', // 21
  '#8E5CF9', // 22
  '#B492FB', // 23
  '#D9C9FD', // 24
]

export const DATEPICKER_OPTIONS = {
  timezone: 'America/Toronto',
  dateAndTimeFormat: {
    en: 'MM/DD/YYYY HH:mm',
    fr: 'DD/MM/YYYY HH:mm',
  },
  dateFormat: {
    en: 'MM/DD/YYYY',
    fr: 'DD/MM/YYYY',
  },
  dbDateFormat: 'MM/DD/YYYY HH:mm',
  values: {
    display: {
      allDay: false,
      range: [{}, {}],
      repeat: 'no',
      rule: 'show',
      differentTimezones: false,
    },
  },
}

export const ROLES = ['superadmin', 'superowner', 'owner', 'admin', 'editor', 'redactor', 'viewer'];

export const ROLES_GLOBAL_ACCESS = {
  'PageDashboard': ['superadmin', 'superowner', 'owner', 'admin', 'editor', 'redactor', 'viewer'],
  'PageFeedbacks': ['superadmin', 'superowner', 'owner', 'admin', 'editor', 'redactor', 'viewer'],
  'PageData': ['superadmin', 'superowner', 'owner', 'admin'],
  'PageInputs': ['superadmin', 'superowner', 'owner', 'admin', 'editor', 'redactor', 'viewer'],
  'PageItems': ['superadmin', 'superowner', 'owner', 'admin', 'editor', 'redactor', 'viewer'],
  'PageLists': ['superadmin', 'superowner', 'owner', 'admin', 'editor', 'redactor', 'viewer'],
  'PageLocks': ['superadmin', 'superowner', 'owner', 'admin', 'editor', 'redactor', 'viewer'],
  'PageRandomStory': ['superadmin', 'superowner', 'owner', 'admin', 'editor', 'redactor', 'viewer'],
  'PageTriggers': ['superadmin', 'superowner', 'owner', 'admin', 'editor', 'redactor', 'viewer'],
  'PagePhotobooths': ['superadmin', 'superowner', 'owner', 'admin', 'editor', 'redactor', 'viewer'],
  'PageManageApps': ['superadmin', 'superowner', 'owner', 'admin'],
  'PageManageUsers': ['superadmin', 'superowner', 'owner', 'admin'],
  'PageTutorial': ['superadmin', 'superowner', 'owner', 'admin', 'editor', 'redactor', 'viewer'],
  'PageVoxpop': ['superadmin', 'superowner', 'owner', 'admin', 'editor', 'redactor', 'viewer'],
  'PageVoxpopFiles': ['superadmin', 'superowner', 'owner', 'admin', 'editor', 'redactor', 'viewer'],
  'PageContact': ['superadmin', 'superowner', 'owner', 'admin', 'editor', 'redactor', 'viewer'],
  'PageSettings': ['superadmin', 'superowner', 'owner', 'admin', 'editor', 'redactor', 'viewer'],
  'PageFolders': ['superadmin', 'superowner', 'owner', 'admin', 'editor', 'redactor', 'viewer'],
  'PageCards': ['superadmin', 'superowner', 'owner', 'admin', 'editor', 'redactor', 'viewer'],
  'PageCard': ['superadmin', 'superowner', 'owner', 'admin', 'editor', 'redactor', 'viewer'],
  'PageCardEdit': ['superadmin', 'superowner', 'owner', 'admin', 'editor', 'redactor', 'viewer'],
  'PageCardNew': ['superadmin', 'superowner', 'owner', 'admin', 'editor', 'redactor', 'viewer'],
  'SeeSectionManage': ['superadmin', 'superowner', 'owner', 'admin'],
}

export const ROLES_GLOBAL_ACTION = {
  'superowner' : {
    'apps': ['add-app', 'edit-name', 'edit-legal', 'edit-notes', 'logo', 'default-theme'],
    'users': ['list', 'add', 'delete', 'edit'],
    'pages': ['view', 'add', 'duplicate', 'delete', 'edit', 'status', 'theme', 'folders'],
    'folders': ['view', 'add', 'duplicate', 'delete', 'edit', 'share']
  },
  'owner': {
    'apps': ['edit-name', 'edit-legal', 'edit-notes', 'logo', 'default-theme'],
    'users': ['list', 'add', 'delete', 'edit'],
    'pages': ['view', 'add', 'duplicate', 'delete', 'edit', 'status', 'theme', 'folders'],
    'folders': ['view', 'add', 'duplicate', 'delete', 'edit', 'share']
  },
  'admin': {
    'apps': ['edit-name', 'edit-notes', 'logo', 'default-theme'],
    'users': ['list', 'add', 'delete', 'edit'],
    'pages': ['view', 'add', 'duplicate', 'delete', 'edit', 'status', 'theme', 'folders'],
    'folders': ['view', 'add', 'duplicate', 'delete', 'edit', 'share']
  },
  'editor': {
    'pages': ['view', 'add', 'duplicate', 'delete', 'edit', 'status', 'theme', 'folders'],
    'folders': ['view', 'add', 'duplicate', 'delete', 'edit', 'share'],
  },
  'redactor': {
    'pages': ['view', 'add', 'edit', 'duplicate', 'theme'],
    'folders': ['view', 'add', 'duplicate'],
  }
}