import PropTypes from 'prop-types'
import { DividerForm, SubtitleForm, TextForm, LanguageLinkForm } from 'components'

const Texts = ({ app, part, partIndex, onChange, onFormChange }) => {
  return (
    <>
      {part.type === 'H2' && <SubtitleForm onChange={onChange} part={part} partIndex={partIndex} onFormChange={onFormChange} />}

      {part.type === 'Text' && <TextForm onChange={onChange} part={part} partIndex={partIndex} onFormChange={onFormChange} />}

      {part.type === 'Separator' && <DividerForm onChange={onChange} part={part} partIndex={partIndex} onFormChange={onFormChange} />}

      {part.type === 'LanguageLink' && (
        <LanguageLinkForm part={part} partIndex={partIndex} onChange={onChange} onFormChange={onFormChange} />
      )}
    </>
  )
}

Texts.propTypes = {
  app: PropTypes.object,
  onChange: PropTypes.func,
  part: PropTypes.object,
  partIndex: PropTypes.number,
  onFormChange: PropTypes.func,
}

export default Texts
