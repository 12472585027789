const oneCard = (tokenId, cardId, date = 0, theme) => {
  let title = ''
  let parts = [{ type: '' }]
  return {
    cardId,
    createdTime: date,
    parts,
    title,
    tokenId,
    theme,
    updateTime: date,
  }
}

export const createCards = (tokenCardLinks, date, theme) => {
  const list = [];

  Object.values(tokenCardLinks).map((link) => list.push(oneCard(link.tokenId, link.cardId, date, theme)))

  return list
}

export const getTokenCardLinks = (tokenList, date) => {
  const list = {}

  tokenList.map((token, index) => {
    return list[index] = {cardId: token.toString(), tokenId: parseInt(token), update: date}
  })

  return list
}

const getListThemes = (themes) => {
  const list = []

  themes.map((theme) => list.push(theme.value))

  return list
}

const getTokenList = (tokens) => {
  const list = []
  for (let i = 1; i <= tokens; i += 1) {
    list.push(i)
  }
  return list
}

export const getApp = (dbApp, app, date) => {
  const tokens = app.numberTokens //parseInt(app.numberTokens, 10)

  const tokenList = getTokenList(tokens)
  const themes = getListThemes(app.themes)

  return {
      ...dbApp,
      name: app.name,
      theme: app.theme,
      themes: themes,
      notes: app.notes,
      lastCardUpdateTime: date,
      tracking: {
        ga4code: (app.tracking && app.tracking.ga4code),
      },
      legalContacts: app.legalContacts,
      internalNotes: app.internalNotes,
      GDPR: app.GDPR,
      plan: app.plan,
      tokenList,
  }
}

export const getNewApp = (app, date) => {
  const tokens = app.numberTokens; //parseInt(app.numberTokens, 10)

  const tokenList = getTokenList(tokens)
  const tokenCardLinks = getTokenCardLinks(tokenList, date)
  const themes = getListThemes(app.themes)

  return {
    id: app.id,
    name: app.name,
    theme: app.theme || '',
    themes: themes || [],
    notes: app.notes || '',
    lastCardUpdateTime: date,
    tracking: {
      ga4code: (app.tracking && app.tracking.ga4code) || '',
    },
    legalContacts: app.legalContacts,
    internalNotes: app.internalNotes ? app.internalNotes : '',
    GDPR: app.GDPR,
    foldersOrder: 'desc',
    plan: app.plan,
    cardList: [],
    tokenCardLinks,
    tokenList
  };
}
