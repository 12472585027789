import { defineMessages } from 'react-intl'

const scope = 'app.containers.PageRandomStory'

export default defineMessages({
  metaTitle: { id: `${scope}.metaTitle` },
  keyCode: { id: `${scope}.keyCode` },
  intro: { id: `${scope}.intro` },
  myRandomStory: { id: `${scope}.myRandomStory` },
  addRandomStory: { id: `${scope}.addRandomStory` },
  editRandomStory: { id: `${scope}.editRandomStory` },
  newRandomStory: { id: `${scope}.newRandomStory` },
  nameRandomStory: { id: `${scope}.nameRandomStory` },
  nameHelpRandomStory: { id: `${scope}.nameHelpRandomStory` },
  nameErrorRandomStory: { id: `${scope}.nameErrorRandomStory` },
  beginningLink: { id: `${scope}.beginningLink` },
  beginningLinkHelp: { id: `${scope}.beginningLinkHelp` },
  beginningLinkError: { id: `${scope}.beginningLinkError` },
  links: { id: `${scope}.links` },
  linksHelp: { id: `${scope}.linksHelp` },
  linksError: { id: `${scope}.linksError` },
  addInput: { id: `${scope}.addInput` },
  select: { id: `${scope}.select` },
  limit: { id: `${scope}.limit` },
  endLink: { id: `${scope}.endLink` },
  endLinkHelp: { id: `${scope}.endLinkHelp` },
  endLinkError: { id: `${scope}.endLinkError` },
  endStory: { id: `${scope}.endStory` },
  endStoryHelp: { id: `${scope}.endStoryHelp` },
  endStoryLabelOpt1: { id: `${scope}.endStoryLabelOpt1` },
  endStoryLabelOpt2: { id: `${scope}.endStoryLabelOpt2` },
  endStoryError: { id: `${scope}.endStoryError` },
  delete: { id: `${scope}.delete` },
  modalText: { id: `${scope}.modalText` },
  modalTitle: { id: `${scope}.modalTitle` },
  cancel: { id: `${scope}.cancel` },
  save: { id: `${scope}.save` },
  id: { id: `${scope}.id` },
  noRelatedCards: { id: `${scope}.noRelatedCards` },
  page: { id: `${scope}.page` },
  relatedCards: { id: `${scope}.relatedCards` },
  view: { id: `${scope}.view` },
  viewMore: { id: `${scope}.viewMore` },
  viewLess: { id: `${scope}.viewLess` },
})
