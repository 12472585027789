import state from './state'
import actions from './actions'
import sagas from './sagas'
import mutations from './mutations'

export const storeModule = {
  name: 'manageUsers',
  state,
  actions,
  mutations,
  sagas,
}
