import PropTypes from 'prop-types'
import { useState } from 'react'
import { useIntl } from 'react-intl'
import { BasicButton } from 'components'

import CardEditModals from './CardEditModals'
import EditPageButtons from './EditPageButtons'
import messages from './messages'
import { StyledContainer, StyledButtons } from './styles'

/*
  new page
  .. cancel, save
  ..
  previously saved page
  .. save
  .. view page (expand view)
  .. view page in visit
  .. copy visit link
  .. delete (2 types of delete for pages and landing pages)
*/
const CardEditActions = ({ appId, cardId, isNew, loading, title, tokenId, onFormChange}) => {
  const [cancelPage, setCancelPage] = useState(false)
  const [deletePage, setDeletePage] = useState(false)
  const [resetPage, setResetPage] = useState(false)
  const intl = useIntl()

  // tokenId 'c' is the old contact page
  const handleOnDelete = () => {
    if (tokenId === 0 || tokenId === 'c') {
      setDeletePage(true)
    } else {
      setResetPage(true)
    }
  }

  return (
    <>
      <StyledContainer title={String(!!title)}>
        {!!title && <span>{title}</span>}

        <StyledButtons>
          {isNew ? (
            <BasicButton
              onClick={() => setCancelPage(!cancelPage)}
              label={intl.formatMessage(messages.cancel)}
              outlined
            />
          ) : (
            <EditPageButtons appId={appId} cardId={cardId} onDelete={handleOnDelete} tokenId={tokenId} />
          )}

          <BasicButton
            type="submit"
            label={intl.formatMessage(messages.save)}
            color="green"
            loading={loading}
            onClick={() => onFormChange(false)}
          />
        </StyledButtons>
      </StyledContainer>

      <CardEditModals
        appId={appId}
        cancelPage={cancelPage}
        cardId={cardId}
        deletePage={deletePage}
        resetPage={resetPage}
        toggleCancel={() => setCancelPage(!cancelPage)}
        toggleDelete={() => setDeletePage(!deletePage)}
        toggleReset={() => setResetPage(!resetPage)}
        onFormChange={onFormChange}
      />
    </>
  )
}

CardEditActions.propTypes = {
  appId: PropTypes.string,
  cardId: PropTypes.string,
  isNew: PropTypes.bool,
  loading: PropTypes.bool,
  title: PropTypes.string,
  tokenId: PropTypes.number,
  onFormChange: PropTypes.func
}

export default CardEditActions
