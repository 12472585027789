import PropTypes from 'prop-types'
import {BasicInput, BasicInfo, ButtonHalf, BasicCheckbox, BasicWarning} from 'components'
import { useIntl } from 'react-intl'
import connectStore from 'redux-box/dist/connectStore'
import { storeModule as AppModule } from 'store/app'

import messages from './messages'

/*
  borderRadius: '50', // 0, 10, 25, [50]
  borderWidth: '3', // 0, 1, 2, [3]
  nbrPerCol: '2', // [2], 3, 4
*/

const demo = (style, text, file, url) => {
  return {
    style,
    text: text || '',
    type: 'Button-Half',
    file: file,
    url: url,
  }
}

const StyleForm = ({
  app,
  setAsBackButton,
  partIndex,
  onChange,
  currentStyle,
  text,
  file,
  url,
  msjBrowser,
  onCheckedChange,
  onFormChange
}) => {
  const intl = useIntl()

  return (
    <>
      <div style={{ maxWidth: '400px', margin: 'auto' }}>
        <ButtonHalf part={demo(currentStyle, text, file, url)} currentIndex="0" />
      </div>

      <BasicInput
        partIndex={partIndex}
        type="select"
        onChange={(e) => {
          onChange(e);
          onFormChange(true);
        }}
        label={intl.formatMessage(messages.BorderRadiusLabel)}
        helpMessage={intl.formatMessage(messages.BorderRadiusHelp)}
        name="style.borderRadius"
        value={currentStyle.borderRadius || '50'}
      >
        <option value="0">{intl.formatMessage(messages.BorderRadius0)}</option>
        <option value="10">{intl.formatMessage(messages.BorderRadius10)}</option>
        <option value="25">{intl.formatMessage(messages.BorderRadius25)}</option>
        <option value="50">{intl.formatMessage(messages.BorderRadius50)}</option>
      </BasicInput>

      <BasicInput
        partIndex={partIndex}
        type="select"
        onChange={(e) => {
          onChange(e);
          onFormChange(true);
        }}
        label={intl.formatMessage(messages.BorderWidthLabel)}
        helpMessage={intl.formatMessage(messages.BorderWidthHelp)}
        name="style.borderWidth"
        value={currentStyle.borderWidth || '3'}
      >
        <option value="0">{intl.formatMessage(messages.BorderWidth0)}</option>
        <option value="1">{intl.formatMessage(messages.BorderWidth1)}</option>
        <option value="2">{intl.formatMessage(messages.BorderWidth2)}</option>
        <option value="3">{intl.formatMessage(messages.BorderWidth3)}</option>
      </BasicInput>

      <BasicInput
        partIndex={partIndex}
        type="select"
        onChange={(e) => {
          onChange(e);
          onFormChange(true);
        }}
        label={intl.formatMessage(messages.NbrPerColLabel)}
        helpMessage={intl.formatMessage(messages.NbrPerColHelp)}
        name="style.nbrPerCol"
        value={currentStyle.nbrPerCol || '2'}
      >
        <option value="2">{intl.formatMessage(messages.NbrPerCol2)}</option>
        <option value="3">{intl.formatMessage(messages.NbrPerCol3)}</option>
        <option value="4">{intl.formatMessage(messages.NbrPerCol4)}</option>
      </BasicInput>

      <BasicInfo text={intl.formatMessage(messages.StyleInfo)} />

      {!!app.access.MSJBrowser && !setAsBackButton && (
        <div style={{ marginTop: '20px' }}>
          <BasicCheckbox
            label={intl.formatMessage(messages.msjBrowser)}
            name="msjBrowser"
            value={msjBrowser || false}
            onChange={(e) => {
              onCheckedChange(e);
              onFormChange(true);
            }}
          />
          <BasicWarning text={intl.formatMessage(messages.warning)} style={{ marginTop: '-15px' }} />
        </div>
      )}
    </>
  )
}

StyleForm.defaultProps = {
  currentStyle: {},
  onChange: () => {},
}

StyleForm.propTypes = {
  app: PropTypes.object,
  currentStyle: PropTypes.object,
  partIndex: PropTypes.number,
  onChange: PropTypes.func,
  text: PropTypes.string,
  file: PropTypes.any,
  setAsBackButton: PropTypes.bool,
  msjBrowser: PropTypes.bool,
  onCheckedChange: PropTypes.func,
  onFormChange: PropTypes.func,
}

export default connectStore({ app: AppModule })(StyleForm)
