/*
 * FileList Messages
 *
 * This contains all the text for the FileList component.
 */
import { defineMessages } from 'react-intl'

const scope = 'app.components.FileList'

export default defineMessages({
  header: {
    id: `${scope}.header`,
    defaultMessage: 'This is the FileList component !',
  },
  to: {
    id: `${scope}.to`,
  },
  of: {
    id: `${scope}.of`,
  },
})
