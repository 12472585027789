import { BasicInput, BasicInputTimezone, BasicTextArea } from 'components'
import { useIntl } from 'react-intl'

import { StyledLegend } from './styles'
import messages from '../messages'

const EmailTrigger = () => {
  const intl = useIntl()

  return (
    <>
      <StyledLegend inSection>{intl.formatMessage(messages.email)}</StyledLegend>

      <BasicInput
        type="email"
        name="params.mailto"
        label={intl.formatMessage(messages.recipient)}
        helpMessage={intl.formatMessage(messages.recipientHelp)}
        error={intl.formatMessage(messages.recipientError)}
        required
      />

      <BasicInput
        name="params.subject"
        label={intl.formatMessage(messages.subject)}
        error={intl.formatMessage(messages.subjectError)}
        required
      />

      <BasicTextArea
        name="params.content"
        label={intl.formatMessage(messages.content)}
        helpMessage={intl.formatMessage(messages.contentHelp)}
        error={intl.formatMessage(messages.contentError)}
        required
      />

      <BasicInput
        name="params.sender"
        value="system-trigger@mail.mysmartjourney.co"
        readOnly
        label={intl.formatMessage(messages.sender)}
        helpMessage={intl.formatMessage(messages.senderHelp)}
      />

      <BasicInputTimezone
        name="params.timezone"
        label={intl.formatMessage(messages.timezone)}
        helpMessage={intl.formatMessage(messages.timezoneHelp)}
      />
    </>
  )
}

export default EmailTrigger
