import styled, { css } from 'styled-components'

export const StyledContainer = styled.div`
  position: relative;
  padding: 1.25rem 1.25rem 0 18px;

  @media (max-width: 568px) {
    padding-right: 3rem;
  }
`

export const StyledTitle = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 15px;

  h2 {
    font-family: 'Nunito', sans-serif;
    font-size: 26px;
    font-weight: 700;
    line-height: 28px;
    letter-spacing: -0.7px;
    overflow-wrap: break-word;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
    flex: 1;
    margin-left: 10px;
    margin-bottom: 0;
    margin-right: 34px;
  }
`

export const StyledLegend = styled.legend`
  font-style: italic;
  font-size: 12px;
  color: #6c757d;
  padding: 5px 5px 0 5px;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  margin-bottom: 0;
`

export const StyledButtons = styled.div`
  position: absolute;
  top: 20px;
  right: 20px;

  button:first-of-type {
    margin-bottom: 8px;
  }

  button, .btn-group, a {
    border: 1px solid #12263f;
    color: #12263f;
    width: 27px;
    height: 27px;
    background: transparent;

    &:hover {
      color: #12263f !important;
      border: 1px solid #12263f !important;
      background: transparent !important;
    }
  }
  .btn-group {
    border-radius: 50%;
    padding: 0;
    margin: 0;
    .btn {
      padding: 0;
      margin: 0;
      border:none;
      &:hover {
        border:none!important;
      }
    }
    .dropdown-toggle{
    }
    .dropdown-menu{
      width: 100%;
      overflow: visible!important;
      .btn {
        padding: 2px;
        margin: 2px;
        border:none;
        width: 100%;
        &:hover {
          border-radius:15px;
          border: 1px solid #12263f !important;
        }
      }
    }
  }
`

export const StyledModal = styled.div`
  min-height: 350px;

  .list-group {
    max-height: 200px;
    overflow-y: scroll;
  }

  .list-element {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
    padding-bottom: 10px;
    border-bottom: 1px solid rgba(18, 38, 63, 0.35);
  }

  .list-element:first-child {
    margin-top: 10px;
  }

  .userInfo {
    padding-right: 10px;
    width: 40%;
  }

  .ant-picker {
    width: 20%;
    margin-right: 10px;
  }

  button{
    width: 20%;
  }
`

export const StyledHeader = styled.div`
  font-family: Nunito, serif;
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: baseline;
  margin-bottom: 2px;
  z-index: 1;

  h5 {
    font-size: 26px;
    font-weight: 600;
    line-height: 34px;
    color: #03162c;
    word-break: break-word;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }
`

export const StyledFormContainer = styled.div`
  //display: flex;
  width: 95%;
  font-family: Nunito, serif;
  margin-bottom: 15px;
  //border-bottom: 1px solid #d9d9d9;
  padding-bottom: 5px;

  .elements {
      margin: 5px 0;
  }
`
export const StyledNoFormContainer = styled.div`
  display: flex;
  width: 95%;
  font-family: Nunito, serif;
  margin-bottom: 15px;
  //border-bottom: 1px solid #d9d9d9;
  padding-bottom: 5px;
`

export const StyledFormSelect = styled.div`
  width: 20%;

  select {
    border: 1px solid #d9d9d9;
    border-radius: 5px;
    padding: 5px;
    text-align: center;
    cursor: not-allowed;
  }
`

export const StyledFormDatePicker = styled.div`
  width: 45%;

  .ant-picker {
    width: 40%;
    height: 30px;
    border-radius: 5px;
  }
`

export const StyledFormButtonAction = styled.div`
  width: 20%;
  display: flex;

  button {
    width: 100%;
    border-radius: 5px;
    height: 30px;
    margin-right: 5px;
  }
`


export const StyledFormHeader = styled.div`
  p {
    margin: 0;
  }
  .nameUser {
    font-weight: 500;
  }
  .emailUser {
    font-style: italic;
    margin-bottom: 5px;
  }
`

export const StyledSubtitle = styled.h4`
  font-size: 17px;
  color: #416db2;
  margin-bottom: -2px;
`;

export const StyledUsersContainers = styled.div`
  margin-top: 20px;
  max-height: 300px;
  overflow-y: auto;

  &::-webkit-scrollbar {
    width: 3px;
  }
  &::-webkit-scrollbar-track {
    background: #f1f1f1;
  }
  &::-webkit-scrollbar-thumb {
    background: #888;
  }
  &::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
`;

export const StyledHelpMessage = styled.span`
  font-size: 12px;
  color: #6c757d;
  margin-top: -5px;
  line-height: 17px;
  display: inline-block;
  margin-bottom: ${({ error }) => error ? '' : '10px'};
`;

export const StyledDateErrorMessage = styled.span`
  font-size: 12px;
  color: red;
  margin-top: -5px;
  line-height: 17px;
  display: inline-block;
  margin-bottom: 10px;
`;

export const StyledPublicLink = styled.div`
  margin-bottom: 10px;
  height: 95px;
    
  p {
    margin: 0;
    font-family: Nunito, serif;
    font-weight: 500;
  }
  .linkContent {
    display: flex;
    
    button {
      border: 1px solid #12263f;
      background-color: white;
      color: #12263f;
      width: 30px;
      height: 30px;
      margin-left: 20px;

      &:hover {
        color: #12263f !important;
        border: 1px solid #12263f !important;
        background: transparent !important;
      }
    }
  }
`;

export const StyledCopyLink = styled.div`
  background-color: rgba(255,210,50, 0.7);
  border-radius: 5px;
  width: 350px;
  height: 25px;
  line-height: 25px;
  text-align: center;
  margin: auto;
  margin-top: 5px;
  color: white;
  font-size: 18px;
`;

export const StyledPublicLinkContent = styled.div`
  border: 1px solid #bebebe;
  border-radius: 10px;
  background-color: #eaecef;
  height: 34px;
  padding-left: 10px;
  line-height: 30px;
  color: #8b8b8b;
  font-weight: 400;
  width: 90%;
  cursor: text;
`;

export const StyledUserIcon = styled.span`
  width: 50px;
  text-align: center;
  
  div {
    background-color: #ffd232;
    border-radius: 100%;
    display: inline-block;
    width: 40px;
    height: 40px;
    padding-top: 2px;
  }
  
  svg {
    width: 30px;
    height: 30px;
  }
`;

export const StyledUsersEnableContainer = styled.tr`
  margin-bottom: 15px;
`;

export const StyledUserInfo = styled.span`
  p {
    margin: 0;
  }
  
  .userName {
    font-family: Nunito, serif;
    font-weight: 600;
    font-size: 16px;
  }
  
  .userPeriod, .userName span {
    font-style: italic;
    font-size: 12px;
  }

  .userNoAccess {
    font-style: italic;
    font-size: 12px;
    color: red;
  }
`;

export const StyledUserActions = styled.td`
  width: 150px;
`;

export const StyledCollapse = styled.div`
  display: flex;
  flex-direction: row;
  height: 50px;
  border-bottom: 1px solid lightgrey;

  i {
    color: #89959f;
    cursor: pointer;
    
    svg {
      margin-top: 15px;
    }
  }

  legend {
    cursor: pointer;
    
  }
`
export const StyledCollapseLegend = styled.legend`
  margin-top: 0 !important;
  display: flex;
`

export const StyledCollapseDiv = styled.div`
  ${({ open, $noUser }) => !open && css`
    height: 0;
    visibility: hidden;
    margin-top: ${$noUser ? '10px' : '-10px'};

    form {
      margin: 0;
    }
  `}

  form {
    margin-top: 20px;
  }

  .borderRole {
    border: 1px solid #dfe3e9;
    border-radius: 8px;
    padding: 10px;
    margin-bottom: 10px;
  }

  option:disabled {
    background-color: #808080;
    color: #dfe3e9;
  }
`;
