import { createSagas } from 'redux-box'
import { call, put, all } from 'redux-saga/effects'
import rsFirebase from 'services/rsf'

export default createSagas({
  *DATA_GET_VOXPOPLIST({ appId }) {
    try {
      const voxpopFilesToFetch = []
      const voxpopList = yield call(rsFirebase.firestore.getCollection, `appdata/${appId}/voxpop`)
      const data = []

      for (let i = 0; i < voxpopList.docs.length; i += 1) {
        const voxpopData = voxpopList.docs[i].data()
        voxpopFilesToFetch.push(
          yield call(rsFirebase.firestore.getCollection, `appdata/${appId}/voxpop/${voxpopData.id}/files/`)
        )
        data.push({ ...voxpopData, files: [] })
      }

      const allVoxpopFiles = yield all(voxpopFilesToFetch)
      allVoxpopFiles.forEach((fileCollection, voxpopIndex) => {
        fileCollection.docs.forEach((file) => {
          data[voxpopIndex].files.push(file.data())
        })
      })

      yield put({
        type: 'DATA_SET_VOXPOPLIST',
        data,
      })
    } catch (error) {}
  },

  *DATA_GET_VOXPOPFILES({ appId, voxpopId }) {
    try {
      const data = []
      const path = `appdata/${appId}/files-received/`
      const colRef = rsFirebase.app.firestore().collection(path)

      const collection = yield call(
        rsFirebase.firestore.getCollection,
        colRef.where('origin.id', '==', voxpopId).orderBy('dateAdded', 'desc')
      )

      collection.forEach((file) => {
        data.push(file.data())
      })

      yield put({
        type: 'DATA_SET_VOXPOPFILES',
        voxpopId,
        data,
      })
    } catch (error) {}
  },
})
