import PropTypes from 'prop-types'
import { useIntl } from 'react-intl'
import { PartOptions, BasicLabel } from 'components'
import ReactQuill, { Quill } from 'react-quill'
import 'react-quill/dist/quill.snow.css'
import Emoji from 'react-quill-emoji';
import 'react-quill-emoji/dist/quill-emoji.css'
import { newEvent } from 'utils'
import styled from 'styled-components'

import messages from './messages'
import { useState } from 'react'

const { EmojiBlot, ShortNameEmoji, ToolbarEmoji, TextAreaEmoji } = Emoji;
Quill.register({
  'formats/emoji': EmojiBlot,
  'modules/emoji-shortname': ShortNameEmoji,
  'modules/emoji-toolbar': ToolbarEmoji,
  'modules/emoji-textarea': TextAreaEmoji
}, true);

const StyledQuill = styled.div`
  .ql-container.ql-snow {
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    border: 1px solid #bebebe;
  }

  .ql-toolbar.ql-snow {
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    border: 1px solid #bebebe;
  }

  .ql-editor {
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, sans-serif,
      'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';
    font-size: 15px;
    line-height: 22.5px;
    padding: 14px;
  }
  .textarea-emoji-control {
    position: absolute;
    top: auto !important;
    bottom: 5px;
    right: 5px;
  }
`

const TextForm = ({ onChange, partIndex, part, onFormChange }) => {
  const [isFocused, setIsFocused] = useState(false);

  const intl = useIntl()
  if(part.text==null){
    part.text = "<p></p>"
  }
  const handleOnChange = (content) => {
    if (isFocused) {
      onChange(newEvent('text', content), partIndex);
      onFormChange(true);
    }
  }

  const modules = {
    toolbar: [
      ['bold', 'italic', 'underline', 'strike'],
      [{ list: 'ordered' }, { list: 'bullet' }],
      ['link'],
      ['clean']
    ],
    'emoji-toolbar': false,
    "emoji-textarea": true,
    "emoji-shortname": false,
  }

  const formats = ['bold', 'italic', 'underline', 'strike','link', 'list', 'emoji']

  return (
    <>
      <PartOptions partIndex={partIndex} onChange={onChange} values={part.options} onFormChange={onFormChange} />

      <div style={{ marginTop: '10px' }}>
        <BasicLabel label={intl.formatMessage(messages.text)} />
      </div>
      <StyledQuill>
        <ReactQuill
          theme="snow"
          value={part.text}
          onChange={(e) => {
            handleOnChange(e);
          }}
          modules={modules}
          formats={formats}
          onFocus={() => setIsFocused(true)}
          //onBlur={() => setIsFocused(false)}
        />
      </StyledQuill>
    </>
  )
}

TextForm.propTypes = {
  onChange: PropTypes.func,
  part: PropTypes.object,
  partIndex: PropTypes.number,
  onFormChange: PropTypes.func,
}

export default TextForm
