/**
*
* VimeoPlayer
*
*/

import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

const Container = styled.div`
  position: relative;
  padding-bottom: 56.25%;
  height: 0;
  overflow: hidden;
  max-width: 100%;
  background: black;
  iframe, object, embed {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
  &._RATIO-1-1 {
    padding-bottom: 100%;
  }
  &._RATIO-4-3 {
    padding-bottom: 75%;
  }
  &._RATIO-16-9 {
    padding-bottom: 56.25%;
  }
  &._RATIO-21-9 {
    padding-bottom: 42.85%;
  }
  &._RATIO-3-4 {
    padding-bottom: 133.33%;
  }
  &._RATIO-9-16 {
    padding-bottom: 177.77%;
  }
  &._RATIO-9-21 {
    padding-bottom: 233.33%;
  }
`
const VimeoPlayer = ({ videoId, part }) => {
  const cls = part.style ? `_RATIO-${part.style.videoRatios}` : ''
    return (
      <Container className={cls}>
        <iframe
          src={`https://player.vimeo.com/video/${videoId}?${Date.now()}`}
          frameBorder="0"
          webkitallowfullscreen="true"
          mozallowfullscreen="true"
          allowFullScreen
          title={videoId}
        />
      </Container>
    )
  }

VimeoPlayer.propTypes = {
  videoId: PropTypes.string,
  part: PropTypes.object.isRequired,
  style: PropTypes.string,
}

export default VimeoPlayer
