const getLockById = ({ list }, id) => Object.values(list).find((i) => i.id === id)

const getLockByName = ({ list }, name) => Object.values(list).filter((l) => l.name === name)

const selectors = {
  getLockById: (state) => (id) => getLockById(state, id),
  getLockByName: (state) => (name) => getLockByName(state, name),
  lock: (state) => (id) => state.list[id] || {},
}
export default selectors
