import uid from 'uid'
import rsFirebase from 'services/rsf'
import { call, put } from 'redux-saga/effects'
import { createSagas } from 'redux-box'
import { push } from 'react-router-redux'
import { rteCardsTag, rteFolderTag } from 'utils/routes'
import { getAppLegalContacts, getMoreAppInfos, saveLastApp, updateLegalContacts } from "./workers";
import { splitFileName } from 'services/utils'
import { uploadFile64, uploadFile } from 'services/fileManager'
import { updateCardInfo } from '../card/workers'
import { getUserId, getUserProfile } from '../manageUsers/workers'

function* handleFile(appId, folder) {
  const fileNameObj = splitFileName(folder.file.name)
  const fileName = `${folder.forlderId}-${fileNameObj.name}-${uid()}.${fileNameObj.ext}`
  const path = `/appdata/${appId}/${fileName}`

  const fileInfos = folder.base64
    ? yield uploadFile64(folder.base64.slice(23), path)
    : yield uploadFile(folder.file, path)

  delete folder.file
  delete folder.base64

  folder.url = fileInfos.url
  folder.size = fileInfos.metadata.size

  return folder
}

export default createSagas({
  *APP_FETCH_BY_ID({ appId }) {
    try {
      yield put({ type: 'APP_FETCHING' })
      const appSnapshot = yield call(rsFirebase.firestore.getDocument, `appdata/${appId}`)
      const app = appSnapshot.data()
      // set access based on plans.js
      app.access = yield call(getMoreAppInfos, appId)

      // legal contacts (firestore)
      app.legalContacts = yield call(getAppLegalContacts, appId)

      // folders (firestore)
      const folderSnap = yield call(rsFirebase.firestore.getCollection, `appdata/${appId}/folders`)
      const folders = {}
      folderSnap.forEach((folder) => (folders[folder.id] = folder.data()))
      app.folders = folders

      // plan (firestore)
      const appSnap = yield call(rsFirebase.firestore.getDocument, `appdata/${appId}`)
      const data = appSnap.data()
      app.plan = data.plan
      yield put({ type: 'APP_SET_APPINFO', app })

      if (app.foldersOrder) {
        const order = app.foldersOrder
        yield put({ type: 'APP_SET_FOLDERS', order })
      }
      yield put({ type: 'APP_FETCH_SUCCESS' })
    } catch (error) {
      yield put({ type: 'APP_FETCH_ERROR' })
    }
  },
  *APP_SAVE_SETTINGS({ data }) {
    try {
      yield put({ type: 'APP_SAVING' })

      // update app in firebase
      yield call(rsFirebase.firestore.updateDocument, `appdata/${data.id}`, data)

      // map on cards for themes
      const cardsSnap = yield call(rsFirebase.firestore.getCollection, `appdata/${data.id}/cards`)
      const cards = {}
      cardsSnap.forEach((data) => {
        cards[data.id] = data.data()
      })

      Object.values(cards).forEach((card) => {
        const c = { ...card, theme: data.theme }
        Object.assign(cards, { [card.cardId]: c })
        updateCardInfo(data.id, card)
      })

      yield put({ type: 'CARDLIST_SET', list: cards })

      // legal contacts (firestore)
      yield call(updateLegalContacts, data)

      yield put({ type: 'APP_SET_SETTINGS', data })
      yield put({ type: 'MANAGE_APP_SAVE_SETTINGS', data })
      yield put({ type: 'APP_SAVE_SUCCESS' })
    } catch (error) {
      yield put({ type: 'APP_SAVE_ERROR' })
    }
  },
  *APP_SWITCH({ appId }) {
    try {
      yield put({ type: 'APP_FETCHING' })
      yield call(saveLastApp, appId)

      // get cards
      yield put({ type: 'CARDLIST_REQUEST_LIST', appId })

      // get modules
      yield put({ type: 'MODULE_GET_ALL', appId })
      yield put({ type: 'LOCK_GET_LIST', appId })

      // set appInfo and fetching to false
      yield put({ type: 'APP_FETCH_BY_ID', appId })

      // redirect to linked pages
      yield put(push(rteCardsTag(appId, 'linked')))
    } catch (error) {
      yield put({ type: 'APP_FETCH_ERROR', error })
    }
  },
  *APP_SAVE_FOLDER({ folder, appId, cardId }) {
    try {
      const isNew = !folder.id;
      yield put({ type: 'APP_SAVING' })
      if (folder.file && folder.file instanceof File) yield call(handleFile, appId, folder)
      else if (folder.file !== "") folder.url = folder.file
      const folderData = {
        ...folder,
        id: folder.id || uid(),
        dateCreated: folder.dateCreated || Date.now().toString(),
        dateUpdated: folder.dateCreated ? '' : new Date(Date.now()),
      }

      if (folderData.url === undefined) folderData.url = ''
      yield call(rsFirebase.firestore.setDocument, `appdata/${appId}/folders/${folderData.id}`, folderData)
      yield put({ type: 'APP_SET_FOLDER', folder: folderData, isNew: isNew })
      if (cardId) yield put({ type: 'CARD_ADD_FOLDER', folder: folderData })
      yield put({ type: 'APP_SAVE_SUCCESS' })
      return folderData
    } catch (error) {
      yield put({ type: 'APP_SAVE_ERROR' })
    }
  },
  *APP_SORT_FOLDERS({ appId, value }) {
    try {
      const appSnap = yield call(rsFirebase.firestore.getDocument, `appdata/${appId}`)
      const app = appSnap.data()
      app.foldersOrder = value
      yield call(rsFirebase.firestore.updateDocument, `appdata/${appId}`, app)

      yield put({ type: 'APP_SET_FOLDERS', order: value })
    } catch (error) {
      yield put({ type: 'APP_SAVE_ERROR' })
    }
  },
  *FOLDER_DELETE_REQUEST({ appId, folderId, redirect = false }) {
    try {
      yield call(rsFirebase.firestore.deleteDocument, `appdata/${appId}/folders/${folderId}`)
      yield put({ type: 'FOLDER_DELETE_ONE', folderId })
      if (redirect) {
        let folders = []
        const f = yield call(rsFirebase.firestore.getCollection, `appdata/${appId}/folders`)

        const userId = yield call(getUserId);
        const user = yield call(getUserProfile, userId);
        const userApp = user.apps.find(app => app.appId === appId);
        const userRole = userApp.roles[0];

        if (userRole.typeAccess === 'none_exept') {
          const accessibleFolders = userRole.access.folders.map(f => f.folderId);
          f.forEach((folder) => {
            if (accessibleFolders.includes(folder.id)) {
              folders[folder.id] = folder.data();
            }
          });
        } else {
          f.forEach((folder) => {
            folders[folder.id] = folder.data();
          });
        }

        yield put(
          push(
            Object.keys(folders).length > 0
              ? rteFolderTag(appId, Object.keys(folders)[0], 'linked')
              : rteCardsTag(appId, 'linked')
          )
        )
      }
      return null
    } catch (error) {
      return error
    }
  },
})
