import styled from 'styled-components'

export const StyledLegend = styled.legend`
  text-transform: uppercase;
  color: #406cb3;
  font-weight: 600 !important;
  margin: 0 !important;
  font-size: 14px !important;
`

export const StyledButtonHalf = styled.a`
  display: block;
  ${({ url }) => url && `background-image: url(${url})`};
  background-size: cover;
  background-position: center center;
  border-radius: ${({ borderRadius }) => borderRadius}%;
  border: ${({ borderWidth }) => borderWidth}px solid black;
  width: 150px;
  margin: auto;
  cursor: default;

  &:after {
    content: '';
    display: block;
    padding-bottom: 100%;
  }
`

export const StyledButtonLabel = styled.span`
  font-weight: 600;
  font-size: 15px;
  word-break: break-word;
  text-align: center;
`
