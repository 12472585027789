import { getColor } from 'styles/functions'
import styled from 'styled-components'

export const StyledIcon = styled.div`
  float: right;
  display: flex;
  flex-direction: row;
  margin-left: 10px;

  button,
  a {
    background-color: ${getColor('yellow')};
    border: 1px solid #12263f;
    color: #12263f;
    width: 27px;
    height: 27px;
    pointer-events: none;

    &:hover {
      color: #12263f !important;
      border: 1px solid #12263f !important;
      background-color: ${getColor('yellow')} !important;
    }
  }
`
