import 'moment/locale/en-ca'
import 'moment/locale/fr-ca'
import localeEn from 'antd/es/date-picker/locale/en_US'
import localeFr from 'antd/es/date-picker/locale/fr_FR'
import { DatePicker } from 'antd'

import PropTypes from 'prop-types'
import { useState } from 'react'
import { useIntl } from 'react-intl'
import { newEvent } from 'utils'

const options = {
  timezone: 'America/Toronto',
  dateAndTimeFormat: {
    en: 'MM/DD/YYYY HH:mm',
    fr: 'DD/MM/YYYY HH:mm',
  },
  dateFormat: {
    en: 'MM/DD/YYYY',
    fr: 'DD/MM/YYYY',
  },
  dbDateFormat: 'MM/DD/YYYY HH:mm',
  values: {
    display: {
      allDay: false,
      range: [{}, {}],
      repeat: 'no',
      rule: 'show',
      differentTimezones: false,
    },
  },
}

const BasicDatePicker = ({ values, name, onChange, allDay, onFormChange }) => {
  const [datepickerValue, setDatepickerValue] = useState(values)

  const intl = useIntl()

  const format =
    !allDay || allDay === 'false' ? options.dateAndTimeFormat[intl.locale] : options.dateFormat[intl.locale]

  const handleDateChange = (values) => {
    if (values === null) {
      values = [null, null]
    }

    setDatepickerValue(values)
    onFormChange(true);

    values.forEach((value, i) => {
      const eventName = (input) => `${name}.range.${i}.${input}`

      if (!value) {
        onChange(newEvent(eventName('date'), value))
      } else {
        const dateString = value.format(options.dbDateFormat)
        onChange(newEvent(eventName('date'), dateString))
      }
    })
  }

  return (
    <div style={{ flex: '1' }}>
      <DatePicker.RangePicker
        style={{ width: '100%', borderRadius: '8px' }}
        locale={intl.locale === 'fr' ? localeFr : localeEn}
        onChange={handleDateChange}
        format={format}
        defaultValue={datepickerValue}
        size="large"
        showTime
      />
    </div>
  )
}

BasicDatePicker.propTypes = {
  allDay: PropTypes.any,
  values: PropTypes.array,
  name: PropTypes.string,
  onChange: PropTypes.func,
  onFormChange: PropTypes.func,
}

export default BasicDatePicker
