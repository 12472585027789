import PropTypes from 'prop-types'
import { BasicInput, DropZone, BasicInfo } from 'components'
import { useIntl } from 'react-intl'

import { StyledLegend } from './styles'
import messages from '../messages'

const GeneralInfo = ({ icon, id, getModuleByName }) => {
  const intl = useIntl()

  const handleValidation = (value) => {
    const items = getModuleByName('listitem', value) || []
    const differentId = items.filter((i) => i.id !== id)
    return differentId.length <= 0
  }

  return (
    <>
      <StyledLegend>{intl.formatMessage(messages.generalInfo)}</StyledLegend>

      <BasicInput name="id" type="hidden" />

      <BasicInput name="cardId" type="hidden" />

      <BasicInput
        name="name"
        type="text"
        label={intl.formatMessage(messages.name)}
        helpMessage={intl.formatMessage(messages.nameHelp)}
        error={intl.formatMessage(messages.nameError)}
        required
        validation={{
          async: (value) => handleValidation(value),
        }}
      />

      <BasicInput
        name="shortName"
        label={intl.formatMessage(messages.shortName)}
        helpMessage={intl.formatMessage(messages.shortNameHelp)}
      />

      <DropZone
        label={intl.formatMessage(messages.icon)}
        helpMessage={intl.formatMessage(messages.iconHelp)}
        accept="image/jpeg, image/png, image/x-png, image/svg+xml"
        name="icon"
        url={icon || ''}
        type="image"
      />

      <div style={{ margin: '-15px 0 0 0' }}>
        <BasicInfo text={intl.formatMessage(messages.iconFormat)} />
      </div>
    </>
  )
}

GeneralInfo.propTypes = {
  getModuleByName: PropTypes.func,
  icon: PropTypes.string,
  id: PropTypes.string,
}

export default GeneralInfo
