import { defineMessages } from 'react-intl'

const scope = 'app.components.PartActions.PhotoboothForm'

export default defineMessages({
  create: { id: `${scope}.create` },
  edit: { id: `${scope}.edit` },
  info: { id: `${scope}.info` },
  input: { id: `${scope}.input` },
  moduleInfo: { id: `${scope}.moduleInfo` },
  photobooth: { id: `${scope}.photobooth` },
  photobooths: { id: `${scope}.photobooths` },
  required: { id: `${scope}.required` },
  select: { id: `${scope}.select` },
  selectInput: { id: `${scope}.selectInput` },
  tutorial: { id: `${scope}.tutorial` },
})
