import { defineMessages } from 'react-intl'

const scope = 'app.components.PartActions.GoogleFormsForm'

export default defineMessages({
  googleForms: { id: `${scope}.googleForms` },
  googleFormsError: { id: `${scope}.googleFormsError` },
  googleFormsHelp: { id: `${scope}.googleFormsHelp` },
  info: { id: `${scope}.info` },
  inputs: { id: `${scope}.inputs` },
  moduleInfo: { id: `${scope}.moduleInfo` },
  select: { id: `${scope}.select` },
  tutorial: { id: `${scope}.tutorial` },
})
