import PropTypes from 'prop-types'
import { useState, useEffect } from 'react'
import { useIntl } from 'react-intl'
import { BasicInput, BasicWarning } from 'components'

import connectStore from 'redux-box/dist/connectStore'
import { storeModule as LockModule } from 'store/locks'

import messages from './messages'

const LockSelect = ({ locks, partIndex, onSelect, id }) => {
  const [lock, setLock] = useState()

  const intl = useIntl()

  useEffect(() => {
    if (!id) {
      setLock('')
    } else {
      const data = locks.getLockById(id)
      setLock(data)
    }
  }, [id])

  return (
    <>
      <BasicInput
        type="select"
        name="id"
        value={!lock && !!id ? '' : id}
        onChange={onSelect}
        required
        label={intl.formatMessage(messages.select)}
        error={intl.formatMessage(messages.error)}
        partIndex={partIndex}
      >
        <option value="" disabled>
          {intl.formatMessage(messages.select)}
        </option>

        {Object.values(locks.list).map(
          (lock, i) =>
            lock.name &&
            lock.name.length > 0 && (
              <option value={lock.id} key={i}>
                {lock.name}
              </option>
            )
        )}
      </BasicInput>

      {!lock && id && (
        <div style={{ margin: '-10px 0 20px 0' }}>
          <BasicWarning text={intl.formatMessage(messages.deletedLock)} />
        </div>
      )}
    </>
  )
}

LockSelect.propTypes = {
  locks: PropTypes.any,
  onSelect: PropTypes.func,
  partIndex: PropTypes.number,
  id: PropTypes.string,
}

export default connectStore({ locks: LockModule })(LockSelect)
