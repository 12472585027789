import PropTypes from 'prop-types'
import { BasicInput } from 'components'
import { useIntl } from 'react-intl'
import { Icon } from 'react-icons-kit'
import { youtube } from 'react-icons-kit/feather/youtube'

import { StyledPreviewBox } from './styles'
import messages from '../messages'

const StyleForm = ({ index, onChange, style, onFormChange }) => {
  const intl = useIntl()

  return (
    <>
      <BasicInput
        label={intl.formatMessage(messages.ratio)}
        helpMessage={intl.formatMessage(messages.ratioHelp)}
        name="style.videoRatios"
        onChange={(e) => {
          onChange(e);
          onFormChange(true);
        }}
        partIndex={index}
        type="select"
        value={style.videoRatios || '16-9'}
      >
        <option value="1-1">{intl.formatMessage(messages.ratio11)}</option>
        <option value="4-3">{intl.formatMessage(messages.ratio43)}</option>
        <option value="16-9">{intl.formatMessage(messages.ratio169)}</option>
        <option value="21-9">{intl.formatMessage(messages.ratio219)}</option>
        <option value="3-4">{intl.formatMessage(messages.ratio34)}</option>
        <option value="9-16">{intl.formatMessage(messages.ratio916)}</option>
        <option value="9-21">{intl.formatMessage(messages.ratio921)}</option>
      </BasicInput>

      <StyledPreviewBox ratio={style.videoRatios}>
        <Icon icon={youtube} />
      </StyledPreviewBox>
    </>
  )
}

StyleForm.defaultProps = {
  onChange: () => {},
  style: {},
}

StyleForm.propTypes = {
  index: PropTypes.number,
  onChange: PropTypes.func,
  style: PropTypes.object,
  onFormChange: PropTypes.func,
}

export default StyleForm
