import { DropdownToggle, DropdownItem } from 'reactstrap'
import { Icon } from 'react-icons-kit'
import { getColor } from 'styles/functions'
import styled from 'styled-components'

export const StyledContainer = styled.div`
  display: flex;
  flex-direction: row;

  .list-group-item {
    padding: 0;
    margin: 0;
    background-color: #fff;
    border: none;
  }

  .dropdown-item {
    display: flex;
    flex-direction: row;
    align-items: center;

    i {
      display: flex !important;
      margin-right: 7px;
    }
  }

  a {
    border-radius: 8px;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    border-right: 1px solid white;
    min-width: 174px;

    &:hover,
    &:focus,
    &:active {
      border-right: 1px solid white !important;
    }
  }
`

export const StyledDropdownToggle = styled(DropdownToggle)`
  font-size: 13px;
  font-weight: 600;
  background-color: ${getColor('green')};
  color: white;
  border: 2px solid ${getColor('green')};
  border-left: 1px solid white;
  border-radius: 8px;
  padding: 5px 7px 5px 10px;
  white-space: nowrap !important;
  transition: all 0.2s ease-in-out;
  cursor: pointer;

  &:hover:not(:disabled),
  &:focus,
  &:active,
  &.active {
    color: ${getColor('green')};
    background-color: white;
    border: 2px solid ${getColor('green')};
    border-left: 1px solid white;
    box-shadow: none !important;

    i::before {
      border-color: ${getColor('green')};
    }
  }

  &:disabled {
    color: white;
    background-color: #e9e9e9;
    border-color: #e9e9e9;
    cursor: default;
  }

  &::after {
    display: none;
  }
`

export const StyledDropdownItem = styled(DropdownItem)`
  cursor: pointer;

  &:disabled {
    cursor: default;
  }
`

export const StyledCaret = styled(Icon)`
  position: relative;
  margin-right: 0 !important;
`
