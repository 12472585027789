import PropTypes from 'prop-types'
import { useIntl } from 'react-intl'
import { AvGroup, AvInput, AvFeedback } from 'availity-reactstrap-validation'
import { BasicInfo, BasicLabel, BasicButton, PartOptions } from 'components'
import { validationsPatterns, newEvent } from 'utils'

import { Icon } from 'react-icons-kit'
import { trash } from 'react-icons-kit/feather/trash'
import { plus } from 'react-icons-kit/feather/plus'

import Params from './Params'
import { StyledInput } from './styles'
import messages from './messages'

const RandomRedirectForm = ({ onChange, onCheckedChange, partIndex, part, onFormChange }) => {
  const intl = useIntl()

  const addInput = (e) => {
    e.preventDefault()
    const urlsCopy = [...part.urls]
    urlsCopy.push('')
    onChange({ target: { id: 'urls', value: urlsCopy } }, partIndex)
  }

  const handleRemoveUrl = (e, i) => {
    e.preventDefault()
    const urlsCopy = [...part.urls]
    urlsCopy.splice(i, 1)
    if (urlsCopy.length <= 1) {
      urlsCopy.push('')
    }
    onChange({ target: { id: 'urls', value: urlsCopy } }, partIndex)
  }

  return (
    <>
      <PartOptions
        partIndex={partIndex}
        onChange={onChange}
        values={part.options}
        styleForm={
          <Params onChange={onChange} onCheckedChange={onCheckedChange} part={part} partIndex={partIndex} onFormChange={onFormChange} />
        }
        onFormChange={onFormChange}
      />

      <AvGroup>
        <div style={{ marginBottom: '5px' }}>
          <BasicLabel
            label={intl.formatMessage(messages.links)}
            helpMessage={intl.formatMessage(messages.linksHelp)}
          />
        </div>

        {part.urls.map((url, i) => (
          <StyledInput key={`url-input-${i}`}>
            <span style={{ whiteSpace: 'nowrap' }}>{i + 1}</span>

            <AvInput
              name={`urls.${i}-${partIndex}`}
              id={`urls.${i}-${partIndex}`}
              type="text"
              onChange={(e) => {
                onChange(newEvent(`urls.${i}`, e.target.value), partIndex);
                onFormChange(true);
              }}
              value={url}
              required={i !== 0}
              validate={{ pattern: validationsPatterns.urlExtended }}
            />

            <BasicButton
              icon={<Icon icon={trash} />}
              onClick={(e) => handleRemoveUrl(e, i)}
              round
              color="grey"
              outlined
              small
            />
          </StyledInput>
        ))}

        <AvFeedback>{intl.formatMessage(messages.linksError)}</AvFeedback>

        <BasicButton
          color="green"
          medium
          label={intl.formatMessage(messages.addInput)}
          icon={<Icon icon={plus} />}
          onClick={addInput}
        />
      </AvGroup>

      <div style={{ marginTop: '20px' }}>
        <BasicInfo text={intl.formatMessage(messages.info)} />
      </div>
    </>
  )
}

RandomRedirectForm.propTypes = {
  onChange: PropTypes.func,
  onCheckedChange: PropTypes.func,
  part: PropTypes.object,
  partIndex: PropTypes.number,
  onFormChange: PropTypes.func,
}

export default RandomRedirectForm
