import { defineMessages } from 'react-intl'

const scope = 'app.components.CardDisplayFooter'

export default defineMessages({
  addFolder: { id: `${scope}.addFolder` },
  addToFolder: { id: `${scope}.addToFolder` },
  cancel: { id: `${scope}.cancel` },
  confirm: { id: `${scope}.confirm` },
  delete: { id: `${scope}.delete` },
  duplicate: { id: `${scope}.duplicate` },
  edit: { id: `${scope}.edit` },
  folder: { id: `${scope}.folder` },
  folders: { id: `${scope}.folders` },
  select: { id: `${scope}.select` },
  view: { id: `${scope}.view` },
})
