import styled from 'styled-components'
import { Icon } from 'react-icons-kit'

export const StyledCard = styled.div`
  border-radius: 8px;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
  background-color: white;
  padding: 23px 40px;
  max-width: 600px;

  p {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  &:not(:last-of-type) {
    margin-bottom: 20px;
  }
`

export const StyledIcon = styled(Icon)`
  color: #ffd232;
  display: flex !important;
  margin-right: 10px;

  &:last-of-type {
    margin-left: 10px;
    margin-right: 0;
  }
`

export const StyledSmallLegend = styled.span`
  color: #89959f;
  font-family: Nunito;
  font-size: 12px;
  font-weight: 600;
  line-height: 33px;
  text-transform: uppercase;
`
