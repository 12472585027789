const mutations = {
  CARDLIST_ADD: (state, { card }) => {
    state.list.push(card)
  },
  CARDLIST_SET: (state, { list }) => {
    state.list = list
    state.fetching = false
  },
  CARDLIST_SAVING: (state) => {
    state.saving = true
  },
  CARDLIST_SAVING_ERROR: (state) => {
    state.saving = false
    state.error = true
  },
  CARDLIST_FETCHING: (state) => {
    state.fetching = true
  },
  CARDLIST_FETCHING_ERROR: (state) => {
    state.fetching = false
    state.error = true
  },
  CARDLIST_RESET_STATUS: (state) => {
    state.fetching = false
    state.error = false
  },
  CARDLIST_UPDATE_CARD: (state, { card }) => {
    const index = state.list.findIndex((c) => c.cardId === card.cardId)
    if (index >= 0) {
      state.list[index] = card
    } else {
      state.list.push(card)
    }
  },
  CARDLIST_DELETE_CARD: (state, { cardId }) => {
    const listWithoutCard = state.list.filter((cardFromList) => cardFromList.cardId !== cardId)
    state.list = listWithoutCard
  },
  CARDLIST_RESET: (state) => {
    state.list = []
  },
}
export default mutations
