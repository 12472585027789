import { defineMessages } from 'react-intl'

const scope = 'app.components.PartActions.DividerForm'

export default defineMessages({
  large: { id: `${scope}.large` },
  medium: { id: `${scope}.medium` },
  required: { id: `${scope}.required` },
  select: { id: `${scope}.select` },
  size: { id: `${scope}.size` },
  sizeHelp: { id: `${scope}.sizeHelp` },
  small: { id: `${scope}.small` },
})
