import emoji from 'assets/svg/stickers/soulager.svg'
import { defineMessages, useIntl } from 'react-intl'
import styled from 'styled-components'

const messages = defineMessages({
  empty: { id: 'app.components.EmptyListMsg.empty' },
})

const StyledContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  img {
    width: 50px;
    margin-right: 10px;
  }
`

const EmptyListMsg = () => {
  const intl = useIntl()

  return (
    <StyledContainer>
      <img src={emoji} alt="emoji" />
      <span>{intl.formatMessage(messages.empty)}</span>
    </StyledContainer>
  )
}

EmptyListMsg.propTypes = {}

export default EmptyListMsg
