import { defineMessages } from 'react-intl'

const scope = 'app.components.PartBtnUnityForm'

export default defineMessages({
  actionError: { id: `${scope}.actionError` },
  actionLabel: { id: `${scope}.actionLabel` },
  actionPlaceholder: { id: `${scope}.actionPlaceholder` },
  btnError: { id: `${scope}.btnError` },
  btnLabel: { id: `${scope}.btnLabel` },
  btnPlaceholder: { id: `${scope}.btnPlaceholder` },
})
