import { defineMessages } from 'react-intl'

const scope = 'app.components.PartActions.Videos'

export default defineMessages({
  legend: { id: `${scope}.legend` },
  legendHelp: { id: `${scope}.legendHelp` },
  previewInfo: { id: `${scope}.previewInfo` },
  ratio: { id: `${scope}.ratio` },
  ratio11: { id: `${scope}.ratio11` },
  ratio169: { id: `${scope}.ratio169` },
  ratio219: { id: `${scope}.ratio219` },
  ratio34: { id: `${scope}.ratio34` },
  ratio43: { id: `${scope}.ratio43` },
  ratio916: { id: `${scope}.ratio916` },
  ratio921: { id: `${scope}.ratio921` },
  ratioHelp: { id: `${scope}.ratioHelp` },
  video: { id: `${scope}.video` },
  videoHelp: { id: `${scope}.videoHelp` },
  videoError: { id: `${scope}.videoError` },
  videoReject: { id: `${scope}.videoReject` },
  vimeo: { id: `${scope}.vimeo` },
  vimeoError: { id: `${scope}.vimeoError` },
  vimeoHelp: { id: `${scope}.vimeoHelp` },
  youtube: { id: `${scope}.youtube` },
  youtubeError: { id: `${scope}.youtubeError` },
  youtubeHelp: { id: `${scope}.youtubeHelp` },
})
