import styled from 'styled-components'
import { styles } from '../../assets/styles-variables'

export const StyledDiv = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 24px;

  @media screen and (max-width: 960px) {
    justify-content: flex-end;
    flex-direction: column;
    align-items: flex-start;
  }
`

export const StyledSelect = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  white-space: nowrap;
  font-family: Nunito;
  font-weight: 700;
  font-size: 13px;

  select {
    box-sizing: border-box;
    font-family: Nunito;
    font-weight: 700;
    font-size: 13px;
    border: 1px solid #12263f;
    border-radius: 6px;
    margin-left: 10px;
    background-color: transparent;
    padding: 2px 5px 0 5px;
    height: fit-content;
    min-width: 115px;
    width: 115px;

    &:focus,
    &:active {
      background-color: transparent;
    }
  }
`

export const StyledSmallLabel = styled.span`
  font-family: Nunito;
  font-weight: 700;
  font-size: 13px;
  line-heigth: 22px;

  @media screen and (max-width: 960px) {
    display: none;
  }
`

export const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;

  @media screen and (max-width: 960px) {
    margin-top: 24px;
  }
`

export const StyledButtons = styled.div`
  a {
    padding: 0.1rem 0.75rem;
    background: transparent;
    font-size: 0.8rem;
    font-weight: 600;
    color: #03162c;
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 7px;
    white-space: nowrap;
    margin-top: 10px;

    @media screen and (max-width: 960px) {
      margin-top: 0;
    }

    &:not(:last-child) {
      margin-right: 0.5rem;
    }

    &.active,
    &.active:focus,
    &.active:active {
      background-color: ${styles.color.yellow} !important;
      border: 1px solid ${styles.color.yellow} !important;
      color: ${styles.color.main} !important;
      cursor: default;
    }

    &:hover,
    &:focus,
    &:active {
      color: ${styles.color.main};
      background-color: ${styles.color.yellow};
      border: 1px solid ${styles.color.yellow};
    }
  }
`
