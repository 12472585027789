import PropTypes from 'prop-types'
import styled from 'styled-components'

const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
`

const StyledLabel = styled.label`
  font-family: Nunito;
  font-size: 16px;
  font-weight: 600;
  line-height: 1.5;
  margin-bottom: 2px;
  color: rgb(33, 37, 41) !important;

  @media (max-width: 568px) {
     margin-top: 25px!important;
  }
  @media (min-width: 770px) and (max-width: 836px) {
      margin-top: 25px!important;
  }
`

const StyledHelpMessage = styled.span`
  font-size: 12px;
  color: #6c757d;
  margin-top: -5px;
  line-height: 17px;
`

// comes from class invalid-feedback in bootstrap file
const StyledError = styled.span`
  opacity: 1;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 12px;
  color: #dc3545;
`

const BasicLabel = ({ helpMessage, label, name, required }) => (
  <StyledContainer>
    <StyledLabel htmlFor={name}>
      {label && label}
      {required && <StyledError>&nbsp;*</StyledError>}
    </StyledLabel>

    {helpMessage && <StyledHelpMessage>{helpMessage}</StyledHelpMessage>}
  </StyledContainer>
)

BasicLabel.defaultProps = {
  required: false,
}

BasicLabel.propTypes = {
  label: PropTypes.any,
  helpMessage: PropTypes.string,
  name: PropTypes.string,
  required: PropTypes.bool,
}

export default BasicLabel
