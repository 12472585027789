import PropTypes from 'prop-types'
import { GoogleMapsForm, GoogleFormsForm } from 'components'

const Integrations = ({ part, partIndex, app, onChange, onFormChange }) => {
  return (
    <>
      {part.type === 'GoogleMaps' && (
        <GoogleMapsForm appId={app.id} onChange={onChange} part={part} partIndex={partIndex} onFormChange={onFormChange} />
      )}

      {part.type === 'FormGoogle' && (
        <GoogleFormsForm appId={app.id} onChange={onChange} part={part} partIndex={partIndex} onFormChange={onFormChange} />
      )}
    </>
  )
}

Integrations.propTypes = {
  app: PropTypes.object,
  onChange: PropTypes.func,
  part: PropTypes.object,
  partIndex: PropTypes.number,
  onFormChange: PropTypes.func,
}

export default Integrations
