import styled from 'styled-components'
import BasicForm from 'components/BasicForm'
import Link from 'components/Link'

export const StyledContainer = styled.div`
  margin-left: 0px;
  display: flex;
  flex-direction: column;
  padding-left: 30px;

  @media (min-width: 768px) {
    padding: 0 20px;
  }
`

const StyledButton = styled.button`
  display: flex;
  align-items: center;
  font-size: 12px;
  font-family: Nunito;
  font-weight: 600;
  line-height: 11px;
  color: #212529;
  background-color: #ffd232;
  padding: 2px 10px 1px 10px;
  border: 2px solid #ffd232;
  border-radius: 16px;
  width: fit-content;
  cursor: pointer;
  transition: all ease-in-out 0.2s;

  i {
    padding-bottom: 1px;
    margin-right: 3px;
  }

  svg {
    color: #12263f;
    transition: all ease-in-out 0.2s;
  }

  &:hover {
    background-color: transparent;
    color: #ffd232;

    svg {
      color: #ffd232;
    }
  }
`

export const StyledLabel = styled(Link)`
  position: relative;
  font-family: Nunito;
  font-size: 14px;
  line-height: 22px;
  color: ${({ selected }) => (selected ? '#61a7fb' : 'white')};
  padding: 3px 0 3px 20px;
  word-break: break-word;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  width: 100%;

  &:hover {
    text-decoration: none !important;
  }

  &::before {
    content: '';
    position: absolute;
    top: 8px;
    left: 0;
    height: 11px;
    width: 11px;
    background-color: ${({ color }) => color || '#88959F'};
    border-radius: 100%;
  }
`

export const StyledLabelLock = styled.span`
  position: relative;
  font-family: Nunito;
  font-size: 14px;
  line-height: 22px;
  color: #89959f;
  padding: 3px 0 3px 20px;
  word-break: break-word;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  width: 100%;
  cursor: pointer;

  &:hover {
    text-decoration: none !important;
  }

  &::before {
    content: '';
    position: absolute;
    top: 8px;
    left: 0;
    height: 11px;
    width: 11px;
    background-color: ${({ color }) => color || '#88959F'};
    border-radius: 100%;
  }
`

export const StyledRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`

export const StyledColumn = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin: 10px 0;
`

export const StyledAll = styled.span`
  color: #ffd232;
  font-size: 12px;
  font-family: Nunito;
  line-height: 22px;
  margin: -10px 0 10px 20px;
  cursor: pointer;
`

export const StyledAdd = styled(StyledButton)`
  margin-bottom: 10px;
`

export const StyledSave = styled(StyledButton)`
  padding: 5px 10px;
  margin-top: -10px;
  margin-bottom: 0;
  align-self: flex-end;
`

export const StyledForm = styled(BasicForm)`
  display: flex;
  flex-direction: column;
  border-bottom: 1px solid #1a2d4b;
  padding-bottom: 20px;

  label {
    font-family: Nunito;
    color: white;
    margin-bottom: 4px;
  }
`

export const StyledInput = styled.div`
  display: flex;
  flex-direction: column;

  .form-group {
    margin: 0;
  }

  label {
    font-size: 14px !important;
  }

  input,
  input:focus,
  input:active,
  select,
  select:focus,
  select:active {
    padding: 5px 10px !important;
    border-radius: 4px !important;
    border: none;
    font-family: Nunito;
    font-weight: 600;
    color: #212529;
    height: 100%;
    font-size: 14px !important;
  }

  select {
    padding: 5px 10px 4px 10px;
  }

  &:last-of-type div {
    margin-top: 0;
  }
`

export const StyledColorPicker = styled.div`
  margin: 10px 0;

  label {
    font-size: 14px;
    color: white !important;
  }

  .w-color-swatch {
    justify-content: flex-start;

    &.w-color-circle > div {
      height: 24px !important;
      width: 24px !important;
    }
  }

  .w-color-block {
    width: 100% !important;
  }

  .w-color-editable-input {
    display: none !important;
  }
`
