import PropTypes from 'prop-types'
import { useEffect, useState, createContext } from 'react'
import { Container } from 'reactstrap'
import { useIntl } from 'react-intl'
import { SideNav, NavBar, Footer, BasicModal, UpgradeBox, BasicButton } from 'components'
import styled from 'styled-components'

import { connectStore } from 'redux-box'
import { storeModule as SessionModule } from 'store/manageUsers'
import { storeModule as AppModule } from 'store/app'

import { Icon } from 'react-icons-kit'
import { arrowUp } from 'react-icons-kit/feather/arrowUp'

import AppManager from './AppManager'
import messages from './messages'

const StyledContainer = styled(Container)`
  background-color: #fafcfe;
  min-height: 100vh;
  padding: 0;
`
const StyledMain = styled.main`
  padding: 1.75rem 1.25rem 6rem 17.5rem;
  height: 100%;
  width: 100%;

  @media (max-width: 768px) {
    padding: 1.75rem 1.25rem 6rem 1.25rem;
  }
`

const StyledButton = styled.div`
  position: fixed;
  bottom: 40px;
  right: 20px;
  z-index: 2;

  button {
    &:hover,
    &:focus,
    &:active {
      background: white !important;
    }
  }
`

export const MainContainerContext = createContext()

const MainAppContainer = ({ children, appId, cardId, session, style, app }) => {
  const [open, setOpen] = useState(false)
  const [showButton, setShowButton] = useState(false)

  const intl = useIntl()

  useEffect(() => {
    window.addEventListener('scroll', handleOnScrollDown)

    return () => {
      window.removeEventListener('scroll', handleOnScrollDown)
    }
  }, [])

  const handleOnScrollDown = () => {
    if (window.pageYOffset > 300) {
      setShowButton(true)
    } else {
      setShowButton(false)
    }
  }

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    })
  }

  return (
    <MainContainerContext.Provider
      value={{
        open,
        toggleUpgradeModal: () => {
          setOpen(!open)
        },
      }}
    >
      <AppManager appId={appId} cardId={cardId} />

      <StyledContainer fluid>
        {session.profile.apps && <>
          <NavBar
            app={app}
            appId={appId}
            appName={app.name}
            changeApp={app.switchApp}
            superadmin={session.profile.superadmin}
            userRoles={session.profile.apps}
            user={session.email}
          />
          <SideNav
          app={app}
            appId={appId}
            appList={session.appList}
            appName={app.name}
            changeApp={app.switchApp}
            superadmin={session.profile.superadmin}
            userRoles={session.profile.apps}
            user={session.email}
          />
        </>}

        <StyledMain style={style}>
          {children}

          {showButton && (
            <StyledButton>
              <BasicButton
                small
                color="blue"
                label={intl.formatMessage(messages.scroll)}
                onClick={scrollToTop}
                icon={<Icon icon={arrowUp} />}
              />
            </StyledButton>
          )}
        </StyledMain>
      </StyledContainer>

      {open && (
        <BasicModal
          content={<UpgradeBox appId={appId} inModal />}
          isOpen={open}
          toggle={() => setOpen(false)}
          size="medium"
        />
      )}

      <Footer />
    </MainContainerContext.Provider>
  )
}

MainAppContainer.propTypes = {
  app: PropTypes.object,
  appId: PropTypes.string,
  cardId: PropTypes.string,
  children: PropTypes.node,
  session: PropTypes.object,
  style: PropTypes.object,
}

export default connectStore({ session: SessionModule, app: AppModule })(MainAppContainer)
