import Cookies from 'js-cookie'

const state = {
  users: {
    list: [],
    fetching: false,
    error: false,
    success: false,
    saving: false,
  },
  appList: [],
  profile: {
    email: '',
    firstName: '',
    id: '',
    lastName: '',
    superadmin: false,
  },
  loggedIn: !!Cookies.get('uid'),
  loading: false,
  error: false,
  success: false,
  emailSent: false,
}

export default state
