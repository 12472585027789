import { defineMessages } from 'react-intl'

const scope = 'app.containers.PagePhotobooth'
const wfScope = `${scope}.wf`
const efScope = `${scope}.ef`
const pfScope = `${scope}.pf`
const spfScope = `${scope}.spf`
const sfScope = `${scope}.sf`

export default defineMessages({
  add: { id: `${scope}.add` },
  alternativeStyle: { id: `${scope}.alternativeStyle` },
  alternativeStyleHelp: { id: `${scope}.alternativeStyleHelp` },
  cancel: { id: `${scope}.cancel` },
  defaultInfo: { id: `${scope}.defaultInfo` },
  delete: { id: `${scope}.delete` },
  edit: { id: `${scope}.edit` },
  emptyList: { id: `${scope}.emptyList` },
  english: { id: `${scope}.english` },
  files: { id: `${scope}.files` },
  filesInfo: { id: `${scope}.filesInfo` },
  filesPreview: { id: `${scope}.filesPreview` },
  french: { id: `${scope}.french` },
  generalInfo: { id: `${scope}.generalInfo` },
  intro: { id: `${scope}.intro` },
  language: { id: `${scope}.language` },
  languageHelp: { id: `${scope}.languageHelp` },
  metaTitle: { id: `${scope}.metaTitle` },
  modalButtonText: { id: `${scope}.modalButtonText` },
  modalButtonTextHelp: { id: `${scope}.modalButtonTextHelp` },
  modalButtonTextPlaceholder: { id: `${scope}.modalButtonTextPlaceholder` },
  modalText: { id: `${scope}.modalText` },
  modalTitle: { id: `${scope}.modalTitle` },
  name: { id: `${scope}.name` },
  nameDuplicate: { id: `${scope}.nameDuplicate` },
  nameError: { id: `${scope}.nameError` },
  nameHelp: { id: `${scope}.nameHelp` },
  newPhotobooth: { id: `${scope}.newPhotobooth` },
  openInModal: { id: `${scope}.openInModal` },
  openInModalHelp: { id: `${scope}.openInModalHelp` },
  photobooth: { id: `${scope}.photobooth` },
  required: { id: `${scope}.required` },
  save: { id: `${scope}.save` },
  saving: { id: `${scope}.saving` },
  saveSuccess: { id: `${scope}.saveSuccess` },
  saveError: { id: `${scope}.saveError` },
  select: { id: `${scope}.select` },
  subtitle: { id: `${scope}.subtitle` },
  viewCards: { id: `${scope}.viewCards` },
  viewFiles: { id: `${scope}.viewFiles` },
  viewInfo: { id: `${scope}.viewInfo` },
  viewLess: { id: `${scope}.viewLess` },
  viewList: { id: `${scope}.viewList` },
  viewMore: { id: `${scope}.viewMore` },

  // welcome frame
  wf: {
    enableUpload: { id: `${wfScope}.enableUpload` },
    image: { id: `${wfScope}.image` },
    imageHelp: { id: `${wfScope}.imageHelp` },
    message: { id: `${wfScope}.message` },
    messageHelp: { id: `${wfScope}.messageHelp` },
    messagePlaceholder: { id: `${wfScope}.messagePlaceholder` },
    startButton: { id: `${wfScope}.startButton` },
    startButtonHelp: { id: `${wfScope}.startButtonHelp` },
    startButtonPlaceholder: { id: `${wfScope}.startButtonPlaceholder` },
    title: { id: `${wfScope}.title` },
    uploadText: { id: `${wfScope}.uploadText` },
    uploadTextHelp: { id: `${wfScope}.uploadTextHelp` },
    uploadTextPlaceholder: { id: `${wfScope}.uploadTextPlaceholder` },
    welcomeImage: {
      1: { id: `${wfScope}.welcomeImage.1` },
      2: { id: `${wfScope}.welcomeImage.2` },
      3: { id: `${wfScope}.welcomeImage.3` },
      4: { id: `${wfScope}.welcomeImage.4` },
      5: { id: `${wfScope}.welcomeImage.5` },
      6: { id: `${wfScope}.welcomeImage.6` },
    },
  },

  // error frame
  ef: {
    cameraError: { id: `${efScope}.cameraError` },
    cameraErrorHelp: { id: `${efScope}.cameraErrorHelp` },
    cameraErrorPlaceholder: { id: `${efScope}.cameraErrorPlaceholder` },
    info: { id: `${efScope}.info` },
    title: { id: `${efScope}.title` },
    upload: { id: `${efScope}.upload` },
    uploadHelp: { id: `${efScope}.uploadHelp` },
    uploadPlaceholder: { id: `${efScope}.uploadPlaceholder` },
  },

  // photbooth frame
  pf: {
    custom: { id: `${pfScope}.custom` },
    default: { id: `${pfScope}.default` },
    large: { id: `${pfScope}.large` },
    medium: { id: `${pfScope}.medium` },
    none: { id: `${pfScope}.none` },
    noWatermark: { id: `${pfScope}.noWatermark` },
    opacity: { id: `${pfScope}.opacity` },
    opacityError: { id: `${pfScope}.opacityError` },
    opacityHelp: { id: `${pfScope}.opacityHelp` },
    position: { id: `${pfScope}.position` },
    positionHelp: { id: `${pfScope}.positionHelp` },
    preview: { id: `${pfScope}.preview` },
    previewHelp: { id: `${pfScope}.previewHelp` },
    small: { id: `${pfScope}.small` },
    stickerFormatError: { id: `${pfScope}.stickerFormatError` },
    stickers: { id: `${pfScope}.stickers` },
    stickersError: { id: `${pfScope}.stickersError` },
    stickersHelp: { id: `${pfScope}.stickersHelp` },
    title: { id: `${pfScope}.title` },
    watermark: { id: `${pfScope}.watermark` },
    watermarkError: { id: `${pfScope}.watermarkError` },
    watermarkHelp: { id: `${pfScope}.watermarkHelp` },
    watermarkInfo: { id: `${pfScope}.watermarkInfo` },
    watermarkSize: { id: `${pfScope}.watermarkSize` },
  },

  // save photo frame
  spf: {
    button: { id: `${spfScope}.button` },
    buttonHelp: { id: `${spfScope}.buttonHelp` },
    buttonPlaceholder: { id: `${spfScope}.buttonPlaceholder` },
    openTerms: { id: `${spfScope}.openTerms` },
    terms: { id: `${spfScope}.terms` },
    termsUrlHelp: { id: `${spfScope}.termsUrlHelp` },
    termsUrl: { id: `${spfScope}.termsUrl` },
    text: { id: `${spfScope}.text` },
    textHelp: { id: `${spfScope}.textHelp` },
    textPlaceholder: { id: `${spfScope}.textPlaceholder` },
    title: { id: `${spfScope}.title` },
  },

  // share frame
  sf: {
    download: { id: `${sfScope}.download` },
    downloadButton: { id: `${sfScope}.downloadButton` },
    downloadButtonHelp: { id: `${sfScope}.downloadButtonHelp` },
    downloadButtonPlaceholder: { id: `${sfScope}.downloadButtonPlaceholder` },
    facebook: { id: `${sfScope}.facebook` },
    facebookButton: { id: `${sfScope}.facebookButton` },
    facebookButtonHelp: { id: `${sfScope}.facebookButtonHelp` },
    facebookButtonPlaceholder: { id: `${sfScope}.facebookButtonPlaceholder` },
    facebookMessage: { id: `${sfScope}.facebookMessage` },
    facebookMessageHelp: { id: `${sfScope}.facebookMessageHelp` },
    facebookMessagePlaceholder: { id: `${sfScope}.facebookMessagePlaceholder` },
    hashtag: { id: `${sfScope}.hashtag` },
    hashtagError: { id: `${sfScope}.hashtagError` },
    hashtagHelp: { id: `${sfScope}.hashtagHelp` },
    hashtagPlaceholder: { id: `${sfScope}.hashtagPlaceholder` },
    link: { id: `${sfScope}.link` },
    linkButton: { id: `${sfScope}.linkButton` },
    linkButtonHelp: { id: `${sfScope}.linkButtonHelp` },
    linkButtonPlaceholder: { id: `${sfScope}.linkButtonPlaceholder` },
    linkPlaceholder: { id: `${sfScope}.linkPlaceholder` },
    shareInfo: { id: `${sfScope}.shareInfo` },
    thanks: { id: `${sfScope}.thanks` },
    thanksHelp: { id: `${sfScope}.thanksHelp` },
    thanksPlaceholder: { id: `${sfScope}.thanksPlaceholder` },
    title: { id: `${sfScope}.title` },
  },
})
