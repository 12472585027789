import PropTypes from 'prop-types'
import { useState, useContext } from 'react'
import { useIntl } from 'react-intl'
import { Dropdown, DropdownMenu } from 'reactstrap'
import { MainContainerContext } from 'containers'
import { allFeatures } from 'domain/plans'
import { newEvent } from 'utils'

import { Icon } from 'react-icons-kit'
import { lock } from 'react-icons-kit/feather/lock'

import { StyledItem, StyledLegend, StyledToggle } from './styles'
import messages from './messages'

const TypeSelect = ({ onChange, partIndex, type, app }) => {
  const [open, setOpen] = useState(false)

  const intl = useIntl()

  const context = useContext(MainContainerContext)

  const hasAccess = (name) => {
    return !!Object.keys(app.access).find((a) => a.includes(name))
  }

  const handleOnChange = (type) => {
    if (type === 'RandomReset' && !hasAccess('Random')) {
      context.toggleUpgradeModal()
      return
    }

    if (type === 'RandomStoryReset' && !hasAccess('RandomStory')) {
      context.toggleUpgradeModal()
      return
    }

    if (type !== 'RandomReset' && type !== 'RandomStoryReset' && !hasAccess(type)) {
      context.toggleUpgradeModal()
      return
    }

    if (type === 'ButtonHalf') {
      onChange(newEvent('type', 'Button-Half'), partIndex)
      return
    }

    onChange(newEvent('type', type), partIndex)
  }

  // parce que si tu modifies le type dans le raw
  // editor, ca plante
  const typeExists = (type) => {
    if (type === 'Button-Half') {
      return Object.keys(allFeatures).includes('ButtonHalf')
    }
    if (type === 'RandomReset' || type === 'LockCancel' || type === 'LockPage') {
      return true
    }
    return Object.keys(allFeatures).includes(type)
  }

  return (
    <Dropdown toggle={() => setOpen(!open)} isOpen={open}>
      <StyledToggle>
        {intl.formatMessage(type && typeExists(type) ? messages[type] : messages.typePlaceholder)}
      </StyledToggle>
      <DropdownMenu style={{ overflow: 'auto', width: '100%' }}>
        {/* Text */}
        <StyledLegend>-&nbsp;{intl.formatMessage(messages.sectionText)}&nbsp;-</StyledLegend>
        <StyledItem onClick={() => handleOnChange('H2')} selected={type === 'H2'}>
          {intl.formatMessage(messages.subtitle)}
          {!hasAccess('H2') && <Icon icon={lock} />}
        </StyledItem>
        <StyledItem onClick={() => handleOnChange('Text')} selected={type === 'Text'}>
          {intl.formatMessage(messages.text)}
          {!hasAccess('Text') && <Icon icon={lock} />}
        </StyledItem>
        <StyledItem onClick={() => handleOnChange('Separator')} selected={type === 'Separator'}>
          {intl.formatMessage(messages.divider)}
          {!hasAccess('Separator') && <Icon icon={lock} />}
        </StyledItem>

        {/* Images and 3D */}
        <StyledLegend>-&nbsp;{intl.formatMessage(messages.sectionImage)}&nbsp;-</StyledLegend>
        <StyledItem onClick={() => handleOnChange('Picture')} selected={type === 'Picture'}>
          {intl.formatMessage(messages.pictureUpload)}
          {!hasAccess('Picture') && <Icon icon={lock} />}
        </StyledItem>
        <StyledItem onClick={() => handleOnChange('PictureUrl')} selected={type === 'PictureUrl'}>
          {intl.formatMessage(messages.picture)}
          {!hasAccess('PictureUrl') && <Icon icon={lock} />}
        </StyledItem>
        <StyledItem onClick={() => handleOnChange('Giphy')} selected={type === 'Giphy'}>
          {intl.formatMessage(messages.giphy)}
          {!hasAccess('Giphy') && <Icon icon={lock} />}
        </StyledItem>
        <StyledItem onClick={() => handleOnChange('SketchFab')} selected={type === 'SketchFab'}>
          {intl.formatMessage(messages.sketchFab)}
          {!hasAccess('SketchFab') && <Icon icon={lock} />}
        </StyledItem>

        {/* Audio */}
        <StyledLegend>-&nbsp;{intl.formatMessage(messages.sectionAudio)}&nbsp;-</StyledLegend>
        <StyledItem onClick={() => handleOnChange('Audio')} selected={type === 'Audio'}>
          {intl.formatMessage(messages.audio)}
          {!hasAccess('Audio') && <Icon icon={lock} />}
        </StyledItem>
        <StyledItem onClick={() => handleOnChange('SoundCloud')} selected={type === 'SoundCloud'}>
          {intl.formatMessage(messages.soundCloud)}
          {!hasAccess('SoundCloud') && <Icon icon={lock} />}
        </StyledItem>

        {/* Video */}
        <StyledLegend>-&nbsp;{intl.formatMessage(messages.sectionVideo)}&nbsp;-</StyledLegend>
        <StyledItem onClick={() => handleOnChange('Video')} selected={type === 'Video'}>
          {intl.formatMessage(messages.video)}
          {!hasAccess('Video') && <Icon icon={lock} />}
        </StyledItem>
        <StyledItem onClick={() => handleOnChange('Vimeo')} selected={type === 'Vimeo'}>
          {intl.formatMessage(messages.vimeo)}
          {!hasAccess('Vimeo') && <Icon icon={lock} />}
        </StyledItem>
        <StyledItem onClick={() => handleOnChange('VideoYoutube')} selected={type === 'VideoYoutube'}>
          {intl.formatMessage(messages.youtube)}
          {!hasAccess('VideoYoutube') && <Icon icon={lock} />}
        </StyledItem>

        {/* Button */}
        <StyledLegend>-&nbsp;{intl.formatMessage(messages.sectionButton)}&nbsp;-</StyledLegend>
        <StyledItem onClick={() => handleOnChange('Button')} selected={type === 'Button'}>
          {intl.formatMessage(messages.button)}
          {!hasAccess('Button') && <Icon icon={lock} />}
        </StyledItem>
        <StyledItem onClick={() => handleOnChange('ButtonHalf')} selected={type === 'Button-Half'}>
          {intl.formatMessage(messages.halfButton)}
          {!hasAccess('ButtonHalf') && <Icon icon={lock} />}
        </StyledItem>
        <StyledItem
          onClick={() => handleOnChange('FacebookShareButton')}
          selected={type === 'FacebookShareButton'}
        >
          {intl.formatMessage(messages.facebookButton)}
          {!hasAccess('FacebookShareButton') && <Icon icon={lock} />}
        </StyledItem>
        <StyledItem onClick={() => handleOnChange('BtnUnity')} selected={type === 'BtnUnity'}>
          {intl.formatMessage(messages.unityButton)}
          {!hasAccess('BtnUnity') && <Icon icon={lock} />}
        </StyledItem>
        {hasAccess('LanguageLink') && (
            <StyledItem onClick={() => handleOnChange('LanguageLink')} selected={type === 'LanguageLink'}>
              {intl.formatMessage(messages.languageLink)}
            </StyledItem>
        )}

        {/* Integration */}
        <StyledLegend>-&nbsp;{intl.formatMessage(messages.sectionIntegration)}&nbsp;-</StyledLegend>
        <StyledItem onClick={() => handleOnChange('GoogleMaps')} selected={type === 'GoogleMaps'}>
          {intl.formatMessage(messages.googleMaps)}
          {!hasAccess('GoogleMaps') && <Icon icon={lock} />}
        </StyledItem>
        <StyledItem onClick={() => handleOnChange('FormGoogle')} selected={type === 'FormGoogle'}>
          {intl.formatMessage(messages.googleForm)}
          {!hasAccess('FormGoogle') && <Icon icon={lock} />}
        </StyledItem>

        {/* Redirect */}
        <StyledLegend>-&nbsp;{intl.formatMessage(messages.sectionRedirect)}&nbsp;-</StyledLegend>
        <StyledItem onClick={() => handleOnChange('Redirect')} selected={type === 'Redirect'}>
          {intl.formatMessage(messages.redirect)}
          {!hasAccess('Redirect') && <Icon icon={lock} />}
        </StyledItem>
        <StyledItem onClick={() => handleOnChange('Random')} selected={type === 'Random'}>
          {intl.formatMessage(messages.randomRedirect)}
          {!hasAccess('Random') && <Icon icon={lock} />}
        </StyledItem>
        <StyledItem onClick={() => handleOnChange('RandomReset')} selected={type === 'RandomReset'}>
          {intl.formatMessage(messages.randomReset)}
          {!hasAccess('Random') && <Icon icon={lock} />}
        </StyledItem>
        <StyledItem onClick={() => handleOnChange('RandomStory')} selected={type === 'RandomStory'}>
          {intl.formatMessage(messages.RandomStory)}
          {!hasAccess('RandomStory') && <Icon icon={lock} />}
        </StyledItem>
        {/*<StyledItem onClick={() => handleOnChange('RandomStoryReset')} selected={type === 'RandomStoryReset'}>
          {intl.formatMessage(messages.RandomStoryReset)}
          {!hasAccess('RandomStory') && <Icon icon={lock} />}
        </StyledItem>*/}
        <StyledItem onClick={() => handleOnChange('RandomStoryReset')} selected={type === 'RandomStoryReset'}>
          {intl.formatMessage(messages.RandomStoryReset)}
          {!hasAccess('RandomStoryReset') && <Icon icon={lock} />}
        </StyledItem>

        {/* Lock / Key Codes */}
        <StyledLegend>-&nbsp;{intl.formatMessage(messages.sectionLock)}&nbsp;-</StyledLegend>
        <StyledItem onClick={() => handleOnChange('Lock')} selected={type === 'Lock'}>
          {intl.formatMessage(messages.lock)}
          {!hasAccess('Lock') && <Icon icon={lock} />}
        </StyledItem>
        <StyledItem onClick={() => handleOnChange('LockCancel')} selected={type === 'LockCancel'}>
          {intl.formatMessage(messages.lockReset)}
          {!hasAccess('LockCancel') && <Icon icon={lock} />}
        </StyledItem>
        <StyledItem onClick={() => handleOnChange('LockPage')} selected={type === 'LockPage'}>
          {intl.formatMessage(messages.lockPage)}
          {!hasAccess('LockPage') && <Icon icon={lock} />}
        </StyledItem>

        {/* Premium */}
        <StyledLegend>-&nbsp;{intl.formatMessage(messages.sectionPremium)}&nbsp;-</StyledLegend>
        <StyledItem onClick={() => handleOnChange('Photobooth')} selected={type === 'Photobooth'}>
          {intl.formatMessage(messages.photobooth)}
          {!hasAccess('Photobooth') && <Icon icon={lock} />}
        </StyledItem>
        <StyledItem onClick={() => handleOnChange('List')} selected={type === 'List'}>
          {intl.formatMessage(messages.list)}
          {!hasAccess('List') && <Icon icon={lock} />}
        </StyledItem>

        {/* Advanced modules */}
        <StyledLegend>-&nbsp;{intl.formatMessage(messages.sectionModule)}&nbsp;-</StyledLegend>
        <StyledItem onClick={() => handleOnChange('Trigger')} selected={type === 'Trigger'}>
          {intl.formatMessage(messages.trigger)}
          {!hasAccess('Trigger') && <Icon icon={lock} />}
        </StyledItem>
        <StyledItem onClick={() => handleOnChange('ListItem')} selected={type === 'ListItem'}>
          {intl.formatMessage(messages.product)}
          {!hasAccess('ListItem') && <Icon icon={lock} />}
        </StyledItem>
        <StyledItem onClick={() => handleOnChange('Input')} selected={type === 'Input'}>
          {intl.formatMessage(messages.input)}
          {!hasAccess('Input') && <Icon icon={lock} />}
        </StyledItem>
        <StyledItem onClick={() => handleOnChange('VisitCounter')} selected={type === 'VisitCounter'}>
          {intl.formatMessage(messages.visitCounter)}
          {!hasAccess('VisitCounter') && <Icon icon={lock} />}
        </StyledItem>
      </DropdownMenu>
    </Dropdown>
  )
}

TypeSelect.propTypes = {
  app: PropTypes.object,
  onChange: PropTypes.func,
  partIndex: PropTypes.number,
  type: PropTypes.string,
}

export default TypeSelect
