import PropTypes from 'prop-types'
import { ButtonForm, ButtonHalfForm, FacebookShareButtonForm, PartBtnUnityForm } from 'components'

const Buttons = ({ part, partIndex, app, onChange, onCheckedChange, onPictureChange, onFormChange }) => {
  return (
    <>
      {part.type === 'Button' && (
        <ButtonForm onChange={onChange} onCheckedChange={onCheckedChange} part={part} partIndex={partIndex} onFormChange={onFormChange} />
      )}

      {part.type === 'Button-Half' && (
        <ButtonHalfForm
          onChange={onChange}
          onCheckedChange={onCheckedChange}
          onPictureChange={onPictureChange}
          part={part}
          partIndex={partIndex}
          onFormChange={onFormChange}
        />
      )}

      {part.type === 'FacebookShareButton' && (
        <FacebookShareButtonForm onChange={onChange} part={part} partIndex={partIndex} onFormChange={onFormChange} />
      )}

      {part.type === 'BtnUnity' && (
        <PartBtnUnityForm
          action={part.action}
          onChange={onChange}
          options={part.options}
          partIndex={partIndex}
          text={part.text}
          onFormChange={onFormChange}
        />
      )}
    </>
  )
}

Buttons.propTypes = {
  app: PropTypes.object,
  onChange: PropTypes.func,
  onCheckedChange: PropTypes.func,
  onVideoChange: PropTypes.func,
  part: PropTypes.object,
  partIndex: PropTypes.number,
  onFormChange: PropTypes.func,
}

export default Buttons
