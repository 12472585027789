import PropTypes from 'prop-types'
import { Alert } from 'reactstrap'
import { BasicButton } from 'components'
import { Icon } from 'react-icons-kit'
import { x } from 'react-icons-kit/feather/x'
import styled, { css } from 'styled-components'
import { useIntl } from 'react-intl'

import messages from './messages'

const getColor = (color) => {
  switch (color) {
    case 'success':
      return '#155724'
    case 'danger':
      return '#721c24'
    default:
      return '#004085'
  }
}

const StyledAlert = styled(Alert)`
  position: fixed;
  top: 10px;
  right: 10px;
  display: flex;
  flex-direction: row;
  max-width: 500px;
  z-index: 9999;

  ${({ position }) =>
    position === 'relative' &&
    css`
      @media (max-width: 577px) {
        position: relative;
        top: 0;
        right: 0;
      }
    `}

  button {
    padding: 2px;
    width: fit-content;
    height: fit-content;
    margin-left: 10px;
    color: ${({ color }) => getColor(color)};
    border: 1px solid ${({ color }) => getColor(color)};

    &:hover {
      color: white;
      border: 1px solid ${({ color }) => getColor(color)} !important;
      background-color: ${({ color }) => getColor(color)} !important;
    }
  }

  i {
    display: flex !important;
    margin: 0;
  }
`

/*
  BasicToast used in forms for user feedback
  ...
  Label can be 'success', 'error', 'saving', 'deleted', 'copied'
  If custom message, use text props
*/
const BasicToast = ({ label, color, open, toggle, text, position }) => {
  const intl = useIntl()
  return open ? (
    <StyledAlert color={color} position={position}>
      {!!label ? intl.formatMessage(messages[label]) : text}
      {toggle && <BasicButton onClick={toggle} small outlined icon={<Icon icon={x} linkstyle="true" />} />}
    </StyledAlert>
  ) : null
}

BasicToast.propTypes = {
  color: PropTypes.string,
  label: PropTypes.string,
  open: PropTypes.bool,
  position: PropTypes.string,
  text: PropTypes.string,
  toggle: PropTypes.func,
}

export default BasicToast
