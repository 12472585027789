import orderBy from 'lodash/orderBy'

const mutations = {
  APP_SET_APPINFO: (state, { app }) => {
    state.access = { ...app.access }
    state.cardList = Object.values(app.cardList)
    state.fetching = false
    state.folders = app.folders || []
    state.foldersOrder = app.foldersOrder || ''
    state.id = app.id
    state.lastCardUpdateTime = app.lastCardUpdateTime
    state.legalContacts = Object.values(app.legalContacts) || []
    state.logo = { ...app.logo }
    state.name = app.name
    state.notes = app.notes || ''
    state.plan = app.plan
    state.theme = app.theme || ''
    state.themes = app.themes ? Object.values(app.themes) : []
    state.tokenCardLinks = Object.values(app.tokenCardLinks)
    state.tokenList = Object.values(app.tokenList)
    state.tracking = app.tracking || {}
  },
  APP_SET_SETTINGS: (state, { data }) => {
    state.name = data.name
    state.theme = data.theme
    state.notes = data.notes
    state.logo = { ...data.logo }
    state.legalContacts = Object.values(data.legalContacts) || []
  },
  APP_SET_FOLDERS: (state, { order }) => {
    switch (order) {
      case 'asc':
        state.folders = orderBy(state.folders, [(folder) => folder.name.toLowerCase()], ['asc'])
        break
      case 'desc':
        state.folders = orderBy(state.folders, [(folder) => folder.name.toLowerCase()], ['desc'])
        break
      case 'new':
        state.folders = orderBy(state.folders, ['dateCreated'], ['desc'])
        break
      case 'old':
        state.folders = orderBy(state.folders, ['dateCreated'], ['asc'])
        break
      default:
        break
    }
  },
  APP_SET_FOLDER: (state, { folder, isNew }) => {
    if (isNew === true) {
      if (!Array.isArray(state.folders)) {
        state.folders = Object.values(state.folders);
      }
      state.folders.push(folder);
    }
    if (isNew === false) {
      const index = state.folders.findIndex(f => f.id === folder.id);
      if (index !== -1) {
        state.folders[index] = folder;
      }
    }
  },
  APP_REMOVE_THEME: (state, { theme }) => {
    if (state.themes) state.themes.filter((t) => t !== theme.value)
    if (state.theme && state.theme === theme.value) state.theme = ''
  },
  // status
  APP_RESET_STATUS: (state) => {
    state.fetching = false
    state.saving = false
    state.success = false
    state.error = false
  },
  // fetch
  APP_FETCHING: (state) => {
    state.fetching = true
  },
  APP_FETCH_SUCCESS: (state) => {
    state.fetching = false
  },
  APP_FETCH_ERROR: (state, { error }) => {
    state.fetching = false
    state.error = { error }
  },
  // save
  APP_SAVING: (state) => {
    state.saving = true
    state.success = false
    state.error = false
  },
  APP_SAVE_SUCCESS: (state) => {
    state.saving = false
    state.success = true
    state.error = false
  },
  APP_SAVE_ERROR: (state) => {
    state.saving = false
    state.success = false
    state.error = true
  },
  // ?
  APP_ADD_CARD: (state, { cardId }) => {
    state.cardList[cardId] = cardId
  },
  APP_DELETE_CARD: (state, { cardId }) => {
    delete state.cardList[cardId]
  },
  FOLDER_DELETE_ONE: (state, { folderId }) => {
    const index = state.folders.findIndex(f => f.id === folderId);
    if (index !== -1) {
      state.folders.splice(index, 1);
    }
  },
}
export default mutations
