import { defineMessages } from 'react-intl'

const scope = 'app.components.PartActions.InputForm'

export default defineMessages({
  edit: { id: `${scope}.edit` },
  info: { id: `${scope}.info` },
  input: { id: `${scope}.input` },
  required: { id: `${scope}.required` },
  select: { id: `${scope}.select` },
  tutorial: { id: `${scope}.tutorial` },
})
