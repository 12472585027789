import PropTypes from 'prop-types'
import { addBrToText } from 'utils'
import { useIntl } from 'react-intl'
import { FormText } from 'reactstrap'
import styled from 'styled-components'

import { ScheduleTooltip } from '../components/Tooltips'
import messages from '../messages'

const StyledWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: ${({ options }) => (options ? 'space-between' : 'center')};
  align-items: ${({ options }) => (options ? 'flex-start' : 'center')};
  margin: 20px 0;
  padding: 0 20px;
  width: 100%;
`

const StyledLegend = styled(FormText)`
  text-align: center;
  margin: 5px 20px 0 20px;
  line-height: 14px;
  word-break: break-word;
`

const Audio = ({ part, partIndex, cardId }) => {
  const intl = useIntl()
  const hasOptions = part.options && part.options.display && part.options.display.length !== {}

  return (
    <StyledWrapper key={`${cardId}-picture-${partIndex}`} options={hasOptions}>
      <div style={{ display: 'flex', alignItems: 'center', flexDirection: 'column', width: '100%' }}>
        <audio controls style={{ width: '100%' }}>
          <source src={part.url} type="audio/mpeg" />
          {intl.formatMessage(messages.audioElementError)}
        </audio>
        {part.legend && <StyledLegend color="muted">{addBrToText(part.legend, 'part-lgd')}</StyledLegend>}
      </div>

      <ScheduleTooltip part={part} />
    </StyledWrapper>
  )
}

Audio.propTypes = {
  part: PropTypes.object,
  partIndex: PropTypes.number,
  cardId: PropTypes.string,
}

export default Audio
