import { useState } from 'react'
import PropTypes from 'prop-types'
import { Col } from 'reactstrap'
import { useIntl } from 'react-intl'
import { BasicInput, BasicCheckbox, BasicFormSection } from 'components'

import { StyledLegend } from './styles'
import messages from '../messages'

const welcomeImages = [
  { value: '1' },
  { value: '2' },
  { value: '3' },
  { value: '4' },
  { value: '5' },
  { value: '6' },
]

const WelcomeFrame = ({ photobooth }) => {
  const [canUpload, setCanUpload] = useState(photobooth.inputPhoto || false)

  const intl = useIntl()

  return (
    <>
      <StyledLegend>{intl.formatMessage(messages.wf.title)}</StyledLegend>

      <BasicInput
        name="part.welcomeMessage"
        label={intl.formatMessage(messages.wf.message)}
        placeholder={intl.formatMessage(messages.wf.messagePlaceholder)}
        helpMessage={intl.formatMessage(messages.wf.messageHelp)}
      />

      <BasicInput
        type="select"
        name="part.welcomeImage"
        label={intl.formatMessage(messages.wf.image)}
        helpMessage={intl.formatMessage(messages.wf.imageHelp)}
        error={intl.formatMessage(messages.required)}
        required
      >
        <option value="" disabled>
          {intl.formatMessage(messages.select)}
        </option>

        {welcomeImages.map((img, i) => (
          <option key={i} value={img.value}>
            {intl.formatMessage(messages.wf.welcomeImage[img.value])}
          </option>
        ))}
      </BasicInput>

      <BasicInput
        name="part.labelStartCamera"
        label={intl.formatMessage(messages.wf.startButton)}
        helpMessage={intl.formatMessage(messages.wf.startButtonHelp)}
        placeholder={intl.formatMessage(messages.wf.startButtonPlaceholder)}
      />

      <BasicCheckbox
        name="part.inputPhoto"
        label={intl.formatMessage(messages.wf.enableUpload)}
        onChange={(e) => setCanUpload(e.target.checked)}
      />

      {canUpload && (
        <BasicFormSection>
          <Col>
            <BasicInput
              name="part.inputPhotoText"
              label={intl.formatMessage(messages.wf.uploadText)}
              helpMessage={intl.formatMessage(messages.wf.uploadTextHelp)}
              placeholder={intl.formatMessage(messages.wf.uploadTextPlaceholder)}
            />
          </Col>
        </BasicFormSection>
      )}
    </>
  )
}

WelcomeFrame.propTypes = {
  photobooth: PropTypes.object,
}

export default WelcomeFrame
