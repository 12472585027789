import PropTypes from 'prop-types'
import { useState, useRef, useEffect } from 'react'
import Dropzone from 'react-dropzone'
import { BasicButton, BasicLabel } from 'components'
import { useIntl } from 'react-intl'
import { AvGroup, AvInput } from 'availity-reactstrap-validation'
import whiteSquare from '../../assets/img/whitesquare.png'

import { Icon } from 'react-icons-kit'
import { image } from 'react-icons-kit/feather/image'
import { youtube } from 'react-icons-kit/feather/youtube'

import DropZonePreview from './Preview'
import {
  StyledDropZone,
  StyledContent,
  StyledText,
  StyledIcon,
  StyledError,
  StyledErrorMsg,
  StyledButton,
  StyledHiddenInput,
} from './styles'
import messages from './messages'

const DropZone = ({
  accept,
  disabled,
  error,
  helpMessage,
  label,
  maxSize,
  name,
  onDrop,
  onRemoveFile,
  partIndex,
  rejectLabel,
  required,
  size,
  type,
  url,
  file,
  onFormChange
}) => {
  const [droppedFile, setDroppedFile] = useState(file)
  const [urlValue, setUrlValue] = useState(url)
  const inputRef = useRef(null)
  const [fileTypeError, setFileTypeError] = useState(false);

  const intl = useIntl()

  useEffect(() => {
    setDroppedFile(file)
  }, [file])

  if (urlValue === whiteSquare) {
    setUrlValue('')
  }

  useEffect(() => {
    setUrlValue(url)
  }, [url])

  const partName = partIndex ? `${name}-${partIndex}` : name

  const isFileTooLarge = (file) => file && file.length > 0 && file[0].file.size > maxSize

  const getError = () =>
    inputRef &&
    inputRef.current &&
    inputRef.current.context &&
    inputRef.current.context.FormCtrl.getInputState(partName).error

  const handleDropFile = (acceptedFiles, rejectedFiles) => {
    if (rejectedFiles.length > 0) {
      setFileTypeError(true);
    }

    if (acceptedFiles !== undefined && acceptedFiles.length > 0) {
      setFileTypeError(false);
      setDroppedFile(acceptedFiles[0])
      onDrop(acceptedFiles, rejectedFiles)
      if(onFormChange) onFormChange(true);
    }
  }

  const handleRemoveFile = (e) => {
    setDroppedFile(null)
    setUrlValue('')
    onDrop([], [])
    onRemoveFile(e)
    onFormChange(true);
  }

  return (
    <StyledHiddenInput>
      <AvGroup ref={inputRef}>
        <BasicLabel label={label} required={required} helpMessage={helpMessage} name={partName} />

        {/* for data in forms */}
        <AvInput
          name={partName}
          value={droppedFile || urlValue}
          style={{ margin: 0, height: 0, padding: 0, visibility: 'hidden' }}
          required={required}
        />
      </AvGroup>

      {!droppedFile && !urlValue ? (
        <>
          <Dropzone
            name={partName}
            id={partName}
            onDrop={handleDropFile}
            accept={accept}
            maxSize={maxSize}
            onRemove={handleRemoveFile}
            disabled={disabled}
          >
            {({ getRootProps, getInputProps, isDragReject, isDragActive, fileRejections }) => (
              <div {...getRootProps()}>
                <StyledDropZone isDragReject={isDragReject || isFileTooLarge(fileRejections)} size={size}>
                  <StyledContent disabled={disabled} error={getError()}>
                    <StyledText size={size}>
                      <input {...getInputProps()} />

                      <StyledIcon size={size}>
                        <Icon icon={type === 'image' ? image : youtube} />
                      </StyledIcon>

                      <span>
                        {intl.formatMessage(
                          size === 'small' ? messages.smallDropMessage : messages.dropMessage
                        )}
                      </span>
                    </StyledText>

                    <StyledButton size={size}>
                      <BasicButton
                        color="green"
                        small
                        outlined
                        label={intl.formatMessage(messages.browse)}
                        onClick={(e) => e.preventDefault()}
                      />
                    </StyledButton>

                    {isDragReject && <StyledError>{rejectLabel}</StyledError>}

                    {isFileTooLarge(fileRejections) && !isDragActive && (
                      <StyledError>{intl.formatMessage(messages.fileTooLarge)}</StyledError>
                    )}
                  </StyledContent>
                </StyledDropZone>
              </div>
            )}
          </Dropzone>

          {getError() && <StyledErrorMsg>{error}</StyledErrorMsg>}
          {fileTypeError && <StyledErrorMsg>{intl.formatMessage(messages.errorAudioTypeFile)}</StyledErrorMsg>}
        </>
      ) : (
        <DropZonePreview
          disabled={disabled}
          file={droppedFile}
          onRemove={handleRemoveFile}
          type={type}
          url={urlValue}
        />
      )}
    </StyledHiddenInput>
  )
}

DropZone.defaultProps = {
  accept: '*',
  error: '',
  name: '',
  onDrop: () => {},
  onRemoveFile: () => {},
  rejectLabel: '',
}

DropZone.propTypes = {
  accept: PropTypes.string,
  disabled: PropTypes.bool,
  error: PropTypes.string,
  helpMessage: PropTypes.string,
  label: PropTypes.string,
  maxSize: PropTypes.number,
  name: PropTypes.string,
  onDrop: PropTypes.func,
  onRemoveFile: PropTypes.func,
  partIndex: PropTypes.number,
  rejectLabel: PropTypes.string,
  required: PropTypes.bool,
  size: PropTypes.string,
  type: PropTypes.string,
  url: PropTypes.string,
  file: PropTypes.any,
  onFormChange: PropTypes.func,
}

export default DropZone
