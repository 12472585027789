import PropTypes from 'prop-types'
import { useMemo, useEffect, useRef } from 'react'
import ReactPlayer from 'react-player'
import { BasicButton } from 'components'
import { Icon } from 'react-icons-kit'
import { ic_clear as clear } from 'react-icons-kit/md/ic_clear'

import { StyledContainer, StyledImage, StyledButton } from './styles'

/*
  Audio files cannot be changed by just changing the src
  like an image as there is caching.
  You need to load it and play it again.
  ...
  Memoized Url because blob rerenders on keyDown in
  other fields of the form.
*/
const DropZonePreview = ({ disabled, file, onRemove, url, type }) => {
  const audioRef = useRef(null)

  const memoizedUrl = useMemo(() => {
    let img = url
    try {
      img = URL.createObjectURL(file)
    } catch (e) {
      img = url
    }
    return img
  }, [file])

  useEffect(() => {
    if (type === 'audio' && audioRef.current) {
      audioRef.current.load()
    }
  }, [file])

  return (
    <StyledContainer disabled={disabled}>
      {type === 'video' && <ReactPlayer url={memoizedUrl} controls />}

      {type === 'image' && <StyledImage alt="preview" src={memoizedUrl} />}

      {type === 'audio' && (
        <>
          <audio controls style={{ width: '100%' }} ref={audioRef}>
            <source src={memoizedUrl} type="audio/mpeg" />
          </audio>
        </>
      )}

      <StyledButton>
        <BasicButton
          outlined
          color="pink"
          small
          icon={<Icon icon={clear} />}
          onClick={() => onRemove(file)}
          disabled={disabled}
          round
        />
      </StyledButton>
    </StyledContainer>
  )
}

DropZonePreview.propTypes = {
  disabled: PropTypes.bool,
  file: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  onRemove: PropTypes.func,
  type: PropTypes.string,
  url: PropTypes.string,
}

export default DropZonePreview
