const resizeThisImage = (image, maxWidth, maxHeight) => {
  const canvas = document.createElement('canvas')
  let { width, height } = image

  if (maxWidth && maxHeight) {
    // si on me donne des max
    if (width > height) {
      if (width > maxWidth) {
        height = Math.round((height * maxWidth) / width)
        width = maxWidth
      }
    } else if (height > maxHeight) {
      width = Math.round((width * maxHeight) / height)
      height = maxHeight
    }
  }

  canvas.width = width
  canvas.height = height

  const ctx = canvas.getContext('2d')

  ctx.fillStyle = '#FFF'
  ctx.fillRect(0, 0, canvas.width, canvas.height)
  ctx.drawImage(image, 0, 0, width, height)
  return {
    width,
    height,
    dataUrl: canvas.toDataURL('image/jpeg'),
    name: image.name,
  }
}

export const resizeImage = (file, maxWidth, maxHeight) =>
  new Promise((resolve) => {
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = (event) => {
      const dataUrl = event.target.result

      const image = new Image()
      image.name = file.name
      image.src = dataUrl
      image.onload = () => {
        const resized = resizeThisImage(image, maxWidth, maxHeight, 1)
        resolve(resized)
      }
    }
  })

export const getImageSizePromise = (part) =>
  new Promise((resolve) => {
    const { file } = part
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = (event) => {
      const dataUrl = event.target.result

      const image = new Image()
      image.src = dataUrl
      image.onload = () => {
        part.imageWidth = image.width
        part.imageHeight = image.height

        resolve(part)
      }
    }
  })

export const resizeImagePart = (part, maxWidth, maxHeight) => {
  const { file } = part
  return new Promise((resolve) => {
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = (event) => {
      const dataUrl = event.target.result

      const image = new Image()
      image.src = dataUrl
      image.onload = () => {
        const resized = resizeThisImage(image, maxWidth, maxHeight, 1)
        part.base64 = resized.dataUrl
        part.file = { name: file.name }
        part.imageWidth = resized.width
        part.imageHeight = resized.height
        resolve(part)
      }
    }
  })
}
