import { getColor } from 'styles/functions'
import styled, { keyframes } from 'styled-components'

export const StyledTagContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: baseline;
  margin: 0 20px 0 13px;
`

export const StyledTag = styled.div`
  border: none;
  color: white;
  height: fit-content;
  border-radius: 6px;
  font-family: Nunito;
  font-weight: 600;
  font-size: 12px;
  line-height: 13px;
  padding: 6px 10px;
  margin: 5px 0 0 5px;
  letter-spacing: 0.2px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`

export const StyledSystemTag = styled(StyledTag)`
  background-color: ${getColor('blue')};
`

export const StyledThemeTag = styled(StyledTag)`
  background-color: transparent;
  color: #0a3465;
  padding: 0;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  border-radius: 0;

  i {
    display: flex !important;
    width: 16px;
    height: 16px;
    margin-right: 2.5px;
  }
`

export const StyledFolderTag = styled(StyledTag)`
  position: relative;
  background-color: ${({ color }) => color};
  color: white;

  &:first-of-type {
    margin-top: 10px;
  }
`

const spin = keyframes`
  from {
      transform:rotate(0deg);
  }
  to {
      transform:rotate(360deg);
  }
`

export const StyledLoader = styled.div`
  margin-left: 5px;
  margin-top: 5px;

  i {
    display: flex !important;
    animation: ${spin} 3s linear infinite;
  }
`
