import { defineMessages } from 'react-intl'

const scope = 'app.components.PartActions.GiphyForm'

export default defineMessages({
  legend: { id: `${scope}.legend` },
  legendHelp: { id: `${scope}.legendHelp` },
  link: { id: `${scope}.link` },
  linkError: { id: `${scope}.linkError` },
  linkHelp: { id: `${scope}.linkHelp` },
  rating: { id: `${scope}.rating` },
  required: { id: `${scope}.required` },
  search: { id: `${scope}.search` },
  searchHelp: { id: `${scope}.searchHelp` },
  searchPlaceholder: { id: `${scope}.searchPlaceholder` },
})
