import PropTypes from 'prop-types'
import { withRouter } from 'react-router-dom'
import { Input } from 'reactstrap'
import { useIntl } from 'react-intl'

import { StyledSelect } from './styles'
import messages from './messages'

const Collapse = ({ stateCollapse, changeCollapse }) => {
  const intl = useIntl()

  const handleOnChange = (e) => {
    changeCollapse(e.target.value);
  }

  return (
    <StyledSelect>
      <label>{intl.formatMessage(messages.view)}</label>

      <Input type="select" name="collapse" value={stateCollapse} onChange={handleOnChange}>
        <option value="collapse">{intl.formatMessage(messages.collapse)}</option>
        <option value="expand">{intl.formatMessage(messages.expand)}</option>
      </Input>
    </StyledSelect>
  )
}

Collapse.propTypes = {
  stateCollapse: PropTypes.string,
  changeCollapse: PropTypes.func
}

export default withRouter(Collapse)
