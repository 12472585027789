import PropTypes from 'prop-types'
import { FormText } from 'reactstrap'
import styled, { css } from 'styled-components'
import { useIntl } from 'react-intl'

import { Icon } from 'react-icons-kit'
import { alertCircle } from 'react-icons-kit/feather/alertCircle'

import messages from './messages'

const StyledWarning = styled.div`
  padding: 5px 0;

  div {
    display: flex;
    align-items: flex-end;
  }

  small {
    margin: 0 0 0 35px;
  }

  span {
    font-family: Nunito;
    font-size: 16px;
    font-weight: 600;
    color: #f59f32;
    ${({ full }) => full !== 'true' && 'border-bottom: 2px solid #f59f32'};
    width: 100%;
    margin-left: 5px;
    padding-bottom: 2px;
  }

  svg {
    color: #f59f32;
    width: 26px;
    height: 26px;
  }

  ${({ full }) =>
    full === 'true' &&
    css`
      small {
        border-radius: 10px;
        background-color: #f59f32;
        color: #12263f !important;
        font-size: 16px;
        line-height: 22px;
        margin: 4px 0 0 0;
        padding: 10px 20px;
      }
    `}
`

const BasicWarning = ({ text, style, withBackground }) => {
  const intl = useIntl()
  return (
    <StyledWarning style={style} full={String(withBackground)}>
      <div>
        <Icon icon={alertCircle} />
        <span>{intl.formatMessage(messages.warning)}</span>
      </div>
      <FormText color="muted">{text}</FormText>
    </StyledWarning>
  )
}

BasicWarning.propTypes = {
  text: PropTypes.string,
  style: PropTypes.object,
  withBackground: PropTypes.bool,
}

export default BasicWarning
