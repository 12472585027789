import styled from 'styled-components'
import { Row } from 'reactstrap'

export const StyledRow = styled(Row)`
  flex-direction: row;
  flex-wrap: nowrap;

  .col {
    flex-basis: 1 !important;
  }

  @media (max-width: 1200px) {
    flex-direction: column;

    .col-md-4 {
      max-width: 100%;
      flex: 1;
    }
  }
`

export const StyledHeaderButtons = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;

  a:first-of-type {
    margin-right: 10px;
  }
`

export const StyledLabel = styled.label`
  font-family: Nunito;
  font-size: 16px;
  font-weight: 600;
`

export const StyledButtons = styled.div`
  display: flex;
  flex-direction: row;

  button:not(:first-of-type) {
    margin-left: 10px;
  }
`
