import PropTypes from 'prop-types'
import { useIntl } from 'react-intl'
import { BasicButton, BasicTooltip } from 'components'
import uid from 'uid'

import { Icon } from 'react-icons-kit'
import { iosToggleOutline } from 'react-icons-kit/ionicons/iosToggleOutline'

import { StyledButton } from './styles'
import messages from './messages'

const OptionsButton = ({ onClick, labelOpen }) => {
  const id = uid()
  const intl = useIntl()

  return (
    <StyledButton>
      <div data-for={id}>
        <BasicButton
          color="green"
          label={
            labelOpen ?
              intl.formatMessage(messages.optionsOpen)
              :
              intl.formatMessage(messages.optionsClose)
          }
          outlined iconAfter={true}
          medium
          icon={<Icon icon={iosToggleOutline} style={{marginLeft: '10px', marginRight: '0px'}} />}
          onClick={onClick} />
      </div>

      <BasicTooltip id={id} />
    </StyledButton>
  )
}

OptionsButton.propTypes = {
  onClick: PropTypes.func,
  labelOpen: PropTypes.bool,
}

export default OptionsButton
