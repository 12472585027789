import uid from 'uid'
import { firebaseApp } from './rsf'
import { splitFileName } from 'services/utils'

class FirebaseApi {
  constructor() {
    this.firebase = firebaseApp
    this.storage = this.firebase.storage()
    this.storageRef = this.storage.ref()
  }

  async uploadFile(file, appId) {
    const fileNameObj = splitFileName(file.name)
    const fileName = `${fileNameObj.name}-${uid()}.${fileNameObj.ext}`

    const path = `/apps/${appId}/${fileName}`
    const fileRef = this.storageRef.child(path)

    await fileRef.put(file)
    return fileRef.getDownloadURL()
  }

  async uploadFileDataUrl(file, appId) {
    const fileNameObj = splitFileName(file.name)
    const fileName = `${fileNameObj.name}-${uid()}.${fileNameObj.ext}`

    const path = `/apps/${appId}/${fileName}`
    const fileRef = this.storageRef.child(path)

    await fileRef.putString(file.dataUrl, 'data_url')
    return fileRef.getDownloadURL()
  }
}
const firebaseApi = new FirebaseApi()
export default firebaseApi
