import PropTypes from 'prop-types'
import { useIntl } from 'react-intl'
import { getColor } from 'styles/functions'
import styled from 'styled-components'

import { Icon } from 'react-icons-kit'
import { alertTriangle } from 'react-icons-kit/feather/alertTriangle'

import messages from '../messages'

const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
`

export const StyledNotFoundMsg = styled.span`
  display: flex;
  align-items: top;
  font-size: 16px;
  line-height: 18px;
  font-weight: 600;
  font-family: 'Nunito';

  i {
    display: flex !important;
    margin-right: 5px;
    color: ${getColor('orange')};
  }
`

const StyledSpan = styled.span`
  font-size: 12px !important;
  color: ${getColor('orange')};
  padding-left: 20px;
`

const NotFound = ({ fetching, type, id }) => {
  const intl = useIntl()

  return (
    <>
      {!fetching && (
        <StyledContainer>
          <StyledNotFoundMsg>
            <Icon icon={alertTriangle} />

            <span>
              {type && <>{type}&nbsp;</>}
              {id && <>{id}&nbsp;</>}
              {intl.formatMessage(messages.notFound)}
            </span>
          </StyledNotFoundMsg>

          <StyledSpan>{intl.formatMessage(messages.deleted)}</StyledSpan>
        </StyledContainer>
      )}
    </>
  )
}

NotFound.propTypes = {
  fetching: PropTypes.bool,
  id: PropTypes.string,
  type: PropTypes.string,
}

export default NotFound
