import PropTypes from 'prop-types'
import { FormText, Label } from 'reactstrap'
import { AvGroup, AvFeedback, AvInput } from 'availity-reactstrap-validation'

import styled from 'styled-components'

const StyledGroup = styled(AvGroup)`
  margin: 15px 0;

  .form-text {
    font-size: 12px;
    margin-bottom: 0;
    margin-top: 0;
  }

  .form-check {
    padding-left: 0;
  }

  .invalid-feedback {
    margin-top: -5px;
  }
`

const StyledCheckbox = styled.div`
  display: flex;
  flex-direction: row;
  align-items: baseline;
`

const BasicCheckbox = ({
  disabled,
  error,
  helpMessage,
  label,
  name,
  onChange,
  partIndex,
  readOnly,
  required,
  value,
}) => {
  const partName = partIndex ? `${name}-${partIndex}` : name

  return (
    <StyledGroup>
      <StyledCheckbox>
        <AvInput
          type="checkbox"
          required={required}
          name={partName}
          id={name}
          value={value}
          disabled={disabled}
          readOnly={readOnly}
          checked={value}
          onChange={onChange}
        />

        <Label htmlFor={partName} style={{ paddingLeft: '7px' }}>
          {label}
          {required && <span className="invalid-feedback">&nbsp;*</span>}
        </Label>
      </StyledCheckbox>
      {error && <AvFeedback>{error}</AvFeedback>}

      {helpMessage && <FormText>{helpMessage}</FormText>}
    </StyledGroup>
  )
}

BasicCheckbox.defaultProps = {
  onChange: () => {},
  name: '',
  value: false,
}

BasicCheckbox.propTypes = {
  disabled: PropTypes.bool,
  error: PropTypes.string,
  helpMessage: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  name: PropTypes.string,
  onChange: PropTypes.func,
  partIndex: PropTypes.number,
  readOnly: PropTypes.bool,
  required: PropTypes.bool,
  value: PropTypes.any,
}

export default BasicCheckbox
