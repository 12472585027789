import PropTypes from 'prop-types'
import { useIntl } from 'react-intl'
import { addBrToText } from 'utils'
import styled from 'styled-components'

import { Icon } from 'react-icons-kit'
import { send } from 'react-icons-kit/feather/send'
import { lock } from 'react-icons-kit/feather/lock'
import { messageCircle } from 'react-icons-kit/feather/messageCircle'

import { ScheduleTooltip } from '../components/Tooltips'
import messages from './messages'
import connectStore from "redux-box/dist/connectStore";
import { storeModule as RandomStoryModule } from 'store/randomStory'
import { NotFound } from "../modules";

const StyledWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin: 20px;
`

const StyledLabel = styled.label`
  font-family: Nunito;
  font-size: 16px;
  font-weight: 600;
`

const StyledNotes = styled.div`
  word-break: break-word;
  font-size: 12px;
  color: #6c757d;
  font-style: italic;
  display: flex;
  flex-direction: row;

  i {
    display: flex !important;
    margin-right: 5px;
  }
`

const StyledLock = styled.div`
  display: flex;
  width: 100%;
  font-size: 16px;
  line-height: 18px;
  font-weight: 600;
  font-family: 'Nunito';
  ${({ options }) => options && 'padding-right: 40px'};

  i {
    display: flex !important;
    margin-right: 5px;
  }
`

const RamdonStory = ({ cardId, modules, part, partIndex, type }) => {
  const intl = useIntl()

  const foundStory = modules.getRandomStoryById(part.id) || {}

  return (
    <StyledWrapper key={`${cardId}-${type}-${partIndex}`}>
      {Object.keys(foundStory).length > 0 ? (
        type === 'RandomStory' ? (
          <div>
            <StyledLabel>
              <Icon icon={send} style={{ marginRight: '10px' }} />
              <span>{intl.formatMessage(messages.RandomStory)}:&nbsp;{foundStory.name}</span>
            </StyledLabel>

            <ul style={{ wordBreak: 'break-all' }}>
              <li>{intl.formatMessage(messages.beginLink)} :
                <ul>
                  <li>{foundStory.beginLink ? foundStory.beginLink : intl.formatMessage(messages.noLink)}</li>
                </ul>
              </li>
              <li>{intl.formatMessage(messages.randomLinks)} :
                <ul style={{ wordBreak: 'break-all' }}>
                  {foundStory.urls &&
                    foundStory.urls.map((url, i) => (
                      <li key={`${cardId}-url-${partIndex}-${i}`} style={{ overflowWrap: 'anywhere' }}>
                        {url}
                      </li>
                    ))}
                </ul>
              </li>
              <li>{intl.formatMessage(messages.limit)} :
                <ul>
                  <li>{foundStory.limit}</li>
                </ul>
              </li>
              <li>{intl.formatMessage(messages.endLink)} :
                <ul>
                  <li>{foundStory.endLink ? foundStory.endLink : intl.formatMessage(messages.noLink)}</li>
                </ul>
              </li>
              <li>{intl.formatMessage(messages.endOption)} :
                <ul>
                  {foundStory.endStoryOption === 'restart' && (
                    <li>{intl.formatMessage(messages.endOptionRestart)}</li>
                  )}
                  {foundStory.endStoryOption === 'redirection' && (
                    <>
                      <li>{intl.formatMessage(messages.endOptionRedirect)}</li>
                      <li>{intl.formatMessage(messages.endOptionRedirectLink)} {foundStory.endStoryOptionLink}</li>
                    </>
                  )}
                </ul>
              </li>
            </ul>

            {part.notes && (
              <StyledNotes>
                <Icon icon={messageCircle} />
                <span>{addBrToText(part.notes, 'notes')}</span>
              </StyledNotes>
            )}
          </div>
        ) : (
          <StyledLock>
            <Icon icon={lock} style={{ marginRight: '10px' }} />
            <span>{intl.formatMessage(messages.randomStoryReset)}:&nbsp;{foundStory.name}</span>
          </StyledLock>
        )
      ) : (
        <NotFound id={part.id} type={intl.formatMessage(messages[type])} fetching={modules.fetching} />
      )}

      <ScheduleTooltip part={foundStory} />
    </StyledWrapper>
  )
}

RamdonStory.propTypes = {
  cardId: PropTypes.string,
  part: PropTypes.object,
  partIndex: PropTypes.number,
  type: PropTypes.string,
}

export default connectStore({ modules: RandomStoryModule })(RamdonStory)