const mutations = {
  MANAGE_USERS_ADD: (state, { user }) => {
    const index = state.users.list.findIndex((u) => u.id === user.id)
    if (index >= 0) {
      state.users.list[index] = user
    } else {
      state.users.list.push(user)
    }
  },
  MANAGE_USERS_SET: (state, { list }) => {
    state.users.list = list
    state.users.fetching = false
  },
  // fetch
  MANAGE_USERS_FETCHING: (state) => {
    state.users.fetching = true
  },
  MANAGE_USERS_FETCH_ERROR: (state, { error }) => {
    state.users.fetching = false
    state.users.error = error
  },
  // save
  MANAGE_USERS_SAVING: (state) => {
    state.users.saving = true
    state.users.success = false
    state.users.error = false
  },
  MANAGE_USERS_SAVE_SUCCESS: (state) => {
    state.users.saving = false
    state.users.success = true
    state.users.error = false
  },
  MANAGE_USERS_SAVE_ERROR: (state, { error }) => {
    state.users.saving = false
    state.users.success = false
    state.users.error = error
  },
  // status
  MANAGE_USERS_RESET_STATUS: (state) => {
    state.users.fetching = false
    state.users.saving = false
    state.users.success = false
    state.users.error = false
  },
  MANAGE_SESSION_SET_LOGGED_IN: (state) => {
    state.loggedIn = true
  },
  MANAGE_SESSION_SET_PROFILE: (state, { userProfile }) => {
    state.profile = userProfile
  },
  MANAGE_SESSION_FETCH_SUCCESS: (state, { appList }) => {
    state.appList = appList
    state.loading = false
  },
  MANAGE_SESSION_LOGIN_SUCCESS: (state, { uid, email, userProfile }) => {
    state.user = uid
    state.email = email
    state.profile = userProfile
    state.loading = false
    state.loggedIn = true
    state.success = true
    state.error = false
  },
  MANAGE_SESSION_LOGOUT_SUCCESS: (state) => {
    state.email = ''
    state.password = ''
    state.appList = []
    state.user = undefined
    state.profile = {}
    state.loading = false
    state.loggedIn = false
    state.success = false
    state.error = false
  },
  MANAGE_SESSION_LOADING: (state) => {
    state.loading = true
    state.success = false
    state.error = false
    state.emailSent = false
  },
  MANAGE_SESSION_SUCCESS: (state, { sent }) => {
    state.loading = false
    state.success = true
    state.error = false
    if (sent) state.emailSent = true
  },
  MANAGE_SESSION_ERROR: (state, { error }) => {
    state.loading = false
    state.success = false
    state.error = error
    state.emailSent = false
  },
  MANAGE_SESSION_RESET_STATUS: (state) => {
    state.loading = false
    state.success = false
    state.error = false
    state.emailSent = false
  },
  // add app from manageApps to session appList
  // appList is used in the app dropdown (nav)
  MANAGE_SESSION_ADD_APP: (state, { appId }) => {
    const list = { ...state.appList }
    const exists = Object.values(list).includes(appId)

    if (!exists) {
      state.appList.push(appId)
      state.appList = state.appList.sort()
    }
  },
}
export default mutations
