import { defineMessages } from 'react-intl'

const scope = 'app.components.PartOptions'

export default defineMessages({
  allDay: { id: `${scope}.allDay` },
  allDayHelp: { id: `${scope}.allDayHelp` },
  annuallyDay: { id: `${scope}.annuallyDay` },
  content: { id: `${scope}.content` },
  daily: { id: `${scope}.daily` },
  displayOptions: { id: `${scope}.displayOptions` },
  displayTitle: { id: `${scope}.displayTitle` },
  displayTitleHelp: { id: `${scope}.displayTitleHelp` },
  displayExplanation: { id: `${scope}.displayExplanation` },
  from: { id: `${scope}.from` },
  hidden: { id: `${scope}.hidden` },
  hideContent: { id: `${scope}.hideContent` },
  moduleOptions: { id: `${scope}.moduleOptions` },
  tagsOptions: { id: `${scope}.tagsOptions` },
  monthlyDay: { id: `${scope}.monthlyDay` },
  optionsOpen: { id: `${scope}.optionsOpen` },
  optionsClose: { id: `${scope}.optionsClose` },
  repeat: {
    id: `${scope}.repeat`,
    no: { id: `${scope}.repeat.no` },
    daily: { id: `${scope}.repeat.daily` },
    weekly: { id: `${scope}.repeat.weekly` },
    monthlyDay: { id: `${scope}.repeat.monthlyDay` },
    annuallyDay: { id: `${scope}.repeat.annuallyDay` },
    weekdays: { id: `${scope}.repeat.weekdays` },
    weekends: { id: `${scope}.repeat.weekends` },
  },
  repeatHelp: { id: `${scope}.repeatHelp` },
  repeating: { id: `${scope}.repeating` },
  save: { id: `${scope}.save` },
  showContent: { id: `${scope}.showContent` },
  shown: { id: `${scope}.shown` },
  styleOptions: { id: `${scope}.styleOptions` },
  to: { id: `${scope}.to` },
  tooltip: { id: `${scope}.styleOptionsTooltip` },
  weekdays: { id: `${scope}.weekdays` },
  weekends: { id: `${scope}.weekends` },
  weekly: { id: `${scope}.weekly` },
  timezones: { id: `${scope}.timezones` },
  timezonesDefault: { id: `${scope}.timezonesDefault` },
  diffTimezones: { id: `${scope}.diffTimezones` },
  tzStart: { id: `${scope}.tzStart` },
  tzEnd: { id: `${scope}.tzEnd` },
})
