import PropTypes from 'prop-types'
import moment from 'moment-timezone'
import { BasicInput } from 'components'

const BasicInputTimezone = ({ name, label, helpMessage, value, onChange, hidden }) => {
  const defaultTimezone = moment.tz.guess() || 'America/Toronto'

  const depricatedTimeZones = [
    'UCT',
    'PST8PDT',
    'GB',
    'MST7MDT',
    'EST5EDT',
    'W-SU',
    'CST6CDT',
    'HST',
    'MST',
    'Universal',
    'EET',
    'WET',
    'EST',
    'CET',
    'MET',
    'GMT',
    'Etc',
  ]
  const depricatedTimeZonesRegex = `^${depricatedTimeZones.join('|^')}`

  const allowedTimeZones = moment.tz
    .names()
    .filter((timezone) => timezone.startsWith('A') || !new RegExp(depricatedTimeZonesRegex).test(timezone))
    .sort((timezoneA, timezoneB) => timezoneA.localeCompare(timezoneB))
    .map((timezone) => ({
      timezone,
    }))

  return (
    <BasicInput
      type="select"
      name={name}
      label={label}
      helpMessage={helpMessage}
      value={value || defaultTimezone}
      onChange={onChange}
      hidden={hidden}
    >
      {Object.values(allowedTimeZones).map((tz, i) => (
        <option value={tz.timezone} key={i}>
          {tz.timezone}
        </option>
      ))}
    </BasicInput>
  )
}

BasicInputTimezone.propTypes = {
  name: PropTypes.string,
  label: PropTypes.string,
  helpMessage: PropTypes.string,
  value: PropTypes.any,
  onChange: PropTypes.func,
  hidden: PropTypes.bool,
}

export default BasicInputTimezone
