import PropTypes from 'prop-types'
import { addBrToText } from 'utils'
import { Img } from 'components'
import { FormText } from 'reactstrap'
import styled from 'styled-components'
import GiphyLogo from 'assets/img/PoweredBy_Horizontal_Light.png'

import { ScheduleTooltip } from '../components/Tooltips'

const StyledLegend = styled(FormText)`
  text-align: center;
  margin: 5px 20px 0 20px;
  line-height: 14px;
  word-break: break-word;
`

const StyledWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: ${({ options }) => (options ? 'space-between' : 'center')};
  align-items: center;
  margin: ${({ options }) => (options ? '20px' : '20px 0')};
`

const StyledAttribute = styled.img`
  width: 150px;
`

const Giphy = ({ cardId, part, partIndex }) => {
  const hasOptions = part.options && part.options.display && part.options.display.length !== {}

  return (
    <StyledWrapper key={`${cardId}-picture-${partIndex}`} options={hasOptions}>
      <div style={{ display: 'flex', alignItems: 'center', flexDirection: 'column' }}>
        <Img src={part.gif} alt={part.alt} />

        <StyledAttribute src={GiphyLogo} alt="Powered By Giphy" />

        {part.legend && <StyledLegend color="muted">{addBrToText(part.legend, 'part-lgd')}</StyledLegend>}
      </div>

      <ScheduleTooltip part={part} />
    </StyledWrapper>
  )
}

Giphy.propTypes = {
  cardId: PropTypes.string,
  part: PropTypes.object,
  partIndex: PropTypes.number,
}

export default Giphy
