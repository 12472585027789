import PropTypes from 'prop-types'
import { VideoUploadForm, YoutubeForm, VimeoForm } from 'components'

const Videos = ({ part, partIndex, onChange, onVideoChange, onFormChange }) => {
  return (
    <>
      {part.type === 'Video' && (
        <VideoUploadForm
          onChange={onChange}
          onVideoChange={onVideoChange}
          part={part}
          partIndex={partIndex}
          onFormChange={onFormChange}
        />
      )}

      {part.type === 'VideoYoutube' && <YoutubeForm onChange={onChange} part={part} partIndex={partIndex} onFormChange={onFormChange} />}

      {part.type === 'Vimeo' && <VimeoForm onChange={onChange} part={part} partIndex={partIndex} onFormChange={onFormChange} />}
    </>
  )
}

Videos.propTypes = {
  onChange: PropTypes.func,
  onVideoChange: PropTypes.func,
  part: PropTypes.object,
  partIndex: PropTypes.number,
  onFormChange: PropTypes.func,
}

export default Videos
