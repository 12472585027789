import { defineMessages } from 'react-intl'

const scope = 'app.containers.PageTriggers'

export default defineMessages({
  action: { id: `${scope}.action` },
  actionHelp: { id: `${scope}.actionHelp` },
  add: { id: `${scope}.add` },
  after: { id: `${scope}.after` },
  before: { id: `${scope}.before` },
  btnDelete: { id: `${scope}.btnDelete` },
  btnSave: { id: `${scope}.btnSave` },
  button: { id: `${scope}.button` },
  buttonInfo: { id: `${scope}.buttonInfo` },
  buttonParams: { id: `${scope}.buttonParams` },
  buttonText: { id: `${scope}.buttonText` },
  buttonTextError: { id: `${scope}.buttonTextError` },
  buttonTextHelp: { id: `${scope}.buttonTextHelp` },
  cancel: { id: `${scope}.cancel` },
  content: { id: `${scope}.content` },
  contentError: { id: `${scope}.contentError` },
  contentHelp: { id: `${scope}.contentHelp` },
  displayAs: { id: `${scope}.displayAs` },
  displayType: { id: `${scope}.displayType` },
  edit: { id: `${scope}.edit` },
  email: { id: `${scope}.email` },
  errorMessage: { id: `${scope}.errorMessage` },
  errorMessageError: { id: `${scope}.errorMessageError` },
  errorMessageHelp: { id: `${scope}.errorMessageHelp` },
  generalInfo: { id: `${scope}.generalInfo` },
  id: { id: `${scope}.id` },
  imageUnderButton: { id: `${scope}.imageUnderButton` },
  imageUrl: { id: `${scope}.imageUrl` },
  imageUrlHelp: { id: `${scope}.imageUrlHelp` },
  inputHelp: { id: `${scope}.inputHelp` },
  inputIds: { id: `${scope}.inputIds` },
  inputLabel: { id: `${scope}.inputLabel` },
  inputLabelError: { id: `${scope}.inputLabelError` },
  inputLabelHelp: { id: `${scope}.inputLabelHelp` },
  inputParams: { id: `${scope}.inputParams` },
  intro: { id: `${scope}.intro` },
  mailto: { id: `${scope}.mailto` },
  metaTitle: { id: `${scope}.metaTitle` },
  modalText: { id: `${scope}.modalText` },
  modalTitle: { id: `${scope}.modalTitle` },
  name: { id: `${scope}.name` },
  nameError: { id: `${scope}.nameError` },
  nameHelp: { id: `${scope}.nameHelp` },
  newTrigger: { id: `${scope}.newTrigger` },
  pageInfo: { id: `${scope}.pageInfo` },
  pageParams: { id: `${scope}.pageParams` },
  recipient: { id: `${scope}.recipient` },
  recipientError: { id: `${scope}.recipientError` },
  recipientHelp: { id: `${scope}.recipientHelp` },
  redirect: { id: `${scope}.redirect` },
  redirectUrl: { id: `${scope}.redirectUrl` },
  redirectUrlError: { id: `${scope}.redirectUrlError` },
  redirectUrlHelp: { id: `${scope}.redirectUrlHelp` },
  required: { id: `${scope}.required` },
  selectInput: { id: `${scope}.selectInput` },
  selectTrigger: { id: `${scope}.selectTrigger` },
  sender: { id: `${scope}.sender` },
  senderHelp: { id: `${scope}.senderHelp` },
  subject: { id: `${scope}.subject` },
  subjectError: { id: `${scope}.subjectError` },
  subtitle: { id: `${scope}.subtitle` },
  text: { id: `${scope}.text` },
  textUnderButton: { id: `${scope}.textUnderButton` },
  timezone: { id: `${scope}.timezone` },
  timezoneHelp: { id: `${scope}.timezoneHelp` },
  title: { id: `${scope}.title` },
  type: { id: `${scope}.type` },
  typeError: { id: `${scope}.typeError` },
  typeHelp: { id: `${scope}.typeHelp` },
  typeParams: { id: `${scope}.typeParams` },
  url: { id: `${scope}.url` },
  urlError: { id: `${scope}.urlError` },
  validate: { id: `${scope}.validate` },
  validation: { id: `${scope}.validation` },
  viewLess: { id: `${scope}.viewLess` },
  viewMore: { id: `${scope}.viewMore` },
  warning: { id: `${scope}.warning` },
})
