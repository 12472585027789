import PropTypes from 'prop-types'
import { FormText } from 'reactstrap'
import { AvRadioGroup, AvRadio } from 'availity-reactstrap-validation'
import styled from 'styled-components'

const StyledGroup = styled(AvRadioGroup)`
  margin: 20px 0;

  legend {
    font-family: Nunito;
    font-size: 16px;
    font-weight: 600;
    line-height: 1.5;
    margin-bottom: 0;
  }

  label {
    padding-left: 7px;
  }

  .form-text {
    font-size: 12px;
    margin-top: -5px;
  }

  .invalid-feedback {
    margin-top: 5px;
  }

  @media (max-width: 460px) {
    .form-check {
      display: flex;
      flex-direction: row;
      margin-bottom: 10px;
    }
  }
`

const BasicRadioButtons = ({
  error,
  helpMessage,
  inline,
  label,
  name,
  partIndex,
  onChange,
  radioButtons,
  required,
  value,
}) => {
  const partName = partIndex ? `${name}-${partIndex}` : name

  return (
    <StyledGroup
      value={value}
      id={partName}
      name={partName}
      label={label}
      required={required}
      errorMessage={error}
      onChange={onChange}
      inline={inline}
    >
      {helpMessage && <FormText>{helpMessage}</FormText>}

      {radioButtons.map((rb, i) => (
        <AvRadio key={`rb-${i}`} label={rb.label} value={rb.value} disabled={rb.disabled} />
      ))}
    </StyledGroup>
  )
}

BasicRadioButtons.defaultProps = {
  inline: false,
  name: '',
  onChange: () => {},
}

BasicRadioButtons.propTypes = {
  error: PropTypes.string,
  helpMessage: PropTypes.string,
  inline: PropTypes.bool,
  label: PropTypes.string,
  name: PropTypes.string,
  onChange: PropTypes.func,
  partIndex: PropTypes.number,
  radioButtons: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string,
      label: PropTypes.string,
      disabled: PropTypes.bool,
    })
  ),
  required: PropTypes.bool,
  value: PropTypes.any,
}

export default BasicRadioButtons
