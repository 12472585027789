import rsFirebase from '../../services/rsf'
import { call, put } from 'redux-saga/effects'
import firebase from 'firebase/app';
import 'firebase/auth';
import { LAST_APP_VISITED } from "../../utils/consts";
import { checkAccessDates } from "../../utils/share";

export function* getUserList(userId, appId) {
  let users = [];
  if (!userId) userId = yield call(getUserId);
  const dbUser = yield call(rsFirebase.firestore.getDocument, `/users/${userId}/`);
  const isSuperAdmin = dbUser.data().superadmin;

  if (userId && appId) {
    const dbUsers = yield call(rsFirebase.firestore.getCollection, 'users');
    for (let userDoc of dbUsers.docs) {
      let userData = userDoc.data();
      userData = { ...userData, id: userDoc.id};
      let userApps = []

      if(userData.apps) {
        userData.apps.forEach((app) => {
          if(app !== undefined && app !== null && app !== "" && typeof app === "string") {
            userApps.push(app)
          } else if(app !== undefined && app !== null && typeof app === "object"){
            userApps.push(app.appId || app)
          }
        })
      }

      if (isSuperAdmin || (!isSuperAdmin && !userData.superadmin && userApps.includes(appId))) {
        users.push(userData);
      }
    }
  }
  return users;
}

export function* getUserProfile(userId) {
  try {
    const snapshot = yield call(rsFirebase.firestore.getDocument, `/users/${userId}/`);
    const userProfile = snapshot.data();

    const lastAppVisited = localStorage.getItem(LAST_APP_VISITED);
    let appId;
    if(userProfile.apps.length !== 0) {
      if (userProfile.apps.some(app => app.appId === lastAppVisited && !app.disable)) {
        appId = lastAppVisited;
      } else {
        appId = userProfile.apps[0].appId;
      }
    }

    userProfile.apps = userProfile.apps || [];

    if (userProfile.apps.length > 0) {
      const app = userProfile.apps.find(app => app.appId === appId) || userProfile.apps[0];

      if (app.roles && app.roles.length > 0) {
        const firstRole = app.roles[0];
        userProfile.role = firstRole.role;
        const role = app.roles.find(role => role.role === userProfile.role);

        if (role) {
          userProfile.access = role.access;
        }
      }
    }

    yield put({ type: 'MANAGE_SESSION_SET_PROFILE', userProfile });
    return userProfile;
  } catch (error) {
    yield put({ type: 'MANAGE_SESSION_ERROR', error })
  }
}

export function* getUserActiveApps(userId) {
  try {
    const snapshotUser = yield call(rsFirebase.firestore.getDocument, `users/${userId}`);
    let userData = snapshotUser.data();

    const userAppsList = [];
    userData.apps.map((app) => userAppsList.push(app));

    const userAppsFiltered = userAppsList.filter(app => {
      return app.roles.every(role => {
        return !role.disable && checkAccessDates(role);
      });
    });

    if(userAppsFiltered.length === 0) {
      return []
    }

    if (Array.isArray(userData.apps)) {
      let appsObject = {};
      userData.apps.forEach(appId => {
        appsObject[appId] = {};
      });
      userData.apps = appsObject;
    }

    if (Object.keys(userData.apps).length === 0) {
      return [];
    }

    const snapshot = yield call(rsFirebase.firestore.getCollection, 'appdata');
    let disabledApps = [];
    snapshot.forEach((data) => {
      if (data.data().disabled === true) disabledApps.push(data.id);
    });

    // Assuming userApps is now always an object
    let userApps = userData.apps;

    if (typeof userApps !== 'undefined') {
      // Filter out disabled apps
      let activeApps = Object.keys(userApps).reduce((acc, appId) => {
        if (!disabledApps.includes(appId)) {
          acc[appId] = {};
        }
        return acc;
      }, {});

      // Check if activeApps is empty
      if (Object.keys(activeApps).length === 0) {
        return "All apps are disabled";
      }

      return activeApps;
    }

    return "Something went wrong";

  } catch (error) {
    yield put({ type: 'MANAGE_SESSION_ERROR', error });
  }
}

export function getUserId() {
  const user = firebase.auth().currentUser;
  return user ? user.uid : null;
}