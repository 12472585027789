import { defineMessages } from 'react-intl'

const scope = 'app.components.ThemeModal'

export default defineMessages({
  cancel: { id: `${scope}.cancel` },
  modalText: { id: `${scope}.modalText` },
  modalTitle: { id: `${scope}.modalTitle` },
  name: { id: `${scope}.name` },
  nameError: { id: `${scope}.nameError` },
  nameHelp: { id: `${scope}.nameHelp` },
  save: { id: `${scope}.save` },
  search: { id: `${scope}.search` },
  themes: { id: `${scope}.themes` },
  value: { id: `${scope}.value` },
  valueError: { id: `${scope}.valueError` },
  valueHelp: { id: `${scope}.valueHelp` },
})
