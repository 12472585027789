import { createSagas } from 'redux-box'
import { put, call } from 'redux-saga/effects'
import rsFirebase from 'services/rsf'

export default createSagas({
  *CARDLIST_REQUEST_LIST({ appId }) {
    try {
      yield put({ type: 'CARDLIST_FETCHING' })
      const cardListSnap = yield call(rsFirebase.firestore.getCollection, `appdata/${appId}/cards`)
      const cardList = {}
      cardListSnap.forEach((card) => {
        cardList[card.id] = card.data()
      })
      const cardsListArray = Object.values(cardList)

      yield put({ type: 'CARDLIST_SET', list: cardsListArray })
    } catch (error) {
      yield put({ type: 'CARDLIST_FETCHING_ERROR' })
    }
  },
})
