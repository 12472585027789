import PropTypes from 'prop-types'
import { BasicTextArea, PartOptions } from 'components'
import { useIntl } from 'react-intl'
import { parseHtmlTag } from 'utils'

import messages from './messages'

const youtubeDomains = [
  'googlevideo.com',
  'gvt1.com',
  'video.google.com',
  'youtu.be',
  'youtube-nocookie.com',
  'youtube-ui.l.google.com',
  'youtube.com',
  'youtube.googleapis.com',
  'youtubeeducation.com',
  'youtubei.googleapis.com',
  'yt3.ggpht.com',
  'ytimg.com',
]

// the time start value is handled by react player
const YoutubeForm = ({ onChange, part, partIndex, onFormChange }) => {
  const intl = useIntl()

  const handleUrlChange = (e) => {
    if (e.target.value.includes('<iframe')) {
      const data = parseHtmlTag(e.target.value)
      onChange(data, partIndex)
    }

    onChange(e, partIndex)
  }

  const getIsLinkYoutube = (value) => {
    let isYoutube = false
    Object.values(youtubeDomains).forEach((domain) => {
      if (value.includes(domain)) {
        isYoutube = true
      }
    })
    return isYoutube
  }

  const getIsSingleLink = (value) => {
    const links = []
    Object.values(youtubeDomains).forEach((domain) => {
      if (value.includes(domain)) {
        links.push(domain)
      }
    })

    return links.length <= 1
  }

  const getIsLinkValid = (value) => {
    const splitOnNewLine = value.split(/\s+/).length <= 1
    const splitOnProtocol = value.split('https://').length === 2
    const startsWithHttps = value.startsWith('https://');

    let isLinkValid = !!(splitOnNewLine && splitOnProtocol && startsWithHttps)

    try {
      new URL(value)
    } catch (e) {
      isLinkValid = false
    }

    return isLinkValid
  }

  const handleValidation = (value) => {
    const isIframe = (value.match(/iframe/g) || []).length
    const isSingleLink = getIsSingleLink(value)

    // iframe validation
    if (isIframe > 0 && isSingleLink) {
      const hasLink = value.indexOf('src="https://www.youtube.com') >= 0
      const isSingleIframe = isIframe === 2
      return hasLink && isSingleIframe
    }

    // link validation
    if (isIframe <= 0 && isSingleLink) {
      const isLinkYoutube = getIsLinkYoutube(value)
      const isLinkValid = getIsLinkValid(value)
      return isLinkYoutube && isLinkValid
    }

    return false
  }

  return (
    <>
      <PartOptions partIndex={partIndex} onChange={onChange} values={part.options} onFormChange={onFormChange} />

      <BasicTextArea
        name="url"
        label={intl.formatMessage(messages.youtube)}
        helpMessage={intl.formatMessage(messages.youtubeHelp)}
        error={intl.formatMessage(messages.youtubeError)}
        onChange={(e) => {
          handleUrlChange(e);
          onFormChange(true);
        }}
        partIndex={partIndex}
        value={part.url}
        size="small"
        required
        validation={{
          async: (value) => handleValidation(value),
        }}
      />

      <BasicTextArea
        label={intl.formatMessage(messages.legend)}
        helpMessage={intl.formatMessage(messages.legendHelp)}
        name="legend"
        partIndex={partIndex}
        value={part.legend || ''}
        onChange={(e) => {
          onChange(e, partIndex);
          onFormChange(true);
        }}
        size="xsmall"
      />
    </>
  )
}

YoutubeForm.propTypes = {
  onChange: PropTypes.func,
  part: PropTypes.object,
  partIndex: PropTypes.number,
  onFormChange: PropTypes.func,
}

export default YoutubeForm
