import sortBy from 'lodash/sortBy'

// getList is used to get the list of any module type
const getList = (state, module, property = ['name', 'id']) =>
  sortBy(Object.values(state[module].list), property)

const getListById = ({ list }, id) => Object.values(list.list).find((l) => l.id === id)

const getListItemById = ({ listitem }, id) => Object.values(listitem.list).find((i) => i.id === id)

const getTriggerById = ({ trigger }, id) => Object.values(trigger.list).find((t) => t.id === id)

const getInputById = ({ input }, id) => Object.values(input.list).find((i) => i.id === id)

const getVoxpopById = ({ voxpop }, id) => Object.values(voxpop.list).find((p) => p.id === id)

const getModuleByName = (state, module, name) =>
  Object.values(state[module].list).filter((p) => p.name === name) // might have many

const getItemInListById = (state, id) => {
  const res = []
  // eslint-disable-next-line array-callback-return
  Object.values(state.list.list).map((list) => {
    if (list.children && Object.values(list.children).find((child) => child.id === id)) {
      res.push(list)
    }
  })
  return res
}

const getItemByCardId = (state, id) => {
  return Object.values(state.listitem.list).find((item) => item.cardId === id)
}

const selectors = {
  isFetching: (state) => (moduleName) => state[moduleName].fetching,
  getList: (state) => (module, property) => getList(state, module, property),
  getListById: (state) => (id) => getListById(state, id),
  getListItemById: (state) => (id) => getListItemById(state, id),
  getItemInListById: (state) => (id) => getItemInListById(state, id),
  getItemByCardId: (state) => (id) => getItemByCardId(state, id),
  getTriggerById: (state) => (id) => getTriggerById(state, id),
  getInputById: (state) => (id) => getInputById(state, id),
  getModuleByName: (state) => (module, name) => getModuleByName(state, module, name),
  getVoxpopById: (state) => (id) => getVoxpopById(state, id),
}

export default selectors
