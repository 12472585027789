import moment from 'moment-timezone'
import DEFAULTS from '../defaults'

const { dbDateFormat } = DEFAULTS

export const valuesToTzRange = (values, defaultTimeZone) => {
  if (!values || !values.range || !Array.isArray(values.range)) {
    return [defaultTimeZone, defaultTimeZone]
  }

  const tz = []
  tz[0] = (values.range[0] && values.range[0].timezone) || defaultTimeZone
  tz[1] = tz[0]

  if (values.range[1] && values.range[1].timezone) {
    tz[1] = values.range[1].timezone
  }

  return tz
}

export const valuesToDateRange = (values) => {
  if (!values || !values.range || !Array.isArray(values.range) || !values.range[0]) {
    return [null, null]
  }

  const date0 = values.range[0].date
  const date1 = (values.range[1] && values.range[1].date) || null

  const dateRange = []

  dateRange[0] = date0 ? moment(date0, dbDateFormat) : date0
  dateRange[1] = date1 ? moment(date1, dbDateFormat) : date1

  return dateRange
}

export const getDates = (values) => {
  const date0 = values[0].date || null
  const date1 = (values[1] && values[1].date) || null

  const dateRange = []

  dateRange[0] = date0 ? moment(date0, dbDateFormat) : date0
  dateRange[1] = date1 ? moment(date1, dbDateFormat) : date1

  return dateRange
}

export const getRepeatOptions = (values) => {
  const options = []
  const range = getDates(values)

  if (!!range[0] && !!range[1]) {
    // range not greater than a day
    if (range[0].isSame(range[1], 'day')) {
      options.push('daily')
    }

    // range not greater than a week
    if (Math.abs(range[0].diff(range[1], 'days')) <= 6) {
      options.push('weekly')
    }

    // range not greater than a month
    if (Math.abs(range[0].diff(range[1], 'month')) === 0) {
      options.push('monthlyDay')
    }

    // range not greater than a year
    if (Math.abs(range[0].diff(range[1], 'year')) === 0) {
      options.push('annuallyDay')
    }

    // range not greater than a day
    if (range[0].isSame(range[1], 'day')) {
      // not the weekend
      if (range[0].format('d') !== '0' && range[0].format('d') !== '6') {
        options.push('weekdays')
      }

      // not a day of the week
      if (range[0].format('d') === '0' || range[0].format('d') === '6') {
        options.push('weekends')
      }
    }
  }

  return options
}
