import PropTypes from 'prop-types'
import { useState, useEffect } from 'react'
import { useIntl } from 'react-intl'
import { AvForm } from 'availity-reactstrap-validation'
import { LayoutCard } from 'components'
import { scrollToError } from 'utils'

import { connectStore } from 'redux-box'
import { storeModule as ModuleModules } from 'store/modules'

import messages from '../messages'
import PageActions from '../PageActions'
import ErrorFrame from './ErrorFrame'
import GeneralInfo from './GeneralInfo'
import PhotoboothFrame from './PhotoboothFrame'
import SavePhotoFrame from './SavePhotoFrame'
import ShareFrame from './ShareFrame'
import WelcomeFrame from './WelcomeFrame'

const PhotoboothEditForm = ({ appId, modules, data, onCancel, onSave, onDelete, isNew }) => {
  const [termsLanguage, setTermsLanguage] = useState('fr')
  const [pos, setPos] = useState((data && data.part && data.part.watermarkPosition) || 8)

  const intl = useIntl()

  useEffect(() => {
    modules.resetPhotoboothStatus()
  }, [])

  useEffect(() => {
    if (modules.voxpop.success || modules.voxpop.error) {
      setTimeout(() => modules.resetStatus('voxpop'), 5000)
    }
  }, [modules.voxpop.success, modules.voxpop.error])

  const handleLangChange = (e) => {
    setTermsLanguage(e.target.value)
  }

  const handleOnSubmit = (e, errors, values) => {
    if (errors.length) {
      scrollToError()
      return false
    }
    values.part.watermarkPosition = pos
    modules.savePhotobooth(appId, data.id, values, onSave)
  }

  return (
    <AvForm onSubmit={handleOnSubmit} model={data}>
      <fieldset disabled={modules.voxpop.saving}>
        <LayoutCard
          title={isNew ? intl.formatMessage(messages.newPhotobooth) : intl.formatMessage(messages.edit)}
          headerChildren={
            <PageActions
              onCancel={onCancel}
              onDelete={onDelete}
              loading={modules.voxpop.saving}
              isNew={isNew}
            />
          }
          footerChildren={
            <PageActions
              onCancel={onCancel}
              onDelete={onDelete}
              loading={modules.voxpop.saving}
              isNew={isNew}
            />
          }
        >
          <GeneralInfo
            handleLangChange={handleLangChange}
            photobooth={data.part}
            getModuleByName={modules.getModuleByName}
            id={data.id}
          />

          <hr />

          <WelcomeFrame photobooth={data.part} />

          <hr />

          <ErrorFrame />

          <hr />

          <PhotoboothFrame photobooth={data.part} onPosChange={(value) => setPos(value)} pos={pos} />

          <hr />

          <SavePhotoFrame termsLanguage={termsLanguage} />

          <hr />

          <ShareFrame photobooth={data.part} />
        </LayoutCard>
      </fieldset>
    </AvForm>
  )
}

PhotoboothEditForm.defaultProps = {
  data: { part: { name: '' } },
}

PhotoboothEditForm.propTypes = {
  appId: PropTypes.string,
  data: PropTypes.object,
  isNew: PropTypes.bool,
  onCancel: PropTypes.func,
  onSave: PropTypes.func,
  modules: PropTypes.object,
  onDelete: PropTypes.func,
}

export default connectStore({ modules: ModuleModules })(PhotoboothEditForm)
