import styled from 'styled-components'
import BasicInput from 'components/BasicInput'

export const StyledContainer = styled.div`
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  width: 100%;
`

export const StyledRow = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
  margin-bottom: 0;
  width: 100%;
  position: relative;

  &:first-of-type {
    align-items: center;
    span.optionNum {
      position: relative;
      top: 14px;
    }

    button {
      position: relative;
      top: 14px;
    }
  }

  &:last-of-type {
    align-items: center;
  }

  div {
    min-width: 44%;
  }

  div:first-of-type {
    margin: 5px 10px 5px 0px !important;
  }

  div:last-of-type {
    margin: 5px 5px 5px 0px !important;
  }

  label {
    margin-top: 10px;
  }

  span {
    width: 1rem;
    margin-right: 0.5rem;
    word-break: keep-all;
  }

  input {
    margin: 5px 10px 5px 0px;
    font-size: 13px;
  }
`

export const StyledInput = styled.div`
  display: flex;
  align-items: flex-start;
  flex-direction: column;
`

export const StyledBasicInput = styled(BasicInput)`
  margin-top: 0;
  margin-bottom: 0;
  &.form-group {
    color: orange;
    max-width: 44%;
  }
`
