const actions = {
  getLockList: (appId) => ({ type: 'LOCK_GET_LIST', appId }),
  deleteLock: (id, appId) => ({ type: 'LOCK_DELETE', id, appId }),
  saveLock: (appId, cardId, lock, onSave) => ({
    type: 'LOCK_SAVE',
    appId,
    cardId,
    lock,
    onSave,
  }),
  resetStatus: () => ({ type: 'LOCK_STATUS_RESET' }),
}

export default actions
