import { defineMessages } from 'react-intl'

const scope = 'app.components.PartActions.AudioForm'

export default defineMessages({
  audio: { id: `${scope}.audio` },
  audioHelp: { id: `${scope}.audioHelp` },
  fileTypeError: { id: `${scope}.fileTypeError` },
  legend: { id: `${scope}.legend` },
  legendHelp: { id: `${scope}.legendHelp` },
  required: { id: `${scope}.required` },
})
