import state from './state'
import actions from './actions'
import sagas from './sagas'
import mutations from './mutations'
import selectors from './selectors'

export const storeModule = {
  name: 'cardList',
  state,
  actions,
  mutations,
  sagas,
  selectors,
}
