import PropTypes from 'prop-types'
import { useIntl } from 'react-intl'
import { BasicInfo, BasicInput, PartOptions, BasicTextArea } from 'components'
import { validationsPatterns } from 'utils'

import messages from './messages'

const RedirectForm = ({ onChange, part, partIndex, onFormChange }) => {
  const intl = useIntl()

  return (
    <>
      <PartOptions partIndex={partIndex} onChange={onChange} values={part.options} onFormChange={onFormChange} />

      <BasicInput
        name="url"
        partIndex={partIndex}
        label={intl.formatMessage(messages.url)}
        helpMessage={intl.formatMessage(messages.urlHelp)}
        value={part.url}
        onChange={(e) => {
          onChange(e, partIndex);
          onFormChange(true);
        }}
        required
        error={intl.formatMessage(messages.urlError)}
        validation={{ pattern: validationsPatterns.urlExtended }}
      />

      <BasicInfo text={intl.formatMessage(messages.info)} />

      <BasicTextArea
        name="notes"
        partIndex={partIndex}
        label={intl.formatMessage(messages.notes)}
        helpMessage={intl.formatMessage(messages.notesHelp)}
        value={part.notes || ''}
        onChange={(e) => {
          onChange(e, partIndex);
          onFormChange(true);
        }}
        size="xsmall"
      />
    </>
  )
}

RedirectForm.propTypes = {
  onChange: PropTypes.func,
  part: PropTypes.object,
  partIndex: PropTypes.number,
  onFormChange: PropTypes.func,
}

export default RedirectForm
