import { defineMessages } from 'react-intl'

const scope = 'app.components.CardModals'

export default defineMessages({
  adminDelete: { id: `${scope}.adminDelete` },
  cancel: { id: `${scope}.cancel` },
  cancelInfo: { id: `${scope}.cancelInfo` },
  cancelTitle: { id: `${scope}.cancelTitle` },
  close: { id: `${scope}.close` },
  confirm: { id: `${scope}.confirm` },
  deleteInfo: { id: `${scope}.deleteInfo` },
  deleteTitle: { id: `${scope}.deleteTitle` },
  itemTitle: { id: `${scope}.itemTitle` },
  itemInfo: { id: `${scope}.itemInfo` },
  itemPage: { id: `${scope}.itemPage` },
  fbsTitle: { id: `${scope}.fbsTitle` },
  fbsInfo: { id: `${scope}.fbsInfo` },
  resetInfo: { id: `${scope}.resetInfo` },
  resetTitle: { id: `${scope}.resetTitle` },
})
