import PropTypes from 'prop-types'
import { Route, Redirect } from 'react-router-dom'
import RTE from 'utils/routes'
import { storeModule as AppModule } from 'store/app'
import { connectStore } from 'redux-box'
import { storeModule as SessionModule } from 'store/manageUsers'
import { checkGlobalAccess } from '../../services/utils'
import { useEffect, useState } from "react";

function PrivateRoute({ component: Component, destination, session, app, ...rest }) {
  const disabledUser = session.loggedIn && session.profile.disabled && session.profile.email.length > 0
  const [roles, setRoles] = useState([]);
  const [appId, setAppId] = useState('');

  if(disabledUser) session.logout()

  useEffect(() => {
    if (session.profile.apps && app.id) {
      const userApps = session.profile.apps
      setRoles(userApps);
      setAppId(app.id)
    }
  }, [session.profile.apps, app.id]);

  if(roles && roles.length > 0 && appId !== '' && !checkGlobalAccess(appId, session.profile.superadmin, roles, destination)) {
    session.logout()
  }

  return !session.loggedIn || disabledUser ? (
    <Route
      {...rest}
      render={() => {
        return <Redirect to={{ pathname: RTE.LOGIN.path }} />
      }}
    />
  ) : (
    <Route
      {...rest}
      render={(props) => {
        return <Component {...props} />
      }}
    />
  )
}

PrivateRoute.propTypes = {
  component: PropTypes.func,
  session: PropTypes.object,
  app: PropTypes.object,
  destination: PropTypes.string,
}

export default connectStore({ session: SessionModule, app: AppModule })(PrivateRoute)
