import { defineMessages } from 'react-intl'

const scope = 'app.components.CardFilters'

export default defineMessages({
  addUnlinkedCard: { id: `${scope}.addUnlinkedCard` },
  all: { id: `${scope}.all` },
  collapse: { id: `${scope}.collapse` },
  expand: { id: `${scope}.expand` },
  linkedCards: { id: `${scope}.linkedCards` },
  show: { id: `${scope}.show` },
  unlinkedCards: { id: `${scope}.unlinkedCards` },
  view: { id: `${scope}.view` },
})
