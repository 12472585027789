import styled from 'styled-components'
import { AvForm } from 'availity-reactstrap-validation'

const StyledForm = styled(AvForm)`
  legend {
    font-size: 1rem;
    margin-top: 1.2rem;
    font-weight: bold;
  }

  input[type='checkbox'] + label,
  input[type='radio'] + label {
    padding-left: 7px;
  }

  label + .form-text {
    margin-top: -5px;
    margin-bottom: 5px;
  }

  .ant-picker {
    margin-top: 0.9em;
    border-radius: 8px;
    max-height: 48px;
    height: 48px;

    .anticon-swap-right {
      margin-top: -2px;
    }
  }
`

const BasicForm = ({ ...props }) => <StyledForm {...props} />

export default BasicForm
