import styled from 'styled-components'
import { Modal, ModalHeader, ModalFooter } from 'reactstrap'

const getSize = (size) => {
  switch (size) {
    case 'small':
      return '500px'
    case 'medium':
      return '650px'
    case 'xlarge':
      return '80%'
    default:
      return '800px'
  }
}

export const StyledModal = styled(Modal)`
  .modal-content {
    padding: 30px 20px;
    border-radius: 8px;
    width: 80vw;
    max-width: ${({ size }) => getSize(size)};

    &::before {
      content: ${({ $share }) => $share ? '""' : 'none'};
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      border-top-right-radius: 8px;
      border-top-left-radius: 8px;
      height: 200px;
      background: linear-gradient(to bottom, rgba(255,210,50, 0.5), white);
      z-index: 0;
    }
  }

  .modal-body {
    padding: 0;
    white-space: break-spaces;
  }

  .close {
    display: none;
  }
`

export const StyledModalTitle = styled(ModalHeader)`
  //margin-bottom: 20px;
  margin-bottom: 50px;
  border-bottom: none;
  padding: 0;

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-size: 26px;
    font-weight: 600;
    line-height: 34px;
    color: #03162c;
    word-break: break-word;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }
`

export const StyledModalFooter = styled(ModalFooter)`
  border-top: none;
  padding-bottom: 0;
`

export const StyledHeader = styled.div`
  font-family: ${({ $share }) => $share ? 'Nunito, serif' : ''};
  display: flex;
  justify-content: ${({ title }) => (title === 'true' ? 'space-between' : 'flex-end')};
  width: 100%;
  align-items: baseline;
  margin-bottom: ${({ title }) => (title === 'true' ? '2px' : '20px')};
  z-index: 1;
`
