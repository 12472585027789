import PropTypes from 'prop-types'
import styled from 'styled-components'

const StyledPastille = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  height: 50px;
  width: 50px;
  border: 2px solid #ef466f;
  border-radius: 100%;

  span {
    font-family: Poppins, sans-serif;
    font-size: 20px;
    font-weight: 600;
    color: #03162c;
    text-transform: uppercase;
  }
`

const Pastille = ({ number, large }) => (
  <StyledPastille large={String(large)}>
    <span>{number}</span>
  </StyledPastille>
)

Pastille.defaultProps = {
  number: '',
}

Pastille.propTypes = {
  number: PropTypes.any,
}

export default Pastille
