import PropTypes from 'prop-types'
import { BasicInput } from 'components'
import { useIntl } from 'react-intl'
import messages from './messages'
import { StyledButtonHalf, StyledButtonLabel, StyledLegend } from './styles'

/*
  borderRadius: '50', // 0, 10, 25, [50]
  borderWidth: '3', // 0, 1, 2, [3]
  nbrPerCol: '2', // [2], 3, 4
*/

const ButtonStyleFields = ({ onChange, style, url }) => {
  const { borderRadius, borderWidth, nbrPerCol } = style
  const intl = useIntl()

  return (
    <>
      <StyledLegend>{intl.formatMessage(messages.buttonStyle)}</StyledLegend>

      <div style={{ width: '100%', display: 'flex', alignItems: 'center', flexDirection: 'column' }}>
        <StyledButtonHalf borderWidth={borderWidth || '3'} borderRadius={borderRadius || '50'} url={url} />
        <StyledButtonLabel>Demo</StyledButtonLabel>
      </div>

      <BasicInput
        type="select"
        onChange={onChange}
        label={intl.formatMessage(messages.BorderRadiusLabel)}
        helpMessage={intl.formatMessage(messages.BorderRadiusHelp)}
        name="style.borderRadius"
        value={borderRadius || '50'}
      >
        <option value="0">{intl.formatMessage(messages.BorderRadius0)}</option>
        <option value="10">{intl.formatMessage(messages.BorderRadius10)}</option>
        <option value="25">{intl.formatMessage(messages.BorderRadius25)}</option>
        <option value="50">{intl.formatMessage(messages.BorderRadius50)}</option>
      </BasicInput>

      <BasicInput
        type="select"
        onChange={onChange}
        label={intl.formatMessage(messages.BorderWidthLabel)}
        helpMessage={intl.formatMessage(messages.BorderWidthHelp)}
        name="style.borderWidth"
        value={borderWidth || '3'}
      >
        <option value="0">{intl.formatMessage(messages.BorderWidth0)}</option>
        <option value="1">{intl.formatMessage(messages.BorderWidth1)}</option>
        <option value="2">{intl.formatMessage(messages.BorderWidth2)}</option>
        <option value="3">{intl.formatMessage(messages.BorderWidth3)}</option>
      </BasicInput>

      <BasicInput
        type="select"
        onChange={onChange}
        label={intl.formatMessage(messages.NbrPerColLabel)}
        helpMessage={intl.formatMessage(messages.NbrPerColHelp)}
        name="style.nbrPerCol"
        value={nbrPerCol || '2'}
      >
        <option value="2">{intl.formatMessage(messages.NbrPerCol2)}</option>
        <option value="3">{intl.formatMessage(messages.NbrPerCol3)}</option>
        <option value="4">{intl.formatMessage(messages.NbrPerCol4)}</option>
      </BasicInput>
    </>
  )
}

ButtonStyleFields.defaultProps = {
  style: {},
  index: '',
  onChange: () => {},
}

ButtonStyleFields.propTypes = {
  style: PropTypes.object,
  onChange: PropTypes.func,
  url: PropTypes.string,
}

export default ButtonStyleFields
