import PropTypes from 'prop-types'
import { useIntl } from 'react-intl'
import topPosition from 'assets/img/language-link-position-top.png'
import bottomPosition from 'assets/img/language-link-position-bottom.png'

import {
  BasicInput,
  BasicWarning,
  BasicButton,
  BasicInfo,
  BasicRadioButtonsImage,
  BasicCheckbox
} from 'components'
import { newEvent, validationsPatterns } from 'utils'

import { Icon } from 'react-icons-kit'
import { chevronUp } from 'react-icons-kit/feather/chevronUp'
import { chevronDown } from 'react-icons-kit/feather/chevronDown'
import { externalLink } from "react-icons-kit/feather";

import messages from './messages'
import {
  StyledCollapse,
  StyledLegend,
  StyledCollapseDiv,
  StyledLangLabelContainer,
  StyledLangSquareContainer,
  StyledLangCircleContainer
} from "./styles";
import { useEffect, useState } from "react";

const LanguageLinkForm = ({ onChange, partIndex, part, onFormChange }) => {
  const intl = useIntl()
  const [openPosition, setOpenPosition] = useState(false)
  const [openAppearance, setOpenAppearance] = useState(false)
  const [languageLinkPosition, setLanguageLinkPosition] = useState(part.languageLinkPosition || 'top')
  const [languageLinkApperance, setLanguageLinkApperance] = useState(part.languageLinkApperance || 'label')
  const [languageLinkApperanceOutlined, setLanguageLinkApperanceOutlined] = useState(part.languageLinkApperanceOutlined || false)
  const [languageLinkEmojiChecked, setLanguageLinkEmojiChecked] = useState(part.languageLinkEmoji || false);

  useEffect(() => {
    onChange(newEvent('languageLinkPosition', languageLinkPosition), partIndex)
    onChange(newEvent('languageLinkApperance', languageLinkApperance), partIndex)
    onChange(newEvent('languageLinkApperanceOutlined', languageLinkApperanceOutlined), partIndex)
  }, [part, languageLinkPosition, languageLinkApperance, languageLinkApperanceOutlined, onChange, partIndex])

  const handlePositionChange = (e) => {
    setLanguageLinkPosition(e.target.value)
    onChange(newEvent('languageLinkPosition', e.target.value), partIndex)
    onFormChange(true);
  }

  const handleApperanceChange = (e) => {
    setLanguageLinkApperance(e.target.value)
    onChange(newEvent('languageLinkApperance', e.target.value), partIndex)
    onFormChange(true);
  }

  const handleApperanceOutlinedChange = (e) => {
    setLanguageLinkApperanceOutlined(e.target.checked)
    onChange(newEvent('languageLinkApperanceOutlined', e.target.checked), partIndex)
    onFormChange(true);
  }

  const handleLanguageLinkEmojiChange = (e) => {
    setLanguageLinkEmojiChecked(e.target.checked);
    onChange(newEvent('languageLinkEmoji', e.target.checked), partIndex);
    onFormChange(true);
  };

  return (
    <>
      <BasicInfo
        text={intl.formatMessage(messages.infos)}
        children={
          <BasicButton
            label={intl.formatMessage(messages.tutorial)}
            icon={<Icon icon={externalLink} />}
            isLink
            href={'#'/*rteAppId('#', 'TUTORIAL')*/}
            target="_blank"
            linkstyle
            small
          />
        }
      />
      <hr/>
      <StyledCollapse onClick={() => setOpenPosition(!openPosition)}>
        <StyledLegend collapsable="true">
          {intl.formatMessage(messages.languageLinkPosition)}
        </StyledLegend>
        <Icon icon={openPosition ? chevronUp : chevronDown} />
      </StyledCollapse>
      <StyledCollapseDiv open={openPosition}>
        <BasicRadioButtonsImage
          name="languageLinkPosition"
          partIndex={partIndex}
          onChange={handlePositionChange}
          value={languageLinkPosition}
          helpMessage={intl.formatMessage(messages.languageLinkPositionInfo)}
          radioButtons={[
            {
              label: intl.formatMessage(messages.left),
              value: 'top',
              image: <img src={topPosition} alt={`Option left`} style={{ display: 'block', marginBottom: '10px', width: '250px' }} />,
              width: '250px',
              disabled: false
            },
            {
              label: intl.formatMessage(messages.center),
              value: 'bottom',
              image: <img src={bottomPosition} alt={`Option left`} style={{ display: 'block', marginBottom: '10px', width: '250px' }} />,
              width: '250px',
              disabled: false
            },
          ]}
          required
        />
      </StyledCollapseDiv>

      <StyledCollapse onClick={() => setOpenAppearance(!openAppearance)}>
        <StyledLegend collapsable="true">
          {intl.formatMessage(messages.languageLinkApperance)}
        </StyledLegend>
        <Icon icon={openAppearance ? chevronUp : chevronDown} />
      </StyledCollapse>
      <StyledCollapseDiv open={openAppearance}>
        <BasicRadioButtonsImage
          name="languageLinkApperance"
          partIndex={partIndex}
          onChange={handleApperanceChange}
          value={languageLinkApperance}
          helpMessage={intl.formatMessage(messages.languageLinkApperanceInfo)}
          radioButtons={[
            {
              label: intl.formatMessage(messages.left),
              value: 'label',
              image: <StyledLangLabelContainer outlined={languageLinkApperanceOutlined}><span>XX</span></StyledLangLabelContainer>,
              width: '50px',
              disabled: false
            },
            {
              label: intl.formatMessage(messages.center),
              value: 'square',
              image: <StyledLangSquareContainer outlined={languageLinkApperanceOutlined}><span>XX</span></StyledLangSquareContainer>,
              width: '50px',
              disabled: false
            },
            {
              label: intl.formatMessage(messages.center),
              value: 'circle',
              image: <StyledLangCircleContainer outlined={languageLinkApperanceOutlined}><span>XX</span></StyledLangCircleContainer>,
              width: '50px',
              disabled: false
            },
          ]}
          required
        />

        <BasicCheckbox
          label={intl.formatMessage(messages.languageLinkApperanceOutlined)}
          name="languageLinkApperanceOutlined"
          value={part.languageLinkApperanceOutlined}
          helpMessage={intl.formatMessage(messages.languageLinkApperanceOutlinedHelp)}
          onChange={handleApperanceOutlinedChange}
        />
      </StyledCollapseDiv>

      <BasicInput
        name="languageAbbr"
        partIndex={partIndex}
        label={intl.formatMessage(messages.abbreviation)}
        helpMessage={intl.formatMessage(messages.abbreviationHelp)}
        value={part.languageAbbr || ''}
        onChange={(e) => {
          onChange(e, partIndex);
          onFormChange(true);
        }}
        validation={languageLinkEmojiChecked ? null : { pattern: { value: /^.{2}$/ } }}
        error={intl.formatMessage(messages.abbreviationError)}
        required
      />

      <BasicCheckbox
        label={intl.formatMessage(messages.languageLinkEmoji)}
        name="languageLinkEmoji"
        value={part.languageLinkEmoji}
        onChange={handleLanguageLinkEmojiChange}
      />

      <BasicInput
        name="languageLink"
        partIndex={partIndex}
        label={intl.formatMessage(messages.link)}
        helpMessage={intl.formatMessage(messages.linkHelp)}
        value={part.languageLink || ''}
        onChange={(e) => {
          onChange(e, partIndex);
          onFormChange(true);
        }}
        validation={{ pattern: validationsPatterns.urlExtended }}
        error={intl.formatMessage(messages.linkError)}
        required
      />

      <BasicWarning text={intl.formatMessage(messages.linkWarning)} />
    </>
  )
}

LanguageLinkForm.propTypes = {
  onChange: PropTypes.func,
  part: PropTypes.object,
  partIndex: PropTypes.number,
  onFormChange: PropTypes.func,
}

export default LanguageLinkForm
