import PropTypes from 'prop-types'
import { useIntl } from 'react-intl'
import styled, { css } from 'styled-components'

import DEFAULTS from './defaults'
import messages from './messages'

const StyledText = styled.div`
  color: ${({ color }) => (color === 'white' ? 'white' : '#6c757d')};
  font-size: 12px;

  ${({ inForm }) =>
    inForm &&
    css`
      margin-top: -18px;
      width: calc(100% - 40px);
    `}
`

const ScheduleResume = ({ color, values }) => {
  const intl = useIntl()

  if (!values) return ''

  const showTimezone = (tz) => (tz && tz !== DEFAULTS.timezone ? `(${tz})` : '')

  const startDate = values.range[0]
  const endDate = values.range[1]

  const formattedDate = (date) => {
    const d = values.allDay
      ? intl.formatDate(date.date, {
          weekday: 'long',
          day: 'numeric',
          month: 'long',
          year: 'numeric',
        })
      : intl.formatDate(date.date, {
          weekday: 'long',
          day: 'numeric',
          month: 'long',
          year: 'numeric',
          hour: 'numeric',
          minute: 'numeric',
        })
    return d
  }

  const showRepeat = values.repeat && values.repeat !== 'no' && values.repeat !== 'false'

  return (
    <StyledText color={color}>
      <span>{intl.formatMessage(messages.content)}&nbsp;</span>
      <b>{intl.formatMessage(values.rule === 'show' || !values.rule ? messages.shown : messages.hidden)}</b>
      <span>&nbsp;{intl.formatMessage(messages.from)}&nbsp;</span>
      <b>{formattedDate(startDate)}</b>
      {startDate.timezone && <span>&nbsp;{showTimezone(startDate.timezone)}</span>}
      {startDate.date !== endDate.date && (
        <>
          <span>&nbsp;{intl.formatMessage(messages.to)}&nbsp;</span>
          <b>{formattedDate(endDate)}</b>
          {endDate.timezone && <span>&nbsp;{showTimezone(endDate.timezone)}</span>}
        </>
      )}
      {showRepeat && (
        <span>
          {intl.formatMessage(messages.repeating)}&nbsp;
          <b>{intl.formatMessage(messages[values.repeat])}</b>.
        </span>
      )}
    </StyledText>
  )
}

ScheduleResume.defaultProps = {
  inForm: true,
}

ScheduleResume.propTypes = {
  color: PropTypes.string,
  inForm: PropTypes.bool,
  values: PropTypes.any,
}

export default ScheduleResume
