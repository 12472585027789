import styled from 'styled-components'

export const StyledLegend = styled.legend`
  text-transform: uppercase;
  color: #406cb3;
  font-weight: 600 !important;
  font-size: 14px !important;
`

export const StyledButtons = styled.div`
  display: flex;
  flex-direction: row;

  button:not(:first-of-type) {
    margin-left: 10px;
  }
`
