import PropTypes from 'prop-types'
import { useIntl } from 'react-intl'
import { BasicInput, BasicMultiSelect } from 'components'

import { connectStore } from 'redux-box'
import { storeModule as ModuleModules } from 'store/modules'
import { storeModule as SessionModule } from 'store/manageUsers'

import messages from '../messages'
import { StyledLegend } from './styles'
import PageValidation from './PageValidation'

const Inputs = ({ selectedInputs, onSelectChange, modules, session }) => {
  const intl = useIntl()

  const allInputs = modules.getList('input') || []

  return (
    <>
      <StyledLegend>{intl.formatMessage(messages.inputIds)}</StyledLegend>

      <BasicMultiSelect
        label={intl.formatMessage(messages.inputIds)}
        helpMessage={intl.formatMessage(messages.inputHelp)}
        name="inputIds"
        options={allInputs}
        optionsValue="name"
        placeholder={intl.formatMessage(messages.selectInput)}
        onChange={onSelectChange}
        selectedValues={selectedInputs}
      />

      {selectedInputs && Object.keys(selectedInputs).length > 0 && (
        <>
          <hr />
          <StyledLegend>{intl.formatMessage(messages.inputParams)}</StyledLegend>
        </>
      )}

      {selectedInputs &&
        Object.keys(selectedInputs).length > 0 &&
        Object.values(selectedInputs).map(
          (input, i) =>
            input && (
              <div key={i}>
                <BasicInput
                  name={`onTrigger.validate.inputs.${input.id}.inputLabel`}
                  label={intl.formatMessage(messages.inputLabel)}
                  helpMessage={intl.formatMessage(messages.inputLabelHelp)}
                />

                <BasicInput
                  name={`onTrigger.validate.inputs.${input.id}.errorMessage`}
                  label={`Input: ${input.name}`}
                  helpMessage={intl.formatMessage(messages.errorMessageHelp)}
                />
              </div>
            )
        )}

      {session.profile.superadmin && selectedInputs && Object.keys(selectedInputs).length > 0 && (
        <PageValidation />
      )}
    </>
  )
}

Inputs.propTypes = {
  modules: PropTypes.object,
  selectedInputs: PropTypes.array,
  selectableInputs: PropTypes.array,
}

export default connectStore({ modules: ModuleModules, session: SessionModule })(Inputs)
