import PropTypes from 'prop-types'
import { useEffect } from 'react'
import { ModalBody } from 'reactstrap'
import { BasicButton } from 'components'

import { Icon } from 'react-icons-kit'
import { x } from 'react-icons-kit/feather/x'
import { StyledModalTitle, StyledModal, StyledModalFooter, StyledHeader } from './styles'

const BasicModal = ({ toggle, isOpen, content, title, actions, size, onKeyDown, share = false }) => {
  useEffect(() => {
    document.addEventListener('keydown', onKeyDown)

    return () => {
      document.removeEventListener('keydown', onKeyDown)
    }
  }, [])

  return (
    <StyledModal isOpen={isOpen} toggle={toggle} size={size} $share={share}>
      <StyledHeader title={String(!!title)} $share={share}>
        <StyledModalTitle toggle={toggle}>{title}</StyledModalTitle>
        <BasicButton outlined onClick={toggle} round icon={<Icon icon={x} />} />
      </StyledHeader>

      <ModalBody>{content}</ModalBody>

      {actions && <StyledModalFooter>{actions}</StyledModalFooter>}
    </StyledModal>
  )
}

BasicModal.propTypes = {
  actions: PropTypes.node,
  content: PropTypes.node,
  isOpen: PropTypes.bool,
  onKeyDown: PropTypes.func,
  size: PropTypes.string,
  title: PropTypes.string,
  toggle: PropTypes.func,
  share: PropTypes.bool,
}

export default BasicModal
