import PropTypes from 'prop-types'
import { BasicTextArea, PartOptions, BasicInfo, BasicButton } from 'components'
import { useIntl } from 'react-intl'
import { rteAppId } from 'utils/routes'

import { Icon } from 'react-icons-kit'
import { externalLink } from 'react-icons-kit/feather'

import messages from './messages'

// when user input is a iframe tag
const parseHtmlTag = (html) => {
  let e = {}
  try {
    const dom = document.createElement('html')
    dom.innerHTML = html
    const iframe = dom.getElementsByTagName('iframe')[0]
    const data = {
      height: iframe.getAttribute('height'),
      src: iframe.getAttribute('src'),
    }

    Object.keys(data).forEach((key) => {
      e = { target: { id: key, value: data[key] } }
    })
  } catch (error) {
    console.error(error)
  }
  return e
}

const GoogleMapsForm = ({ appId, onChange, part, partIndex, onFormChange }) => {
  const intl = useIntl()

  const googleMapsParser = (input) => {
    let returnData = {}
    if (input.indexOf('iframe') >= 0) {
      returnData = parseHtmlTag(input)
    } else {
      returnData = null
    }
    onChange(returnData, partIndex)
  }

  const handleOnChange = (e) => {
    onChange(e, partIndex)
    onFormChange(true);
    googleMapsParser(e.target.value)
  }

  return (
    <>
      <PartOptions partIndex={partIndex} onChange={onChange} values={part.options} onFormChange={onFormChange} />

      <BasicTextArea
        name="userInput"
        label={intl.formatMessage(messages.googleMaps)}
        helpMessage={intl.formatMessage(messages.googleMapsHelp)}
        partIndex={partIndex}
        onChange={(e) => handleOnChange(e, partIndex)}
        value={part.userInput}
        required
        error={intl.formatMessage(messages.googleMapsError)}
        validation={{
          async: (value) => (value ? value.indexOf('<iframe src="https://www.google.com/maps') >= 0 : true),
        }}
        size="small"
      />

      <BasicInfo
        text={intl.formatMessage(messages.info)}
        children={
          <BasicButton
            label={intl.formatMessage(messages.tutorial)}
            icon={<Icon icon={externalLink} />}
            isLink
            href={rteAppId(appId, 'TUTORIAL')}
            target="_blank"
            linkstyle
            small
          />
        }
      />
    </>
  )
}

GoogleMapsForm.propTypes = {
  appId: PropTypes.string,
  onChange: PropTypes.func,
  part: PropTypes.object,
  partIndex: PropTypes.number,
  onFormChange: PropTypes.func,
}

export default GoogleMapsForm
