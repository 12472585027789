import PropTypes from 'prop-types'
import { withRouter } from 'react-router'
import { rteFolderTag } from 'utils/routes'
import { FOLDERS_TO_DISPLAY } from 'utils/consts'

import {StyledLabel, StyledLabelLock, StyledRow} from './styles'
import { connectStore } from "redux-box";
import { storeModule as SessionModule } from "../../../store/manageUsers";
import { checkFoldersAccess } from "../../../services/utils";
import { lock } from 'react-icons-kit/feather/lock'
import {Icon} from "react-icons-kit";
import { BasicModal } from "../../index";
import {useState} from "react";
import ModalFolder from "./modal";

const FolderList = ({ all, app, session, folderId }) => {
  const [open, setOpen] = useState(false);

  const show = (folder, i) => {
    const isFolderVisible = all ? true : i < FOLDERS_TO_DISPLAY
    const isNameEmpty = folder.name.split(' ').join('') === ''
    return isFolderVisible && !isNameEmpty
  }

  const filteredFolders = Object.values(app.folders)
    .sort((a, b) => {
      const hasAccessA = checkFoldersAccess(app.id, session.profile, a.id);
      const hasAccessB = checkFoldersAccess(app.id, session.profile, b.id);
      return hasAccessA === hasAccessB ? 0 : hasAccessA ? -1 : 1;
    })
    .filter((folder, i) => show(folder, i))

  const getColor = (color) => {
    switch (color) {
      case 'pink':
        return '#EF456F'
      case 'grey':
        return '#88959F'
      case 'green':
        return '#3DB076'
      case 'blue':
        return '#3F6CB3'
      case 'yellow':
        return '#FFD232'
      case 'orange':
        return '#F59E33'
      default:
        return color
    }
  }

  return (
    <>
      {filteredFolders.map((folder, i) => (
        <StyledRow isfolder="true" key={`folder-${i}`}>
          {checkFoldersAccess(app.id, session.profile, folder.id) ?
            <>
              <StyledLabel
                color={getColor(folder.color)}
                to={rteFolderTag(app.id, folder.id, 'all')}
                selected={folderId === folder.id}
              >
                {folder.name}
              </StyledLabel>
            </>
          :
            <StyledLabelLock
              onClick={() => setOpen(true)}
              color={getColor(folder.color)}
            >
              <Icon icon={lock} />&nbsp;
              {folder.name}
            </StyledLabelLock>
          }
        </StyledRow>
      ))}

      {open && (
        <BasicModal
          content={<ModalFolder inModal />}
          isOpen={open}
          toggle={() => setOpen(false)}
          size="medium"
        />
      )}
    </>
  )
}

FolderList.propTypes = {
  all: PropTypes.bool,
  app: PropTypes.object,
  folderId: PropTypes.string,
}
export default connectStore({ session: SessionModule })(withRouter(FolderList))

