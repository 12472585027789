import styled, { keyframes } from 'styled-components'

export const StyledContainer = styled.div`
  position: relative;
  z-index: 2;
`

const rotate = keyframes`
  to {
    transform: rotate(360deg);
  }
`

export const StyledAnimation = styled.div`
  &:before {
    content: '';
    box-sizing: border-box;
    position: absolute;
    top: 50%;
    left: 50%;
    width: 30px;
    height: 30px;
    margin-top: -15px;
    margin-left: -15px;
    border-radius: 50%;
    border: 3px solid #ccc;
    border-top-color: #000;
    animation: ${rotate} 0.6s linear infinite;
  }
`
