import styled from 'styled-components'

export const StyledButton = styled.div`
  display: block;
  background-size: cover;
  background-position: center center;
  text-align: center;
  margin: 0 auto 10px auto;
  border-radius: ${({ borderRadius }) => borderRadius}%;
  border: ${({ borderWidth }) => borderWidth}px solid black;
  width: 100%;
  line-height: 10px;

  &:after {
    content: '';
    display: block;
    padding-bottom: 100%;
  }
`

export const StyledContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  width: 100%;
  line-height: 10px;
`

const getFlex = (columns) => {
  switch (columns) {
    case '3':
      return '33%'
    case '4':
      return '25%'
    default:
      return '43%'
  }
}

export const StyledItem = styled.div`
  flex-basis: ${({ columns }) => getFlex(columns)};
  max-width: ${({ columns }) => getFlex(columns)};
  padding: 10px;
  text-align: center;

  span {
    font-weight: 600;
    font-size: 15px;
    line-height: 16px;
    word-break: break-word;
    color: black;
  }
`
