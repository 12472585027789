const actions = {
  getModuleList: (module, appId) => ({ type: 'MODULE_GET_LIST', module, appId }),
  getAllModules: (appId) => ({ type: 'MODULE_GET_ALL', appId }),
  saveModule: (appId, module, data, onSave) => ({ type: 'MODULE_SAVE', appId, module, data, onSave }),
  addItemToList: (appId, item, id) => ({ type: 'MODULE_LIST_ADD_ITEM', appId, item, id }),
  deleteItemInList: (appId, item, id) => ({ type: 'MODULE_LIST_DELETE_ITEM', appId, item, id }),
  updateItemInList: (appId, item, id) => ({ type: 'MODULE_LIST_UPDATE_ITEM', appId, item, id }),
  deleteModule: (appId, module, data, redirectTo) => ({
    type: 'MODULE_DELETE',
    appId,
    module,
    data,
    redirectTo,
  }),
  deletePhotoboothFile: (photoboothId, fileId) => ({
    type: 'MODULE_DELETE_PHOTOBOOTH_FILE',
    photoboothId,
    fileId,
  }),
  savePhotobooth: (appId, id, data, onSave) => ({
    type: 'MODULE_SAVE_PHOTOBOOTH',
    appId,
    id,
    data,
    onSave,
  }),
  resetPhotoboothStatus: () => ({ type: 'MODULE_RESET_PHOTOBOOTH_STATUS' }),
  resetStatus: (module) => ({ type: 'MODULE_STATUS_RESET', module }),
}
export default actions
