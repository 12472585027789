import PropTypes from 'prop-types'
import { useEffect, useState, useContext } from 'react'
import { useIntl } from 'react-intl'
import { LockEditForm, MainContainerContext } from 'containers'
import { BasicButton, BasicModal } from 'components'

import connectStore from 'redux-box/dist/connectStore'
import { storeModule as LockModule } from 'store/locks'
import { storeModule as AppModule } from 'store/app'

import { Icon } from 'react-icons-kit'
import { edit2 } from 'react-icons-kit/feather/edit2'
import { plus } from 'react-icons-kit/feather/plus'
import { lock as lockIcon } from 'react-icons-kit/feather/lock'

import { StyledButtonContainer } from './styles'
import messages from './messages'

const LockModal = ({ app, appId, id, locks, onSave, open, setOpen, toggleDisable }) => {
  const [lock, setLock] = useState()
  const [edit, setEdit] = useState(false)

  const context = useContext(MainContainerContext)

  const intl = useIntl()

  useEffect(() => {
    const data = locks.getLockById(id)
    setLock(data)
  }, [id, locks.list])

  const toggleOpen = () => {
    setOpen(!open)
    toggleDisable()
  }

  const toggleEdit = () => {
    setEdit(!edit)
    toggleDisable()
  }

  return (
    <>
      <StyledButtonContainer>
        {!!app.access.Lock ? (
          <BasicButton
            color="green"
            medium
            onClick={toggleOpen}
            label={intl.formatMessage(messages.create)}
            icon={<Icon icon={plus} />}
          />
        ) : (
          <BasicButton
            icon={<Icon icon={lockIcon} />}
            onClick={() => context.toggleUpgradeModal()}
            label={intl.formatMessage(messages.create)}
            medium
            color="green"
          />
        )}

        {id && (
          <BasicButton
            color="blue"
            medium
            onClick={() => setEdit(!edit)}
            label={intl.formatMessage(messages.edit)}
            icon={<Icon icon={edit2} />}
          />
        )}
      </StyledButtonContainer>

      {open && (
        <BasicModal
          isOpen={open}
          toggle={toggleOpen}
          content={<LockEditForm appId={appId} onSave={onSave} onCancel={toggleOpen} isNew />}
          title={intl.formatMessage(messages.locks)}
        />
      )}

      {edit && (
        <BasicModal
          isOpen={edit}
          toggle={toggleEdit}
          content={
            <LockEditForm appId={appId} data={lock} onSave={toggleEdit} onCancel={toggleEdit} noDelete />
          }
          title={intl.formatMessage(messages.locks)}
        />
      )}
    </>
  )
}

LockModal.propTypes = {
  app: PropTypes.object,
  appId: PropTypes.string,
  id: PropTypes.string,
  locks: PropTypes.any,
  onChange: PropTypes.func,
  onSave: PropTypes.func,
  open: PropTypes.bool,
  setOpen: PropTypes.func,
  toggleDisable: PropTypes.func,
}

export default connectStore({ app: AppModule, locks: LockModule })(LockModal)
