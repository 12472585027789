import PropTypes from 'prop-types'

import Texts from './Texts'
import Images from './Images'
import Audios from './Audios'
import Videos from './Videos'
import Buttons from './Buttons'
import Integrations from './Integrations'
import Redirections from './Redirections'
import Locks from './Locks'
import Premium from './Premium'
import Advanced from './Advanced'

const EditFormParts = ({
  app,
  onChange,
  onCheckedChange,
  onPictureChange,
  onVideoChange,
  part,
  partIndex,
  toggleDisable,
  toggleModal,
  tokenId,
  onFormChange
}) => {
  return (
    <>
      {part.type !== '' && <hr />}

      <Texts app={app} onChange={onChange} part={part} partIndex={partIndex} onFormChange={onFormChange} />

      <Images
        app={app}
        onChange={onChange}
        onCheckedChange={onCheckedChange}
        onPictureChange={onPictureChange}
        part={part}
        partIndex={partIndex}
        onFormChange={onFormChange}
      />

      <Audios app={app} onChange={onChange} onVideoChange={onVideoChange} part={part} partIndex={partIndex} onFormChange={onFormChange} />

      <Videos app={app} onChange={onChange} onVideoChange={onVideoChange} part={part} partIndex={partIndex} onFormChange={onFormChange} />

      <Buttons
        app={app}
        onChange={onChange}
        onCheckedChange={onCheckedChange}
        onPictureChange={onPictureChange}
        part={part}
        partIndex={partIndex}
        onFormChange={onFormChange}
      />

      <Integrations app={app} onChange={onChange} part={part} partIndex={partIndex} onFormChange={onFormChange} />

      <Redirections
        app={app}
        onChange={onChange}
        onCheckedChange={onCheckedChange}
        part={part}
        partIndex={partIndex}
        tokenId={tokenId}
        toggleDisable={toggleDisable}
        onFormChange={onFormChange}
      />

      <Locks
        app={app}
        onChange={onChange}
        part={part}
        partIndex={partIndex}
        toggleDisable={toggleDisable}
        toggleModal={toggleModal}
        onFormChange={onFormChange}
      />

      {/* Photobooth, Voxpop, List */}
      <Premium
        app={app}
        onChange={onChange}
        part={part}
        partIndex={partIndex}
        toggleDisable={toggleDisable}
        onFormChange={onFormChange}
      />

      {/* Trigger, Input, Product, VisitCounter */}
      <Advanced
        app={app}
        onChange={onChange}
        onCheckedChange={onCheckedChange}
        part={part}
        partIndex={partIndex}
        onFormChange={onFormChange}
      />
    </>
  )
}

EditFormParts.propTypes = {
  app: PropTypes.object,
  onChange: PropTypes.func,
  onCheckedChange: PropTypes.func,
  onPictureChange: PropTypes.func,
  onVideoChange: PropTypes.func,
  part: PropTypes.object,
  partIndex: PropTypes.number,
  toggleDisable: PropTypes.func,
  toggleModal: PropTypes.func,
  onFormChange: PropTypes.func,
  tokenId: PropTypes.any,
}

export default EditFormParts
