import PropTypes from 'prop-types'
import { Button } from 'reactstrap'

import connectStore from 'redux-box/dist/connectStore'
import { storeModule as ModuleModules } from 'store/modules'

import ListHalfButton from './ListHalfButton'
import EmptyList from './EmptyList'

const Products = ({ appId, currentList, modules, type }) => {
  const getChild = (childType, childId) => modules[childType].list[childId] || {}

  const children = currentList.children
    ? // eslint-disable-next-line array-callback-return
      Object.values(currentList.children).map((c) => {
        const child = getChild(c.type, c.id)
        if (Object.keys(child).length > 0) {
          return { ...child, style: { ...currentList.style, ...c.style }, ...c }
        }
      })
    : []

  const childrenToButtonsHalf = () => {
    const items = children.filter((c) => c)
    return items.map((c) => ({
      style: c.style || currentList.style,
      type: 'Button-Half',
      link: '#',
      text: c.shortName && c.shortName !== '' ? c.shortName : c.name,
      url: c.icon && c.icon !== '' ? c.icon : c.img,
    }))
  }

  const hasItems = currentList && currentList.children && Object.keys(currentList.children).length > 0

  return (
    <>
      {type === 'Button-Half' && (
        <>
          {hasItems ? (
            <ListHalfButton allParts={childrenToButtonsHalf()} currentIndex="0" style={currentList.style} />
          ) : (
            <EmptyList appId={appId} currentList={currentList} />
          )}
        </>
      )}

      {type === 'Button' && (
        <>
          {hasItems ? (
            <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
              {children.length > 0 && (
                <>
                  {children.map((item, i) => (
                    <>
                      {item && (
                        <Button
                          btn-color="yellow"
                          block
                          href="#"
                          key={`lis-btn-${item.id}-${i}`}
                          outline={item.style && item.style.alternate ? item.style.alternate : ''}
                          style={{ margin: '10px 0' }}
                        >
                          {item.shortName && item.shortName !== '' ? item.shortName : item.name}
                        </Button>
                      )}
                    </>
                  ))}
                </>
              )}
            </div>
          ) : (
            <EmptyList appId={appId} currentList={currentList} />
          )}
        </>
      )}
    </>
  )
}

Products.propTypes = {
  appId: PropTypes.string,
  currentList: PropTypes.object,
  modules: PropTypes.object,
  type: PropTypes.string,
}

export default connectStore({ modules: ModuleModules })(Products)
