/*
 * PageFeedbacks Messages
 *
 * This contains all the text for the PageFeedbacks component.
 */
import { defineMessages } from 'react-intl'

const scope = 'app.containers.PageFeedbacks'

export default defineMessages({
  metaTitle: {
    id: `${scope}.metaTitle`,
  },
  knowMoreAboutFeedbacks: {
    id: `${scope}.knowMoreAboutFeedbacks`,
  },
  contactUs: {
    id: `${scope}.contactUs`,
  },
  createModule: {
    id: `${scope}.createModule`,
  },
  moduleName: {
    id: `${scope}.moduleName`,
  },
  feedbackError: {
    id: `${scope}.feedbackError`,
  },
  moduleLocation: {
    id: `${scope}.moduleLocation`,
  },
  addModule: {
    id: `${scope}.addModule`,
  },
  feedbackSuccess: {
    id: `${scope}.feedbackSuccess`,
  },
})
