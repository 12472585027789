import PropTypes from 'prop-types'
import { useMemo } from 'react'
import { StyledContainer, StyledButton, StyledItem } from './styles'

const ButtonHalf = ({ part, style }) => {
  const memoizedUrl = useMemo(() => {
    let img = part.url
    try {
      img = URL.createObjectURL(part.file)
    } catch (e) {
      img = part.url
    }
    return img
  }, [part])

  const getStyle = (name, part) => {
    if (part && part.style && part.style[name]) {
      return part.style[name]
    }
    if (style && style[name]) {
      return style[name]
    }
    return null
  }

  return (
    <StyledContainer>
      <StyledItem columns={getStyle('nbrPerCol', part)}>
        <StyledButton
          borderWidth={getStyle('borderWidth', part) || '3'}
          borderRadius={getStyle('borderRadius', part) || '50'}
          style={part.file || part.url ? { backgroundImage: `url("${memoizedUrl}")` } : {}}
        />

        {part.text && (
          <a href={part.link} target="_blank" rel="noreferrer">
            <span>{part.text}</span>
          </a>
        )}
      </StyledItem>
    </StyledContainer>
  )
}

ButtonHalf.propTypes = {
  part: PropTypes.object,
  style: PropTypes.object,
}

export default ButtonHalf
