import styled from "styled-components";
import {AvRadioGroup} from "availity-reactstrap-validation";

export const StyledGroup = styled(AvRadioGroup)`
  margin: 20px 0;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;

  legend {
    font-family: Nunito, serif;
    font-size: 16px;
    font-weight: 600;
    line-height: 1.5;
    margin-bottom: 0;
  }

  label {
    padding-left: 7px;
  }

  .form-text {
    font-size: 12px;
    margin-top: -5px;
  }

  .invalid-feedback {
    margin-top: 5px;
  }

  .custom-radio-image {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-right: 20px;
    cursor: pointer;
  }

  @media (max-width: 460px) {
    flex-direction: column;
    align-items: center;
  }
`
