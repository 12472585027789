import PropTypes from 'prop-types'
import { useIntl } from 'react-intl'
import { BasicModal, BasicButton } from 'components'
import { rteAppId } from 'utils/routes'

import { connectStore } from 'redux-box'
import { storeModule as SessionModule } from 'store/manageUsers'

import { Icon } from 'react-icons-kit'
import { externalLink } from 'react-icons-kit/feather/externalLink'

import messages from './messages'

const InfoModal = ({ appId, isItem, open, toggle, session, onConfirm }) => {
  const intl = useIntl()

  const handleOnKeyDown = (e) => {
    if (e.key === 'Enter') toggle()
  }

  return (
    <BasicModal
      title={intl.formatMessage(isItem ? messages.itemTitle : messages.fbsTitle)}
      size="small"
      content={
        <>
          <p>{intl.formatMessage(isItem ? messages.itemInfo : messages.fbsInfo)}</p>

          {isItem && (
            <BasicButton
              label={intl.formatMessage(messages.itemPage)}
              icon={<Icon icon={externalLink} />}
              isLink
              href={rteAppId(appId, 'ITEMS')}
              target="_blank"
              linkstyle
              small
            />
          )}
        </>
      }
      actions={
        <>
          <BasicButton onClick={toggle} label={intl.formatMessage(messages.close)} outlined />
          {session.profile.superadmin && (
            <BasicButton color="green" onClick={onConfirm} label={intl.formatMessage(messages.adminDelete)} />
          )}
        </>
      }
      isOpen={open}
      toggle={toggle}
      onKeyDown={handleOnKeyDown}
    />
  )
}

InfoModal.propTypes = {
  appId: PropTypes.string,
  isItem: PropTypes.bool,
  open: PropTypes.bool,
  onConfirm: PropTypes.func,
  session: PropTypes.object,
  toggle: PropTypes.func,
}

export default connectStore({ session: SessionModule })(InfoModal)
