import PropTypes from 'prop-types'
import { addBrToText } from 'utils'
import styled from 'styled-components'

import { ScheduleTooltip } from '../components/Tooltips'

const StyledWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin: 20px;
`

const StyledText = styled.h2`
  font-family: 'Nunito', sans-serif;
  font-size: 22px;
  font-weight: 700;
  line-height: 24px;
  word-break: break-word;
`

const Subtitle = ({ part, partIndex, cardId }) => {
  return (
    <StyledWrapper key={`${cardId}-h2-${partIndex}`}>
      <StyledText>{part.text && addBrToText(part.text, 'part-txt')}</StyledText>
      <ScheduleTooltip part={part} />
    </StyledWrapper>
  )
}

Subtitle.propTypes = {
  part: PropTypes.object,
  partIndex: PropTypes.number,
  cardId: PropTypes.string,
}

export default Subtitle
