/*
 * PageData Messages
 *
 * This contains all the text for the PageData component.
 */
import { defineMessages } from 'react-intl'

const scope = 'app.components.ButtonWithConfirm'

export default defineMessages({
  cancel: {
    id: `${scope}.cancel`,
  },
  confirm: {
    id: `${scope}.confirm`,
  },
})
