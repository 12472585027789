const mutations = {
  MODULE_SAVE_ERROR: (state, { module }) => {
    state[module].error = true
    state[module].saving = false
    state[module].success = false
  },
  MODULE_SAVE_SUCCESS: (state, { module }) => {
    state[module].error = false
    state[module].saving = false
    state[module].success = true
  },
  MODULE_SAVING: (state, { module }) => {
    state[module].error = false
    state[module].saving = true
    state[module].success = false
  },
  MODULE_STATUS_RESET: (state, { module }) => {
    state[module].deleted = false
    state[module].deleting = false
    state[module].error = false
    state[module].saving = false
    state[module].success = false
  },
  MODULE_DELETING: (state, { module }) => {
    state[module].deleted = false
    state[module].deleting = true
    state[module].error = false
  },
  MODULE_DELETE_SUCCESS: (state, { module }) => {
    state[module].deleted = true
    state[module].deleting = false
    state[module].error = false
  },
  MODULE_SET_ALL: (state, { data }) => {
    Object.entries(data).forEach(([module, list]) => {
      state[module].list = list
      state[module].fetching = false
    })
  },
  MODULE_IS_FETCHING: (state) => {
    state.fetching = true
  },
  MODULE_FETCHED: (state) => {
    state.fetching = false
  },
  MODULE_FETCH_ERROR: (state, error) => {
    state.error = error
  },
  MODULE_GET_LIST: (state, data) => {
    if (data && data.module) {
      state[data.module].fetching = true
    }
  },
  MODULE_SET_LIST: (state, { data }) => {
    if (data && data.module) {
      state[data.module].list = data.list || {}
      state[data.module].fetching = false
    }
  },
  MODULE_SET_LISTS: (state, { lists }) => {
    state.list.list = lists
  },
  MODULE_ADD_ONE: (state, { module, data }) => {
    state[module].saving = false
    state[module].list[data.id] = data
  },
  MODULE_REMOVE_ONE: (state, { module, data }) => {
    const newModules = { ...state[module].list }
    delete newModules[data.id]
    state[module].list = newModules
  },
  MODULE_SAVE_PHOTOBOOTH: (state) => {
    state.voxpop.saving = true
  },
  MODULE_DELETE_PHOTOBOOTH_FILE: (state, { photoboothId, fileId }) => {
    const { files } = state.voxpop.list[photoboothId]
    let newGroups = []
    files.map((array) => newGroups.push(array.filter((el) => el.id !== fileId)))
    state.voxpop.list[photoboothId].files = newGroups

    state.voxpop.list[photoboothId].allFiles.filter((el) => el.id !== fileId)
  },
  MODULE_PHOTOBOOTH_SAVING_SUCCESS: (state, { photobooth }) => {
    state.voxpop.list[photobooth.id] = photobooth
    state.voxpop.success = true
    state.voxpop.saving = false
  },
  MODULE_PHOTOBOOTH_SAVE_ERROR: (state) => {
    state.voxpop.error = true
    state.voxpop.success = false
    state.voxpop.saving = false
  },
  MODULE_RESET_PHOTOBOOTH_STATUS: (state) => {
    state.voxpop.error = false
    state.voxpop.success = false
    state.voxpop.saving = false
    state.voxpop.fetching = false
  },
}
export default mutations
