import { VISIT_URL, VISIT_SHORT_URL, ADMIN_URL } from './consts'
import RTE from './routes'

export const facebookShareUrl = (appId, tokenId, cardId) => {
  const facebookShare = 'https://www.facebook.com/sharer/sharer.php?u='
  const utmMedium = parseInt(tokenId, 10) ? `jeton-${tokenId}` : `carte-${cardId}`
  const utm = `?utm_source=facebook&utm_medium=${utmMedium}&utm_campaign=facebook-share-button`
  let urlToShare

  if (parseInt(tokenId, 10) !== parseInt(cardId, 10)) {
    // unlinked card
    urlToShare = encodeURIComponent(`${VISIT_URL}/${appId}/card/${cardId}/${utm}`)
  } else {
    urlToShare = encodeURIComponent(`${VISIT_URL}/${appId}/${tokenId}/${utm}`)
  }
  return `${facebookShare}${urlToShare}`
}

export const cardDirectLink = (appId, tokenId, cardId) => {
  if (cardId !== 0 && parseInt(tokenId, 10) === 0) {
    return `${VISIT_SHORT_URL}/${appId}/card/${cardId}`
  }
  if (tokenId !== 0 && parseInt(tokenId, 10) !== 0) {
    return `${VISIT_SHORT_URL}/${appId}/${tokenId}`
  }
  return ''
}

export const feedbackDirectLink = (appId, feedbackId, childId) => {
  if (!childId) {
    return `${VISIT_SHORT_URL}/${appId}/${feedbackId}`
  }
  return `${VISIT_SHORT_URL}/${appId}/${feedbackId}/${childId.split('_')[1]}`
}

export const oneCardLink = (appId, cardId) =>
  RTE.ONE_CARD.path.replace(':appId', appId).replace(':cardId', cardId)

export const oneCardEditLink = (appId, cardId) =>
  RTE.ONE_CARD_EDIT.path.replace(':appId', appId).replace(':cardId', cardId)

export const oneUserManagementLink = (appId, userId) =>
  RTE.MANAGEUSERS_SINGLE.path.replace(':appId', appId).replace(':userId', userId)

export const usersManagementLink = (appId) => RTE.MANAGEUSERS.path.replace(':appId', appId)

export const userInitPasswordLink = (userEmail) =>
  `${ADMIN_URL}${RTE.INIT_PASSWORD.path}/?&email=${userEmail}`

export const viewOnFacebookLink = (appId, fbsId) => `${VISIT_SHORT_URL}/${appId}/fbs/${fbsId}`

export const oneLockLink = (appId, lockId) =>
  RTE.LOCKS_SINGLE.path.replace(':appId', appId).replace(':id', lockId)
