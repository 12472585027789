import PropTypes from 'prop-types'
import { useIntl } from 'react-intl'
import { BasicButton, ButtonWithConfirm } from 'components'

import messages from './messages'
import { StyledButtons } from './styles'

const PageActions = ({ loading, onDelete, onCancel, isNew, onEdit, noDelete }) => {
  const intl = useIntl()

  return (
    <StyledButtons>
      {!isNew && onDelete && !noDelete && (
        <ButtonWithConfirm
          color="pink"
          medium
          label={intl.formatMessage(messages.delete)}
          onConfirm={onDelete}
          modalText={intl.formatMessage(messages.modalText)}
          modalTitle={intl.formatMessage(messages.modalTitle)}
          modalSize="small"
          outlined
        />
      )}

      {onCancel && (
        <>
          <BasicButton medium onClick={onCancel} label={intl.formatMessage(messages.cancel)} outlined />

          <BasicButton
            medium
            color="green"
            label={intl.formatMessage(messages.save)}
            type="submit"
            loading={loading}
          />
        </>
      )}

      {onEdit && (
        <BasicButton medium onClick={onEdit} label={intl.formatMessage(messages.editRandomStory)} color="blue" />
      )}
    </StyledButtons>
  )
}

PageActions.propTypes = {
  isNew: PropTypes.bool,
  loading: PropTypes.bool,
  onCancel: PropTypes.func,
  onDelete: PropTypes.func,
  onEdit: PropTypes.func,
  noDelete: PropTypes.bool,
}

export default PageActions
