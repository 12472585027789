import styled from 'styled-components'

export const StyledTitle = styled.div`
  font-size: 1.6rem;
  font-family: Nunito;
  font-weight: 700;
  line-height: 34px;
  margin: ${({ color }) => (color ? '0 0 1rem 1.5rem' : '0 0 1rem 0')};
  position: relative;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: baseline;
  word-break: break-word;

  &::before {
    display: ${({ color }) => (color ? 'inline-block' : 'none')};
    content: '';
    position: absolute;
    top: 10px;
    left: -1.5rem;
    width: 1rem;
    height: 1rem;
    margin: 0 10px 0 0;
    background-color: ${({ color }) => color || '#88959F'};
    border-radius: 100%;
  }

  span:first-of-type {
    margin-right: 20px;
    margin-bottom: 10px;
  }
`
