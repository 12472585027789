import PropTypes from 'prop-types'
import { useIntl } from 'react-intl'
import { BasicInput, BasicInfo, BasicTooltip } from 'components'

import { Icon } from 'react-icons-kit'
import { externalLink } from 'react-icons-kit/feather/externalLink'

import { StyledLegend, StyledCopy } from './styles'
import messages from '../messages'

const FrLegal = 'http://mysmartjourney.com/legal/conditions-utilisation/conditions-utilisation-1.5.pdf'
const EnLegal = 'http://mysmartjourney.com/legal/terms-of-use/terms-of-use-1.5.pdf'

const SavePhotoFrame = ({ termsLanguage }) => {
  const intl = useIntl()

  return (
    <>
      <StyledLegend>{intl.formatMessage(messages.spf.title)}</StyledLegend>

      <BasicInput
        name="part.shareAdmin.title"
        label={intl.formatMessage(messages.spf.text)}
        helpMessage={intl.formatMessage(messages.spf.textHelp)}
        placeholder={intl.formatMessage(messages.spf.textPlaceholder)}
      />

      <BasicInput
        name="part.shareAdmin.button"
        label={intl.formatMessage(messages.spf.button)}
        helpMessage={intl.formatMessage(messages.spf.buttonHelp)}
        placeholder={intl.formatMessage(messages.spf.buttonPlaceholder)}
      />

      <BasicInfo text={intl.formatMessage(messages.spf.terms)} />

      <BasicInput
        name="part.tos"
        label={
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
            }}
          >
            {intl.formatMessage(messages.spf.termsUrl)}
            <StyledCopy
              data-for="tos"
              data-tip={intl.formatMessage(messages.spf.openTerms)}
              href={termsLanguage === 'en' ? EnLegal : FrLegal}
              target="_blank"
              rel="noreferrer"
            >
              <Icon icon={externalLink} />
            </StyledCopy>
          </div>
        }
        helpMessage={intl.formatMessage(messages.spf.termsUrlHelp)}
        value={termsLanguage === 'en' ? EnLegal : FrLegal}
        readOnly
      />

      <BasicTooltip id="tos" maxWidth={125} />
    </>
  )
}

SavePhotoFrame.propTypes = {
  termsLanguage: PropTypes.string,
}

export default SavePhotoFrame
