import PropTypes from 'prop-types'
import { useEffect, useState, createContext } from 'react'
import { IntlProvider } from 'react-intl'
import Cookies from 'js-cookie'

import messages_en from '../../translations/en.json'
import messages_fr from '../../translations/fr.json'

export const LangContext = createContext()

export const LanguageProvider = ({ children }) => {
  const [locale, setLocale] = useState('fr')
  const [messages, setMessages] = useState(messages_fr)

  useEffect(() => {
    const cookie = Cookies.get('msj_app_language')
    const local = navigator.language.split('-')[0]

    if (!cookie) {
      setLocale(local)
      local.includes('en') ? setMessages(messages_en) : setMessages(messages_fr)
    } else {
      setLocale(cookie)
      cookie.includes('en') ? setMessages(messages_en) : setMessages(messages_fr)
    }
  }, [])

  const selectLanguage = (lang) => {
    Cookies.set('msj_app_language', lang)
    setLocale(lang)
    lang.includes('en') ? setMessages(messages_en) : setMessages(messages_fr)
  }

  return (
    <LangContext.Provider value={{ locale, selectLanguage }}>
      <IntlProvider messages={messages} locale={locale} defaultLocale="en">
        {children}
      </IntlProvider>
    </LangContext.Provider>
  )
}

LanguageProvider.propTypes = {
  children: PropTypes.element,
}

export default LanguageProvider
