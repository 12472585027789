import PropTypes from 'prop-types'
import { useEffect, useState, useContext } from 'react'
import { useIntl } from 'react-intl'
import { RandomStoryEditForm, MainContainerContext } from 'containers'
import { BasicButton, BasicModal } from 'components'

import connectStore from 'redux-box/dist/connectStore'
import { storeModule as RandomStoryModule } from 'store/randomStory'
import { storeModule as AppModule } from 'store/app'

import { Icon } from 'react-icons-kit'
import { edit2 } from 'react-icons-kit/feather/edit2'
import { plus } from 'react-icons-kit/feather/plus'
import { lock } from 'react-icons-kit/feather/lock'

import { StyledButtonContainer } from './styles'
import messages from './messages'

const RandomStoryModal = ({ app, appId, id, stories, onSave, open, setOpen, toggleDisable }) => {
  const [story, setStory] = useState()
  const [edit, setEdit] = useState(false)

  const context = useContext(MainContainerContext)

  const intl = useIntl()

  useEffect(() => {
    const data = stories.getRandomStoryById(id)
    setStory(data)
  }, [id, stories.list])

  const toggleOpen = () => {
    setOpen(!open)
    toggleDisable()
  }

  const toggleEdit = () => {
    setEdit(!edit)
    toggleDisable()
  }

  return (
    <>
      <StyledButtonContainer>
        {!!app.access.RandomStory ? (
          <BasicButton
            color="green"
            medium
            onClick={toggleOpen}
            label={intl.formatMessage(messages.create)}
            icon={<Icon icon={plus} />}
          />
        ) : (
          <BasicButton
            icon={<Icon icon={lock} />}
            onClick={() => context.toggleUpgradeModal()}
            label={intl.formatMessage(messages.create)}
            medium
            color="green"
          />
        )}

        {id && (
          <BasicButton
            color="blue"
            medium
            onClick={() => setEdit(!edit)}
            label={intl.formatMessage(messages.edit)}
            icon={<Icon icon={edit2} />}
          />
        )}
      </StyledButtonContainer>

      {open && (
        <BasicModal
          isOpen={open}
          toggle={toggleOpen}
          content={<RandomStoryEditForm appId={appId} onSave={onSave} onCancel={toggleOpen} isNew />}
          title={intl.formatMessage(messages.stories)}
        />
      )}

      {edit && (
        <BasicModal
          isOpen={edit}
          toggle={toggleEdit}
          content={
            <RandomStoryEditForm appId={appId} data={story} onSave={toggleEdit} onCancel={toggleEdit} noDelete />
          }
          title={intl.formatMessage(messages.stories)}
        />
      )}
    </>
  )
}

RandomStoryModal.propTypes = {
  app: PropTypes.object,
  appId: PropTypes.string,
  id: PropTypes.string,
  stories: PropTypes.any,
  onChange: PropTypes.func,
  onSave: PropTypes.func,
  open: PropTypes.bool,
  setOpen: PropTypes.func,
  toggleDisable: PropTypes.func,
}

export default connectStore({ app: AppModule, stories: RandomStoryModule })(RandomStoryModal)
