import PropTypes from 'prop-types'
import { useState, useRef, useCallback } from 'react'
import ReactPlayer from 'react-player'
import { useIntl } from 'react-intl'
import { FormText } from 'reactstrap'
import { BasicTooltip, BasicButton } from 'components'
import { addBrToText } from 'utils'
import styled from 'styled-components'

import { Icon } from 'react-icons-kit'
import { help } from 'react-icons-kit/ionicons/help'

import { ScheduleTooltip } from '../components/Tooltips'
import messages from './messages'

const StyledWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  margin: 20px;
`

const StyledVideo = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  border: 1px solid #eee;
  width: ${({ type }) => (type === 'Video' ? '90%' : '100%')};

  & > div {
    max-height: ${({ singleview }) => (singleview ? '286px' : '176px')};
  }

  .react-player {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
`

const StyledInfo = styled.div`
  button,
  a {
    background-color: transparent;
    border: 1px solid #6c757d;
    color: #6c757d;
    pointer-events: none;
    width: 27px;
    height: 27px;

    i {
      display: flex !important;
    }

    &:hover {
      color: #6c757d !important;
      border: 1px solid #6c757d !important;
      background-color: transparent !important;
    }
  }
`

const StyledLegend = styled(FormText)`
  text-align: center;
  margin: 5px 20px 0 20px;
  line-height: 14px;
`

const StyledTooltips = styled.div`
  position: absolute;
  right: 0;
  top: 10px;
  display: flex;
  flex-direction: column;
`

const Videos = ({ cardId, singleview, part, partIndex }) => {
  const [isReady, setIsReady] = useState(false)
  const intl = useIntl()
  const playerRef = useRef()

  const getIsLinkVimeo = () => {
    return part.url ? part.url.indexOf('https://vimeo.com/') >= 0 || part.url.indexOf('https://www.vimeo.com/') >= 0 : false
  }

  const onReady = useCallback(() => {
    if (!isReady && Number(part.timeStart) > 0) {
      playerRef.current.seekTo(Number(part.timeStart), 'seconds')
      setIsReady(true)
    }
  }, [isReady])

  return (
    <>
      <StyledWrapper key={`${cardId}-video-${partIndex}`}>
        <StyledVideo singleview={singleview} type={part.type}>
          {getIsLinkVimeo() ? (
            <ReactPlayer
              id={`video-player-${partIndex}`}
              ref={playerRef}
              url={part.src || part.url}
              controls
              onReady={onReady}
            />
          ) : (
            <ReactPlayer id={`video-player-${partIndex}`} url={part.src || part.url} controls />
          )}

          <StyledTooltips>
            {part.type === 'Video' && (
              <StyledInfo data-for={`video-${partIndex}`} data-tip="">
                <BasicButton icon={<Icon icon={help} />} small outlined round />
              </StyledInfo>
            )}

            <ScheduleTooltip part={part} />
          </StyledTooltips>
        </StyledVideo>

        {part.legend && <StyledLegend color="muted">{addBrToText(part.legend, 'part-lgd')}</StyledLegend>}
      </StyledWrapper>

      <BasicTooltip id={`video-${partIndex}`} content={intl.formatMessage(messages.videoInfo)} />
    </>
  )
}

Videos.propTypes = {
  cardId: PropTypes.string,
  singleview: PropTypes.bool,
  part: PropTypes.object,
  partIndex: PropTypes.number,
}

export default Videos
