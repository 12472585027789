import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Card, CardBody, CardHeader, CardFooter } from 'reactstrap'
import { Link } from 'react-router-dom'

import { Icon } from 'react-icons-kit'
import { chevronsRight } from 'react-icons-kit/feather/chevronsRight'

const StyledCard = styled(Card)`
  margin-bottom: 1.5rem;

  box-shadow: 3px 3px 5px rgba(0, 0, 0, 0.05);

  &.per-col-1 {
    height: calc(100vh - 200px);
  }
  &.per-col-2 {
    height: calc(50vh - 80px);
  }
  &.per-col-3 {
    height: calc(33vh - 100px);
  }
`

const StyledCardHeader = styled(CardHeader)`
  display: flex;
  align-items: center;
  justify-content: ${({ hastitle }) => (hastitle === 'true' ? 'space-between' : 'flex-end')};
  min-height: 59px;
`

const StyledName = styled.span`
  margin-right: 20px;
`

const StyledCardBody = styled(CardBody)`
  ${({ height }) => height && `max-height: ${height}px`};
  ${({ overflow }) => (overflow === 'true' ? 'overflow-y: auto' : '')};
`

function LayoutCard({
  autoOverflow,
  children,
  footerChildren,
  footerLink,
  footerLinkText,
  footerText,
  headerChildren,
  id,
  link,
  linkText,
  maxHeight,
  perCol,
  style,
  title,
}) {
  return (
    <StyledCard className={perCol ? `per-col-${perCol}` : ''} style={style} id={id}>
      <StyledCardHeader hastitle={String(!!title)}>
        <StyledName>{title}</StyledName>

        {link && (
          <Link to={link}>
            {linkText}
            <Icon icon={chevronsRight} />
          </Link>
        )}
        {headerChildren}
      </StyledCardHeader>

      <StyledCardBody overflow={String(autoOverflow)} height={maxHeight}>
        {children}
      </StyledCardBody>

      {(footerLink || footerLinkText) && (
        <CardFooter>
          {footerLink ? (
            <Link to={link}>
              {footerLinkText || footerText} <Icon icon={chevronsRight} />
            </Link>
          ) : (
            <span>{footerText}</span>
          )}
        </CardFooter>
      )}

      <CardFooter
        style={{
          display: 'flex',
          justifyContent: 'flex-end',
          alignItems: 'flex-start',
        }}
      >
        {footerChildren}
      </CardFooter>
    </StyledCard>
  )
}

LayoutCard.propTypes = {
  autoOverflow: PropTypes.bool,
  children: PropTypes.node,
  footerChildren: PropTypes.any,
  footerLink: PropTypes.string,
  footerLinkText: PropTypes.string,
  footerText: PropTypes.string,
  headerChildren: PropTypes.any,
  id: PropTypes.string,
  link: PropTypes.string,
  linkText: PropTypes.string,
  maxHeight: PropTypes.number,
  perCol: PropTypes.number,
  style: PropTypes.any,
  title: PropTypes.string,
}

export default LayoutCard
