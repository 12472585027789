import { defineMessages } from 'react-intl'

const scope = 'app.components.PartActions.GoogleMapsForm'

export default defineMessages({
  googleMaps: { id: `${scope}.googleMaps` },
  googleMapsError: { id: `${scope}.googleMapsError` },
  googleMapsHelp: { id: `${scope}.googleMapsHelp` },
  info: { id: `${scope}.info` },
  tutorial: { id: `${scope}.tutorial` },
})
