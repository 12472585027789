/*
 * PartVistCounterForm Messages
 *
 * This contains all the text for the PartVistCounterForm component.
 */
import { defineMessages } from 'react-intl'

const scope = 'app.components.PartVistCounterForm'

export default defineMessages({
  intro: {
    id: `${scope}.intro`,
    defaultMessage: 'Vous trouverai le nombre de visites sur cette page dans la page données sous appId/card/name. Faire dux trackers au même nom vous permetterais de compiler les données de plusieurs cartes ensemble',
  },
  nameLabel: {
    id: `${scope}.nameLabel`,
    defaultMessage: 'Nom unique du tracker',
  },
  nameError: {
    id: `${scope}.nameError`,
    defaultMessage: 'Ce champs est requis',
  },
})
