/*
 * PartSurveyMonkeyForm Messages
 *
 * This contains all the text for the PartSurveyMonkeyForm component.
 */
import { defineMessages } from 'react-intl'

const scope = 'app.components.PartSurveyMonkeyForm'

export default defineMessages({
  inputLabel: {
    id: `${scope}.inputLabel`,
  },
  inputError: {
    id: `${scope}.inputError`,
  },
})
