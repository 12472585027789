import { BasicButton, BasicModal } from "components";
import messages from './message';
import { useIntl } from 'react-intl';

import { connectStore } from 'redux-box';
import { storeModule as SessionModule, storeModule as ManageUsersModule } from 'store/manageUsers'
import { storeModule as ManageAppsModule } from 'store/manageApps'
import { storeModule as ManageCardModule } from 'store/card'
import { storeModule as ManageAppModule } from 'store/app'
import { storeModule as ManageCardListModule } from 'store/cardList'

import PropTypes from 'prop-types';
import {
  StyledModal,
  StyledHeader,
  StyledSubtitle,
  StyledPublicLink,
  StyledPublicLinkContent,
  StyledCopyLink,
  StyledHelpMessage,
  StyledUsersContainers
} from "./styles";
import { useEffect, useState } from 'react';
import UsersShare from "./users";
import { cardDirectLink } from "../../utils/generator";
import { Icon } from "react-icons-kit";
import { copy } from 'react-icons-kit/feather/copy'
import { CopyToClipboard } from "react-copy-to-clipboard";
import { checkAccessDates } from "../../utils/share";

const ShareModal = ({ cardId, tokenId, openModal, setOpenModal, manageApps, appId, cardTitle, session }) => {
  const intl = useIntl();
  const [usersOfApp, setUsersOfApp] = useState([]);
  const [copyLink, setCopyLink] = useState(false);

  useEffect(() => {
    const updatedUsers = manageApps.appInfo.userList
      .filter(user => !user.superadmin)
      .filter(user => {
        return !user.apps.some(app =>
          app.roles.some(role => role.role === "admin" || role.role === "owner")
        );
      })
      .map(user => {
        const hasAccess = user.apps.some(app => {
          if (app.appId === appId && app.roles.length === 1) {
            const role = app.roles[0];
            let dateValid = checkAccessDates(role);

            switch (role.typeAccess) {
              case "allApp":
                return dateValid;
              case "none_exept":
                if (tokenId === 0) {
                  return dateValid && role.access.unlinked.some(cardAccess => {
                    return cardAccess.cardId === cardId && checkAccessDates(cardAccess);
                  });
                } else {
                  return dateValid && role.access.linked.some(cardAccess => {
                    return cardAccess.cardId === cardId && checkAccessDates(cardAccess);
                  });
                }
              default:
                return false;
            }
          }
          return false;
        });
        return {...user, hasAccess};
    });

    setUsersOfApp(updatedUsers);
  }, [manageApps.appInfo, appId, cardId, tokenId]);

  const handleClickCopyLink = () => {
    setCopyLink(true);

    setTimeout(() => {
      setCopyLink(false);
    }, 5000)
  }

  return <BasicModal
    isOpen={openModal}
    toggle={() => setOpenModal(!openModal)}
    share
    content={
      <StyledModal>
        <StyledPublicLink>
          <p>{intl.formatMessage(messages.linkViewPage)}</p>
          <div className="linkContent">
            <StyledPublicLinkContent>{cardDirectLink(appId, tokenId, cardId)}</StyledPublicLinkContent>
            <CopyToClipboard text={cardDirectLink(appId, tokenId, cardId)} onCopy={handleClickCopyLink}>
              <BasicButton icon={<Icon icon={copy} />} small outlined round />
            </CopyToClipboard>
          </div>
          {copyLink && <StyledCopyLink>{intl.formatMessage(messages.copyLinkViewPage)}</StyledCopyLink>}
        </StyledPublicLink>
        {session.profile.role !== 'editor' && session.profile.role !== 'redactor' && <StyledUsersContainers>
          <StyledHeader><h5>{intl.formatMessage(messages.share)}</h5></StyledHeader>

          {usersOfApp.length > 0 && <>
            <StyledSubtitle>{intl.formatMessage(messages.userHasAccess)}</StyledSubtitle>
            <StyledHelpMessage>{intl.formatMessage(messages.helpSectionUsers)}</StyledHelpMessage>
            {usersOfApp.map(
              (user, index) =>
                <UsersShare
                  key={index}
                  index={index}
                  user={user}
                  appId={appId}
                  cardId={cardId}
                  tokenId={tokenId}
                />,
            )}
          </>}
        </StyledUsersContainers>}
      </StyledModal>
    }
    title={`${intl.formatMessage(messages.accessSubtitle)} - ${cardTitle}`}
  />;
};

ShareModal.propTypes = {
  cardId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  tokenId: PropTypes.number,
  isFolder: PropTypes.bool,
  manageUsers: PropTypes.object.isRequired,
  manageApps: PropTypes.object.isRequired,
  manageCard: PropTypes.object.isRequired,
  manageCardList: PropTypes.object.isRequired,
  manageApp: PropTypes.object.isRequired,
  session: PropTypes.object.isRequired,
  folders: PropTypes.array,
  cardTitle: PropTypes.string,
};

export default connectStore({
  manageUsers: ManageUsersModule,
  manageApps: ManageAppsModule,
  manageCard: ManageCardModule,
  manageCardList: ManageCardListModule,
  manageApp: ManageAppModule,
  session: SessionModule
})(ShareModal);

