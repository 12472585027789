import PropTypes from 'prop-types'
import { useRef, useState } from 'react'
import { Collapse } from 'reactstrap'
import { newEvent } from 'utils'
import styled from 'styled-components'

import OptionsButton from './OptionsButton'
import OptionsTabs from './OptionsTabs'
import ScheduleResume from './ScheduleResume'
import DEFAULTS from './defaults'

const StyledRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: ${({ timer }) => (timer ? 'space-between' : 'flex-end')};
  ${({ timer }) => timer && 'margin-bottom: 20px'};
`

const PartOptions = ({ moduleForm, onChange, partIndex, hideTimer, styleForm, tagsForm, values, onFormChange }) => {
  const [isOpen, setOpen] = useState(false)
  const options = { ...DEFAULTS.values.display, ...values.display }

  const collapseRef = useRef(null)

  // reset timer data
  const clearOptions = () => {
    onChange(newEvent('options.display', {}), partIndex)
    setOpen(!isOpen)
  }

  const showTimerResume =
    !!options &&
    !!options.range &&
    !!options.range[0] &&
    options.range[0].date &&
    !!options.range[1] &&
    options.range[1].date

  // hideTimer is only used in ButtonHalf form (options need to be implemented in ButtonHalf)
  const showOptions = !hideTimer || styleForm || moduleForm || tagsForm

  return (
    showOptions && (
      <>
        <StyledRow timer={!hideTimer && showTimerResume && !isOpen}>
          {!hideTimer && showTimerResume && !isOpen && <ScheduleResume values={options} />}
          <OptionsButton onClick={() => setOpen(!isOpen)} labelOpen={isOpen} />
        </StyledRow>

        <div ref={collapseRef} style={{ marginTop: isOpen ? '5px' : '-20px' }}>
          <Collapse isOpen={isOpen}>
            {isOpen && (
              <OptionsTabs
                moduleForm={moduleForm}
                onChange={onChange}
                onClearOptions={clearOptions}
                partIndex={partIndex}
                hideTimer={hideTimer}
                styleForm={styleForm}
                tagsForm={tagsForm}
                options={options}
                emptyOptions={!values.display || Object.keys(values.display).length <= 0}
                onFormChange={onFormChange}
              />
            )}
          </Collapse>
        </div>
      </>
    )
  )
}

PartOptions.defaultProps = {
  onChange: () => {},
  partIndex: 0,
  values: {},
}

PartOptions.propTypes = {
  moduleForm: PropTypes.node,
  onChange: PropTypes.func,
  partIndex: PropTypes.number,
  hideTimer: PropTypes.bool,
  styleForm: PropTypes.node,
  tagsForm: PropTypes.node,
  values: PropTypes.any,
  onFormChange: PropTypes.func,
}

export default PartOptions
