/*
Jaune : #FFD232
Rose = #EF466F
Rouge = #d1003b
Vert = #3DB076
Bleu = #3F6CB3
*/

export const styles = {
  color: {
    red: '#d1003b',
    transparent: 'transparent',
    background: '#eee',
    black: '#000',
    blue: '#3F6CB3',
    blueOver: '#32568f',
    cancel: '#D9534F',
    charcoal: '#444',
    gray: '#505B68',
    green: '#3DB076',
    greenOver: '#298a5c',
    main: '#03162C',
    orange: '#F59F34',
    orangeOver: '#ee880b',
    pink: '#EF466F',
    pinkOver: '#d42752',
    secondary: '#12263F',
    success: '#5CB85C',
    white: '#fff',
    yellow: '#FFD232',
    yellowOver: '#f4bf00',
    adminApp: '#093465',
    parcours: '#88959F',
    borderColor: '#1A2D4B',
    lightGray: '#E9E9E9',
  },
  dimension: {
    maxWidth: '1366px',
    maxWidthCard: '480px',
  },
  border: {
    radius: '1rem',
  },
}

// name : Sera utiliser pour le nom de la className ex:=> button.green
export const buttons = [
  {
    name: 'green',
    color: styles.color.green,
    over: styles.color.greenOver,
    text: styles.color.white,
    radius: '1rem',
    outlinedColor: styles.color.green,
    outlineOver: styles.color.greenOver,
    outlinedText: styles.color.green,
  },
  {
    name: 'blue',
    color: styles.color.blue,
    over: styles.color.blueOver,
    text: styles.color.white,
    radius: null,
    outlinedColor: styles.color.blue,
    outlineOver: styles.color.blueOver,
    outlinedText: styles.color.blue,
  },
  {
    name: 'pink',
    color: styles.color.pink,
    over: styles.color.pinkOver,
    text: styles.color.white,
    radius: null,
    outlinedColor: styles.color.pink,
    outlineOver: styles.color.pinkOver,
    outlinedText: styles.color.pink,
  },
  {
    name: 'orange',
    color: styles.color.orange,
    over: styles.color.orangeOver,
    text: styles.color.charcoal,
    radius: null,
    outlinedColor: styles.color.orange,
    outlineOver: styles.color.orangeOver,
    outlinedText: styles.color.charcoal,
  },
  {
    name: 'yellow',
    color: styles.color.yellow,
    over: styles.color.yellowOver,
    text: styles.color.black,
    radius: null,
    outlinedColor: styles.color.yellow,
    outlineOver: styles.color.yellowOver,
    outlinedText: styles.black,
  },
]

export default styles
