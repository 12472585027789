import { defineMessages } from 'react-intl'

const scope = 'app.components.CardDisplayHeader'

export default defineMessages({
  copy: { id: `${scope}.copy` },
  newTab: { id: `${scope}.newTab` },
  note: { id: `${scope}.note` },
  share: { id: `${scope}.share` },
  googleAnalyticsTag: { id: `${scope}.googleAnalyticsTag` },
})
