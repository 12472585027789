import PropTypes from 'prop-types'
import { useState, useContext, useEffect } from 'react'
import { useIntl } from 'react-intl'
import { withRouter } from 'react-router'
import { FOLDERS_TO_DISPLAY } from 'utils/consts'
import { MainContainerContext } from 'containers'

import { connectStore } from 'redux-box'
import { storeModule as ModuleApp } from 'store/app'

import { Icon } from 'react-icons-kit'
import { chevronDown } from 'react-icons-kit/feather/chevronDown'
import { chevronUp } from 'react-icons-kit/feather/chevronUp'
import { x } from 'react-icons-kit/feather/x'
import { plus } from 'react-icons-kit/feather/plus'
import { lock } from 'react-icons-kit/feather/lock'
import Cookies from 'js-cookie'

import FolderList from './FolderList'
import AddFolderForm from './AddFolderForm'
import SortFolders from './SortFolders'
import messages from './messages'
import { StyledContainer, StyledAll, StyledAdd, StyledColumn } from './styles'
import { storeModule as SessionModule } from "../../../store/manageUsers";
import { checkActionsAccess } from "../../../services/utils";

const Folders = ({ className, app, session, folderId }) => {
  const cookie = Cookies.get('msj_folder_open')

  const [all, setAll] = useState(cookie === 'true')
  const [showForm, setShowForm] = useState(false)

  useEffect(() => {
    Cookies.set('msj_folder_open', all)
  }, [all])

  const intl = useIntl()

  const context = useContext(MainContainerContext)

  return (
    <StyledContainer className={className}>
      <SortFolders />

      <FolderList app={app} all={all} folderId={folderId} />

      <StyledColumn>
        {FOLDERS_TO_DISPLAY < Object.values(app.folders).length && (
          <StyledAll onClick={() => setAll(!all)}>
            <span>{intl.formatMessage(messages.all)}</span>
            <Icon icon={all ? chevronUp : chevronDown} />
          </StyledAll>
        )}

        {checkActionsAccess(app.id, session.profile, 'folders', 'add') ? (
            !!app.access.Folders ? (
              <StyledAdd onClick={() => setShowForm(!showForm)}>
                <Icon icon={showForm ? x : plus} />
                <span>{intl.formatMessage(showForm ? messages.cancel : messages.addFolder)}</span>
              </StyledAdd>
            ) : (
              <StyledAdd onClick={() => context.toggleUpgradeModal()}>
                <Icon icon={lock} />
                <span>{intl.formatMessage(messages.addFolder)}</span>
              </StyledAdd>
            )
          ) : null
        }
      </StyledColumn>

      {showForm && <AddFolderForm app={app} handleShowForm={() => setShowForm(!showForm)} />}
    </StyledContainer>
  )
}

Folders.propTypes = {
  app: PropTypes.object,
  session: PropTypes.object,
  className: PropTypes.string,
  folderId: PropTypes.string,
  toggleUpgradeModal: PropTypes.func,
}

export default connectStore({ app: ModuleApp, session: SessionModule })(withRouter(Folders))
