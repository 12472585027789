import { useState, useEffect } from 'react'
import { firebaseApp } from 'services/rsf'

const StatusCheck = () => {
  const [res, setRes] = useState()
  const [show, setShow] = useState(false)

  useEffect(() => {
    const connectedRef = firebaseApp.database().ref('.info/connected')
    connectedRef.on('value', (snap) => {
      if (!snap && !snap.val() && snap.val() !== true) {
        setRes({ statusCode: 500 })
      } else {
        setRes({ statusCode: 200 })
      }
    })
    setTimeout(() => setShow(true), 500)
  }, [])

  return (
    show && (
      <div
        style={{
          width: '100%',
          height: '100vh',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          flexDirection: 'column',
        }}
      >
        {res.statusCode === 200 ? (
          <>
            <h4>All is good!</h4>
            <span>{JSON.stringify(res)}</span>
            <img src="https://i.gifer.com/5PZO.gif" alt="" style={{ marginTop: '10px' }} />
          </>
        ) : (
          <>
            <h4>Fuuuu...!</h4>
            <span>{JSON.stringify(res)}</span>
            <img src="https://i.gifer.com/1Uop.gif" alt="" style={{ marginTop: '10px' }} />
          </>
        )}
      </div>
    )
  )
}
export default StatusCheck
