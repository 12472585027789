import PropTypes from 'prop-types'
import styled from 'styled-components'
import { LazyLoadImage } from 'react-lazy-load-image-component'
import 'react-lazy-load-image-component/src/effects/blur.css'

const StyledImageContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;

  img {
    max-width: 100%;
  }
`

const Img = ({ src, alt }) => (
  <StyledImageContainer>
    <LazyLoadImage alt={alt} effect="blur" src={src} />
  </StyledImageContainer>
)

Img.defaultProps = {
  src: '',
  alt: '',
}
Img.propTypes = {
  src: PropTypes.string,
  alt: PropTypes.string,
}

export default Img
