import PropTypes from 'prop-types'

import { StyledContainer, StyledAnimation } from './styles'

const Spinner = ({ style }) => (
  <StyledContainer style={style}>
    <StyledAnimation />
  </StyledContainer>
)

Spinner.defaultProps = {
  style: {},
}

Spinner.propTypes = {
  style: PropTypes.object,
}

export default Spinner
