const options = {
  timezone: 'America/Toronto',
  dateAndTimeFormat: {
    en: 'MM/DD/YYYY HH:mm',
    fr: 'DD/MM/YYYY HH:mm',
  },
  dateFormat: {
    en: 'MM/DD/YYYY',
    fr: 'DD/MM/YYYY',
  },
  dbDateFormat: 'MM/DD/YYYY HH:mm',
  values: {
    display: {
      allDay: false,
      range: [{ date: '' }, { date: '' }],
      repeat: 'no',
      rule: 'show',
      differentTimezones: false,
    },
  },
}

export default options
