import PropTypes from 'prop-types'
import { useEffect } from 'react'
import { BasicToast } from 'components'

const PageAlerts = ({
  copySuccess,
  deleteInProgress,
  deleteSuccess,
  onResetStatus,
  saveError,
  saveInProgress,
  saveSuccess,
}) => {
  useEffect(() => {
    if (!!saveSuccess || !!deleteSuccess || !!copySuccess) {
      setTimeout(() => onResetStatus(), 5000)
    }
  }, [saveSuccess, deleteSuccess, copySuccess])

  return (
    <>
      <BasicToast color="success" label="success" open={saveSuccess} toggle={() => onResetStatus()} />
      <BasicToast color="danger" label="error" open={saveError} toggle={() => onResetStatus()} />
      <BasicToast color="success" label="deleted" open={deleteSuccess} toggle={() => onResetStatus()} />
      <BasicToast color="success" label="copied" open={copySuccess} toggle={() => onResetStatus()} />
      <BasicToast color="primary" label="saving" open={saveInProgress || deleteInProgress} />
    </>
  )
}

PageAlerts.defaultProps = {
  copySuccess: false,
  deleteSuccess: false,
  deleteInProgress: false,
  onResetStatus: () => {},
  saveError: false,
  saveSuccess: false,
  saveInProgress: false,
}

PageAlerts.propTypes = {
  copySuccess: PropTypes.bool,
  deleteSucess: PropTypes.bool,
  deleteInProgress: PropTypes.bool,
  onResetStatus: PropTypes.func,
  saveError: PropTypes.bool,
  saveSuccess: PropTypes.bool,
  saveInProgress: PropTypes.bool,
}

export default PageAlerts
