import PropTypes from 'prop-types'
import { useState } from 'react'
import { useIntl } from 'react-intl'
import { BasicInput, BasicForm, BasicButton, ButtonWithConfirm } from 'components'
import styled from 'styled-components'

import { connectStore } from 'redux-box'
import { storeModule as DataModule } from 'store/data'

import { Icon } from 'react-icons-kit'
import { trash } from 'react-icons-kit/feather/trash'
import { check } from 'react-icons-kit/feather/check'
import { x } from 'react-icons-kit/feather/x'
import { edit2 } from 'react-icons-kit/feather/edit2'

import messages from './messages'

const StyledTheme = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 20px 0;
  border-bottom: 1px solid #eee;

  form {
    width: 100%;
  }
`

const StyledRow = styled.div`
  display: flex;
  flex-direction: row;

  b {
    font-weight: 700;
    margin-right: 5px;
  }

  p {
    margin: 0;
  }
`

const StyledButtons = styled.div`
  display: flex;
  flex-direction: row;
  width: 100;
  justify-content: flex-end;

  ${({ inform }) => inform === 'true' && 'margin-top: 12px'};

  button:first-of-type {
    margin-right: 5px;
  }
`

const ThemeRow = ({ data, theme }) => {
  const [edit, setEdit] = useState(false)

  const intl = useIntl()

  const handleOnEdit = () => setEdit(!edit)

  const handleOnDelete = () => data.deleteTheme(theme)

  const handleOnSubmit = (e, values) => {
    e.preventDefault()
    data.saveTheme(values)
    setEdit(!edit)
  }

  return (
    <StyledTheme key={theme.value}>
      {edit ? (
        <>
          <BasicForm onValidSubmit={handleOnSubmit} model={{ ...theme }}>
            <fieldset disabled={data.saving || data.deleting}>
              <BasicInput
                label={intl.formatMessage(messages.name)}
                helpMessage={intl.formatMessage(messages.nameHelp)}
                name="name"
                error={intl.formatMessage(messages.nameError)}
                required
              />

              <BasicInput
                label={intl.formatMessage(messages.value)}
                helpMessage={intl.formatMessage(messages.valueHelp)}
                name="value"
                error={intl.formatMessage(messages.valueError)}
                required
              />

              <StyledButtons inform="true">
                <BasicButton
                  icon={<Icon icon={x} />}
                  onClick={handleOnEdit}
                  outlined
                  round
                  color="pink"
                  small
                />
                <BasicButton icon={<Icon icon={check} />} type="submit" round color="green" small />
              </StyledButtons>
            </fieldset>
          </BasicForm>
        </>
      ) : (
        <>
          <StyledRow>
            <div>
              <p>
                <b>{intl.formatMessage(messages.name)}:</b>
                {theme.name}
              </p>

              <p>
                <b>{intl.formatMessage(messages.value)}:</b>
                {theme.value}
              </p>
            </div>
          </StyledRow>

          <StyledButtons>
            <ButtonWithConfirm
              icon={<Icon icon={trash} />}
              onConfirm={handleOnDelete}
              modalTitle={intl.formatMessage(messages.modalTitle)}
              modalText={`${intl.formatMessage(messages.modalText)} ¯\\_(ツ)_/¯`}
              modalSize="small"
              outlined
              round
              color="grey"
              small
            />

            <BasicButton
              icon={<Icon icon={edit2} />}
              onClick={handleOnEdit}
              round
              color="blue"
              small
              disabled
            />
          </StyledButtons>
        </>
      )}
    </StyledTheme>
  )
}

ThemeRow.defaultProps = {
  theme: { name: '', value: '' },
}

ThemeRow.propTypes = {
  data: PropTypes.object.isRequired,
  theme: PropTypes.object,
}

export default connectStore({ data: DataModule })(ThemeRow)
