/*
 * File Messages
 *
 * This contains all the text for the File component.
 */
import { defineMessages } from 'react-intl'

const scope = 'app.components.File'

export default defineMessages({
  actions: {
    id: `${scope}.actions`,
  },
  delete: {
    id: `${scope}.delete`,
  },
  deleteFile: {
    id: `${scope}.deleteFile`,
  },
  ofFacebook: {
    id: `${scope}.ofFacebook`,
  },
  removePublicAccess: {
    id: `${scope}.removePublicAccess`,
  },
  theFile: {
    id: `${scope}.theFile`,
  },
  voxPopName: {
    id: `${scope}.voxPopName`,
  },
  viewFacebook: {
    id: `${scope}.viewFacebook`,
  },
  unavailableLink: { id: `${scope}.unavailableLink` },
})
