import styled from 'styled-components'

export const StyledContainer = styled.div`
  display: flex;
  align-items: baseline;
  justify-content: ${({ title }) =>
    title === 'true' ? 'space-between' : 'flex-end'};
  flex-wrap: wrap;
  max-width: 771px;
  margin-bottom: 20px;
  ${({ title }) => title !== 'true' && 'margin-top: 20px'};

  @media (max-width: 579px) {
    margin-right: 0;
    flex-direction: column;
  }

  span {
    font-family: Nunito;
    font-weight: 600;
    font-size: 18px;

    @media (max-width: 579px) {
      margin-bottom: 20px;
    }
  }
`

export const StyledButtons = styled.div`
  max-width: 771px;
  display: flex;
  align-items: center;
  justify-content: flex-end;

  a,
  button {
    margin-left: 7px;
  }

  @media (max-width: 579px) {
    justify-content: flex-start;
    flex-wrap: wrap;

    a,
    button {
      margin-bottom: 10px;
    }
  }
`
