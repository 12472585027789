import { defineMessages } from 'react-intl'

const scope = 'app.components.PartActions.ButtonHalfForm'

export default defineMessages({
  link: { id: `${scope}.link` },
  linkError: { id: `${scope}.linkError` },
  linkHelp: { id: `${scope}.linkHelp` },
  linkPlaceholder: { id: `${scope}.linkPlaceholder` },
  image: { id: `${scope}.image` },
  imageHelp: { id: `${scope}.imageHelp` },
  msjBrowser: { id: `${scope}.msjBrowser` },
  msjBrowserHelp: { id: `${scope}.msjBrowserHelp` },
  required: { id: `${scope}.required` },
  return: { id: `${scope}.return` },
  returnHelp: { id: `${scope}.returnHelp` },
  text: { id: `${scope}.text` },
  textHelp: { id: `${scope}.textHelp` },
  textPlaceholder: { id: `${scope}.textPlaceholder` },
  warning: { id: `${scope}.warning` },

  BorderRadius0: { id: `${scope}.BorderRadius0` },
  BorderRadius10: { id: `${scope}.BorderRadius10` },
  BorderRadius25: { id: `${scope}.BorderRadius25` },
  BorderRadius50: { id: `${scope}.BorderRadius50` },
  BorderRadiusHelp: { id: `${scope}.BorderRadiusHelp` },
  BorderRadiusLabel: { id: `${scope}.BorderRadiusLabel` },
  BorderWidth0: { id: `${scope}.BorderWidth0` },
  BorderWidth1: { id: `${scope}.BorderWidth1` },
  BorderWidth2: { id: `${scope}.BorderWidth2` },
  BorderWidth3: { id: `${scope}.BorderWidth3` },
  BorderWidthHelp: { id: `${scope}.BorderWidthHelp` },
  BorderWidthLabel: { id: `${scope}.BorderWidthLabel` },
  NbrPerCol2: { id: `${scope}.NbrPerCol2` },
  NbrPerCol3: { id: `${scope}.NbrPerCol3` },
  NbrPerCol4: { id: `${scope}.NbrPerCol4` },
  NbrPerColHelp: { id: `${scope}.NbrPerColHelp` },
  NbrPerColLabel: { id: `${scope}.NbrPerColLabel` },
  StyleInfo: { id: `${scope}.StyleInfo` },
})
