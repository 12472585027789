import PropTypes from 'prop-types'
import { useIntl } from 'react-intl'
import { BasicWarning, BasicInput, PartOptions } from 'components'
import { validationsPatterns } from 'utils'

import messages from './messages'

const FacebookShareButtonForm = ({ onChange, part, partIndex, onFormChange }) => {
  const intl = useIntl()

  return (
    <>
      <PartOptions partIndex={partIndex} onChange={onChange} values={part.options} onFormChange={onFormChange} />

      <BasicInput
        name="text"
        partIndex={partIndex}
        value={part.text}
        required
        label={intl.formatMessage(messages.text)}
        helpMessage={intl.formatMessage(messages.textHelp)}
        error={intl.formatMessage(messages.textError)}
        onChange={(e) => {
          onChange(e, partIndex);
          onFormChange(true);
        }}
      />

      <BasicInput
        name="message"
        partIndex={partIndex}
        label={intl.formatMessage(messages.message)}
        helpMessage={intl.formatMessage(messages.messageHelp)}
        value={part.message}
        onChange={(e) => {
          onChange(e, partIndex);
          onFormChange(true);
        }}
      />

      <BasicInput
        name="hashtag"
        partIndex={partIndex}
        label={intl.formatMessage(messages.hashtag)}
        helpMessage={intl.formatMessage(messages.hastagHelp)}
        value={part.hashtag}
        onChange={(e) => {
          onChange(e, partIndex);
          onFormChange(true);
        }}
        validation={{ pattern: validationsPatterns.oneHashtag }}
        error={intl.formatMessage(messages.hashtagError)}
      />

      <BasicWarning text={intl.formatMessage(messages.warning)} />
    </>
  )
}

FacebookShareButtonForm.propTypes = {
  onChange: PropTypes.func,
  part: PropTypes.object,
  partIndex: PropTypes.number,
  onFormChange: PropTypes.func,
}

export default FacebookShareButtonForm
