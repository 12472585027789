import { Button } from 'reactstrap'
import styled, { css, keyframes } from 'styled-components'
import { getColor } from 'styles/functions'
import { Link } from 'react-router-dom'

const setColor = ({ color, noborder, linkstyle }) => {
  if (noborder === 'true' || linkstyle === 'true') {
    return 'transparent'
  }
  return getColor(color)
}

const setColorOnHover = ({ color, outlined }) => {
  if (outlined === 'true') {
    return getColor(color)
  }
  return 'transparent'
}

const getPadding = ({ small, medium, round, linkstyle }) => {
  if (linkstyle === 'true') return 0
  if (small === 'true') return '2px 10px'
  if (round === 'true') return '2px 5px'
  if (medium === 'true') return '5px 10px'
  return '10px 20px'
}

const getRadius = ({ round, medium, radius }) => {
  if (!!radius) return `${radius}px`
  if (round === 'true') return '100%'
  if (medium === 'true') return '8px'
  return '16px'
}

const getFontSize = ({ small, medium }) => {
  if (small === 'true') return '12px'
  if (medium === 'true') return '13px'
  return '15px'
}

export const StyledButton = styled(Button)`
  appearance: none !important;
  height: ${({ round }) => (round === 'true' ? '30px' : 'fit-content')};
  width: ${({ round }) => (round === 'true' ? '30px' : 'fit-content')};
  ${({ minwidth }) => minwidth && `min-width: ${minwidth}px`};
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: ${(props) => getFontSize(props)};
  font-weight: 600;
  color: ${({ color, outlined }) => (outlined === 'true' ? getColor(color) : 'white')};
  background-color: ${({ color, outlined }) => (outlined === 'true' ? 'transparent' : getColor(color))};
  border: 2px solid ${(props) => setColor(props)};
  border-radius: ${(props) => getRadius(props)};
  padding: ${(props) => getPadding(props)};
  transition: all 0.2s ease-in-out;
  cursor: pointer;
  white-space: nowrap !important;
  outline: none;
  word-break: break-all;

  ${(props) =>
    props.linkstyle === 'true' &&
    css`
      background: transparent;
      color: #416db2;
      outlined: none;
    `}

  i {
    display: flex !important;
    ${({ round }) => round !== 'true' && 'margin-right: 5px'};
  }

  &:hover:not(:disabled),
  &:focus,
  &:active {
    color: ${({ color, outlined }) => (outlined === 'true' ? 'white' : getColor(color))};
    background-color: ${(props) => setColorOnHover(props)};
    border: 2px solid ${({ color, linkstyle }) => (linkstyle === 'true' ? 'transparent' : getColor(color))};
    text-decoration: none;

    ${({ linkstyle }) =>
      linkstyle === 'true' &&
      css`
        text-decoration: underline !important;
        color: ${getColor('blue')} !important;
        box-shadow: none;
      `}
  }

  &:disabled {
    color: white;
    background-color: #6c757d;
    border: 2px solid #6c757d;
    pointer-events: none;
    cursor: default;
  }
`

export const StyledLink = styled(Link)`
  appearance: none !important;
  height: ${({ round }) => (round === 'true' ? '30px' : 'fit-content')};
  width: ${({ round }) => (round === 'true' ? '30px' : 'fit-content')};
  ${({ minwidth }) => minwidth && `min-width: ${minwidth}px`};
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: ${(props) => getFontSize(props)};
  font-weight: 600;
  color: ${({ color, outlined }) => (outlined === 'true' ? getColor(color) : 'white')};
  background-color: ${({ color, outlined }) => (outlined === 'true' ? 'transparent' : getColor(color))};
  border: 2px solid ${(props) => setColor(props)};
  border-radius: ${(props) => getRadius(props)};
  padding: ${(props) => getPadding(props)};
  transition: all 0.2s ease-in-out;
  cursor: pointer;
  white-space: nowrap !important;
  outline: none;
  word-break: break-all;

  ${(props) =>
    props.linkstyle === 'true' &&
    css`
      background: transparent;
      color: #416db2;
      outlined: none;
    `}

  i {
    display: flex !important;
    ${({ round }) => round !== 'true' && 'margin-right: 5px'};
  }

  &:hover:not(:disabled),
  &:focus,
  &:active {
    color: ${({ color, outlined }) => (outlined === 'true' ? 'white' : getColor(color))};
    background-color: ${(props) => setColorOnHover(props)};
    border: 2px solid ${({ color, linkstyle }) => (linkstyle === 'true' ? 'transparent' : getColor(color))};
    text-decoration: none;

    ${({ linkstyle }) =>
      linkstyle === 'true' &&
      css`
        text-decoration: underline;
      `}
  }

  &[disabled] {
    color: white;
    background-color: #9aa0a4;
    border: 2px solid #9aa0a4;
    pointer-events: none;
    cursor: default;
  }
`

export const StyledExternalLink = styled.a`
  appearance: none !important;
  height: ${({ round }) => (round === 'true' ? '30px' : 'fit-content')};
  width: ${({ round }) => (round === 'true' ? '30px' : 'fit-content')};
  ${({ minwidth }) => minwidth && `min-width: ${minwidth}px`};
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: ${(props) => getFontSize(props)};
  font-weight: 600;
  color: ${({ color, outlined }) => (outlined === 'true' ? getColor(color) : 'white')};
  background-color: ${({ color, outlined }) => (outlined === 'true' ? 'transparent' : getColor(color))};
  border: 2px solid ${(props) => setColor(props)};
  border-radius: ${(props) => getRadius(props)};
  padding: ${(props) => getPadding(props)};
  transition: all 0.2s ease-in-out;
  cursor: pointer;
  white-space: nowrap !important;
  outline: none;
  word-break: break-all;

  ${(props) =>
  props.linkstyle === 'true' &&
  css`
      background: transparent;
      color: #416db2;
      outlined: none;
    `}

  i {
    display: flex !important;
    ${({ round }) => round !== 'true' && 'margin-right: 5px'};
  }

  &:hover:not(:disabled),
  &:focus,
  &:active {
    color: ${({ color, outlined }) => (outlined === 'true' ? 'white' : getColor(color))};
    background-color: ${(props) => setColorOnHover(props)};
    border: 2px solid ${({ color, linkstyle }) => (linkstyle === 'true' ? 'transparent' : getColor(color))};
    text-decoration: none;

    ${({ linkstyle }) =>
  linkstyle === 'true' &&
  css`
        text-decoration: underline;
      `}
  }

  &[disabled] {
    color: white;
    background-color: #9aa0a4;
    border: 2px solid #9aa0a4;
    pointer-events: none;
    cursor: default;
  }
`

const spin = keyframes`
  from {
      transform:rotate(0deg);
  }
  to {
      transform:rotate(360deg);
  }
`

export const StyledLoader = styled.div`
  i {
    display: flex !important;
    animation: ${spin} 3s linear infinite;
  }
`
