import PropTypes from 'prop-types'
import { useState, useEffect } from 'react'
import { useIntl } from 'react-intl'
import { AvForm } from 'availity-reactstrap-validation'
import { LayoutCard } from 'components'
import { scrollToError } from 'utils'

import { connectStore } from 'redux-box'
import { storeModule as ModuleModules } from 'store/modules'

import messages from '../messages'
import PageActions from '../PageActions'
import GeneralInfo from './GeneralInfo'
import Inputs from './Inputs'

const TriggerEditForm = ({ appId, data, isNew, modules, onCancel, onDelete, onSave }) => {
  const [selectedInputs, setSelectedInputs] = useState([])

  const intl = useIntl()

  const selectableInputs = modules.getList('input') || []

  const inputData = (data && data.inputIds) || []
  const defaultValues = data || { onSuccess: { action: 'redirect' } }

  useEffect(() => {
    setSelectedInputs(getSelectedInputs)
  }, [])

  const getSelectedInputs = () => {
    if (data && data.inputIds && Object.keys(data.inputIds).length > 0) {
      const t = inputData.map((id) => selectableInputs.find((input) => input.id === id)).filter((i) => i)
      return t
    }
    return []
  }

  const handleSelectChange = (values) => {
    setSelectedInputs(values)
  }

  const handleOnSubmit = (e, errors, values) => {
    e.preventDefault()
    if (errors.length) {
      scrollToError()
      return false
    }
    values.inputIds = selectedInputs.map((input) => input.id)
    modules.saveModule(appId, 'trigger', values, onSave)
    setTimeout(() => modules.resetStatus('trigger'), 5000)
  }

  return (
    <AvForm onSubmit={handleOnSubmit} model={defaultValues}>
      <fieldset disabled={modules.trigger.saving}>
        <LayoutCard
          title={isNew ? intl.formatMessage(messages.newTrigger) : intl.formatMessage(messages.edit)}
          headerChildren={
            <PageActions
              onCancel={onCancel}
              onDelete={onDelete}
              loading={modules.trigger.saving}
              isNew={isNew}
            />
          }
          footerChildren={
            <PageActions
              onCancel={onCancel}
              onDelete={onDelete}
              loading={modules.trigger.saving}
              isNew={isNew}
            />
          }
        >
          <GeneralInfo data={data} id={(data && data.id) || ''} />

          <hr />

          <Inputs
            data={data}
            selectableInputs={selectableInputs}
            selectedInputs={selectedInputs || getSelectedInputs()}
            onSelectChange={(values) => handleSelectChange(values)}
            id={(data && data.id) || ''}
          />
        </LayoutCard>
      </fieldset>
    </AvForm>
  )
}

TriggerEditForm.defaultProps = {
  onCancel: () => {},
  onDelete: () => {},
  onSave: () => {},
  onSubmit: () => {},
}

TriggerEditForm.propTypes = {
  appId: PropTypes.any,
  data: PropTypes.any,
  isNew: PropTypes.bool,
  modules: PropTypes.any,
  onCancel: PropTypes.func,
  onDelete: PropTypes.func,
  onSave: PropTypes.func,
}

export default connectStore({ modules: ModuleModules })(TriggerEditForm)
