import PropTypes from 'prop-types'
import objectPath from 'object-path'
import { useState } from 'react'
import uuidv4 from 'uuid/v4'
import FirebaseApi from 'services/FirebaseApi'
import { TEMPLATES } from 'utils/consts'
import { rteModulesId } from 'utils/routes'
import { scrollToError } from 'utils'
import history from 'services/history'
import { AvForm } from 'availity-reactstrap-validation'
import { resizeImage } from 'components/CardEditForm/helpers'
import { LayoutCard } from 'components'
import { useIntl } from 'react-intl'

import { connectStore } from 'redux-box'
import { storeModule as ModuleModules } from 'store/modules'
import { storeModule as CardModule } from 'store/card'
import { storeModule as CardListModule } from 'store/cardList'

import { getNewCard } from './helpers'
import GeneralInfo from './GeneralInfo'
import PageInfo from './PageInfo'
import Template from './Template'
import Trigger from './Trigger'
import messages from '../messages'
import PageActions from '../PageActions'

import whiteSquare from '../../../assets/img/whitesquare.png';

const ItemEditForm = ({
  appId,
  card,
  cardList,
  data,
  inList,
  isNew,
  modules,
  noDelete,
  onCancel,
  onDelete,
  onSave,
  styleComponent,
}) => {
  const [template, setTemplate] = useState(data && data.template ? data.template : 'default')

  const intl = useIntl()

  const handleTemplateChange = async (values) => {
    if (template !== 'default' && TEMPLATES[template]) {
      for (const input of TEMPLATES[template]) {
        if (input.inputType === 'file') {
          const file = objectPath.get(values, input.name)

          if (file && file.name) {
            const fileUrl = await FirebaseApi.uploadFile(file, appId)
            objectPath.set(values, input.name, fileUrl)
          }
        }
      }
    }
    return values
  }

  const handlePage = (values) => {
    if (!values.cardId || values.cardId === '') {
      const newCard = getNewCard(values, appId)
      card.saveCard(newCard, { id: appId }, true)
    } else {
      const page = cardList.getCardById(values.cardId)
      if (values.pageTitle) {
        const newCard = { ...page, title: values.pageTitle }
        card.updateCard(newCard, appId)
      }
    }
  }

  const handleImgFiles = async (values) => {
    if (values.img && values.img.name) {
      const img = await resizeImage(values.img, 1920, 1920)
      values.img = await FirebaseApi.uploadFileDataUrl(img, appId)
    }
    if (values.icon && values.icon.name) {
      const img = await resizeImage(values.icon, 600, 600)
      values.icon = await FirebaseApi.uploadFileDataUrl(img, appId)
    }else if (!values.icon && !values.icon.name){
      values.icon = (whiteSquare);
    }
    return values
  }

  const handleOnSubmit = async (e, errors, values) => {
    e.preventDefault()
    if (errors.length) {
      scrollToError()
      return false
    }
    if (!values.id || values.id === '') values.id = uuidv4()
    handlePage(values)

    values = await handleImgFiles(values)
    values = await handleTemplateChange(values)

    modules.saveModule(appId, 'listitem', values)

    if (!inList) history.push(rteModulesId(appId, 'ITEMS_SINGLE', values.id))
    onSave(values.id)
  }

  return (
    <AvForm onSubmit={handleOnSubmit} model={data || { name: '', template: 'default' }}>
      <fieldset disabled={modules.listitem.saving}>
        <LayoutCard
          title={isNew ? intl.formatMessage(messages.newItem) : intl.formatMessage(messages.edit)}
          headerChildren={
            <PageActions
              onCancel={onCancel}
              onDelete={onDelete}
              loading={modules.listitem.saving}
              isNew={isNew}
              noDelete={noDelete}
              id={data ? data.id : ''}
            />
          }
          footerChildren={
            <PageActions
              onCancel={onCancel}
              onDelete={onDelete}
              loading={modules.listitem.saving}
              isNew={isNew}
              noDelete={noDelete}
              id={data ? data.id : ''}
            />
          }
        >
          <GeneralInfo
            icon={data && data.icon}
            getModuleByName={modules.getModuleByName}
            id={(data && data.id) || ''}
          />

          <hr />

          {styleComponent && (
            <>
              {styleComponent}
              <hr />
            </>
          )}

          <PageInfo image={data && data.img} />

          <hr />

          <Trigger appId={appId} triggers={modules.getList('trigger') || []} />

          <Template
            appId={appId}
            data={data}
            template={template}
            onTemplateChange={(e) => setTemplate(e.target.value)}
          />
        </LayoutCard>
      </fieldset>
    </AvForm>
  )
}

ItemEditForm.defaultProps = {
  onSave: () => {},
  onCancel: () => {},
  onDelete: () => {},
}

ItemEditForm.propTypes = {
  appId: PropTypes.string,
  card: PropTypes.any.isRequired,
  cardList: PropTypes.object,
  data: PropTypes.any,
  inList: PropTypes.bool,
  modules: PropTypes.any.isRequired,
  noDelete: PropTypes.bool,
  onCancel: PropTypes.func,
  onDelete: PropTypes.func,
  onSave: PropTypes.func,
  styleComponent: PropTypes.node,
}

export default connectStore({ modules: ModuleModules, card: CardModule, cardList: CardListModule })(
  ItemEditForm
)
