import PropTypes from 'prop-types'
import { useState } from 'react'
import { useIntl } from 'react-intl'
import { BasicInput, BasicLabel, BasicCheckbox, BasicDatePicker } from 'components'
import { newEvent } from 'utils'

import { ic_date_range as date } from 'react-icons-kit/md/ic_date_range'
import { ic_schedule as schedule } from 'react-icons-kit/md/ic_schedule'

import { valuesToDateRange } from './utils'
import messages from '../messages'

import { StyledTimeDateIcon, DatePickerWrapper } from './styles'

const TimeAndDateFields = ({ values, onChange, onDateChange, onFormChange }) => {
  const [allDay, setAllDay] = useState(values.allDay || false)

  const intl = useIntl()

  const range = valuesToDateRange(values)

  // this is a patch to handle the string coming from the data...
  // something weird is going on with the init timer?
  const handleCheckedChange = (e) => {
    const { value } = e.target
    const v = value === 'true' ? false : true
    setAllDay(v)
    onChange(newEvent('options.display.allDay', v))
    onFormChange(true);
  }

  return (
    <>
      <BasicLabel
        label={
          <span style={{ display: 'flex', marginTop: '10px' }}>
            {intl.formatMessage(messages.displayTitle)}
            <StyledTimeDateIcon icon={date} />
            <StyledTimeDateIcon icon={schedule} />
          </span>
        }
        helpMessage={intl.formatMessage(messages.displayTitleHelp)}
      />

      <DatePickerWrapper>
        <BasicInput name="options.display.rule" type="select" onChange={(e) => {onChange(e);onFormChange(true);}} value={values.rule}>
          <option value="show">{intl.formatMessage(messages.showContent)}</option>
          <option value="hide">{intl.formatMessage(messages.hideContent)}</option>
        </BasicInput>

        <BasicDatePicker name="options.display" values={range} onChange={onDateChange} allDay={allDay} onFormChange={onFormChange} />
      </DatePickerWrapper>

      <div style={{ marginBottom: '20px' }}>
        <BasicCheckbox
          label={intl.formatMessage(messages.allDay)}
          helpMessage={intl.formatMessage(messages.allDayHelp)}
          name="options.display.allDay"
          value={Boolean(allDay)}
          onChange={handleCheckedChange}
        />
      </div>
    </>
  )
}

TimeAndDateFields.propTypes = {
  values: PropTypes.any,
  onChange: PropTypes.func,
  onDateChange: PropTypes.func,
  onFormChange: PropTypes.func,
}

export default TimeAndDateFields
