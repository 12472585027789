import PropTypes from 'prop-types'
import { PhotoboothForm, ListForm } from 'components'

const Premium = ({ part, partIndex, app, onChange, toggleDisable, onFormChange }) => {
  return (
    <>
      {part.type === 'Voxpop' && (
        <PhotoboothForm
          appId={app.id}
          onChange={onChange}
          options={part.options}
          part={part}
          partIndex={partIndex}
          toggleDisable={toggleDisable}
          onFormChange={onFormChange}
        />
      )}

      {part.type === 'Photobooth' && (
        <PhotoboothForm
          appId={app.id}
          onChange={onChange}
          options={part.options}
          partIndex={partIndex}
          toggleDisable={toggleDisable}
          id={part.id}
          voxpopId={part.voxpopId}
          onFormChange={onFormChange}
        />
      )}

      {part.type === 'List' && (
        <ListForm
          appId={app.id}
          onChange={onChange}
          part={part}
          partIndex={partIndex}
          options={part.options}
          onFormChange={onFormChange}
        />
      )}
    </>
  )
}

Premium.propTypes = {
  app: PropTypes.object,
  onChange: PropTypes.func,
  part: PropTypes.object,
  partIndex: PropTypes.number,
  toggleDisable: PropTypes.func,
  onFormChange: PropTypes.func,
}

export default Premium
