import PropTypes from 'prop-types'

import { Icon } from 'react-icons-kit'
import { loader } from 'react-icons-kit/feather/loader'

import { StyledButton, StyledLoader, StyledLink, StyledExternalLink } from "./styles";

const BasicButton = ({
  color,
  disabled,
  href,
  icon,
  id,
  isLink,
  label,
  linkstyle,
  loading,
  medium,
  minwidth,
  noBorder,
  onClick,
  outlined,
  radius,
  round,
  small,
  target,
  type,
  iconAfter,
}) => {
  const isExternalLink = href && (href.startsWith('http') || href.startsWith('https'));

  return isLink ? (
    isExternalLink ? (
      <StyledExternalLink
        href={href}
        color={color}
        id={id}
        linkstyle={String(linkstyle)}
        className={`${medium ? 'medium' : ''} ${small ? 'small' : ''} ${noBorder ? 'no-border' : ''}`}
        onClick={onClick}
        target={target}
        rel={target === '_blank' ? 'noopener noreferrer' : undefined}
        disabled={disabled}
      >
        {loading && (
          <StyledLoader>
            <Icon icon={loader} />
          </StyledLoader>
        )}
        {!iconAfter && icon}
        {label}
        {iconAfter && icon}
      </StyledExternalLink>
    ) : (
      <StyledLink
        to={href}
        color={color}
        id={id}
        linkstyle={String(linkstyle)}
        medium={String(medium)}
        minwidth={minwidth}
        noborder={String(noBorder)}
        onClick={onClick}
        outlined={outlined ? 'true' : ''}
        round={String(round)}
        small={String(small)}
        target={target}
        type={type}
        disabled={disabled}
        radius={radius}
      >
        {loading && (
          <StyledLoader>
            <Icon icon={loader} />
          </StyledLoader>
        )}
        {!iconAfter && icon}
        {label}
        {iconAfter && icon}
      </StyledLink>
    )
  ) : (
    <StyledButton
      color={color}
      href={href}
      id={id}
      linkstyle={String(linkstyle)}
      medium={String(medium)}
      minwidth={minwidth}
      noborder={String(noBorder)}
      onClick={onClick}
      outlined={String(outlined)}
      round={String(round)}
      small={String(small)}
      type={type}
      target={target}
      disabled={disabled}
      radius={radius}
    >
      {loading && (
        <StyledLoader>
          <Icon icon={loader} />
        </StyledLoader>
      )}
      {!iconAfter && icon}
      {label}
      {iconAfter && icon}
    </StyledButton>
  )
}

BasicButton.defaultProps = {
  color: '',
  disabled: false,
  isLink: false,
  label: '',
  onClick: () => {},
  type: 'button',
}

BasicButton.propTypes = {
  color: PropTypes.string,
  disabled: PropTypes.bool,
  href: PropTypes.string,
  icon: PropTypes.any,
  id: PropTypes.string,
  isLink: PropTypes.bool,
  label: PropTypes.any,
  linkstyle: PropTypes.any,
  loading: PropTypes.bool,
  medium: PropTypes.bool,
  minwidth: PropTypes.number,
  noBorder: PropTypes.bool,
  onClick: PropTypes.func,
  outlined: PropTypes.any,
  radius: PropTypes.number,
  round: PropTypes.bool,
  small: PropTypes.any,
  target: PropTypes.string,
  type: PropTypes.string,
  iconAfter: PropTypes.bool,
}

export default BasicButton
