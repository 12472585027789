import { Component } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import Transition from 'react-transition-group/Transition'
import {
  mapToCssModules,
  omit,
  pick,
  TransitionTimeouts,
  TransitionPropTypeKeys,
  TransitionStatuses,
} from './utils'

const defaultProps = {
  ...Transition.defaultProps,
  isOpen: false,
  appear: false,
  enter: true,
  exit: true,
  tag: 'div',
  timeout: TransitionTimeouts.Collapse,
}

const transitionStatusToClassHash = {
  [TransitionStatuses.ENTERING]: 'fading',
  [TransitionStatuses.ENTERED]: 'fading show',
  [TransitionStatuses.EXITING]: 'fading',
  [TransitionStatuses.EXITED]: 'fading hide',
}

function getTransitionClass(status) {
  return transitionStatusToClassHash[status] || 'collapse'
}

class Fade extends Component {
  constructor(props) {
    super(props)

    this.state = {
      opacity: null,
    }
    const binds = ['onEntering', 'onEntered', 'onExit', 'onExiting', 'onExited']
    binds.forEach((name) => {
      this[name] = this[name].bind(this)
    })
  }

  onEntering(node, isAppearing) {
    this.setState({ opacity: 1 })
    this.props.onEntering(node, isAppearing)
  }

  onEntered(node, isAppearing) {
    this.setState({ opacity: null })
    this.props.onEntered(node, isAppearing)
  }

  onExit(node) {
    this.setState({ opacity: 1 })
    this.props.onExit(node)
  }

  onExiting(node) {
    // getting this variable triggers a reflow
    const _unused = node.offsetHeight // eslint-disable-line no-unused-vars
    this.setState({ opacity: 0 })
    this.props.onExiting(node)
  }

  onExited(node) {
    this.setState({ opacity: null })
    this.props.onExited(node)
  }

  render() {
    const { tag: Tag, isOpen, className, navbar, cssModule, children, innerRef, ...otherProps } = this.props

    const { opacity } = this.state

    // In NODE_ENV=production the Transition.propTypes are wrapped which results in an
    // empty object "{}". This is the result of the `react-transition-group` babel
    // configuration settings. Therefore, to ensure that production builds work without
    // error, we can either explicitly define keys or use the Transition.defaultProps.
    // Using the Transition.defaultProps excludes any required props. Thus, the best
    // solution is to explicitly define required props in our utilities and reference these.
    // This also gives us more flexibility in the future to remove the prop-types
    // dependency in distribution builds (Similar to how `react-transition-group` does).
    // Note: Without omitting the `react-transition-group` props, the resulting child
    // Tag component would inherit the Transition properties as attributes for the HTML
    // element which results in errors/warnings for non-valid attributes.
    const transitionProps = pick(otherProps, TransitionPropTypeKeys)
    const childProps = omit(otherProps, TransitionPropTypeKeys)
    return (
      <Transition
        {...transitionProps}
        in={isOpen}
        onEntering={this.onEntering}
        onEntered={this.onEntered}
        onExit={this.onExit}
        onExiting={this.onExiting}
        onExited={this.onExited}
      >
        {(status) => {
          const collapseClass = getTransitionClass(status)
          const classes = mapToCssModules(
            classNames(className, collapseClass, navbar && 'navbar-collapse'),
            cssModule
          )
          const style = {
            opacity: opacity === null ? null : opacity,
          }
          return (
            <Tag
              {...childProps}
              style={{ ...childProps.style, ...style }}
              className={classes}
              ref={this.props.innerRef}
            >
              {children}
            </Tag>
          )
        }}
      </Transition>
    )
  }
}

const propTypes = {
  // eslint-disable-next-line react/forbid-foreign-prop-types
  ...Transition.propTypes,
  isOpen: PropTypes.bool,
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
  tag: PropTypes.oneOfType([PropTypes.func, PropTypes.string]),
  className: PropTypes.node,
  navbar: PropTypes.bool,
  cssModule: PropTypes.object,
  innerRef: PropTypes.oneOfType([PropTypes.func, PropTypes.string, PropTypes.object]),
}
Fade.propTypes = propTypes
Fade.defaultProps = defaultProps
export default Fade
