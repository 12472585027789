import PropTypes from 'prop-types'
import { useIntl } from 'react-intl'
import { CardBody } from 'reactstrap'
import { minus } from 'react-icons-kit/fa/minus'

import messages from '../messages'
import Folders from './Folders'
import Settings from './Settings'
import { StyledCloseIcon, StyledCollapse, StyledHeaderContainer, StyledTitle } from './styles'

const PropertiesPanel = ({ app, handleChange, onCheckedChange, isOpen, option, toggleClose, onAddFolder, onFormChange }) => {
  const intl = useIntl()

  return (
    <StyledCollapse isOpen={isOpen}>
      <StyledHeaderContainer>
        <StyledTitle>{intl.formatMessage(messages.properties)}</StyledTitle>
        <StyledCloseIcon onClick={toggleClose} icon={minus} />
      </StyledHeaderContainer>

      <CardBody>
        {option === 'folders' ? (
          <Folders app={app} handleChange={handleChange} onAddFolder={onAddFolder} onFormChange={onFormChange} />
        ) : (
          <Settings handleChange={handleChange} onCheckedChange={onCheckedChange} onFormChange={onFormChange} />
        )}
      </CardBody>
    </StyledCollapse>
  )
}

PropertiesPanel.defaultProps = {
  open: false,
  toggleClose: () => {},
  onAddFolder: () => {},
  option: '',
}

PropertiesPanel.propTypes = {
  app: PropTypes.object,
  handleChange: PropTypes.func,
  onCheckedChange: PropTypes.func,
  option: PropTypes.string,
  isOpen: PropTypes.bool,
  toggleClose: PropTypes.func,
  onAddFolder: PropTypes.func,
  onFormChange: PropTypes.func,
}

export default PropertiesPanel
