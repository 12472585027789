import { defineMessages } from 'react-intl'

const scope = 'app.containers.CardEditForm'

export default defineMessages({
  addPart: { id: `${scope}.addPart` },
  cancel: { id: `${scope}.cancel` },
  cancelText: { id: `${scope}.cancelText` },
  cancelTitle: { id: `${scope}.cancelTitle` },
  confirm: { id: `${scope}.confirm` },
  content: { id: `${scope}.content` },
  createCard: { id: `${scope}.createCard` },
  deleteCard: { id: `${scope}.deleteCard` },
  editCard: { id: `${scope}.editCard` },
  locks: { id: `${scope}.locks` },
  modalContent: { id: `${scope}.modalContent` },
  modalTitle: { id: `${scope}.modalTitle` },
  save: { id: `${scope}.save` },
  saveError: { id: `${scope}.saveError` },
  saveSuccess: { id: `${scope}.saveSuccess` },
  savingProgress: { id: `${scope}.savingProgress` },
  viewPage: { id: `${scope}.viewPage` },
  previewTitle: { id: `${scope}.previewTitle` },
  previewInfo: { id: `${scope}.previewInfo` },
})
