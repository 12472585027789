import PropTypes from 'prop-types'
import { Fragment } from 'react'
import { Link } from 'react-router-dom'

import { StyledButtonHalf, StyledHalfContainer, StyledContainerItem } from './styles'

// mes questions:
// - Pourquoi on n'a pas pris le half button dans component?
const ListHalfButton = ({ allParts, currentIndex }) => {
  const isBtnHalf = (part) => part && part.type === 'Button-Half'
  let loopTestNext = true

  if (!isBtnHalf(allParts[currentIndex]) || isBtnHalf(allParts[currentIndex - 1])) {
    return null
  }

  const currentBtnParts = allParts.filter((part, i) => {
    if (i < currentIndex || !loopTestNext) return false
    if (i === currentIndex) return true
    if (isBtnHalf(part)) return true
    loopTestNext = false
    return false
  })

  const getStyle = (name, part) => {
    if (part && part.style && part.style[name]) {
      return part.style[name]
    }
    return null
  }

  return (
    <StyledHalfContainer>
      {currentBtnParts.map((part, i) => (
        <Fragment key={i}>
          <StyledContainerItem columns={getStyle('nbrPerCol', part)}>
            {part.return ? (
              <>
                <StyledButtonHalf
                  borderWidth={getStyle('borderWidth', part) || '3'}
                  borderRadius={getStyle('borderRadius', part) || '50'}
                  id="back button"
                  style={part.url && part.url.length > 0 && { backgroundImage: `url("${part.url}")` }}
                  onClick={() => window.history.back()}
                >
                  <span style={{ display: 'none' }}>{part.text}</span>
                </StyledButtonHalf>

                <span id="back link" onClick={() => window.history.back()}>
                  {part.text}
                </span>
              </>
            ) : (
              <>
                {part.link && part.link.length > 0 ? (
                  <>
                    <StyledButtonHalf
                      tag={part.msjBrowser ? Link : 'button'}
                      href={part.msjBrowser ? '' : part.link}
                      to={part.msjBrowser ? '' : part.link}
                      borderWidth={getStyle('borderWidth', part) || '3'}
                      borderRadius={getStyle('borderRadius', part) || '50'}
                      id="action with picture button"
                      style={part.url && part.url.length > 0 && { backgroundImage: `url("${part.url}")` }}
                      target={part.target}
                    >
                      <span style={{ display: 'none' }}>{part.text}</span>
                    </StyledButtonHalf>

                    <a id="action with picture link" href={part.link}>
                      <span>{part.text}</span>
                    </a>
                  </>
                ) : (
                  <>
                    <StyledButtonHalf
                      borderWidth={getStyle('borderWidth', part) || '3'}
                      borderRadius={getStyle('borderRadius', part) || '50'}
                      id="action with picture button"
                      style={
                        part.url && part.url.length > 0
                          ? {
                              backgroundImage: `url("${part.url}")`,
                              pointerEvents: 'none',
                            }
                          : {
                              pointerEvents: 'none',
                            }
                      }
                      target={part.target}
                    >
                      <span style={{ display: 'none' }}>{part.text}</span>
                    </StyledButtonHalf>

                    <span>{part.text}</span>
                  </>
                )}
              </>
            )}
          </StyledContainerItem>
        </Fragment>
      ))}
    </StyledHalfContainer>
  )
}

ListHalfButton.propTypes = {
  allParts: PropTypes.array,
  currentIndex: PropTypes.any,
}

export default ListHalfButton
