import PropTypes from 'prop-types'
import { useEffect } from 'react'
import { useIntl } from 'react-intl'
import { connectStore } from 'redux-box'
import { storeModule as DataModule } from 'store/data'

import { StyledCard, StyledSmallLegend } from './styles'
import messages from './messages'

const VisitCounter = ({ data, appId }) => {
  const intl = useIntl()

  useEffect(() => data.getVisitCounter(appId), [])

  return (
    <>
      {data.visitCounters.length > 0 && (
        <>
          <StyledSmallLegend>{intl.formatMessage(messages.visitCounter)}</StyledSmallLegend>

          {data.visitCounters.map((counter) => (
            <StyledCard>
              <p>
                {intl.formatMessage(messages.name)}:&nbsp;{counter.name}
              </p>

              <p style={{ marginBottom: '0' }}>
                {intl.formatMessage(messages.total)}:&nbsp;{counter.nbrVisits}
              </p>
            </StyledCard>
          ))}
        </>
      )}
    </>
  )
}

VisitCounter.propTypes = {
  appId: PropTypes.string,
  data: PropTypes.object,
}

export default connectStore({ data: DataModule })(VisitCounter)
