const state = {
  visitCounters: [],
  themes: {},
  fetching: false,
  error: false,
  saving: false,
  success: false,
  deleting: false,
  deleted: false,
}
export default state
