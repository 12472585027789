import { TabContent } from 'reactstrap'
import styled from 'styled-components'

export const StyledContainer = styled.div`
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;

  @media (max-width: 576px) {
    flex-direction: column-reverse;
    margin-bottom: 20px;
    width: fit-content;

    button {
      margin-top: 10px;
    }
  }
`

export const StyledTabContent = styled(TabContent)`
  background: #fff;
  padding: 1rem;
  border-radius: 0 0 5px 5px;
  border: 1px solid #dee2e6;
  border-top: 0;
`

export const StyledButton = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  justify-content: flex-end;
  flex: 0;
  padding-left: 20px;
`
