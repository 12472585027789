import PropTypes from 'prop-types'
import { TriggerForm, ItemForm, InputForm, PartVisitCounterForm } from 'components'

const Advanced = ({ part, partIndex, app, onChange, onCheckedChange, onFormChange }) => {
  return (
    <>
      {part.type === 'Trigger' && (
        <TriggerForm appId={app.id} onChange={onChange} part={part} partIndex={partIndex} onFormChange={onFormChange} />
      )}

      {part.type === 'ListItem' && (
        <ItemForm appId={app.id} onChange={onChange} part={part} partIndex={partIndex} onFormChange={onFormChange} />
      )}

      {part.type === 'Input' && (
        <InputForm appId={app.id} onChange={onChange} part={part} partIndex={partIndex} onFormChange={onFormChange} />
      )}

      {part.type === 'VisitCounter' && (
        <PartVisitCounterForm
          partIndex={partIndex}
          handleOnChange={onChange}
          handleCheckChange={onCheckedChange}
          name={part.name}
          displayResult={part.displayResult}
          options={part.options}
          onFormChange={onFormChange}
        />
      )}
    </>
  )
}

Advanced.propTypes = {
  app: PropTypes.object,
  onChange: PropTypes.func,
  onCheckedChange: PropTypes.func,
  part: PropTypes.object,
  partIndex: PropTypes.number,
  onFormChange: PropTypes.func,
}

export default Advanced
