import PropTypes from 'prop-types'
import { useIntl } from 'react-intl'
import connectStore from 'redux-box/dist/connectStore'
import { storeModule as ModuleModules } from 'store/modules'

import { Icon } from 'react-icons-kit'
import { power } from 'react-icons-kit/feather/power'

import NotFound from './NotFound'
import { StyledTrigger } from './styles'
import messages from '../messages'

const ModuleTrigger = ({ modules, id }) => {
  const foundTrigger = modules.getTriggerById(id) || {}
  const intl = useIntl()

  return (
    <StyledTrigger>
      {foundTrigger.name ? (
        <>
          <Icon icon={power} />
          <span>
            {intl.formatMessage(messages.trigger)}:&nbsp;{foundTrigger.name}
          </span>
        </>
      ) : (
        <NotFound type={intl.formatMessage(messages.trigger)} id={id} fetching={modules.fetching} />
      )}
    </StyledTrigger>
  )
}

ModuleTrigger.propTypes = {
  id: PropTypes.string,
  modules: PropTypes.object,
}

export default connectStore({ modules: ModuleModules })(ModuleTrigger)
