import PropTypes from 'prop-types'
import {BasicRadioButtons, BasicTextArea, PartOptions} from 'components'
import { useIntl } from 'react-intl'
import messages from './messages'
import {newEvent} from "../../../utils";

const SubtitleForm = ({ onChange, part, partIndex, onFormChange }) => {
  const intl = useIntl()

  return (
    <>
      <PartOptions partIndex={partIndex} onChange={onChange} onFormChange={onFormChange} values={part.options} />

      <BasicRadioButtons
        name="subtitleSize"
        label={intl.formatMessage(messages.type)}
        value={part.subtitleSize ? part.subtitleSize : 'h2'}
        onChange={(e) => {
          onChange(newEvent('subtitleSize', e.target.value), partIndex);
          onFormChange(true);
        }}
        partIndex={partIndex}
        radioButtons={[
          { value: 'h2', label: intl.formatMessage(messages.subtitleH2), disabled: false },
          { value: 'h3', label: intl.formatMessage(messages.subtitleH3), disabled: false },
        ]}
        required
      />

      <BasicTextArea
        name="text"
        label={intl.formatMessage(messages.subtitle)}
        helpMessage={intl.formatMessage(messages.subtitleHelp)}
        size="small"
        partIndex={partIndex}
        value={part.text}
        onChange={(e) => {
          onChange(e, partIndex);
          onFormChange(true);
        }}
        error={intl.formatMessage(messages.required)}
        required
      />
    </>
  )
}

SubtitleForm.propTypes = {
  onChange: PropTypes.func,
  part: PropTypes.object,
  partIndex: PropTypes.number,
  onFormChange: PropTypes.func,
}

export default SubtitleForm
