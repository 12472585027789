const actions = {
  getRandomStoryList: (appId) => ({ type: 'RANDOM_STORY_GET_LIST', appId }),
  deleteRandomStory: (id, appId) => ({ type: 'RANDOM_STORY_DELETE', id, appId }),
  saveRandomStory: (appId, cardId, randomStory, onSave) => ({
    type: 'RANDOM_STORY_SAVE',
    appId,
    cardId,
    randomStory,
    onSave,
  }),
  resetStatus: () => ({ type: 'RANDOM_STORY_STATUS_RESET' }),
}

export default actions
