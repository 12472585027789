import PropTypes from 'prop-types'
import styled from 'styled-components'

const StyledWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin: 20px;
`

const StyledText = styled.h2`
  font-family: 'Nunito', sans-serif;
  font-size: 12px;
  font-weight: 700;
  line-height: 24px;
  word-break: break-word;
`

const LanguageLink = ({ part, partIndex, cardId }) => {
  return (
    <StyledWrapper key={`${cardId}-h2-${partIndex}`}>
      <StyledText>{part.languageAbbr} : {part.languageLink}</StyledText>
    </StyledWrapper>
  )
}

LanguageLink.propTypes = {
  part: PropTypes.object,
  partIndex: PropTypes.number,
  cardId: PropTypes.string,
}

export default LanguageLink
