import PropTypes from 'prop-types'
import { useIntl } from 'react-intl'
import {
  BasicInfo,
  BasicButton,
  PartOptions,
  BasicTextArea,
} from "components";
import { newEvent } from 'utils'

import { Icon } from 'react-icons-kit'



import messages from './messages'
import { externalLink } from "react-icons-kit/feather";
import { useState } from "react";
import RandomStorySelect from "./RandomStorySelect";
import RandomStoryModal from "./RandomStoryModal";

const RandomRedirectStoryForm = ({ appId, onChange, partIndex, part, toggleDisable }) => {
  const [open, setOpen] = useState(false);

  const intl = useIntl();

  const toggleModal = () => {
    setOpen(!open)
    toggleDisable()
  }

  const handleOnSelect = (e) => {
    e.preventDefault()
    onChange(e, partIndex)
  }

  const handleOnSave = (id) => {
    onChange(newEvent('id', id), partIndex)
    toggleModal()
  }

  return (
    <>
      <PartOptions partIndex={partIndex} onChange={onChange} values={part.options} />

      <BasicTextArea
        name="notes"
        partIndex={partIndex}
        label={intl.formatMessage(messages.notes)}
        helpMessage={intl.formatMessage(messages.notesHelp)}
        value={part.notes || ''}
        onChange={(e) => onChange(e, partIndex)}
        size="xsmall"
      />

      <hr/>

      <BasicInfo
        text={intl.formatMessage(messages.info)}
        children={
          <BasicButton
            label={intl.formatMessage(messages.tutorial)}
            icon={<Icon icon={externalLink} />}
            isLink
            href={/*rteAppId(appId, 'TUTORIAL')*/'#'}
            target="_blank"
            linkstyle
            small
          />
        }
      />

      <RandomStorySelect id={part.id} onSelect={handleOnSelect} partIndex={partIndex} />

      <RandomStoryModal
        appId={appId}
        onSave={handleOnSave}
        id={part.id}
        toggleDisable={toggleDisable}
        open={open}
        setOpen={() => setOpen(!open)}
      />

      <div style={{ marginTop: '20px' }}>
        <BasicInfo text={intl.formatMessage(messages.infoModule)} />
      </div>
    </>
  )
}

RandomRedirectStoryForm.propTypes = {
  onChange: PropTypes.func,
  onCheckedChange: PropTypes.func,
  part: PropTypes.object,
  partIndex: PropTypes.number,
}

export default RandomRedirectStoryForm
