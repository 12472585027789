import PropTypes from 'prop-types'
import { PartOptions, BasicInput } from 'components'
import { useIntl } from 'react-intl'
import messages from './messages'

const PartBtnUnityForm = ({ onChange, partIndex, action, options, text, onFormChange }) => {
  const intl = useIntl()

  const handleOnChange = (e) => {
    onChange(e, partIndex)
  }

  return (
    <>
      <PartOptions partIndex={partIndex} onChange={handleOnChange} values={options} onFormChange={onFormChange} />

      <BasicInput
        label={intl.formatMessage(messages.btnLabel)}
        type="text"
        name="text"
        value={text}
        placeholder={intl.formatMessage(messages.btnPlaceholder)}
        onChange={(e) => {
          handleOnChange(e);
          onFormChange(true);
        }}
        partIndex={partIndex}
        required
        error={intl.formatMessage(messages.btnError)}
      />

      <BasicInput
        label={intl.formatMessage(messages.actionLabel)}
        type="text"
        name="action"
        value={action}
        placeholder={intl.formatMessage(messages.actionPlaceholder)}
        onChange={(e) => {
          handleOnChange(e);
          onFormChange(true);
        }}
        partIndex={partIndex}
        required
        error={intl.formatMessage(messages.actionError)}
      />
    </>
  )
}

PartBtnUnityForm.defaultProps = {
  action: '',
  onChange: () => {},
  options: {},
  partIndex: 0,
  text: '',
}

PartBtnUnityForm.propTypes = {
  action: PropTypes.string,
  onChange: PropTypes.func,
  options: PropTypes.object,
  partIndex: PropTypes.number,
  text: PropTypes.string,
  onFormChange: PropTypes.func,
}

export default PartBtnUnityForm
