import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { CardBody, Label, Alert, Button } from 'reactstrap'
import { AvForm, AvGroup, AvInput, AvFeedback } from 'availity-reactstrap-validation'

class PartCommentForm extends Component {
  constructor(props, ...rest) {
    super(props, ...rest)
    this.state = {
      name: '',
      email: '',
      comment: '',
      isSending: false,
      isSent: false,
    }
    this.onChange = this.onChange.bind(this)
    this.onSend = this.onSend.bind(this)
  }
  onChange(e) {
    const newData = {}
    newData[e.target.id.split('-')[0]] = e.target.value
    this.setState(newData)
  }

  onSend(e, values) {
    this.setState({ isSending: true })

    setTimeout(() => {
      this.setState({ isSending: false, isSent: true })
    }, 1000)
  }

  render() {
    const { partIndex } = this.props
    const { name, email, comment, isSending, isSent } = this.state
    return (
      <CardBody>
        {!isSent && (
          <AvForm onValidSubmit={this.onSend}>
            <AvGroup>
              <Label htmlFor={`name-${partIndex}`} required>
                Nom
              </Label>
              <AvInput
                type="text"
                name={`name-${partIndex}`}
                id={`name-${partIndex}`}
                value={name}
                onChange={this.onChange}
                disabled={isSending}
                required
              />
              <AvFeedback>Vouz devez entrer votre nom.</AvFeedback>
            </AvGroup>
            <AvGroup>
              <Label htmlFor={`email-${partIndex}`} required>
                Courriel
              </Label>
              <AvInput
                type="email"
                name={`email-${partIndex}`}
                id={`email-${partIndex}`}
                value={email}
                onChange={this.onChange}
                disabled={isSending}
                required
              />
              <AvFeedback>Vous devez entrer un courriel valide. Ex: nom@domaine.com</AvFeedback>
            </AvGroup>
            <AvGroup>
              <Label htmlFor={`comment-${partIndex}`} required>
                Commentaire
              </Label>
              <AvInput
                type="textarea"
                name={`comment-${partIndex}`}
                id={`comment-${partIndex}`}
                value={comment}
                onChange={this.onChange}
                disabled={isSending}
                required
              />
              <AvFeedback>Merci pour votre commentaire!</AvFeedback>
            </AvGroup>
            <Button btn-color="blue">Envoyer</Button>
          </AvForm>
        )}
        {isSent && <Alert color="success">This is a success alert — check it out!</Alert>}
      </CardBody>
    )
  }
}

PartCommentForm.propTypes = {
  partIndex: PropTypes.number.isRequired,
}

export default PartCommentForm
