import styled from 'styled-components'
import { NavItem } from 'reactstrap'
import { Link } from 'react-router-dom'

export const StyledSideNavContainer = styled.div`
  position: fixed;
  background-color: #02172b;
  width: 260px;
  min-width: 260px;
  top: 0;
  z-index: 1;

  @media (max-width: 768px) {
    display: none;
  }

  .simplebar-track.simplebar-vertical .simplebar-scrollbar:before {
    background-color: #3f6cb3;
  }
`

export const StyledSideNavHeader = styled.div`
  background-color: #093465;
  min-height: 66px;
  display: flex;
  align-items: center;

  .dropdown {
    position: relative;
    z-index: 7;
    width: 100%;
  }
`

export const StyledSideNavItem = styled(NavItem)`
  position: relative;
  padding: 20px 0 16px 6px;
  border-top: 1px solid #1a2d4b;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: baseline;

  a {
    color: white;
    font-family: Nunito;
    font-weight: 500;
    font-size: 16px;
    line-height: 22px;
    display: flex;
    align-items: center;

    &:hover {
      color: #3f6cb3;
    }
  }

  i {
    color: white;
    margin-right: 10px;
    margin-bottom: 4px;

    svg {
      height: 20px;
      width: 20px;
    }
  }

  span {
    color: white;
    font-family: Nunito;
    font-weight: 600;
    font-size: 17px;
    line-height: 22px;
  }

  &::before {
    ${({ selected }) => !selected && 'display: none'};
    content: '';
    position: absolute;
    border-left: 7px solid #3f6cb3;
    border-radius: 3px;
    height: 36%;
    top: 26px;
    left: 0;
  }
`

export const StyledModuleItem = styled(StyledSideNavItem)`
  padding: 3px 10px;
  border: none;

  span {
    color: white;
    font-family: Nunito;
    font-weight: 300;
    font-size: 16px;
    line-height: 22px;
  }

  &::before {
    top: 13px;
  }

  &:last-of-type {
    margin-bottom: 10px;
  }
`

export const StyledSideNavSeparation = styled.div`
  text-transform: uppercase;
  color: #3f6cb3;
  padding: 20px 0 10px 25px;
  border-top: 1px solid #1a2d4b;
  width: 100%;

  span {
    font-size: 14px;
  }
`

export const StyledButton = styled(Link)`
  font-size: 12px !important;
  font-family: 'Nunito';
  font-weight: 600;
  line-height: 11px;
  color: #212529;
  background-color: #ffd232;
  padding: 4px 10px;
  margin-right: 20px;
  border: 2px solid #ffd232;
  border-radius: 16px;
  width: fit-content;
  display: flex;
  align-items: center;
  cursor: pointer;
  transition: all ease-in-out 0.2s;

  &:hover {
    background-color: transparent;
    color: #ffd232 !important;
  }
`
