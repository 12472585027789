import { Fragment } from 'react'
import PropTypes from 'prop-types'
import { Link, withRouter } from 'react-router-dom'
import { NavLink, Nav } from 'reactstrap'
import { FormattedMessage, useIntl } from 'react-intl'
import { rteAppId } from 'utils/routes'

import SimpleBar from 'simplebar-react'
import 'simplebar/dist/simplebar.min.css'

import AppDropdown from '../Dropdowns/AppDropdown'
import messages from '../messages'
import { getNavLinks, isLinkActive, getModuleLinks, getManageLinks } from '../helpers'

import {
  StyledSideNavItem,
  StyledSideNavSeparation,
  StyledSideNavContainer,
  StyledSideNavHeader,
  StyledModuleItem,
} from './styles'
import { checkGlobalAccess } from "../../../services/utils";

// DESKTOP
const SideNav = ({
  app,
  location: { pathname },
  changeApp,
  superadmin,
  userRoles,
  match: {
    params: { appId, folderId },
  },
}) => {
  const intl = useIntl()

  const navlink =  userRoles && getNavLinks(intl.formatMessage(messages.all), appId, folderId, false, userRoles, superadmin)

  const moduleLink = getModuleLinks(superadmin, app)

  const manageLink =  userRoles && getManageLinks(appId, userRoles, superadmin)

  const seeSectionManage =  userRoles && checkGlobalAccess(appId, superadmin, userRoles, 'SeeSectionManage');

  return (
    <StyledSideNavContainer>
      <StyledSideNavHeader>
        <AppDropdown appId={appId} onChangeApp={changeApp} />
      </StyledSideNavHeader>

      <SimpleBar style={{ height: '100vh' }}>
        <Nav vertical style={{ marginBottom: '200px' }}>
          {navlink.map((link, i) => link.hasAccess && (
            <Fragment key={i}>
              <StyledSideNavItem key={i} selected={isLinkActive(pathname, link.route, appId)}>
                <NavLink tag={Link} to={rteAppId(appId, link.route)}>
                  {link.icon}
                  <FormattedMessage {...messages[link.message]} />
                </NavLink>
                {link.button && link.button}
              </StyledSideNavItem>
              {link.children && link.children}
            </Fragment>
          ))}

          <StyledSideNavSeparation>
            <FormattedMessage {...messages.modules} />
          </StyledSideNavSeparation>

          {moduleLink.map((link, i) => (
            <Fragment key={i}>
              {link.hasAccess && (
                <StyledModuleItem selected={isLinkActive(pathname, link.route, appId)}>
                  <NavLink tag={Link} to={rteAppId(appId, link.route)}>
                    <FormattedMessage {...messages[link.message]} />
                  </NavLink>
                </StyledModuleItem>
              )}
            </Fragment>
          ))}

          {seeSectionManage && (
            <StyledSideNavSeparation style={{ marginTop: '10px' }}>
              <FormattedMessage {...messages.manage} />
            </StyledSideNavSeparation>
          )}

          {manageLink.map((link, i) => (
            <Fragment key={i}>
              {link.hasAccess && (
                <StyledModuleItem selected={isLinkActive(pathname, link.route, appId)}>
                  <NavLink tag={Link} to={rteAppId(appId, link.route)}>
                    <FormattedMessage {...messages[link.message]} />
                  </NavLink>
                </StyledModuleItem>
              )}
            </Fragment>
          ))}
        </Nav>
      </SimpleBar>
    </StyledSideNavContainer>
  )
}

SideNav.defaultProps = {
  changeApp: () => {},
}

SideNav.propTypes = {
  app: PropTypes.object,
  changeApp: PropTypes.func,
  location: PropTypes.object,
  match: PropTypes.object,
  superadmin: PropTypes.bool,
  userRoles: PropTypes.array,
}

export default withRouter(SideNav)
