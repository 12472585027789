import PropTypes from 'prop-types'
import { useIntl } from 'react-intl'
import { BasicInfo, BasicInput, PartOptions } from 'components'

import messages from './messages'

const PartRandomReset = ({ handleOnChange, partIndex, part, options, onFormChange }) => {
  const intl = useIntl()

  return (
    <>
      <PartOptions partIndex={partIndex} onChange={handleOnChange} values={options} onFormChange={onFormChange} />

      <BasicInput
        type="number"
        min={0}
        name="tokenToReset"
        partIndex={partIndex}
        value={part.tokenToReset ? part.tokenToReset : ''}
        onChange={(e) => {
          handleOnChange(e, partIndex);
          onFormChange(true);
        }}
        label={intl.formatMessage(messages.typeLabel)}
        helpMessage={intl.formatMessage(messages.typeLabelHelp)}
      />

      <BasicInfo text={intl.formatMessage(messages.description)} />
    </>
  )
}

PartRandomReset.defaultProps = {
  part: {},
  appId: 0,
  tokenId: 0,
  options: {},
}

PartRandomReset.propTypes = {
  partIndex: PropTypes.number.isRequired,
  part: PropTypes.object.isRequired,
  handleOnChange: PropTypes.func.isRequired,
  options: PropTypes.object,
  onFormChange: PropTypes.func,
}

export default PartRandomReset
