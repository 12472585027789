import PropTypes from 'prop-types'
import { BasicInput, BasicInfo, BasicCheckbox } from 'components'
import { useIntl } from 'react-intl'
import { validationsPatterns } from 'utils'
import messages from './messages'

const StyleForm = ({ isNew, part, onCheckedChange, unchecked, partIndex, onChange, onFormChange }) => {
  const intl = useIntl()

  return (
    <>
      {part.type === 'Picture' && (
        <>
          <BasicInput
            type="checkbox"
            name="isCompressed"
            label={intl.formatMessage(messages.compression)}
            onChange={(e) => {
              onCheckedChange(e);
              onFormChange(true);
            }}
            value={!unchecked ? part.isCompressed : false}
            disabled={!unchecked && isNew}
          />

          <BasicInfo text={intl.formatMessage(messages.compressionInfo)} />
        </>
      )}

      <BasicInput
        label={intl.formatMessage(messages.link)}
        helpMessage={intl.formatMessage(messages.linkHelp)}
        name="link"
        partIndex={partIndex}
        value={part.link || ''}
        onChange={(e) => {
          onChange(e);
          onFormChange(true);
        }}
        error={intl.formatMessage(messages.linkError)}
        validation={{
          pattern: validationsPatterns.urlExtended,
        }}
      />

      {!part.withZoom && (
        <BasicCheckbox
          label={intl.formatMessage(messages.hideLinkIcon)}
          helpMessage={intl.formatMessage(messages.hideLinkIconHelp)}
          name="hideLinkIcon"
          onChange={(e) => {
            onCheckedChange(e);
            onFormChange(true);
          }}
          value={part.hideLinkIcon || false}
        />
      )}

      <BasicCheckbox
        label={intl.formatMessage(messages.newTab)}
        name="newTab"
        onChange={(e) => {
          onCheckedChange(e);
          onFormChange(true);
        }}
        value={part.newTab}
      />
    </>
  )
}

StyleForm.defaultProps = {
  isCompressed: true,
  onCheckedChange: () => {},
}

StyleForm.propTypes = {
  isNew: PropTypes.bool,
  onCheckedChange: PropTypes.func,
  onChange: PropTypes.func,
  part: PropTypes.object,
  partIndex: PropTypes.number,
  unchecked: PropTypes.bool,
  onFormChange: PropTypes.func,
}

export default StyleForm
