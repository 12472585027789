import { Card } from 'reactstrap'
import styled from 'styled-components'

export const StyledCard = styled(Card)`
  ${({ multiple }) => !multiple && 'max-width: 550px'};
  ${({ multiple }) => multiple && 'width: 355px'};
  height: fit-content;
  border-radius: 8px;
  box-shadow: 2px 2px 20px rgba(0, 0, 0, 0.15);
  margin-right: 24px;
  margin-bottom: 24px;

  @media (max-width: 576px) {
    width: 100%;
    margin-right: 0;
  }
`

export const StyledPartWithOptions = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin: 24px 1.25rem;

  h2 {
    font-family: 'Nunito', sans-serif;
    font-size: 22px;
    font-weight: 700;
    line-height: 24px;
    letter-spacing: -0.7px;
    margin-bottom: 0;
  }

  p {
    font-size: 15px;
    line-height: 22px;
  }
`

export const StyledMoreButton = styled.div`
  width: 100%;
  position: relative;
  display: flex;
  justify-content: flex-end;
  padding: 10px;

  button {
    background-color: #eeeeee;
    border: none;
    color: #bebebe;
    height: fit-content;
    border-radius: 10px;
    font-family: Nunito;
    font-weight: 600;
    font-size: 12px;
    line-height: 13px;
    padding: 1px 8px;
    letter-spacing: 0.2px;
    white-space: break-spaces;
    width: fit-content;
    z-index: 1;
    cursor: pointer;
  }

  &::before {
    ${({ open }) => open && 'display: none'};
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    height: 95px;
    width: 100%;
    background: linear-gradient(0deg, rgba(255, 255, 255, 1) 50%, rgba(255, 255, 255, 0) 80%);
  }
`

export const StyledCardBody = styled.div`
  overflow: hidden;
  min-height: 20px;
`

export const StyledVideoContainer = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  border: 1px solid #eee;

  & > div {
    max-height: ${({ multiple }) => (multiple ? '176px' : '286px')};
    z-index: 1;
  }

  .react-player {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
`

const getSize = (size) => {
  switch (size) {
    case 'small':
      return '10%'
    case 'medium':
      return '50%'
    default:
      return '100%'
  }
}

export const Separator = styled.hr`
  border-color: black;
  width: ${({ size }) => getSize(size)};
  height: 2px;
`

export const StyledPartContainer = styled.div`
  margin: 24px 1.25rem;
`

export const StyledLabel = styled.label`
  font-family: Nunito;
  font-size: 16px;
  font-weight: 600;
`
