import PropTypes from 'prop-types'
import { useIntl } from 'react-intl'
import { BasicButton, BasicTooltip } from 'components'
import { cardDirectLink } from 'utils/generator'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import { rteCardId } from 'utils/routes'
import uid from 'uid'

import { Icon } from 'react-icons-kit'
import { trash } from 'react-icons-kit/feather/trash'
import { externalLink } from 'react-icons-kit/feather'
import { maximize2 } from 'react-icons-kit/feather/maximize2'
import { link } from 'react-icons-kit/feather/link'

import messages from './messages'
import {checkActionsAccess} from "../../services/utils";
import {connectStore} from "redux-box";
import {storeModule as SessionModule} from "../../store/manageUsers";

/*
  .. delete 
  .. view page in visit
  .. copy visit link
  .. view page in admin (expand view)
*/
const EditPageButtons = ({ appId, cardId, onDelete, tokenId, session }) => {
  const id = uid()
  const intl = useIntl()

  return (
    <>
      {/* Delete */}
      {session.profile.email !== '' && checkActionsAccess(appId, session.profile, 'pages', 'delete') &&
        <div data-for={`delete-${id}`} data-tip={intl.formatMessage(messages.delete)}>
        <BasicButton onClick={onDelete} icon={<Icon icon={trash}/>} outlined round/>
      </div>}

      <BasicTooltip id={`delete-${id}`} />

      {/* View in admin */}
      <div data-for={`newTab-${id}`} data-tip={intl.formatMessage(messages.newTab)}>
        <BasicButton
          href={rteCardId(appId, cardId)}
          target="_blank"
          icon={<Icon icon={maximize2} />}
          color="blue"
          round
          outlined
        />
      </div>

      <BasicTooltip id={`newTab-${id}`} />

      {/* Copy link */}
      <div data-for={`copy-${id}`} data-tip={intl.formatMessage(messages.copy)}>
        <CopyToClipboard text={cardDirectLink(appId, tokenId, cardId)}>
          <BasicButton icon={<Icon icon={link} />} color="blue" round outlined />
        </CopyToClipboard>
      </div>

      <BasicTooltip id={`copy-${id}`} />

      {/* Open visit in new tab */}
      <div style={{ marginLeft: '14px' }}>
        <BasicButton
          href={cardDirectLink(appId, tokenId, cardId)}
          icon={<Icon icon={externalLink} />}
          target="_blank"
          color="blue"
          label={intl.formatMessage(messages.expand)}
        />
      </div>
    </>
  )
}

EditPageButtons.propTypes = {
  appId: PropTypes.string,
  cardId: PropTypes.string,
  onDelete: PropTypes.func,
  tokenId: PropTypes.number,
  session: PropTypes.object,
}

export default connectStore({ session: SessionModule })(EditPageButtons)