import PropTypes from 'prop-types'
import { useIntl } from 'react-intl'

import ActionIcon from './ActionIcon'
import TypeSelect from './TypeSelect'
import { StyledActionTitle } from './styles'
import messages from './messages'

const PartTypeForm = ({ onChange, partIndex, part: { type }, app, onFormChange }) => {
  const intl = useIntl()

  return (
    <>
      <StyledActionTitle>
        <ActionIcon current={type}></ActionIcon>
        <span>
          {intl.formatMessage(messages.action)}&nbsp;{partIndex + 1}
        </span>
      </StyledActionTitle>

      <TypeSelect app={app} onChange={(e) => {
        onChange(e, partIndex);
        onFormChange(true);
      }} partIndex={partIndex} type={type} />
    </>
  )
}

PartTypeForm.propTypes = {
  app: PropTypes.object,
  onChange: PropTypes.func,
  part: PropTypes.object,
  partIndex: PropTypes.number,
  onFormChange: PropTypes.func,
}

export default PartTypeForm
