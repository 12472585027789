import PropTypes from 'prop-types'
import { useIntl } from 'react-intl'
import { Fragment } from 'react'
import connectStore from 'redux-box/dist/connectStore'
import { storeModule as ModuleModules } from 'store/modules'

import { Icon } from 'react-icons-kit'
import { code } from 'react-icons-kit/feather/code'

import NotFound from './NotFound'
import { StyledInput, StyledMessage } from './styles'
import messages from '../messages'

const ModuleInput = ({ modules, id }) => {
  const foundInput = modules.getInputById(id) || {}
  const intl = useIntl()

  return (
    <StyledInput>
      {foundInput.name ? (
        <Fragment>
          {foundInput.type !== 'get-parameter' ? (
            <Fragment>
              <label htmlFor="presentation">
                {intl.formatMessage(messages.input)}:&nbsp;{foundInput.name}&nbsp;
              </label>
              <input disabled name="presentation" type="text" value={`Type: ${foundInput.type}`} />
            </Fragment>
          ) : (
            <StyledMessage>
              <Icon icon={code} />
              <span>
                {intl.formatMessage(messages.input)}:&nbsp;{foundInput.type}
              </span>
            </StyledMessage>
          )}
        </Fragment>
      ) : (
        <NotFound id={id} type={intl.formatMessage(messages.input)} fetching={modules.fetching} />
      )}
    </StyledInput>
  )
}

ModuleInput.propTypes = {
  modules: PropTypes.object,
  id: PropTypes.string,
}

export default connectStore({ modules: ModuleModules })(ModuleInput)
