import { defineMessages } from 'react-intl'

const scope = 'app.components.CardDisplay.components'

export default defineMessages({
  delete: { id: `${scope}.delete` },
  edit: { id: `${scope}.edit` },
  expand: { id: `${scope}.expand` },
  fbsDeleted: { id: `${scope}.fbsDeleted` },
  fbsLinked: { id: `${scope}.fbsLinked` },
  itemDeleted: { id: `${scope}.itemDeleted` },
  itemLinked: { id: `${scope}.itemLinked` },
  Lock: { id: `${scope}.Lock` },
  LockCancel: { id: `${scope}.LockCancel` },
  lockDeleted: { id: `${scope}.lockDeleted` },
  LockPage: { id: `${scope}.LockPage` },
  Locks: { id: `${scope}.Locks` },
  system: { id: `${scope}.system` },
  theme: { id: `${scope}.theme` },
  view: { id: `${scope}.view` },
  viewItem: { id: `${scope}.viewItem` },
  viewLock: { id: `${scope}.viewLock` },
  viewPage: { id: `${scope}.viewPage` },
  viewPhotobooth: { id: `${scope}.viewPhotobooth` },
})
