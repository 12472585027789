import PropTypes from 'prop-types'
import { Fragment } from 'react'
import { useIntl } from 'react-intl'
import { SearchBar } from 'components'
import { useSearch } from 'hooks/useSearch'
import styled from 'styled-components'

import ThemeRow from './ThemeRow'
import messages from './messages'

export const StyledSearch = styled.div`
  width: 100%;

  input {
    width: 100%;
    margin-bottom: 20px;
  }
`

const ThemeList = ({ list }) => {
  const intl = useIntl()

  const { filteredList, setSearchQuery, searchQuery } = useSearch({ list, params: ['name', 'value'] })

  return (
    <div>
      <StyledSearch>
        <SearchBar
          searchQuery={searchQuery}
          setSearchQuery={setSearchQuery}
          placeholder={intl.formatMessage(messages.search)}
        />
      </StyledSearch>

      {Object.values(filteredList).map((t, i) => (
        <Fragment key={i}>
          <ThemeRow theme={t} />
        </Fragment>
      ))}
    </div>
  )
}

ThemeList.defaultProps = {
  list: {},
}

ThemeList.propTypes = {
  list: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
}

export default ThemeList
