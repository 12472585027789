import PropTypes from 'prop-types'
import { BasicInput, PartOptions } from 'components'
import { useIntl } from 'react-intl'
import messages from './messages'

const DividerForm = ({ onChange, part, partIndex, options, onFormChange }) => {
  const intl = useIntl()

  return (
    <>
      <PartOptions partIndex={partIndex} onChange={onChange} values={options} onFormChange={onFormChange} />

      <BasicInput
        type="select"
        name="length"
        partIndex={partIndex}
        label={intl.formatMessage(messages.size)}
        helpMessage={intl.formatMessage(messages.sizeHelp)}
        value={part.length}
        onChange={(e) => {
          onChange(e, partIndex);
          onFormChange(true);
        }}
        error={intl.formatMessage(messages.required)}
        required
      >
        <option value="" disabled>
          {intl.formatMessage(messages.select)}
        </option>
        <option value="small">{intl.formatMessage(messages.small)}</option>
        <option value="medium">{intl.formatMessage(messages.medium)}</option>
        <option value="large">{intl.formatMessage(messages.large)}</option>
      </BasicInput>
    </>
  )
}

DividerForm.propTypes = {
  onChange: PropTypes.func,
  part: PropTypes.object,
  partIndex: PropTypes.number,
  onFormChange: PropTypes.func,
}

export default DividerForm
