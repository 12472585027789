import PropTypes from 'prop-types'
import { BasicButton } from 'components'
import { DropdownMenu, UncontrolledButtonDropdown, ListGroupItem } from 'reactstrap'
import { chevronDown } from 'react-icons-kit/feather/chevronDown'

import { StyledDropdownToggle, StyledCaret, StyledDropdownItem, StyledContainer } from './styles'
import {connectStore} from "redux-box";
import {storeModule as SessionModule} from "../../store/manageUsers";

const ButtonWithDropdown = ({ label, actions, href, loading }) => (
  <StyledContainer>
    <ListGroupItem>
      <UncontrolledButtonDropdown>
        <BasicButton color="green" minwidth={!href ? 130 : null} label={label} isLink={!!href} href={href} loading={loading} disabled={loading} medium={!href} />
        <StyledDropdownToggle caret>
          <StyledCaret icon={chevronDown} />
        </StyledDropdownToggle>

        <DropdownMenu style={{ overflowY: 'auto' }}>
          {actions.map((action, i) => (
            <StyledDropdownItem key={i} onClick={action.onClick}>
              {action.icon}
              {action.label}
            </StyledDropdownItem>
          ))}
        </DropdownMenu>
      </UncontrolledButtonDropdown>
    </ListGroupItem>
  </StyledContainer>
)

ButtonWithDropdown.propTypes = {
  actions: PropTypes.array,
  href: PropTypes.string,
  label: PropTypes.string,
  loading: PropTypes.bool,
}

export default connectStore({ session: SessionModule })(ButtonWithDropdown)