import { CardBody } from 'reactstrap'
import styled from 'styled-components'

export const StyledCardFooter = styled(CardBody)`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-top: 1.25rem;
  border-top: 1px solid #dddddd;
`
