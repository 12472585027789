const state = {
  id: '',
  name: '',
  lastCardUpdateTime: '',
  cardList: [],
  tokenList: [],
  tokenCardLinks: [],
  feedbackList: [],
  createFeedback: {
    fetching: false,
    error: false,
    sent: false,
  },
  logo: {
    url: '',
    newTab: false,
  },
  access: {},
  plan: '',
  visitCounterList: [],
  folders: [],
  theme: '',
  themes: [],
  notes: '',
  fetching: false,
  error: false,
  saving: false,
  success: false,
}

export default state
