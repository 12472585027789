import PropTypes from 'prop-types'
import { StyledTitle } from './styles'

const PageTitle = ({ children, colorCircle }) => <StyledTitle color={colorCircle}>{children}</StyledTitle>

PageTitle.propTypes = {
  children: PropTypes.any,
  colorCircle: PropTypes.string,
}

export default PageTitle
