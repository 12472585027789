/**
 *
 * PageFeedbacks
 *
 */

import React from 'react'
import PropTypes from 'prop-types'
import { connectStore } from 'redux-box'
import { Helmet } from 'react-helmet'
import { injectIntl } from 'react-intl'

import MainAppContainer from 'containers/MainAppContainer'
import MultipleCardDisplay from 'components/MultipleCardDisplay'
import { storeModule as AppModule } from 'store/app'
import { storeModule as CardListModule } from 'store/cardList'
import { storeModule as SessionModule } from 'store/manageUsers'

import { Row, Col, Card, CardBody, CardTitle, Button } from 'reactstrap'

import FeedbackCreationForm from './components/FeedbackCreationForm'
import messages from './messages'

export class PageFeedbacks extends React.PureComponent {
  // eslint-disable-line react/prefer-stateless-function
  constructor(props, ...rest) {
    super(props, ...rest)
    this.state = {}
  }

  render() {
    const {
      match,
      intl,
      app: { feedbackList, createFeedback },
      cardList,
      session: { superadmin },
    } = this.props
    return (
      <div>
        <Helmet title={intl.formatMessage(messages.metaTitle)} />

        <MainAppContainer appId={match.params.appId}>
          <Row>
            <Col>
              {superadmin && <FeedbackCreationForm onValidSubmit={() => {}} status={createFeedback} />}
              {!superadmin && (
                <Card>
                  <CardBody>
                    <CardTitle>{intl.formatMessage(messages.knowMoreAboutFeedbacks)}</CardTitle>
                    <Button btn-color="green" href="mailto: team@mysmartjourney.com">
                      {intl.formatMessage(messages.contactUs)}
                    </Button>
                  </CardBody>
                </Card>
              )}
            </Col>
          </Row>
          {feedbackList.map((feedbackModule) => (
            <Row key={feedbackModule.feedbackId}>
              <Col style={{ marginTop: '20px' }}>
                <h4>{feedbackModule.name}</h4>
                <MultipleCardDisplay cards={cardList.getFeedbackCards(feedbackModule.feedbackId)} />
                <hr />
              </Col>
            </Row>
          ))}
        </MainAppContainer>
      </div>
    )
  }
}

PageFeedbacks.defaultProps = {}
PageFeedbacks.propTypes = {
  match: PropTypes.object.isRequired,
  app: PropTypes.object.isRequired,
  cardList: PropTypes.object.isRequired,
  session: PropTypes.object.isRequired,
}

export default connectStore({ app: AppModule, cardList: CardListModule, session: SessionModule })(
  injectIntl(PageFeedbacks)
)
