import { useState } from 'react'
import PropTypes from 'prop-types'
import { BasicInput, BasicLabel, BasicButton } from 'components'
import styled from 'styled-components'
import { useIntl } from 'react-intl'

import { Icon } from 'react-icons-kit'
import { trash } from 'react-icons-kit/feather/trash'

import messages from './messages'

const StyledContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;

  select {
    min-width: calc(100% + 13px);

    @media (max-width: 460px) {
      margin-top: -20px;
    }
  }
`

const StyledInputs = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
`

const StyledInputsContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: baseline;

  button {
    margin-left: 23px;

    @media (max-width: 460px) {
      position: absolute;
      right: 0;
      bottom: 25px;
    }
  }
`

function BasicInputDuration({ helpMessage, name, required, label, values }) {
  const [value, setValue] = useState(values.value || '')
  const [unit, setUnit] = useState(values.unit || '')
  const intl = useIntl()
  return (
    <StyledContainer>
      <BasicLabel label={label} name={`${name}.value`} required={required} helpMessage={helpMessage} />

      <StyledInputsContainer>
        <StyledInputs>
          <div style={{ marginRight: '10px' }}>
            <BasicInput
              error={intl.formatMessage(messages.unlockTimeError)}
              name={`${name}.value`}
              value={value}
              type="number"
              min={1}
              required={required || !!unit}
              onChange={(e) => setValue(e.target.value)}
            />
          </div>

          <BasicInput
            type="select"
            id={`${name}.unit`}
            name={`${name}.unit`}
            value={unit}
            error={intl.formatMessage(messages.unitError)}
            required={required || !!value}
            onChange={(e) => setUnit(e.target.value)}
          >
            <option value="" disabled>
              {intl.formatMessage(messages.timeUnit)}
            </option>
            <option value="minutes">{intl.formatMessage(messages.minutes)}</option>
            <option value="hours">{intl.formatMessage(messages.hours)}</option>
            <option value="days">{intl.formatMessage(messages.days)}</option>
          </BasicInput>
        </StyledInputs>

        {(value || unit) && (
          <BasicButton
            onClick={() => {
              setValue('')
              setUnit('')
            }}
            icon={<Icon icon={trash} />}
            outlined
            round
            color="grey"
          />
        )}
      </StyledInputsContainer>
    </StyledContainer>
  )
}

BasicInputDuration.propTypes = {
  label: PropTypes.string,
  helpMessage: PropTypes.string,
  required: PropTypes.bool,
  name: PropTypes.string,
  values: PropTypes.object,
}

export default BasicInputDuration
