import { defineMessages } from 'react-intl'

const scope = 'app.components.ButtonStyleForm'

// same msg as in './PartActions/ButtonHalfForm/messages.js'
export default defineMessages({
  buttonStyle: { id: `${scope}.buttonStyle` },
  BorderRadius0: { id: `${scope}.BorderRadius0` },
  BorderRadius10: { id: `${scope}.BorderRadius10` },
  BorderRadius25: { id: `${scope}.BorderRadius25` },
  BorderRadius50: { id: `${scope}.BorderRadius50` },
  BorderRadiusHelp: { id: `${scope}.BorderRadiusHelp` },
  BorderRadiusLabel: { id: `${scope}.BorderRadiusLabel` },
  BorderWidth0: { id: `${scope}.BorderWidth0` },
  BorderWidth1: { id: `${scope}.BorderWidth1` },
  BorderWidth2: { id: `${scope}.BorderWidth2` },
  BorderWidth3: { id: `${scope}.BorderWidth3` },
  BorderWidthHelp: { id: `${scope}.BorderWidthHelp` },
  BorderWidthLabel: { id: `${scope}.BorderWidthLabel` },
  NbrPerCol2: { id: `${scope}.NbrPerCol2` },
  NbrPerCol3: { id: `${scope}.NbrPerCol3` },
  NbrPerCol4: { id: `${scope}.NbrPerCol4` },
  NbrPerColHelp: { id: `${scope}.NbrPerColHelp` },
  NbrPerColLabel: { id: `${scope}.NbrPerColLabel` },
  StyleInfo: { id: `${scope}.StyleInfo` },
})
