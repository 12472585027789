import styled, { css } from 'styled-components'
import { Button } from 'reactstrap'

const getPos = (pos) => {
  switch (pos) {
    case 'top-left':
      return css`
        align-items: flex-start;
        justify-content: flex-start;
      `
    case 'top-center':
      return css`
        align-items: flex-start;
        justify-content: center;
      `
    case 'top-right':
      return css`
        align-items: flex-start;
        justify-content: flex-end;
      `
    case 'left':
      return css`
        align-items: center;
        justify-content: flex-start;
      `
    case 'right':
      return css`
        align-items: center;
        justify-content: flex-end;
      `
    case 'bottom-left':
      return css`
        align-items: flex-end;
        justify-content: flex-start;
      `
    case 'bottom-center':
      return css`
        align-items: flex-end;
        justify-content: center;
      `
    case 'bottom-right':
      return css`
        align-items: flex-end;
        justify-content: flex-end;
      `
    default:
      return css`
        align-items: center;
        justify-content: center;
      `
  }
}

export const StyledLegend = styled.legend`
  text-transform: uppercase;
  color: #406cb3;
  font-weight: 600 !important;
  font-size: 14px !important;
`

export const StyledActions = styled.div`
  width: 100%;
  display: flex;
  justify-content: ${({ position }) => (position === 'top' ? 'space-between' : 'flex-end')};
  align-items: center;

  div {
    display: flex;
    flex-direction: row;

    button:last-of-type {
      margin-left: 10px;
    }
  }
`

export const StyledRow = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  margin: 20px 0;

  @media (max-width: 576px) {
    flex-wrap: wrap;

    div:last-of-type {
      margin-top: 10px;
    }
  }
`

export const StyledGroupButton = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 125px;
  border: 2px dashed rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  margin-top: 10px;
`

export const StyledPosButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 30px;
  height: 30px;
  margin: 5px;
  border-radius: 8px;
  color: white;
  border: none;
  background-color: ${({ selected }) => (selected ? '#34b076' : 'rgba(0, 0, 0, 0.1)')};
  transition: all ease-in-out 0.2s;
  cursor: pointer;

  &:hover {
    background-color: #34b076;
  }
`

export const StyledPosPreview = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 225px;
  width: 125px;
  border: 2px dashed #dedede;
  border-radius: 8px;
  color: #dedede;
  margin-top: 10px;

  & > i {
    position: absolute;

    svg {
      height: 30px;
      width: 30px;
    }
  }
`

export const StyledFakeWatermark = styled.div`
  position: relative;
  display: flex;
  height: 100%;
  width: 100%;
  ${({ position }) => position && getPos(position)};

  i {
    opacity: ${({ opacity }) => Number(opacity / 100)};

    &:first-of-type svg {
      color: #406cb3;
      height: 20px;
      width: 20px;
    }

    &:nth-of-type(2) svg {
      color: #ef4870;
      height: 17px;
      width: 17px;
    }

    &:last-of-type svg {
      color: #f39d36;
      height: 15px;
      width: 15px;
    }
  }
`

export const StyledDropzones = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 20px;
  grid-column-start: 1;

  @media (max-width: 579px) {
    display: flex;
  }
`

export const StyledCopy = styled(Button)`
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #12263f;
  border-radius: 100%;
  padding: 4px;
  margin-left: 10px;
  margin-bottom: 5px;
  background-color: transparent;

  @media (max-width: 600px) {
    margin-bottom: 10px;
  }

  i {
    width: 14px;
    height: 14px;
    display: flex !important;
    align-items: center;
    justify-content: center;

    svg {
      color: #12263f;
      width: 14px;
      height: 14px;
    }
  }

  &:hover {
    border: 1px solid #12263f;
    background: transparent;
  }
`
