import ReactTooltip from 'react-tooltip'
import PropTypes from 'prop-types'
import styled from 'styled-components'

const StyledTooltip = styled(ReactTooltip)`
  max-width: ${({ maxWidth }) => (maxWidth ? `${maxWidth}px` : '221px')};
  font-size: 12px !important;
  font-weight: 400;
  line-height: 18px !important;

  &::before,
  &::after {
    display: none !important;
    margin-top: -10px !important;
    right: -12px !important;
    border-top: 12px solid transparent !important;
    border-bottom: 12px solid transparent !important;
    border-left: 12px solid rgba(3, 22, 44, 0.8) !important;
  }
`

const BasicTooltip = ({ effect, place, id, maxWidth, content }) => (
  <StyledTooltip
    backgroundColor="#12263f"
    effect={effect}
    id={id}
    maxWidth={maxWidth}
    place={place}
    textColor="white"
  >
    {content}
  </StyledTooltip>
)

BasicTooltip.defaultProps = {
  place: 'left',
  effect: 'solid',
}

BasicTooltip.propTypes = {
  id: PropTypes.string,
  place: PropTypes.string,
  effect: PropTypes.string,
  maxWidth: PropTypes.number,
  content: PropTypes.any,
}

export default BasicTooltip
