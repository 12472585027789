import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { StyledFlapPreview } from "./styles";
import openFlapFr from "../../../assets/img/open-preview-fr.png";
import openFlapEn from "../../../assets/img/open-preview-en.png";
import closedFlapFr from "../../../assets/img/close-preview-fr.png";
import closedFlapEn from "../../../assets/img/close-preview-en.png";
import frame from "../../../assets/img/frame.png";
import messages from "../messages";
import { useIntl } from "react-intl";

const FlapPreview = ({ link }) => {
  const intl = useIntl();
  const [isOpen, setIsOpen] = useState(localStorage.getItem("isOpen") !== null ? localStorage.getItem("isOpen") === "true" :  false);
  const [preview, setPreview] = useState('');

  useEffect(() => {
    setPreview(link);
  }, [link]);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
    localStorage.setItem("isOpen", !isOpen);
  };

  return (
    <StyledFlapPreview className={`side-menu ${isOpen ? 'open' : 'closed'}`}>
      <div onClick={toggleMenu} className={`toggle-button ${isOpen ? 'open' : 'closed'}`}>
        {isOpen ?
          <img src={intl.locale === 'fr' ? closedFlapFr : closedFlapEn} alt="MySmartJourney" />
          :
          <img src={intl.locale === 'fr' ? openFlapFr : openFlapEn} alt="MySmartJourney" />
        }
      </div>
      <div className="menu-content">
        <h2>{intl.formatMessage(messages.previewTitle)}</h2>
        <div className="iframe-container">
          <div className="frame">
            <div className="frame-img">
              <img
                src={frame}
                alt="MySmartJourney"
              />
            </div>
            {preview !== '' && <iframe
              title="mobile-preview"
              src={`${preview}`}
              width="519px"
              frameBorder="0"
              marginHeight="0"
              marginWidth="0"
            ></iframe>}
          </div>
        </div>
        <p className="info">{intl.formatMessage(messages.previewInfo)}</p>
      </div>
    </StyledFlapPreview>
  );
};

FlapPreview.propTypes = {
  link: PropTypes.string,
};

export default FlapPreview;
