import PropTypes from 'prop-types'
import { useIntl } from 'react-intl'
import { BasicModal, BasicButton } from 'components'
import history from 'services/history'

import { connectStore } from 'redux-box'
import { storeModule as CardModule } from 'store/card'

import messages from './messages'

/*
  users can cancel if page is new / not saved
  onCancel: history.goBack()
*/
const CancelModal = ({ open, toggle, onFormChange }) => {
  const intl = useIntl()

  const handleOnConfirm = () => history.goBack();


  const handleOnKeyDown = (e) => {
    if (e.key === 'Enter') handleOnConfirm()
  }

  return (
    <BasicModal
      title={intl.formatMessage(messages.cancelTitle)}
      content={intl.formatMessage(messages.cancelInfo)}
      actions={
        <>
          <BasicButton onClick={toggle} label={intl.formatMessage(messages.cancel)} outlined />
          <BasicButton onClick={() => {
            onFormChange(false);
            handleOnConfirm();
          }} label={intl.formatMessage(messages.confirm)} color="green" />
        </>
      }
      isOpen={open}
      toggle={toggle}
      size="small"
      onKeyDown={handleOnKeyDown}
    />
  )
}

CancelModal.propTypes = {
  appId: PropTypes.string,
  card: PropTypes.object,
  cardId: PropTypes.string,
  open: PropTypes.bool,
  toggle: PropTypes.func,
  onFormChange: PropTypes.func
}

export default connectStore({ card: CardModule })(CancelModal)
