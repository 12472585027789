import PropTypes from 'prop-types'
import { useIntl } from 'react-intl'
import { BasicInfo, BasicInput } from 'components'

import { connectStore } from 'redux-box'
import { storeModule as ModuleModules } from 'store/modules'

import messages from './messages'

const ModuleForm = ({ modules, onChange, options, partIndex, onFormChange }) => {
  const allInputs = modules.getList('input')

  const intl = useIntl()

  return (
    <>
      <BasicInfo text={intl.formatMessage(messages.moduleInfo)} />

      <BasicInput
        type="select"
        name="options.modules.Input"
        value={(options.modules && options.modules.Input) || ''}
        label={intl.formatMessage(messages.inputs)}
        onChange={(e) => {
          onChange(e, partIndex);
          onFormChange(true);
        }}
      >
        <option value="" disabled>
          {intl.formatMessage(messages.select)}
        </option>

        {allInputs.map((input, i) => (
          <option key={i} value={input.id}>
            {input.name}
          </option>
        ))}
      </BasicInput>
    </>
  )
}

ModuleForm.defaultProps = {
  modules: {},
  onChange: () => {},
  options: {},
  partIndex: 0,
}

ModuleForm.propTypes = {
  modules: PropTypes.object,
  onChange: PropTypes.func,
  options: PropTypes.object,
  partIndex: PropTypes.number,
  onFormChange: PropTypes.func,
}

export default connectStore({ modules: ModuleModules })(ModuleForm)
