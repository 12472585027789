import FileManager from './VoxpopFileManager'

const getVoxpopByIdSelector = ({ voxpopList, displayVoxpopId }) =>
  voxpopList.filter((vp) => vp.id === displayVoxpopId)[0]

const getVoxpopFilestoDisplay = ({ voxpopList, displayVoxpopId, displayPage }) => {
  const cVP = getVoxpopByIdSelector({ voxpopList, displayVoxpopId })
  let files = []
  let nbrPages = 0
  const currentPage = displayPage

  if (cVP && cVP.completeFiles && cVP.completeFiles[displayPage]) {
    files = cVP.completeFiles[displayPage].map((file) => new FileManager(file))
    nbrPages = cVP.completeFiles.length
  }
  return { files, currentPage, nbrPages }
}

const getVoxpopById = ({ voxpopList }, voxpopId) =>
  Object.values(voxpopList).find((voxpop) => voxpop.id === voxpopId)

const selectors = {
  voxpopToDisplay: (state) => () => getVoxpopByIdSelector(state),
  getVoxpopById: (state) => (voxpopId) => getVoxpopById(state, voxpopId),
  voxpopFilesToDisplay: (state) => () => getVoxpopFilestoDisplay(state),
}

export default selectors
