import { Fragment } from 'react'
import PropTypes from 'prop-types'
import Pagination from 'components/Pagination'
import File from 'components/File'
import { Navbar, Nav } from 'reactstrap'
import { injectIntl } from 'react-intl'
import { NBR_PHOTOBOOTH_FILES_PER_PAGE } from 'utils/consts'

import SimpleBar from 'simplebar-react'
import 'simplebar/dist/simplebar.min.css'

import messages from './messages'

const FileListNavigator = injectIntl(({ currentPage, nbrPages, changePage, nbrFiles, intl }) => {
  // eslint-disable-next-line no-mixed-operators
  const currentStartFile = currentPage * NBR_PHOTOBOOTH_FILES_PER_PAGE - (NBR_PHOTOBOOTH_FILES_PER_PAGE - 1)
  const currentEndFile =
    currentPage * NBR_PHOTOBOOTH_FILES_PER_PAGE > nbrFiles
      ? nbrFiles
      : currentPage * NBR_PHOTOBOOTH_FILES_PER_PAGE

  return (
    <Fragment>
      <Navbar
        color="light"
        light
        expand="md"
        style={{ overflowX: 'hidden', display: 'flex', flexDirection: 'column' }}
      >
        <SimpleBar style={{ width: '100%' }}>
          <Nav className="ml-auto" navbar>
            <Pagination currentPage={currentPage} nbrPages={nbrPages} changePage={changePage} />
          </Nav>
        </SimpleBar>
        <span style={{ marginTop: '10px', fontSize: '12px', alignSelf: 'flex-start' }}>
          {currentStartFile}&nbsp;{intl.formatMessage(messages.to)}&nbsp;{currentEndFile}&nbsp;
          {intl.formatMessage(messages.of)}&nbsp;{nbrFiles}
        </span>
      </Navbar>
    </Fragment>
  )
})

const FileList = ({ changePage, currentPage, files, nbrPages, totalFiles }) => (
  <Fragment>
    {files && files[currentPage] && (
      <Fragment>
        <FileListNavigator
          currentPage={currentPage + 1}
          nbrPages={nbrPages}
          changePage={changePage}
          nbrFiles={totalFiles}
        />
        {/*
        <Card>
          <CardBody>
            <Table hover>
              <tbody>
                {files && files[currentPage].map((file) => <FileRow file={file} key={file.id} />)}
              </tbody>
            </Table>
          </CardBody>
        </Card>
 */}
        {files && files[currentPage].map((file) => <File file={file} key={file.id} />)}
        <FileListNavigator
          currentPage={currentPage + 1}
          nbrPages={nbrPages}
          changePage={changePage}
          nbrFiles={totalFiles}
        />
      </Fragment>
    )}
  </Fragment>
)

FileList.defaultProps = {
  files: [],
  currentPage: 0,
}

FileList.propTypes = {
  changePage: PropTypes.func,
  files: PropTypes.array,
  currentPage: PropTypes.number,
  nbrPages: PropTypes.number,
  totalFiles: PropTypes.number,
}

export default injectIntl(FileList)
